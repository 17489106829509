export default {
  tab: {
    newTask: 'Video Task',
    taskDetail: 'Task Details',
    publishTask: 'Publish Task',
    strippingDetail: 'Stripping Details',
    editManuscript: 'Edit Manuscript',
    planDetail: 'Plan Details',
    productDetail: 'Product Details',
    materialDetail: 'Material Detail',
    worksDetail: 'Works Detail',
    tiktokAccountDetail: 'Account Details',
    createProduct: 'Create Product',
    orderDetail: 'Order Details',
    aiDetail: 'AI Details',
    createModel: 'Create Model',
    modelDetail: 'Model Details',
    createSale: 'Create Sale',
    saleDetail: 'Sale Details',
  },
  newRegister: {
    account: 'Account Name',
    againPassword: 'Re-enter password',
    emailOrPhone: 'Email/Phone Number',
    tipAccount: 'Please enter your account number',
    passwordMismatch: 'The passwords you entered twice do not match',
    getEmailOrPhoneCode: 'Get verification code',
    verificationCode: 'Email/mobile phone verification code',
    tipCorrectEmailOrPhone: 'Please enter your email or phone number in the correct format',
    registerUser: 'User Registration',
    alreadyRegistered: 'Already have an account?',
    login: 'Login',
    setUsername: 'Please set a username',
    setPassword: 'Set a password',
    inputEmailOrPhone: 'Enter email/phone',
    inputCode: 'Enter verification code',
  },
  //登录页面
  login: {
    smartPublishSystem: 'Smart Creation and Publishing Management System',
    oneClickPublish: 'One-click Publish to Multiple Platforms',
    title: 'RuoYi Login Form',
    logIn: 'Login in',
    logIng: 'Logging in...',
    username: 'Account name/email address/phone number',
    password: 'Password',
    code: 'Code',
    rememberMe: 'Remember Me',

    accountLogin: 'Account login',
    emailLogin: 'Email login',
    mobileLogin: 'Mobile login',

    email: 'Email',
    mobile: 'Mobile',
    imageVerificationCode: 'Please enter the image verification code.',
    verificationCode: 'Please enter the verification code',
    noAccount: 'Don\'t have an account yet?',
    signUp: 'sign up right away',
    quickSignUp: 'Quick Registration',
    getEmailCode: 'Get the email verification code',
    getSMSCode: 'Obtain verification code',
    later: ' seconds later, retrieve again.',

    tipEmail: 'Please enter your email address or phone number',
    tipCorrectEmail: 'Please enter the correct email address',
    tipPhone: 'Please enter your phone number',
    tipCorrectPhone: 'Please enter the correct mobile number',
    tipAccount: 'Please enter your account number',
    tipPassword: 'please enter your password',
    tipCode: 'Please enter the verification code',
  },
  loginNew: {
    inputAccount: 'Please enter your account/username/email',
    inputPassword: 'Please enter your password',
    inputCaptcha: 'Please enter the captcha',
    inputPhone: 'Please enter your phone number',
    registerAccount: 'Register Account',
    forgotPassword: 'Forgot Password?',
  },
  //快速注册
  quickSignUp: {
    registrationSuccess: "Registration successful",
    rememberCredentials: "Please remember your username and password for the next login.",
    accountLabel: "<br>Account:",
    passwordLabel: "<br>Password:",
    autoFill: 'AutoFill',
  },
  register: {
    register: 'Register',
    completeInformation: 'Complete Information',
    basicInfo: 'Basic Information',
    setupAccount: 'Configure Account',
    companyName: 'Company Name',
    industry: 'Industry',
    brand: 'Brand',
    brandTip: 'Separate multiple brands with commas',
    product: 'Product',
    productTip: 'Separate multiple products with commas',
    next: 'Next',
    previous: 'Previous',
    registerType: 'Registration Type',
    emailRegister: 'Email Registration',
    phoneRegister: 'Phone Registration',
    password: 'Password',
    passwordHint: 'Please enter a password of 8 characters or more, including uppercase, lowercase, and numbers',
    imageCaptcha: 'Image Captcha',
    enterImageCaptcha: 'Please enter the image captcha',
    verificationCode: 'Verification Code',
    enterCode: 'Please enter the verification code',
    submitRegister: 'Register',
    registering: 'Registering...',
    useExistingAccount: 'Use existing account to log in',
    enterCompanyName: 'Please enter your company name',
    selectIndustry: 'Please select an industry',
    enterBrandName: 'Please enter the brand name',
    enterProduct: 'Please enter the main product of the company',
    passwordEmpty: 'Password cannot be empty',
    passwordCriteria: 'Password must be 8-18 characters long and include uppercase, lowercase, numbers, and special characters',
    enterVerificationCode: 'Please enter the verification code',
    enterImageCaptchaAgain: 'Please enter the image captcha',
    acceptTerms: 'Please check the user service agreement',
    registrationReceived: 'We have received your registration information. It will be reviewed within 24 hours, and you will be notified by email or SMS. Please check your messages.',
    systemPrompt: 'System Prompt',
  },
  forget: {
    forgotPassword: 'Forgot Password',
    resetType: 'Select Account Reset Type',
    resetByEmail: 'Reset by Email',
    resetByPhone: 'Reset by Phone',
    enterNewPassword: 'Please enter a new password',
    resetPassword: 'Reset Password',
    resetting: 'Resetting...',
    backToLogin: 'Return to Login',
    resetSuccess: 'Congratulations, your password has been reset successfully',
    retrievePassword: 'Retrieve Password',
    inputEmailPhone: 'Enter Email/Phone Number',
    setNewPassword: 'Set New Password',
    inputVerificationCode: 'Enter Verification Code',
  },
  agreed: {
    readAndAgree: "I have read and agree to the",
    userAgreement: "User Agreement",
    and: "and",
    privacyPolicy: "Privacy Policy",
    prompt: "Please read and agree to the User Agreement and Privacy Policy first."
  },
  menus: {
    workbench: "Home Page",
    personalCenter: "Personal Center",
    assignRoles: "Assign Roles",
    assignUser: "Assign User",
    dictionaryData: "Dictionary Data",
    dispatchingLog: "Dispatching Log",
    modifyConfig: "Modify build configuration",
    moreMenus: 'More Menus',
  },
  //工作台
  workbench: {
    homePage: 'Home',                                       // 首页
    clickToEnter: 'Click to Enter',                         // 点击进入
    accountManagement: 'Account Management',                // 账号管理
    accountManagementTitle: 'One-click addition, multi-account management', // 一键添加，多账号管理
    accountManagementDes: 'Collaborate across all accounts for intelligent management of data and content', // 全域账号协同，实现数据与内容的智能化管理
    materia: 'Material Library',                            // 素材库
    materiaTitle: 'Clear and concise material categorization', // 素材分类管理清晰明了
    materiaDes: 'Build a multi-dimensional material library ecosystem to inspire creativity', // 构建多维度素材库生态, 激发创作灵感
    videoProduction: 'Video Editing',                       // 视频混剪
    videoProductionTitle: 'Simple and smooth video editing', // 视频剪辑简单轻松、快捷流畅
    videoProductionDes: 'Reshape the video creation process from script generation to intelligent editing', // 重塑视频创作流程, 从脚本生成到智能剪辑
    releaseManagement: 'Release Management',                // 发布管理
    releaseManagementTitle: 'Quick, targeted, scheduled releases', // 定时定向快捷发布
    releaseManagementDes: 'Break geographical and platform boundaries to achieve precise, multi-dimensional content distribution strategies', // 突破地域与平台界限, 实现精准定时、多维度的内容传播策略
    AITitle: 'AI Assistance, automated and worry-free',     // AI助力，自动完成、省心省力
    AIDes: 'From concept to the presentation of the work, seamlessly done', // 从创意萌芽到作品呈现, 一气呵成
    welcome: 'Hi, welcome to InfoPub',                     // Hi,欢迎登录InfoPub
    startVideo: 'Quick video editing, start AI creation now', // 视频快速混剪，开始AI创作吧
    accountCount: 'Number of Accounts',                     // 账号数量
    newAccount: 'Authorize New Account',                    // 授权新账号
    fanCount: 'Fans Count',                                 // 粉丝数
    workCount: 'Works Count',                               // 作品数
    playCount: 'Play Count',                                // 播放数
    commentCount: 'Comment Count',                          // 评论数
    publishTask: 'Publish Task',                            // 发布任务
    account: 'Account',                                     // 账号
    fans: 'Fans',                                           // 粉丝
    likes: 'Likes',                                         // 点赞
    posts: 'Posts',                                         // 发帖
    publishing: 'Publishing',                               // 发布中
    publishSuccess: 'Publish Success',                      // 发布成功
    publishFail: 'Publish Failed',                          // 发布失败
    videoCreation: 'Video Creation',                        // 视频创作
    videoMix: 'Video Editing',                              // 视频混剪
    generating: 'Generating',                               // 生成中
    generateFail: 'Generate Failed',                        // 生成失败
    generateSuccess: 'Generate Success',                    // 生成成功
    AISales: 'AI Sales Performance',                        // AI销售成效
    viewMore: 'View More',                                  // 查看更多
    commentsCount1: '',                           // 已评论xx条
    commentsCount2: ' Comments',                           // 已评论xx条
    announcement: 'Announcement',                           // 公告
    helpDoc: 'Help Documents',                              // 帮助文档
    productSummary: 'Product Summary',                      // 产品概括
    userGuide: 'User Guide',                                // 使用指南
    planDescription: 'Plan Description',                    // 套餐说明
    productNews: 'Product News',                            // 产品资讯
    events: 'Events',                                       // 活动
    messages: 'Messages',                                   // 消息
    notifications: 'Notifications',                          // 通知
    modelTraining: 'Model Training',
    modeLDes: 'Model training, precision forging, so that data-driven wisdom upgrade',
    notOpen: 'Not yet open, stay tuned.',
    communityList: 'Community list',

    videoByText: 'Text-to-Video',
    videoByTextTip: 'Text to video, one click',
    AIVideo: 'AI Video',
    AIVideoTip: 'AI fast cut, easy to make',
    videoTranslation: 'Video Translation',
    videoTranslationTip: 'Quick video understanding, translation support',
    operationsStrategy: 'Operations Strategy',
    operationsStrategyTip: 'Operations with plans, strategy first',
    releaseCommunity: 'Release Community',
    releaseCommunityTip: 'Smooth release, accessible everywhere',
    messageInteraction: 'Message Interaction',
    messageInteractionTip: 'Instant messages, notifications anytime',
  },
  //账号管理
  account: {
    accountBinding: 'Social media account add (TikTok、Instagram、YouTube and so on)',
    tiktokBinding: 'Click Bind TikTok account',
    bindAccount: 'Bind account',
    addAccount: 'Add account',
    account: 'Account',
    tipAccount: 'Please enter your username, account or nickname',
    bindHomePage: 'Account',
    username: 'Username',
    user: 'User',
    nickname: 'Nickname',
    role: 'Role',
    sales: 'Sales',
    operations: 'Operations',
    marketing: 'Marketing',
    tipRole: 'You can select it or enter it directly',
    description: 'Remark',
    countryAndLanguage: 'Country and language',
    tipSetDefault: 'Click Set default country and language',
    bindingState: 'Binding state',
    bindingSource: 'Platform',
    unbundle: 'Unbundle',
    set: 'Setting',
    default: 'Default',
    setDefault: 'Set the default country and language',
    language: 'Default Language',
    country: 'Country and time zone',
    tipCountry: 'Please select a country',
    tipLanguage: 'Please select language',
    tipTimezone: 'Please select time zone',
    successfully: 'Unbind successfully',
    tipUnbind: 'Are you sure to unbind the currently selected account?',
    tipUnbind1: 'Are you sure to unbind "',
    tipUnbind2: '" account? You will not be able to post once you are unbound.',
    tipDelete: 'Are you sure to delete this user and all account data under this user?',
    tipDeleteSuccessfully: 'Delete successfully',
    settingsSaved: 'Settings saved',
    bindTime: 'Binding time',
    bindPlatform: 'Binding platform',
    tipBindPlatform: 'Please select the binding platform',
    updateTime: 'Time of last operation',
    accountDetails: 'Account details',
    repeatTip: 'It is detected that your account is repeatedly bound. You need to manually unbind it to ensure that an account is bound only once.',
    accountQuota: 'User quota',
    number: 'No.',
    exceedTip: 'The user quota has been exceeded. Select an existing user to bind',
    notData: 'No account data is available',
    goBind: 'Go Bind',
  },
  //配额
  quota: {
    minute: 'minute',
  },
  //素材库
  material: {
    all: 'All',
    position: 'Position',
    formRulesNameLength: 'The file name cannot be greater than 20',
    formRulesDescribeLength: 'The file describe\n cannot be greater than 20',
    video: 'Video',
    picture: 'Picture',
    audio: 'Music',
    copywriting: 'Text',
    tipName: 'Material name or description',
    upload: 'Upload material',
    edit: 'Edit material',
    tipSelect: 'Please select a material file',
    tipDelete: 'Are you sure to delete this material?',
    removeSubtitle: "Remove subtitles from video",
    aiFaceSwap: "AI face swap",
    isStripped: "Strip Slice or Not",
    provideFaceSwapMaterial: "Need to provide material for face swap",
    retainOriginalVideo: "Retain the original video",
    tipRemoveSubtitle: "Currently, video upload supports slicing,  subtitling and AI face change functions, of which the subtitling function only supports mp4 format.",
    tipVideo: 'Please select the material first.',
    timeLimit: 'The selected material duration does not meet the requirements of the scenario.',
    exceedQuota: 'The quota has been exceeded.',
    commonMaterial: 'Free',
    materialFile: 'Material file',
    addMaterial: 'Add material',
  },
  //文案
  copywriting: {
    tipName: 'Text name or content',
    new: 'New text',
    edit: 'Edit text',
    view: 'View text',
    title: 'Text title',
    content: 'Text content',
    tipTitle: 'Please enter the text title',
    tipContent: 'Please enter the text content',
    tipDelete: 'Are you sure to delete the text?',
  },
  //文件
  file: {
    file: 'file',
    upload: 'Upload',
    download: 'Download',
    name: 'file name',
    fileSize: "File Size",
    description: 'file description',
    tipSelect: 'Please select file',
    tipName: 'Please enter a file name',
    attachment: 'Attachment',
    clickUpload: 'Click to upload',
    chooseUpload: 'Select a file or folder',
    pleaseUpload: 'Please upload',
    sizeLimit: 'Size must not exceed ',
    formatLimit: 'Format must be ',
    dFile: 'files',
    filePreview: 'Preview',
    invalidFormat1: 'Invalid file format, please upload a file in ',
    invalidFormat2: ' format',
    sizeExceed: 'File size must not exceed ',
    quantityExceed: 'Number of files must not exceed ',
    uploading: 'Uploading, please wait...',
    uploadFailed: 'File upload failed, please try again',
    createFolder: "Create Folder",
    folder: 'Folder',
    addedTo: 'Added To ',
    tipSelectFolder: 'Please select Folder',
    tipDeleteFolderSingle: 'Are you sure to delete the folder and its files?',
    editFolder: 'Edit Folder',
    goBack: "Go Back",
    goBackMaterial: 'Material Library',
    goBackWorks: 'Work Library',
    folderName: "Folder Name",
    folderNameTip: 'Please enter a folder name',
    folderLabel: "Folder Label",
    folderPath: 'Folder Path',
    selectMaterial: 'Select Material',
    selectProduct: 'Select works',
    localUpload: 'Local Upload',
    tipDeleteSystemFolder: 'The file you selected contains a folder. Do you want to delete the folder and all files in it?',
    tipDeleteFolder: 'Do you want to delete the selected file?',
    typeTip: 'The uploaded file type does not meet the requirements',
    sizeTip100: 'The uploaded file size cannot exceed 100MB',
    num3Tip1: 'You can select up to 3 files. You selected ',
    num3Tip2: ' files this time, with ',
    num3Tip3: ' files selected in total',
    format: 'Format',
    imageFormatError: 'Image format error',
    imageInsertionError: 'Image insertion failure',
    closeSearch: 'Close search',
    searchResult: 'Search result',
  },
  //标签
  label: {
    formRulesLabelLength: 'The label cannot be greater than 20',
    label: 'label',
    hintLabel: 'Please enter labels',
    tipLabel: 'The label cannot be empty',
    add: 'Add label',
    edit: 'Edit label',
    tipSelect: 'Please select label',
    tipDelete: 'Are you sure to delete this label?',
  },
  //作品管理:
  product: {
    product: 'Works library',
    video: 'Works',
    tipName: 'Works name or description',
    tipDelete: 'Are you sure you want to delete this works?',
    tipVideo: 'Please select your work first.',
  },
  // AI Video
  ai: {
    headerTitleTip: 'Currently supported languages are: Chinese, English, French, German, Thai, Spanish, Italian, Russian, Korean, Vietnamese, Japanese, Indonesian, Malay, Filipino, Cantonese',
    headerTitle: 'AI Video Production',
    video: 'AI Video',
    doing: 'AI Creation in Progress',
    workHard: 'AI is working hard, input is not supported for now',
    tipInstruct: 'Please enter instructions, including: video theme, language, target country, video duration',
    tipSystem: "Please enter the video theme, video duration, country, video language. For example: 'Please help me make a video introducing the Light brand flashlight, about 30 seconds, Chinese, for the Chinese market'",
    send: 'Send',
    title: 'Title',
    content: 'Content',
    sure: 'Sure',
    scriptSuccess: 'AI Script Generation Successful.',
    scriptSuccessGo: 'AI Script Generation Successful. Please review the script and click to edit.',
    scriptFailed: 'Script Generation Failed, Please try again.',
    tipTitle: 'Please enter a title',
    tipContent: 'Please enter the content',
    videoing: 'Video generation in progress, please wait',
    videoFailed: 'Video Generation Failed',
    videoSuccess: 'AI Video Creation Successful.',
    videoAgain: "Regenerate",
    themeAgain: 'Requestion',
    videoChoose: "Since you have generated multiple videos, please select the one you want to publish:",
    videoChooseFirst: 'Please select the material first.',
    socialChoose: 'Please select a social account:',
    socialChooseFirst: 'Please select a social account first',
    socialChoosed: 'Selected social account:',
    noSocial: 'No social account found. Please bind a social account in account management first.',
    contentFailed: 'Content Generation Failed',
    contenting: 'Generating description text',
    contentSuccessGo: 'Description text generated successfully. Click to edit.',
    contentSuccess: 'Description text generated successfully.',
    publishing: 'Video successfully added to the publishing queue. You can check it later in the publishing management.',
    publishSuccess: 'Video published successfully. You can check it in the "Publish".',
    publishFailed: 'Video Publishing Failed',
    aiWaitMsg: "During AI creation, if the waiting time is too long, you can go and",
    otherTasksMsg: "perform other tasks in",
    setBasicInfo: "Before using the AI video feature, you need to set up the basic information. Please set it first.",
    goToSettings: "Go to Settings",
    defaultTitle1: 'No AI content has been generated yet',
    defaultTitle2: 'You can try to enter information in the input box below to generate creative videos for you AI, come and use it~',
  },
  translate: {
    inProgress: 'Translating...',
    success: 'Translation Successful',
    failure: 'Translation Failed',
  },
  chat: {
    end: 'End Recording',
    start: 'Start Recording',
    cancel: 'Cancel Recording',
    micPermission: 'Please allow microphone access.',
    noMic: 'No microphone detected.',
    micError: 'Recording failed to start. Please check microphone permissions or browser settings.',
    toText: 'Convert to Text',
    textTitle: 'Text Title',
    textContent: 'Text Content',
  },
  selectAccount: {
    choose: 'Select Social Account',
    searchPlaceholder: 'Enter the account to search',
    allPlatforms: 'All Platforms',
    selectedAccounts: 'Selected Accounts',
  },
  // AI Settings
  aiSet: {
    set: 'Settings',
    color: 'Background Color',
    tipColor: 'Please select a background color',
    musicVolume: 'Background Music Volume',
    tipVolume: 'Please set the background music volume',
    speechVoice: 'Video Voice',
    tipVoice: 'Please select a video voice',
    location: 'Storage Location',
    tipLocation: 'Please select a storage location',
    save: 'Save Settings',
    success: 'Settings Saved Successfully',
  },
  // Video Editing
  videoEditor: {
    addScene: 'Add Scene',
    deleteScene: 'Delete Scene',
    scene: 'Scene-',
    draft: 'Save Draft',
    draftFailed: 'Draft Save Failed',
    preview: 'Preview',
    previewResult: 'Preview Mixed Result',
    info: 'Basic Information',
    tipInfo: 'Please complete the basic information first',
    tipInfoMust: 'Please complete the required information first',
    videoName: 'Video Name',
    videoRatio: 'Video Ratio',
    tipVideoName: 'Please enter the video name',
    tipNameMust: 'Video name is required',
    configureInfo: "Configure materials, scripts, duration, and other information for each shot group",
    selectModel: "Select Model",
    keywordDescription: "Keyword Description",
    effectPreview: "Effect Preview",
    viewConfigEffect: "View Configuration Effect",
    generateVideo: "Generate Video",
    videoSettings: "Video Settings",
    createVideoName: "Create a name for the entire video task",
    materialScene: "Material Scene",
    splitScene: "Split Scene",
    videoMixing: "Video Mixing",
    lastSceneWarning: "This is the last scene, cannot be deleted"
  },
  videoSetting: {
    videoParams: 'Configure parameters and properties of video files',          // 对视频文件的参数和属性进行配置
    videoNameLocation: 'Set the video name and storage location',               // 配置视频的名称和存储位置
    visualExperience: 'Create a unique visual experience',                      // 创造独特的视觉体验
    visualElements: 'Build various elements and backgrounds of the visual environment', // 构建视觉环境的各种元素和背景
    segmentAllocation: 'Assign materials, scripts, and duration settings for each video segment', // 为各视频片段分配素材、文案和时长设置
    subtitleTitleEffects: 'Configure subtitle and title effects',               // 配置字幕和标题效果
  },
  basicInfo: {
    sceneSettings: "Scene Settings",
    configureInfo: "Configure materials, scripts, duration, and other information for each shot group",
    scene: 'Scene',
    sceneName: 'Scene Name',
    tipSceneName: 'Please enter a scene name',
    import: 'Import Material',
    tipImport: 'Please import material',
    materialImport: 'Import from Material Library',
    remove: 'Remove',
    isMute: 'Mute Original Sound',
    materia: 'Material Library',
    scene1: 'Scene-1',
    pruning: 'Rough Cut',
    pruningTitle: 'Video Rough Cut',
    mark: 'Mark',
    segment: 'Video Slicing',
    selectSegments: 'Please select the video segments to use',
    startPoint: 'Current position is the start point, cannot segment',
    endPoint: 'Current position is the end point, cannot segment',
    existingMark: 'There is already a mark at the current position',
    minSelect: 'Select at least one video segment to use',
    timeType: "Duration Setting",
    timeVideo: "Original Video Duration",
    timeVideoExp: "According to the original video duration",
    timeAudio: "Smart video clipping based on audio",
    timeAudioExp: "Shot duration = audio duration; video clips are taken to match this duration",
    timeRegular: "Fixed Duration",
    timeRegularExp: "Scene duration = fixed duration; both video and audio clips are taken to match this duration",
    tipDuration: 'Please enter the correct duration',
    previewNotSupported: "Preview is not supported in this mode, but it does not affect video generation",
    audioDurationType: 'Audio mode does not support preview, please directly generate video',
  },
  subtitle: {
    subtitle: 'Subtitles & Voiceover',
    open: 'Open',
    close: 'Close',
    content: 'Subtitle Text',
    tipContent: 'Please enter subtitle text',
    tipContentFirst: 'Please enter subtitle text first',
    font: 'Subtitle Font',
    tipFont: 'Please select a font',
    fontSizeType: 'Subtitle Font Size',
    tipFontSizeType: 'Please select a font size',
    small: 'Small',
    normal: 'Medium',
    big: 'Large',
    spacing: 'Character Spacing',
    fontColor: 'Subtitle Color',
    tipFontColor: 'Please select a font color',
    outline: 'Outline Width',
    outlineColour: 'Outline Color',
    position: 'Subtitle Position',
    top: 'Top',
    center: 'Center',
    bottom: 'Bottom',
    fontFaces: 'Subtitle Styles',
    italic: 'Italic',
    blod: 'Bold',
    underline: 'Underline',
    dubbingSettings: 'Dubbing Settings',
    language: 'Language',
    tipLanguage: 'Please select a Language',
    tipLanguageFirst: 'Please select the language first',
    voice: 'Voiceover Tone',
    tipVoice: 'Please select a tone',
    gain: 'Voiceover Volume',
    preview: 'Real-time Preview',
    effectColorStyle: 'effectColorStyle',
    translate: 'Translate',
    sumup: 'AI Generation',
    abbreviation: 'AI Abbreviation',
    expand: 'AI Expand',
    rewrite: 'AI Rewrite',
    calculate: 'Calculate the broadcast duration',
    tipCalculate1: 'Estimated ',
    tipCalculate2: ' seconds, within the video duration, can be used normally',
    tipCalculate3: ' seconds, may exceed the video duration',
    fitTip: 'The language of the subtitle text must be consistent with that of the subtitle language; otherwise, the video cannot be generated correctly',
  },
  title: {
    title: 'Title',
    show: 'Show Title',
    hide: 'Hide Title',
    tipTitle: 'Please enter the title',
    font: 'Font',
    tipFont: 'Please select a font',
    fontSizeType: 'Font Size',
    tipFontSizeType: 'Please select a font size',
    small: 'Small',
    normal: 'Medium',
    big: 'Large',
    spacing: 'Letter Spacing',
    fontColor: 'Font Color',
    tipFontColor: 'Please select a font color',
    outline: 'Outline Width',
    outlineColour: 'Outline Color',
    alignment: 'Alignment',
    left: 'Left',
    center: 'Center',
    right: 'Right',
    opacity: 'Opacity',
    fontFaces: 'Styles',
    italic: 'Italic',
    blod: 'Bold',
    underline: 'Underline',
    preview: 'Real-time Preview',
    effectColorStyle: 'effectColorStyle',
  },
  mixing: {
    mixSet: 'Mix Settings',
    position: 'Logo Position',
    leftTop: 'Top Left',
    centerTop: 'Top Center',
    rightTop: 'Top Right',
    leftBottom: 'Bottom Left',
    centerBottom: 'Bottom Center',
    rightBottom: 'Bottom Right',
    cover: 'Cover',
    backCover: 'Back Cover',
    music: 'Background Music',
    upload: 'Click to Upload',
    bgColor: 'Background Color',
    filter: 'Filter',
    filterType: 'Matching Mode',
    filterRandom: 'Randomly apply a filter to each scene group',
    filterSame: 'Apply the same filter to all scenes',
    transition: 'Transition',
    transitionType: 'Matching Mode',
    transitionRandom: 'Randomly apply a transition to each scene group',
    transitionSame: 'Apply the same transition to all scenes',
    materia: 'Material Library',
  },
  // Video Management
  videoManager: {
    type: 'Type',
    status: 'Generation Status',
    newTask: 'New Task',
    name: 'Video Name',
    tipName: 'Please enter a video name',
    length: 'Total Video Duration',
    statusDraft: 'Draft',
    publishStatus: 'Publish Status',
    publishTime: 'Publish Time',
    publish: 'Publish',
    playVideo: 'View Video',
    aiPage: 'Details',
    tipDelete: 'Are you sure to delete this data?',
    videoContent: 'Video Content',
  },
  videoItem: {
    clipDetails: "Slice Details",
    clipSuccess: "Slice Success",
    generating: "Generating",
    removeSubtitleSuccess: "Subtitle Removal Success",
    removeSubtitleFail: "Subtitle Removal Fail",
    aiFaceSwapSuccess: "AI Face Swap Success",
    aiFaceSwapFail: "AI Face Swap Fail",
    clipping: "Slicing",
    clipCompleted: "Slice Completed",
    clipFail: "Slice Fail"
  },
  stripping: {
    basicInfo: "Basic Information",
    name: "Name",
    description: "Description",
    tags: "Tags",
    fileSize: "File Size",
    videoDuration: "Video Duration",
    addedTime: "Added Time",
    clipInfo: "Slice Information",
    videoClips: "Video Slice",
    videoTags: "Video Tags",
    videoDescription: "Video Description"
  },
  // Video Preview
  preview: {
    result: 'Mixed Result Preview',
    make: 'Generate Video',
    preview: 'Preview',
    videoPreview: 'Video Preview',
    tipVideo: 'Please select a video',
    tipSuccess: 'Video generation task successful',
    tipFailed: 'Video preview loading failed',
  },
  // Issue Management
  issueManager: {
    searchTip: 'Please enter a post, username, or social media account',
    post: 'Post',
    platform: 'Publishing Platform',
    account: 'Platform Account',
    type: 'Publishing Type',
    status: 'Publishing Status',
    time: 'Publishing Time',
    issue: 'Publish Task',
    taskName: 'Remark',
    social: 'Social Account',
    content: 'Post',
    statusDraft: 'Draft',
    playVideo: 'View Video',
    playResult: 'View the results',
    publishContent: 'Publishing Content',
    notUploaded: 'Not uploaded yet',
    publishing: 'Publishing',
    published: 'Published',
    failed: 'Failed',
    result: 'Result',
    publish: 'Publish',
    unpublished: 'Unpublished',
  },
  issueVideo: {
    taskName: 'Remark',
    tipTaskName: 'Please enter the task name',
    add: 'Add Publish',
    saveDraft: 'Save Draft',
    issue: 'Publish',
    saveSuccess: 'Save Successful',
    tipInfoMust: 'Please complete the required information first',
    addSuccess: 'Task created successfully',
    tipDeleteSure: "Are you sure you want to delete this post?",
    tipDelete: "This is the last post, deletion is not allowed"
  },
  issueItem: {
    account: 'Publishing Account',
    tipAccount: 'Please select a publishing account',
    post: 'Text Edit',
    tipPost: 'Please enter the text',
    tipPostFirst: 'Please enter the text first',
    tipPostLength: 'The length of the Twitter platform copy cannot exceed 280',
    topic: 'Topic',
    emo: 'Emoticons',
    translate: 'Translate',
    oneTranslate: 'One-click translation',
    tipTranslate: 'Please select the language to translate into',
    translateTo: 'Translate text content to:',
    translateLanguage: 'Language to translate into',
    selectVideo: 'Select Video',
    tipVideo: 'Please select a video',
    import: 'Import from Works',
    remove: 'Remove',
    paly: 'Play',
    publishTime: 'Publishing Time',
    publishType: 'Publishing Type',
    tipTimeType: 'Please select the type of publishing time',
    sameTime: 'Uniform time',
    customTime: 'Custom time',
    issueNow: 'Publish Now',
    issueCountry: 'Publish by account country setting (local time)',
    issueUnify: 'Publish at unified time',
    localTime: 'According to the local time of the account',
    standardTime: 'Universal standard time',
    tipDate: 'Select date and time',
    tipTimezone: 'Please select a timezone',
    product: 'Finished Library',
    tipIssueTime: 'Please select a publishing time',
    tipIssueTimezone: 'Please select a publishing timezone',
    tipIssueTimeAll: 'Please complete the release date',
    tipSet: 'Note: You need to set the default country and language for the corresponding account in advance',
    tipSocialTime: 'Note：Please select publish account first',
    tipSetNo: 'The current account has not set a default country and language. Please set it in account management or change to another publishing time.',
    tipBdCode: 'No country or language is set for the currently selected account, so it cannot be translated into the corresponding language with one click. You can use the ordinary translation function to manually select the language to be translated.',
    tipNotFind: 'The currently selected account is not found in Account management, please select another account to publish.',
    reset: 'Copy reset',
    tipOneTranslate: 'Tip: For the copy of one-click translation, if the published account and the copy are modified, you need to click one-click translation again, otherwise there may be no corresponding situation.',
  },
  infoStream: {
    tipKeyword: 'Please enter keywords',
  },
  aiCrosscutting: {
    newTask: 'Video Task',
    templateGeneration: "Template Generation",
    customGeneration: "Free Generation",
    customScript: "Custom Script",
    scriptCopy: 'Script Copy',
    resetContent: 'Reset content',
    viewMore: "View More",
    inputPrompt: "Describe what you want to write",
    enterKeyword: "Enter keywords or topic content",
    keywordPrompt: "Enter keywords or topic content, AI will help generate quality scripts.",
    contentLength: "Content Length",
    short: "Short",
    medium: "Medium",
    long: "Long",
    generationCount: "Generation Quantity",
    maxGeneration: "Can generate up to 5 items",
    seoIntegration: "Label Integration",
    golden3sStart: "Golden 3-second Start",
    generateContent: "AI Generate",
    scriptPreview: "Build preview",
    parseHint: "Select generated content to proceed to content analysis, which can be manually modified after analysis. A maximum of 5 articles can be selected at a time.",
    titleExtraction: "Extract Title",
    scriptSupplement: "Script Supplement",
    scriptPolish: "Polish Script",
    scriptSimplify: "Simplify Script",
    keywordInputHint: "Creative copy not generated yet",
    uniqueContent: "Enter information in the left panel to generate graphic ideas for you",
    analyze: "Analyze",
    script: "Script",
    addScript: "Add Script",
    title: "Title",
    enterTitle: "Enter Title",
    scriptContent: "Script Content",
    enterScriptContent: "Enter Script Content",
    contentRewrite: "Content Rewrite",
    deleteScript: "Delete Current Script",
    selectTemp: 'Select Template',
    selectMaterial: 'Obtain from the material library',
    selectTemplate: "Please Select a Template",
    enterScriptFirst: "Please enter the script first",
    generateScriptFirst: "Please generate the script first",
    selectAtLeastOneScript: "Please select at least one script",
    selectUpTo5Script: 'Select up to 5 scripts at a time',
  },
  template: {
    apply: "Apply",
    scriptTemplate: "Script Template",
    applyTemplate: "Apply this Template",
    changeTemplate: "Change Template",
    moreTemplates: "More Templates",
    simplified: "Simplified Version",
    enriched: "Enriched Version"
  },
  goldenTypes: {
    curiosity: "Curiosity Type",
    leverage: "Leverage Type",
    painPoint: "Pain Point Type",
    extreme: "Extreme Type",
    fear: "Fear Type",
    contrast: "Contrast Type",
    benefitTransfer: "Benefit Transfer",
    empathy: "Empathy"
  },
  editManuscripts: {
    script: "Script",
    title: "Title",
    enterTitle: "Enter Title",
    scriptSegmentation: "Script Slicing",
    addLanguage: 'Add language',
    tipAddLanguage: 'Support up to 3 languages at a time',
    grouping: "Grouping",
    nextStep: "Next Step",
    material: 'Material',
  },
  editVideo: {
    video: "Video",
    generateVideo: "Generate Video",
    title: "Title",
    enterTitle: "Enter Title",
    dataError: "Data error, please modify the parameters and try again",
    transitionSetup: "Transition Settings",
    preview: "Preview",
    selectAudio: "Please select music first",
    autoGenerate: "Auto Generate",
    replaceClip: 'Alternate material',
    musicSelect: 'Selected Music',
    musicVolume: 'Music Volume',
    timbreTip: 'The current tone selection is only valid for Chinese, other languages have been automatically adapted',
  },
  videoMenu: {
    scenes: "Scenes",
    music: "Music",
    voiceOver: "Voiceover",
    subtitles: "Subtitles",
    text: "Title",
    transitions: "Transitions",
    setting: 'Setting',
  },
  aiSubtitle: {
    setup: "Subtitle Settings",
    show: "Show Subtitles",
    hide: "Hide Subtitles",
    font: "Font",
    textColor: "Text Color",
    fontSize: "Font Size",
    style: "Style",
    position: "Position"
  },
  aiTitle: {
    setup: "Title Settings",
    textContent: "Text Content",
    font: "Font",
    fontSize: "Font Size",
    letterSpacing: "Letter Spacing",
    fontColor: "Font Color",
    strokeWidth: "Stroke Width",
    strokeColor: "Stroke Color",
    position: "Position",
    opacity: "Opacity",
    style: "Style",
    decorativeText: "Decorative Text"
  },
  //运营策略
  operation: {
    planName: "Plan Name",
    enterPlanName: "Please enter the plan name",
    timePeriod: "Time Period",
    videoTopicCount: "Number of Video Topics",
    videoTopicRange: "The number of video topics should be at least 1 and at most 100. It is recommended to keep the daily video release count between 1-4.",
    releaseMethod: "Video Release Method",
    distributeEvenly: "Distribute Evenly Over Time",
    aiAssign: "AI Autonomous Allocation",
    userProfile: "User Profile",
    enterUserProfile: "Please enter the user profile",
    planLanguage: "Plan Language",
    socialAccount: "Social Account",
    accountSupport: "Supports batch selection of accounts in the same time zone and language.",
    targetDetails: "Target Industry:\nTarget Company Size:\nTarget Country:\nMain Product Keywords:",
    nameRequired: "Plan name cannot be empty",
    selectTimePeriod: "Please select a time period",
    videoTopicRequired: "Number of video topics cannot be empty",
    selectReleaseMethod: "Please select a video release method",
    userProfileRequired: "User profile cannot be empty",
    selectPlanLanguage: "Please select the plan language",
    selectSocialAccount: "Please select a social account",
    selectExistAccount: 'Please select a currently available social account',
    selectTimeZoneAccount: "Please select a social account in the same time zone",
    releaseTime: "Release Time",
    state: 'Release Status',
    selectReleaseTime: "Please select the release time",
    videoTopic: "Video Topic",
    videoTopicRequired: "Video topic cannot be empty",
    selectVideo: "Please select a video",
    topicCount: "Topic Count",
    executionStatus: "Execution Status",
    completed: "Completed",
    failed: "Failed",
    generationStatus: "Generation Status",
    planTime: "Plan Time",
    executionSuccess: "Execution Successful",
    closeSuccess: "Close Successful",
    addPlan: "Add Operation Plan",
    editPlan: "Edit Operation Plan",
    confirmDeletePlan: "Are you sure you want to delete the selected operation plan?",
    topic: "Topic",
    video: "Video",
    createVideoTopic: "Create Video Topic",
    editVideoTopic: "Edit Video Topic",
    confirmDelete: "Are you sure you want to delete the selected plan?"
  },
  operationState: {
    notStarted: 'Not Started',
    inProgress: 'In Progress',
    completed: 'Completed',
    failed: 'Execution Failed',
    starting: 'Starting',
  },
  //商城相关
  mall: {
    tiktokZone: "Account Zone",
    materialZone: "Material Zone",
    accountType: "Account Type",
    materialType: "Material Type",
    accountTypeRequired: "Please select an account type",
    businessDirection: "Business Direction",
    businessDirectionRequired: "Please select a business direction",
    price: "Price",
    priceMin: "Minimum Price",
    priceMax: "Top Price",
    enterPrice: "Please enter the price",
    baseInfo: "Basic Information",
    productCode: "Product Id",
    productName: "Product Name",
    enterProductName: "Please enter the Product Name",
    productDescription: "Product Description",
    enterDescription: "Please enter the product description",
    productCover: "Product Cover",
    salesInfo: "Sales Information",
    productPrice: "Product Price",
    strikethroughPrice: "Strikethrough Price",
    stock: "Stock",
    residual: "Residual",
    productInfo: "Product Information",
    productCategory: "Product Category",
    material: "Material",
    account: "Account",
    productType: "Product Type",
    avatar: "Avatar",
    nickname: "Nickname",
    resumeDescription: "Resume Description",
    homepageLink: "Homepage Link",
    followers: "Number of Followers",
    following: "Number of Following",
    likes: "Number of Likes Received",
    videos: "Number of Videos",
    accountCountry: "Account Country",
    onSale: "On Sale",
    saleNow: "Put on Sale Now",
    saleLater: "Not on Sale Yet",
    codeRequired: "Product code cannot be empty",
    nameRequired: "Product name cannot be empty",
    descriptionRequired: "Product description cannot be empty",
    coverRequired: "Product cover cannot be empty",
    priceRequired: "Product price cannot be empty",
    strikethroughPriceRequired: "Strikethrough price cannot be empty",
    stockRequired: "Please enter real inventory",
    selectCategory: "Please select a product category",
    selectMaterial: "Please select material",
    accountRequired: "Account cannot be empty",
    selectSaleStatus: "Please select whether to put on sale",
    materialDuration: "Material Duration",
    putOnSale: "Put on Sale",
    removeFromSale: "Remove from Sale",
    saleSuccess: "Successfully Put on Sale",
    removeSuccess: "Successfully Removed from Sale",
    confirmDeleteProduct: "Are you sure you want to delete the selected product?",
    email: "Email",
    emailNote: "Since your purchase includes an account, please provide your email. After the purchase, account information will be sent to your email.",
    addToCart: "Add to Cart",
    addedToCart: "Added to Cart",
    buyNow: "Buy Now",
    purchaseSupport: 'Contact customer service to purchase',
    discountPrice: "Discount Price",
    originalPrice: "Original Price",
    addToCartSuccess: "Successfully Added to Cart",
    orderNumber: "Order Number",
    enterOrderNumber: "Please enter the order number",
    incomeType: "Income and expenditure type",
    transactionStatus: "Transaction Status",
    quantity: "Quantity",
    paidAmount: "Amount Paid",
    orderStatus: "Order Status",
    paymentMethod: "Payment Method",
    wechat: "WeChat",
    cancelOrder: "Cancel Order",
    goToPay: "Go to Pay",
    details: "Details",
    confirmDeleteOrder: "Are you sure you want to delete the selected order?",
    cancelSuccess: "Cancellation Successful",
    orderTime: "Order Time",
    payTime: "Payment Time",
    pay: "Pay",
    transactionNumber: "Transaction Number",
    paymentAmount: "Payment Amount",
    amount: "Amount",
    accountAmount: "Account balance",
    wechatScan: "Please use WeChat to scan and pay",
    remainingTime: "Remaining Payment Time",
    paymentSuccess: "Payment Successful",
    paymentCancel: "Payment Cancelled",
    paymentFail: "Payment Failed",
    paymentOvertime: "Overtime Payment",
    searchNameOrDesc: "Product Name/Description",
    checkout: "Checkout",
    unitPrice: "Unit Price",
    selectProduct: "Please select a product",
    billNumber: "Bill Number",
    enterBillNumber: "Please enter the bill number",
    failure: "Failure",
    success: "Success",
    pendingPayment: 'Pending Payment',
    withdrawable: 'Withdrawable',
    pointInfo: 'Selling point information displayed below the product name on the product details page; recommended within 60 characters',
    sortOrder: 'Sort the published products; the smaller the sort number, the higher the position',
    salePrice: 'This price is the transaction price of the product',
    strikethroughPriceTip: 'The strikethrough price is the original price, which can be compared with the product price, making it easier to sell',
    productDescriptionTip: 'Used to describe detailed attributes of the product, such as the basic profile of an account including name, gender, introduction, business direction, region, and followers; if it’s a material, you can introduce its marketing direction, previously referenced data, etc.',
    productDetails: 'Product Details',
    detailRequired: "Product details cannot be empty",
    sellerInformation: 'Seller information',
    buyInformation: 'Buyer information',
    expense: 'Expense',
    income: 'Income',
  },
  goods: {
    downloadTemplate: 'Download Template',
    importExcel: 'Import Excel',
    account: 'Account',
    password: 'Password',
    duration: 'Duration',
    followers: 'Followers',
    region: 'Region',
    language: 'Language',
    price: 'Price',
    isSold: 'Is Sold',
    importAccountData: 'Please import account data',
    fileReadError: 'File reading failed',
    importSuccess1: 'Successfully imported ',
    importSuccess2: ' records.',
    importSuccess3: ' records, with ',
    importSuccess4: ' duplicate records overwritten.',
    invalidFileType: 'Invalid file type, please upload in xlsx format',
  },
  profile: {
    accountCollaboration: 'Select user', // 账号协同
    selectCollaborationID: 'Select existing users', // 选择账号协同ID
    createCollaboration: 'Creating a user identity', // 创建账号协同
    boundSocialMedia: 'Bound Social Media', // 已绑定社媒
    availableSocialMedia: 'Available Social Media', // 可绑定社媒
    directCreate: 'Direct Create', // 直接创建
    confirmSelection: 'Next', // 确定选择
    pleaseSelectID: 'Please select a Username', // 请选择账号协同ID
    confirmDelete: 'Are you sure you want to delete this data?', // 是否确认删除该项数据？
    topHeader: 'Username',
    enterTip: 'Please enter your username',
    enterTipAllowNone: 'Enter the user name. If you do not enter the user name, the user name is randomly generated.',
    loading: 'Loading...',
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  },
  settings: {
    systemLayout: 'System Layout Configuration',
    themeColor: 'Theme Color',
    languageSucceed: 'Setting the language succeeded',
    layoutSize: 'Layout size',
    personalCenter: "Personal Center",
    layoutSetting: 'Layout setting',
    logout: 'Logout',
    tipLogout: 'Are you sure you want to log out?',
    themeStyle: 'Theme Style Settings',
    themeColorShort: 'Theme Color', // 假设这是为了区分或简写的主题颜色
    systemLanguage: 'System Language',
    open: 'Enable',
    fixed: 'Fix',
    show: 'Display',
    dynamicTitle: 'Dynamic Title',
    saveConfig: 'Save Configuration',
    resetConfig: 'Reset Configuration',
    savingToLocal: 'Saving to local, please wait...',
    clearingCache: 'Clearing settings cache and refreshing, please wait...',
  },
  user: {
    userInfo: 'Personal Information',
    userName: 'Account name',
    mobilePhone: 'Mobile Number',
    userEmail: 'User Email',
    userRole: 'Role',
    creationDate: 'Creation Date',
    basicInfo: 'Basic Information',
    changePassword: 'Change Password',
    oldPassword: 'Old Password',
    oldPasswordRequired: 'Please enter your old password',
    newPassword: 'New Password',
    newPasswordRequired: 'Please enter your new password',
    confirmPassword: 'Confirm Password',
    confirmPasswordRequired: 'Please confirm your new password',
    passwordsDoNotMatch: 'The two passwords do not match',
    oldPasswordCannotBeEmpty: 'Old password cannot be empty',
    newPasswordCannotBeEmpty: 'New password cannot be empty',
    passwordPolicy: 'Password must be 8-18 characters long, including uppercase letters, lowercase letters, digits, and special characters',
    confirmPasswordCannotBeEmpty: 'Confirm password cannot be empty',
    updateSuccess: 'Password changed successfully',
  },
  userInfo: {
    nickName: 'User Nickname',
    tipName: 'User nickname cannot be empty',
    phonenumber: 'Phone Number',
    tipPhone: 'Phone number cannot be empty',
    tipCorrectPhone: 'Please enter a valid phone number',
    email: 'Email',
    tipEmail: 'Email address cannot be empty',
    tipCorrectEmail: 'Please enter a valid email address',
    tipPhoneEmail: 'Fill in at least one mobile phone number and email address',
    sex: 'Gender',
    male: 'Male',
    female: 'Female',
  },
  //操作引导
  guidance: {
    guide: 'Operation Guide',
    txtToVidWelcome: 'Hi, welcome to InfoPub - Text-to-Video Module',
    txtToVidInstructions: 'Enter text on the left, select a template, and generate a video. For assistance, please refer to the beginner\'s tutorial below.',
    vidEditIntro: 'Hi, welcome to InfoPub - Video Mashup Module',
    vidEditInstructions: 'Complete video settings, select material scenes, and generate a video. For assistance, please refer to the beginner\'s tutorial below.'
  },
  model: {
    basicInfo: 'Basic Information',
    modelName: 'Model Name',
    modelDescription: 'Model Description',
    fileModel: 'File Model',
    fileName: 'File Name',
    fileSize: 'File Size',
    trainingModule: 'Training Module',
    trainingAdvice: 'To train a more accurate model, the file content needs to be specific. Providing excessive or unrelated content is not recommended.',
    upload: 'Click to Upload',
    qnaModel: 'Q&A Model',
    question: 'Question',
    answer: 'Answer',
    addQna: 'Add Q&A',
    trainingResult: 'Training Result',
    trainingTime: 'Training Time',
    trainingSuccess: 'Training Successful',
    startTraining: 'Start Training',
    generalInfo: 'General Information',
    enterModelName: 'Please enter the model name',
    enterModelDescription: 'Please enter the model description',
    selectTrainingModule: 'Please select a training module',
    confirmDeleteFile: 'Are you sure you want to delete this training file?',
    confirmDeleteQna: 'Are you sure you want to delete this Q&A?',
    completeQna: 'Please complete the Q&A',
    uploadAtLeastOne: 'At least one File Model or Q&A Model must be uploaded',
    addModel: 'Add Model',
    modelInfo: 'Model Information',
    generate: 'Generate',
    generated: 'Generated',
    trainingInProgress: 'Training in Progress',
    trainingFailed: 'Training Failed',
    trainingLocked: 'Model is being trained, operations are temporarily unavailable',
    confirmDeleteModel: 'Are you sure you want to delete the selected model?',
  },
  search: 'Search',
  reset: 'Reset',
  remove: 'Remove',
  operate: 'Operate',
  cancel: 'Cancel',
  save: 'Save',
  confirm: 'Confirm',
  sure: 'Sure',
  rename: 'Rename',
  add: 'Add',
  update: 'Update',
  edit: 'Edit',
  id: 'Id',
  confirmSelection: 'Confirm Selection',
  checkAll: 'Check All',
  view: 'View',
  play: 'Play',
  pause: 'Pause',
  copyLink: 'Copy Link',
  back: 'Back',
  quota: 'Task quotas',
  yes: 'Yes',
  no: 'No',
  expansion: 'Expansion',
  collapse: 'Collapse',
  change: 'Change',
  select: 'Select',
  export: 'Export',
  detail: 'Detail',
  refresh: 'Refresh',
  sort: 'Sort',
  skip: 'Skip',
  submit: 'Submit',
  all: 'All',
  systemPrompt: 'System Prompt',

  status: {
    status: "Status",
    initiate: 'Initiate',
    disable: 'Disable',
    generating: 'Generating',
    generatingTip: 'Expect 2-3 minutes',
  },

  time: {
    createTime: 'Create Time',
    startDate: 'Start Date',
    endingDate: 'Ending Date',
    generatedTime: 'Generated Time',
  },

  tip: {
    tip: 'Tip',
    input: 'please input',
    select: 'please select',
    update: 'modify successfully',
    add: 'New successfully',
    remove: 'successfully delete',
    submit: 'successfully submit',
    infoPrompt: 'We detected that your information is incomplete. Please click "Complete Now" to submit your basic details. You can also click on the avatar in the top right corner at any time to edit your profile information in the personal center.', // 检测到您还没有完善信息，请点击“去完善”提交您的基本信息。您也可以随时点击右上角头像，在个人中心入口编辑您的资料信息。
    completeNow: 'Complete Now',
    later: 'Maybe Later',
    roleNameInput: 'Please enter a user role',
    fullScreenTip: 'Your browser does not support full screen',
  },
  error: {
    error404: '404 Error!',
    notFoundMessage: 'Sorry, the page you are looking for does not exist. Try checking the URL for errors, then hit the refresh button on your browser or try finding something else in our application.',
    backToHome: 'Return to Homepage',
    pageNotFound: 'Page Not Found',
  },
  publicationStream: {
    title: 'Release Stream',
    plays: 'plays',
    Likes: 'Likes',
    comments: 'comments',
    forwarding: 'forwarding',
    lookAllComments: 'View all comments',
    close: 'Close',
    saySomething: 'Say something',
    comment: 'Comment',
    commentTip: 'Please enter a comment',
    commentSuccess: 'Comment success',
    reply: 'reply',
    commentContent: 'Comment content',
    noComment: 'No comments yet',
    noData: 'No stream data has been published',
    goPublish: 'Go to Publish',
  },
  messageCenter: {
    title: 'Message List',
    account: 'account',
    placeholder: 'Please enter',
    refreshMessage: 'Refresh Message',
  },
  videoTranslationPage: {
    headerTitle: 'Video Translation',
    strip: 'strip',
    selectFromLibrary: 'Select from library',
    videoSettings: 'Video Settings',
    history: 'History',
    tip: 'Currently only supports single translation of audio or subtitles. You need to select the video language and the target language for translation.',
    translationType: 'Translation Type',
    sound: 'sound',
    subtitle: 'subtitle',
    videoLanguage: 'Video Language',
    translationLanguage: 'Translation Language',
    saveto: 'Save to',
    material: 'material',
    work: 'work',
    clickTranslation: 'One-click translation',
    noFinishedVideoLink: 'No finished video link yet',
  },
  faceSwap: {
    faceChanging: 'AI face-changing',
    tip: 'You can upload multiple frontal face videos and generate face-changing videos in batches. The face images must be frontal faces.',
    uploadFaceShape: 'Upload face shape',
    storageLocation: 'Storage location',
    clickAIface: 'One-click AI face-changing',
    successTip: 'Successfully created, please check the history',
    warningTip: 'Please complete the information before making',
  },
  unsubtitle: {
    headerTitle: 'Video subtitle removal',
    setting: 'Go to subtitle settings',
    removeSubtitles: 'Remove subtitles',
    fullScreen: 'full screen',
    selectArea: 'Select area',
    clickSubtitleRemoval: 'One-click subtitle removal',
  },
  videoCarry: {
    headerTitle: 'Video transfer',
    pleaseVideoLink: 'Please enter the video link',
    clearContent: 'Clear content',
    clickPaste: 'One-click paste',
    startParsing: 'Start parsing',
    publishVideo: 'Publish a video',
    editingCopy: 'Editing copy',
    PleaseEnterText: 'Please enter the text',
    selectAccount: 'Select the account to publish',
    successTip: 'Successfully created publishing task',
    errorTip: 'Failed to create a release task',
    parsingSuccess: 'Parsing success',
    pasteTip: 'Unable to access clipboard, please paste manually'
  },
  aiSlicing:{
    headerTitle: 'AI Slicing'
  }
}
