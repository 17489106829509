<template>
  <div class="j-emoji-picker">
      <JTabs :tabs="emojiJson" @tabClick="tabClick"></JTabs>
      <div class="j-emoji-picker-content" ref="emojiPickerContent">
          <div
              class="j-emoji-picker-content-tab"
              @mousedown="handleMouseDown"
              @touchstart="handleTouchStart"
              @touchmove="handleTouchMove"
              @touchend="handleTouchEnd"
          >
              <div
                  class="j-emoji-picker-content-tab-item"
                  v-for="emoji in activatedTab.list"
                  :key="emoji.name"
                  :title="emoji.name"
                  @click="emojiClick(emoji)"
              >
                  <span class="j-emoji-picker-content-tab-item-font">{{
                      emoji.font
                  }}</span>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
const emojiJson = require("./config.json");
import JTabs from "./JTabs.vue";
export default {
  name: "EmoPicker",
  props: {
      showFrequentlyUsedList: {
          type: Boolean,
          default: true,
      },
      emojiList: {
          type: Array,
          default: () => [],
      },
      mode: {
          type: String,
          default: "append", //unshift/append/replace
      },
      hideList: {
          type: Array,
          default: () => [],
      },
      inputId: {
          type: String,
          default: "",
      },
  },
  components: {
      JTabs,
  },
  data() {
      return {
          emojiJson: [],
          activatedTab: {},
          // 鼠标按下时的初始位置
          startY: 0,
          // 滚动的当前位置
          currentScrollLeft: 0,
          // 是否正在滚动
          isScrolling: false,
          scrollDistance: 0,
      };
  },
  computed: {
      tabs() {
          return this.emojiJson.map((item) => {
              return item.emojiTitle || item.title;
          });
      },
  },
  created() {
      this.init();
  },
  methods: {
      addFrequentlyUsedList() {
          if (!this.showFrequentlyUsedList) return [];
          const list = [];
          const frequentlyUsedList = JSON.parse(
              localStorage.getItem("JEmojiPickerFrequentlyUsedList") || "[]"
          );
          list.push({
              title: "⏳️最近常用",
              emojiTitle: "⏳️",
              list: frequentlyUsedList,
          });
          return list;
      },
      init() {
          const list = [...this.addFrequentlyUsedList()];
          emojiJson.forEach((item) => {
              if (!this.hideList.includes(item.title)) {
                  list.push({
                      emojiTitle: item.title
                          .slice(0, 2)
                          .replace(/[\u4e00-\u9fff]/g, ""),
                      ...item,
                  });
              }
          });
          if (this.emojiList.length) {
              switch (this.mode) {
                  case "unshift":
                      list.unshift(...this.emojiList);
                      break;
                  case "replace":
                      list = [...this.emojiList];
                      break;
                  default:
                      list.push(...this.emojiList);
                      break;
              }
          }
          this.emojiJson = list;
          this.activatedTab = list[0];
      },
      tabClick(tab) {
          this.activatedTab = tab;
          this.$refs.emojiPickerContent.scrollTop = 0;
      },
      emojiClick(emoji) {
          if (this.scrollDistance > 10) {
              this.scrollDistance = 0;
              return;
          }
          const frequentlyUsedList =
              this.emojiJson.find((item) => item.title === "⏳️最近常用") ||
              {};
          const list = frequentlyUsedList.list || [];
          const newList = [emoji];
          for (const item of list) {
              if (item.name !== emoji.name) {
                  newList.push(item);
              }
          }
          frequentlyUsedList.list = newList;
          localStorage.setItem(
              "JEmojiPickerFrequentlyUsedList",
              JSON.stringify(newList)
          );
          this.fillBackInput(emoji);
          this.$emit("select", emoji);
      },
      fillBackInput(emoji) {
          const input = document.getElementById(this.inputId);
          if (!input) return;
          let startPos = input.selectionStart;
          let endPos = input.selectionEnd;
          let resultText =
              input.value.substring(0, startPos) +
              emoji.font +
              input.value.substring(endPos);
          input.value = resultText;
          input.focus();
          input.selectionStart = startPos + emoji.font.length;
          input.selectionEnd = startPos + emoji.font.length;
          this.$emit("selectBack", resultText);
      },
      handleMouseDown(event) {
          this.scrollDistance = 0;
          // 记录鼠标按下时的位置和滚动位置
          this.startY = event.clientY;
          this.currentScrollTop = this.$refs.emojiPickerContent.scrollTop;
          this.isScrolling = true;

          // 监听mousemove和mouseup事件
          window.addEventListener("mousemove", this.handleMouseMove);
          window.addEventListener("mouseup", this.handleMouseUp);
      },
      handleMouseMove(event) {
          if (!this.isScrolling) return;

          // 计算鼠标移动的距离，并更新滚动位置
          const deltaY = event.clientY - this.startY;
          const newScrollTop = this.currentScrollTop - deltaY;
          this.scrollDistance = Math.abs(newScrollTop);
          this.$refs.emojiPickerContent.scrollTop = newScrollTop;
      },
      handleMouseUp(event) {
          if (!this.isScrolling) return;
          // 清理工作
          this.isScrolling = false;
          event.preventDefault();
          window.removeEventListener("mousemove", this.handleMouseMove);
          window.removeEventListener("mouseup", this.handleMouseUp);
      },
      handleTouchStart(event) {
          this.scrollDistance = 0;
          this.startTouchY = event.touches[0].clientY;
          this.scrollTopStart = this.$refs.emojiPickerContent.scrollTop;
          this.scrolling = true;
      },
      handleTouchMove(event) {
          if (!this.scrolling) return;

          const touchY = event.touches[0].clientY;
          const deltaY = touchY - this.startTouchY;
          const newScrollTop = this.scrollTopStart - deltaY;
          this.scrollDistance = Math.abs(newScrollTop);
          this.$refs.emojiPickerContent.scrollTop = newScrollTop;

          // 阻止默认滚动行为
          event.preventDefault();
      },
      handleTouchEnd(event) {
          this.scrolling = false;
          event.preventDefault();
          const target = event.target;
          if (
              target.classList.value ===
              "j-emoji-picker-content-tab-item-font"
          ) {
              const value = event.srcElement.innerHTML;
              const activatedTab = this.activatedTab;
              const emoji = activatedTab.list.find(
                  (item) => item.font === value
              );
              this.emojiClick(emoji);
          }
      },
  },
};
</script>

<style lang="scss" scoped>
.j-emoji-picker {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  background: #efe9e9;
  padding: 0.2em;
  border-radius: 10px;
  font-size: 24px;
  user-select: none; /* 防止用户选择文本 */
  &-content {
      background: #fafafa;
      width: 100%;
      flex: 1;
      overflow: hidden;
      overflow-y: scroll;
      cursor: pointer;
      user-select: none; /* 防止用户选择文本 */
      &-tab {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          padding: 3px;
          &-item {
              width: 10%;
              height: 35px;
              display: flex;
              span {
                  margin: auto;
              }
          }
      }
  }
}
</style>
