import request from '@/utils/request'
//运营方案
const baseApi = '/admin/programme'

// 列表
export function listProgramme(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}
// 详情
export function getProgramme(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'get'
  })
}
// 新增
export function addProgramme(data) {
  return request({
    url: baseApi + '/create',
    method: 'post',
    data: data
  })
}
// 执行
export function executeProgramme(data) {
  return request({
    url: baseApi + '/execute',
    method: 'post',
    data: data
  })
}
// 取消执行
export function abandonProgramme(data) {
  return request({
    url: baseApi + '/abandon',
    method: 'post',
    data: data
  })
}
// 修改
export function updateProgramme(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}

// 删除
export function delProgramme(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'delete'
  })
}
