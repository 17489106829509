import request from '@/utils/request'
// 商城-查询商品（买家）
const baseApi = '/mall/material'
// 列表
export function listMallGoods(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}

// 详情
export function getMallGoods(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'get'
  })
}

// 新增
export function addMallGoods(data) {
  return request({
    url: baseApi,
    method: 'post',
    data: data
  })
}

// 修改
export function updateMallGoods(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}

// 删除
export function delMallGoods(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'delete'
  })
}
