import request from '@/utils/request'
//阿里相关
const baseApi = '/system/configuration'
// 转场列表
export function getTransitionsList(query) {
  return request({
    url: baseApi + '/transitionsList',
    method: 'get',
    params: query
  })
}
// 滤镜列表
export function getFilterList(query) {
  return request({
    url: baseApi + '/filterList',
    method: 'get',
    params: query
  })
}
// 花字列表
export function getEffectColorStyleList(query) {
  return request({
    url: baseApi + '/effectColorStyleList',
    method: 'get',
    params: query
  })
}
// 音色列表——["":[]]格式
export function getVoiceList(query) {
  return request({
    url: baseApi + '/aiVoiceGroup',
    method: 'get',
    params: query
  })
}
// 音色列表——直接返回所有——[]格式——代码逻辑里已删掉
export function getVoiceAll(query) {
  return request({
    url: baseApi + '/aiVoice',
    method: 'get',
    params: query
  })
}
// 翻译语种——适配百度、微软（统一保留这一套）
// bdCode——百度code
// localName——人声姓名
// shoreName——音色-原来的voide字段
// gender——性别
// locale——微软语种
export function getLanguages(query) {
  return request({
    url: baseApi + '/languages',
    method: 'get',
    params: query
  })
}
// 翻译语种——适配阿里
// code、nameZh
export function getLanguagesFromAli(query) {
  return request({
    url: baseApi + '/videoTranslationLanguages',
    method: 'get',
    params: query
  })
}
// 获取音色——微软
// code、nameZh
export function getAzureVoice(query) {
  return request({
    url: baseApi + '/azureVoice',
    method: 'get',
    params: query
  })
}
// oss直传——获取临时凭证
export function getOssToken(query) {
  return request({
    url: baseApi + '/get_sts_token_for_oss_upload',
    method: 'get',
    params: query
  })
}

