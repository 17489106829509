import request from '@/utils/request'
//购物车
const baseApi = '/mall/cart'
// 列表
export function listMallCart(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}

// 详情
export function getMallCart(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'get'
  })
}

// 新增
export function addMallCart(data) {
  return request({
    url: baseApi,
    method: 'post',
    data: data
  })
}

// 修改
export function updateMallCart(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}

// 删除
export function delMallCart(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'delete'
  })
}
