var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.user, rules: _vm.rules, "label-width": "80px" },
    },
    [
      _c(
        "el-form-item",
        {
          attrs: { label: _vm.$t("register.companyName"), prop: "companyName" },
        },
        [
          _c("el-input", {
            attrs: {
              type: "text",
              autocomplete: "off",
              placeholder: _vm.$t("tip.input"),
            },
            model: {
              value: _vm.user.companyName,
              callback: function ($$v) {
                _vm.$set(_vm.user, "companyName", $$v)
              },
              expression: "user.companyName",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticStyle: { "margin-bottom": "20px" },
          attrs: {
            label: _vm.$t("register.industry"),
            prop: "industryCategorys",
          },
        },
        [
          _c("el-cascader", {
            staticStyle: { width: "100%" },
            attrs: {
              "show-all-levels": false,
              props: _vm.props,
              options: _vm.industryCategoryOptions,
            },
            model: {
              value: _vm.user.industryCategorys,
              callback: function ($$v) {
                _vm.$set(_vm.user, "industryCategorys", $$v)
              },
              expression: "user.industryCategorys",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("register.brand"), prop: "brand" } },
        [
          _c("el-input", {
            attrs: {
              type: "text",
              autocomplete: "off",
              placeholder: _vm.$t("register.brandTip"),
            },
            model: {
              value: _vm.user.brand,
              callback: function ($$v) {
                _vm.$set(_vm.user, "brand", $$v)
              },
              expression: "user.brand",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("register.product"), prop: "product" } },
        [
          _c("el-input", {
            attrs: {
              type: "text",
              autocomplete: "off",
              placeholder: _vm.$t("register.productTip"),
            },
            model: {
              value: _vm.user.product,
              callback: function ($$v) {
                _vm.$set(_vm.user, "product", $$v)
              },
              expression: "user.product",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("userInfo.nickName"), prop: "nickName" } },
        [
          _c("el-input", {
            attrs: { maxlength: "30" },
            model: {
              value: _vm.user.nickName,
              callback: function ($$v) {
                _vm.$set(_vm.user, "nickName", $$v)
              },
              expression: "user.nickName",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: { label: _vm.$t("userInfo.phonenumber"), prop: "phonenumber" },
        },
        [
          _c("el-input", {
            attrs: { maxlength: "11" },
            model: {
              value: _vm.user.phonenumber,
              callback: function ($$v) {
                _vm.$set(_vm.user, "phonenumber", $$v)
              },
              expression: "user.phonenumber",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("userInfo.email"), prop: "email" } },
        [
          _c("el-input", {
            attrs: { maxlength: "50" },
            model: {
              value: _vm.user.email,
              callback: function ($$v) {
                _vm.$set(_vm.user, "email", $$v)
              },
              expression: "user.email",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: _vm.$t("userInfo.sex") } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.user.sex,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "sex", $$v)
                },
                expression: "user.sex",
              },
            },
            [
              _c("el-radio", { attrs: { label: "0" } }, [
                _vm._v(_vm._s(_vm.$t("userInfo.male"))),
              ]),
              _c("el-radio", { attrs: { label: "1" } }, [
                _vm._v(_vm._s(_vm.$t("userInfo.female"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini", loading: _vm.loading },
              on: { click: _vm.submit },
            },
            [_vm._v(_vm._s(_vm.$t("save")))]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "mini" },
              on: { click: _vm.close },
            },
            [_vm._v(_vm._s(_vm.$t("back")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }