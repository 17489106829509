var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-column" },
    [
      _c("div", { staticClass: "flex-row-center f-between" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("aiSubtitle.setup")))]),
        _c(
          "div",
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.settings.open
                    ? _vm.$t("aiSubtitle.show")
                    : _vm.$t("aiSubtitle.hide")
                ) +
                " "
            ),
            _c("el-switch", {
              staticStyle: { margin: "10px" },
              on: { change: _vm.handleItemUpdated },
              model: {
                value: _vm.settings.open,
                callback: function ($$v) {
                  _vm.$set(_vm.settings, "open", $$v)
                },
                expression: "settings.open",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "subtitle-title" }, [
        _vm._v(_vm._s(_vm.$t("aiSubtitle.font"))),
      ]),
      _c(
        "el-select",
        {
          attrs: { placeholder: _vm.$t("aiSubtitle.font") },
          on: { change: _vm.handleItemUpdated },
          model: {
            value: _vm.settings.font,
            callback: function ($$v) {
              _vm.$set(_vm.settings, "font", $$v)
            },
            expression: "settings.font",
          },
        },
        _vm._l(_vm.dict.type.font_typeface, function (dict) {
          return _c("el-option", {
            key: dict.value,
            attrs: { label: dict.label, value: dict.value },
          })
        }),
        1
      ),
      _c("div", { staticClass: "subtitle-title" }, [
        _vm._v(_vm._s(_vm.$t("aiSubtitle.textColor"))),
      ]),
      _c("el-color-picker", {
        on: { change: _vm.handleItemUpdated },
        model: {
          value: _vm.settings.fontColor,
          callback: function ($$v) {
            _vm.$set(_vm.settings, "fontColor", $$v)
          },
          expression: "settings.fontColor",
        },
      }),
      _c("div", { staticClass: "subtitle-title" }, [
        _vm._v(_vm._s(_vm.$t("aiSubtitle.fontSize"))),
      ]),
      _c(
        "el-radio-group",
        {
          on: { input: _vm.fontSizeChange },
          model: {
            value: _vm.settings.fontSizeType,
            callback: function ($$v) {
              _vm.$set(_vm.settings, "fontSizeType", $$v)
            },
            expression: "settings.fontSizeType",
          },
        },
        [
          _c("el-radio", { attrs: { label: "small" } }, [
            _vm._v(_vm._s(_vm.$t("subtitle.small"))),
          ]),
          _c("el-radio", { attrs: { label: "normal" } }, [
            _vm._v(_vm._s(_vm.$t("subtitle.normal"))),
          ]),
          _c("el-radio", { attrs: { label: "big" } }, [
            _vm._v(_vm._s(_vm.$t("subtitle.big"))),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "subtitle-title" }, [
        _vm._v(_vm._s(_vm.$t("aiSubtitle.style"))),
      ]),
      _c(
        "el-checkbox-group",
        {
          on: { change: _vm.changeFontFace },
          model: {
            value: _vm.settings.FontFaces,
            callback: function ($$v) {
              _vm.$set(_vm.settings, "FontFaces", $$v)
            },
            expression: "settings.FontFaces",
          },
        },
        [
          _c("el-checkbox", { attrs: { label: "Italic" } }, [
            _vm._v(_vm._s(_vm.$t("subtitle.italic"))),
          ]),
          _c("el-checkbox", { attrs: { label: "Bold" } }, [
            _vm._v(_vm._s(_vm.$t("subtitle.blod"))),
          ]),
          _c("el-checkbox", { attrs: { label: "Underline" } }, [
            _vm._v(_vm._s(_vm.$t("subtitle.underline"))),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "subtitle-title" }, [
        _vm._v(_vm._s(_vm.$t("aiSubtitle.position"))),
      ]),
      _c(
        "el-radio-group",
        {
          on: { input: _vm.logoChange },
          model: {
            value: _vm.settings.position,
            callback: function ($$v) {
              _vm.$set(_vm.settings, "position", $$v)
            },
            expression: "settings.position",
          },
        },
        [
          _c("el-radio", { attrs: { label: "top" } }, [
            _vm._v(_vm._s(_vm.$t("subtitle.top"))),
          ]),
          _c("el-radio", { attrs: { label: "center" } }, [
            _vm._v(_vm._s(_vm.$t("subtitle.center"))),
          ]),
          _c("el-radio", { attrs: { label: "bottom" } }, [
            _vm._v(_vm._s(_vm.$t("subtitle.bottom"))),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "subtitle-title flex-row-center" }, [
        _vm._v(_vm._s(_vm.$t("aiTitle.decorativeText")) + " "),
        _c(
          "div",
          { staticClass: "flex-row-center f-grow f-between" },
          [
            _c("el-switch", {
              staticStyle: { margin: "10px" },
              on: { change: _vm.switchEffectUpdated },
              model: {
                value: _vm.settings.openEffectColorStyle,
                callback: function ($$v) {
                  _vm.$set(_vm.settings, "openEffectColorStyle", $$v)
                },
                expression: "settings.openEffectColorStyle",
              },
            }),
            _vm.settings.openEffectColorStyle
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        _vm.isExpansion = !_vm.isExpansion
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.isExpansion
                          ? _vm.$t("collapse")
                          : _vm.$t("expansion")
                      )
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.settings.openEffectColorStyle
        ? _c(
            "div",
            { staticClass: "image-container" },
            _vm._l(_vm.styleList, function (item, index) {
              return _c("img", {
                key: index,
                class: {
                  selected:
                    _vm.settings.effectColorStyle === item.effectColorStyle,
                },
                attrs: { src: item.imgUrl },
                on: {
                  click: function ($event) {
                    return _vm.selectEffectItem(item)
                  },
                },
              })
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }