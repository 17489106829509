<template>
  <div class="container">
    <!-- <el-card style="margin: 10px;">
      <el-row>
        <el-col :span="12">
          <el-form :model="baseForm" :rules="rules" ref="baseForm" label-width="120px" >
            <el-form-item :label="$t('issueVideo.taskName')" prop="taskName" style="margin-bottom: 0px;">
              <el-input v-model="baseForm.taskName" :placeholder="$t('tip.input')" style="width:70%"  maxlength="50" show-word-limit></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <div style="text-align: right;">
            <el-button type="" @click="add">{{$t('issueVideo.add')}}</el-button>
            <el-button type="" :loading="buttonLoading1" @click="saveDraft(1)">{{$t('issueVideo.saveDraft')}}</el-button>
            <el-button type="primary" :loading="buttonLoading2" @click="saveDraft(2)">{{$t('issueVideo.issue')}}</el-button>
          </div>
        </el-col>
      </el-row>
    </el-card> -->

    <div v-loading="loading">
      <issue-item
        v-for="(form, index) in forms"
        :key="index"
        v-model="forms[index]"
        :index="index"
        :accountList="accountList"
        :newAccounts="newAccounts"
        :timeOptions="timeOptions"
        :languageOptions="languageOptions"
        :languageList="languageList"
        @remove="remove(index)"
        @saveDraftClick="saveDraftClick"
        ref="issueItems"
      />
    </div>
  </div>
</template>

<script>
import IssueItem from './issueItem.vue';
import { listAccount } from '../../api/account/tiktok';
import { getLanguages } from '../../api/ai/ali';
import { listLanguage, listTimeZone } from '../../api/ai/language';
import { addPublish, editPublish, getPublish, releasePublish } from '../../api/ai/publish';

export default {
  name:"IssueVideo",
  components: {
    IssueItem
  },
  data() {
    return {
      loading: false,
      baseForm:{
        taskName:'',
        id:'',
      },

      forms: [{
        socialIds: [],
        socialIdsByTranslate:[],
        postContent: '',
        video: [],
        timeType: '0',
        publishTimeType: '0',
        publishTime: '',
        timeZoneId:'',
        productId:'',
        url:''
      }],
      accountList: [],
      newAccounts: [],
      timeOptions:[],
      languageOptions:[],
      rules: {
        // taskName: [
        //   { required: true, message: this.$t('issueVideo.tipTaskName'), trigger: ['blur', 'change'] }
        // ],
      },
      // 按钮loading
      buttonLoading1: false,
      buttonLoading2: false,
      languageList: [],
    };
  },
  created() {
    this.getListAccount();
    this.getTimeZone()
    this.getLanguage()
    this.getLanguageList()
    this.baseForm.id = this.$route.query.id || '';
    if(this.baseForm.id){
      this.getDetail()
    }
    let url = this.$route.query.url || '';
    let productId = this.$route.query.productId || '';
    if(url && productId){
      this.forms = [{
        socialIds: [],
        socialIdsByTranslate:[],
        postContent: '',
        video: [{
          url:url,
          hover: false
        }],
        timeType: '0',
        publishTimeType: '0',
        publishTime: '',
        timeZoneId:'',
        productId:productId,
        url: url ? url : ''
      }]
    }
  },
  methods: {
    saveDraftClick(index) {
      this.saveDraft(index)
    },
    getLanguageList(){
      getLanguages().then(response => {
        const newList = response.data.map(item => {
          return {...item, select: false}
        })
        const oftenZero = newList.filter(item => item.isOften === 1);
        const others = newList.filter(item => item.isOften !== 1);
        this.languageList = oftenZero.concat(others);
      });
    },
    getDetail(){
      this.loading = true
      getPublish(this.baseForm.id).then(res=>{
        this.baseForm = res.data
        this.forms = JSON.parse(res.data.jsonData)
      }).finally(()=>{
        this.loading = false
      })
    },
    add() {
      this.forms.push({
        socialIds: [],
        socialIdsByTranslate:[],
        postContent: '',
        video: [],
        timeType: '0',
        publishTimeType: '0',
        publishTime: '',
        timeZoneId:'',
        productId:'',
        url:''
      });
    },
    getListAccount() {
      listAccount().then(response => {
        this.accountList = response.rows;
        this.transformToCascaderData()
      }).catch(() => {
      });
    },
    transformToCascaderData(){
      const map = {}
      this.accountList.forEach(item => {
        const profileId = item.profileId;
        // 如果 map 中没有这个 profileId，则创建新的项
        if (!map[profileId]) {
          map[profileId] = {
            value: profileId,
            label: item.topHeader || profileId,
            children: []
          };
        }

        // 添加平台信息到 children 中
        map[profileId].children.push({
          value: item.id,
          label: item.displayName,
          platform: item.platform
        });
      })
      this.newAccounts = Object.values(map)
    },
    getTimeZone(){
      listTimeZone().then(res => {
        this.timeOptions = res.data
      }).catch(err => {
        console.log(err)
        })
    },
    getLanguage() {
      listLanguage().then(response => {
        this.languageOptions = response.data;
      });
    },
    remove(index){
      if(this.forms.length > 1){
        this.$modal.confirm(this.$t('issueVideo.tipDeleteSure')).then(()=> {
          this.forms.splice(index, 1);
        })
      }else{
        this.$modal.msgError(this.$t('issueVideo.tipDelete'));
      }
    },
    //type=1存草稿，type=2发布
    saveDraft(type) {
      // 实现存草稿的功能
      // this.$refs["baseForm"].validate(valid => {
      //   if (valid) {
          const newForms = this.forms.map(form => {
            // 确保 socialIds 是数组
            const socialIdsArray = Array.isArray(form.socialIds) ? form.socialIds : [form.socialIds];

            // 判断 socialIdsByTranslate 是否为空，并进行相应的处理
            if (form.socialIdsByTranslate.length === 0) {
              form.socialIdsByTranslate = [{
                text: form.postContent,
                socialIds: socialIdsArray,
              }];
            }
            return {
              ...form,
              socialIds: socialIdsArray,
            };
          });
          let params = {
            ...this.baseForm,
            list: this.forms,
            jsonData:JSON.stringify(this.forms)
          }
          // console.log(params)
          if(type==1){
            if(this.baseForm.id){
              this.buttonLoading1 = true;
              editPublish(params).then(res=>{
                this.$modal.msgSuccess(this.$t('issueVideo.saveSuccess'));
                this.$tab.closeOpenPage({ path: "/issueManager" });
              }).finally(() => {
                this.buttonLoading1 = false;
              });
            } else{
              this.buttonLoading1 = true;
              addPublish(params).then(res => {
                this.$modal.msgSuccess(this.$t('issueVideo.saveSuccess'));
                this.$tab.closeOpenPage({ path: "/issueManager" });
              }).finally(() => {
                this.buttonLoading1 = false;
              });
            }
          }else{
            this.createVideo(params)
          }

        // } else {
        //   this.$modal.msgError(this.$t('issueVideo.tipInfoMust'));
        // }
      // });
    },
    async createVideo(params) {
      // 实现生成视频的功能
      const issueItems = this.$refs.issueItems;
      const validationPromises = Array.isArray(issueItems)
        ? issueItems.map(item => item.validateForm())
        : [issueItems.validateForm()];

      try {
        await Promise.all(validationPromises);
        // 提交所有表单数据
        // 检查并转换 socialIds
        // const transformedForms = this.forms.map(form => ({
        //   ...form,
        //   socialIds: Array.isArray(form.socialIds) ? form.socialIds : [form.socialIds]
        // }));
        // let params = {
        //     ...this.baseForm,
        //     list: transformedForms,
        //     // jsonData:JSON.stringify(this.forms)
        // }
        this.buttonLoading2 = true;
        releasePublish(params).then(res => {
          this.$modal.msgSuccess(this.$t('issueVideo.addSuccess'));
          this.$tab.closeOpenPage({ path: "/issueManager" });
        }).finally(() => {
          this.buttonLoading2 = false;
        });
      } catch (error) {
        console.log(error)
        this.$modal.msgError(this.$t('issueVideo.tipInfoMust'));
      }
    }
  }
};
</script>

<style scoped>
.container {
  /* background-color: white; */
  padding: 24px;
  height: 100%;
  /* box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); */
}
</style>
