import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'

import { Base64 } from 'js-base64';

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDVBFYXugm74atL0b/brWwMO1LK\
51S7fkApMEJXpuQFph3f+ldIA1qnLKajpEr0HgBaM1TOZW+ogEuoUS4sNAsKAmvq\
kHTiV386Mr7as9ecoqSYNsYUNRlpTQZRy/YCrGdDgRDbGPHe1VGLd/B2Qc04jy93\
0FCZLE5Bkv3AEqNlMwIDAQAB"

const privateKey = "MIICeAIBADANBgkqhkiG9w0BAQEFAASCAmIwggJeAgEAAoGBANUEVhe6Cbvhq0vR\
v9utbAw7UsrnVLt+QCkwQlem5AWmHd/6V0gDWqcspqOkSvQeAFozVM5lb6iAS6hR\
Liw0CwoCa+qQdOJXfzoyvtqz15yipJg2xhQ1GWlNBlHL9gKsZ0OBENsY8d7VUYt3\
8HZBzTiPL3fQUJksTkGS/cASo2UzAgMBAAECgYA2wUyYALwrzVBIxvu4LhXN9xnL\
Cjefkk5PyBXljgKi6RZY0nLJEgOlYTbcbYjOwz938CXmf/B5sbDA7U0fE6maSs7n\
RnftmgOBzWFFGR785z9ma5yU0mFOiaZoJ/jDJpK6Mroqg30Dne2TgrqQbIxBa+Es\
ShENgujHqX2fxl7+YQJBAPeULWyHdtKl63IbkF0BtyHME4/qrNpBhBFHZftxaut8\
/lNVZCto9YuNcUS+O0mkGGQLFextTeY5rY5ZpRZUP0kCQQDcQzUFD229DxshuiN9\
tPM8wsrQVAbfee/TdAFjRYpr0RAPXTo9BQT2Ol6ze9rhWwgI0cWPb5BjUGIEf/q1\
VHSbAkEA8dYzXM0iO3fJekgEh2VSjtf6z9zrhbLzn7E/pBLQ30DUaXCDDYhUOkIq\
v3yXC0MYdDkD7FOFAXub1e4pPZuDyQJBAND/MErZ5e2zbLIm9dAsp3Qq/NiKMm9Q\
O2DvvPVeIcbF+7AobL9DiP6+SeeSiRC2GLyNlyDP4jhqKGdOrCvstCsCQQC/CPTx\
xzCI6U53BYguHhFE9uu9N3mXg491pv+SMMGMQqK/9SAQBvXGWp56ItSNTHTUwgRE\
6nDAQ3DyWW9zGnZf"

// 加密
export function encrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对数据进行加密
}

// 解密
export function decrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey) // 设置私钥
  return encryptor.decrypt(txt) // 对数据进行解密
}

export function encodeBase64(text){
  return Base64.encode(text);
}
