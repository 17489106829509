import request from '@/utils/request'
//成品库分类管理
const baseApi = '/admin/productType'

// 列表
export function listProductType(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}

// 新增
export function addProductType(data) {
  return request({
    url: baseApi,
    method: 'post',
    data: data
  })
}
// 修改
export function editProductType(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}

// 删除
export function delProductType(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'delete'
  })
}
