var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "work-tab",
      on: {
        mouseover: function ($event) {
          _vm.hover = true
        },
        mouseleave: function ($event) {
          _vm.hover = false
        },
        click: function ($event) {
          return _vm.goTarget(_vm.item.path)
        },
      },
    },
    [
      _c("div", { staticClass: "flex-row-center" }, [
        _c(
          "div",
          {
            staticClass: "tab-img flex-center",
            style: { background: _vm.item.bgColor },
          },
          [
            _c("i", {
              staticClass: "iconfont",
              class: _vm.item.icon,
              style: { color: _vm.item.color },
            }),
          ]
        ),
        _c("span", { staticClass: "tab-title", class: { hover: _vm.hover } }, [
          _vm._v(_vm._s(_vm.item.name)),
        ]),
      ]),
      _c("div", { staticClass: "des" }, [_vm._v(_vm._s(_vm.item.des))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }