import request from '@/utils/request'
//运营方案——子节点
const baseApi = '/admin/programmeItems'

// 列表
export function listProgrammeItems(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}
// 详情
export function getProgrammeItems(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'get'
  })
}
// 新增
export function addProgrammeItems(data) {
  return request({
    url: baseApi,
    method: 'post',
    data: data
  })
}
// 修改
export function updateProgrammeItems(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}

// 删除
export function delProgrammeItems(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'delete'
  })
}
