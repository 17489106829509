import request from '@/utils/request'

const baseApi = '/admin/profile'

// profile列表
export function listProfile(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}
// 详情
export function getProfile(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'get',
  })
}
// 新增profile
export function addProfile(data) {
  return request({
    url: baseApi,
    method: 'post',
    data: data
  })
}
// 编辑profile
export function editProfile(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}
// 刷新profile
export function refreshProfile() {
  return request({
    url: baseApi + '/refresh',
    method: 'post',
  })
}
// 删除空profile
export function clearProfile() {
  return request({
    url: baseApi + '/clear',
    method: 'get',
  })
}
// 删除profile
export function delProfile(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'delete'
  })
}

// 生成jwt
export function generateJwt(id) {
  return request({
    url: baseApi + '/generateJWT/' + id,
    method: 'get',
  })
}
