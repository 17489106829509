// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/**\n$base-menu-color:hsla(0,0%,100%,.65);\n$base-menu-color-active:#fff;\n$base-menu-background:#001529;\n$base-logo-title-color: #ffffff;\n\n$base-menu-light-color:rgba(0,0,0,.70);\n$base-menu-light-background:#ffffff;\n$base-logo-light-title-color: #001529;\n\n$base-sub-menu-background:#000c17;\n$base-sub-menu-hover:#001528;\n*/", ""]);
// Exports
exports.locals = {
	"menuColor": "#bfcbd9",
	"menuLightColor": "rgba(0, 0, 0, 0.7)",
	"menuColorActive": "#f4f4f5",
	"menuBackground": "#304156",
	"menuLightBackground": "#F1F6FF",
	"subMenuBackground": "#E2EBFD",
	"subMenuHover": "#E2EBFD",
	"sideBarWidth": "240px",
	"logoTitleColor": "#ffffff",
	"logoLightTitleColor": "#001529",
	"menuLightLogoBackground": "#F9FBFC"
};
module.exports = exports;
