import request from '@/utils/request'
//获取模板
const baseApi = '/admin/aiTemplate'

// 列表 type：丰富版、精简版、或不传
export function listTemplate(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}
