<!-- 更多模板 -->
<template>
  <el-dialog
    :visible.sync="localVisible"
    :title="$t('template.moreTemplates')"
    :before-close="handleBeforeClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="70%"
  >
    <el-tabs v-model="activeTab" @tab-click="tabClick">
      <el-tab-pane :label="$t('template.simplified')" name="精简版"></el-tab-pane>
      <el-tab-pane :label="$t('template.enriched')" name="丰富版"></el-tab-pane>
    </el-tabs>
    <div class="flex-row f-wrap" style="height: 50vh;overflow-y: scroll;">
      <div v-for="template in templates" :key="template.id" class="template-more-item">
        <TemplateItem
          :template="template"
          @view-template="viewTemplate"
          @apply-template="applyTemplate"
        />
      </div>
    </div>
    <templateItemDetail
      :visible.sync="detailVisible"
      :template="templateDetail"
      :isInner="true"
      @confirm="applyTemplate"
    />
  </el-dialog>
</template>

<script>
import { listTemplate } from '../../api/aiClip/aiTemplate';
import TemplateItem from './templateItem.vue';
import TemplateItemDetail from './templateItemDetail.vue';
export default {
  components: {
    TemplateItem,
    TemplateItemDetail,
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    template: {
      type: Object,
      default: () => ({})
    },
  },
  created(){
    this.getTemplateList('精简版')
  },
  data() {
    return {
      localVisible: this.visible,
      activeTab:'精简版',
      templates: [],
      detailVisible: false, // 控制模板详情的显示与隐藏
      templateDetail: {}, // 模板详情数据
    };
  },
  watch: {
    visible(val) {
      this.localVisible = val; // 当 prop 变化时更新本地的 visible
    }
  },
  methods: {
    tabClick() {
      this.getTemplateList(this.activeTab)
    },
    getTemplateList(type){
      listTemplate({
        type: type
      }).then(response => {
        this.templates = response.data
      });
    },
    viewTemplate(template){
      this.templateDetail = template
      this.detailVisible = true
    },
    applyTemplate(template){
      this.$emit('apply-template', template)
      this.close()
    },
    handleBeforeClose(done) {
      this.close()
      done();
    },
    handleCancel() {
      this.close()
    },
    handleConfirm() {
      this.$emit('confirm', this.template);
      this.close()
    },
    close(){
      this.localVisible = false;
      this.$emit('update:visible', false);
    }
  }
};
</script>

<style scoped>
.template-more-item{
  width: 23%;
  margin: 1%;
}
</style>
