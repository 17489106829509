var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "chat-message-container",
        { "align-right": _vm.isUser, "align-left": !_vm.isUser },
      ],
    },
    [
      _vm.message.type === "text"
        ? _c(
            "div",
            {
              class: [
                _vm.isUser && "fromMessageBox",
                !_vm.isUser && "toMessageBox",
              ],
            },
            [
              _c("div", { staticClass: "fromMessage" }, [
                _c("div", { staticClass: "fromMessageContent" }, [
                  _vm._v(" " + _vm._s(_vm.message.content) + " "),
                ]),
                _vm.message.needSure
                  ? _c(
                      "div",
                      { staticClass: "flex-center mt5" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "gradientBtn",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("submitTheme")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("ai.sure")))]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "againBtn",
                            on: {
                              click: function ($event) {
                                return _vm.$emit("againTheme")
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("ai.themeAgain")))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          )
        : _vm.message.type === "audio"
        ? _c(
            "div",
            { staticClass: "chat-message" },
            [
              _c("audio", {
                ref: "audioPlayer",
                staticClass: "f-grow",
                attrs: { controls: "", src: _vm.message.url },
              }),
              _vm.message.content && !_vm.isShowText
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          _vm.isShowText = true
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("chat.toText")))]
                  )
                : _vm._e(),
              _vm.isShowText
                ? _c("div", [_vm._v(_vm._s(_vm.message.content))])
                : _vm._e(),
            ],
            1
          )
        : _vm.message.type === "script" || _vm.message.type === "post"
        ? _c(
            "div",
            [
              _c("EditableTextList", {
                attrs: { message: _vm.message },
                on: {
                  submitScript: function ($event) {
                    return _vm.$emit("submitScript", _vm.message)
                  },
                  submitPost: function ($event) {
                    return _vm.$emit("submitPost", _vm.message)
                  },
                  submit: function ($event) {
                    return _vm.$emit("submitMultiText", _vm.message)
                  },
                },
              }),
            ],
            1
          )
        : _vm.message.type === "video"
        ? _c(
            "div",
            [
              _c("VideoPlayerMessage", {
                attrs: { message: _vm.message },
                on: {
                  again: function ($event) {
                    return _vm.$emit("againVideo", _vm.message)
                  },
                  submit: function ($event) {
                    return _vm.$emit("submitVideo", _vm.message)
                  },
                },
              }),
            ],
            1
          )
        : _vm.message.type === "listVideo"
        ? _c(
            "div",
            [
              _c("ListVideo", {
                attrs: { message: _vm.message },
                on: { submit: _vm.chooseVideo },
              }),
            ],
            1
          )
        : _vm.message.type === "listSelector"
        ? _c(
            "div",
            [
              _c("ListSelector", {
                attrs: { message: _vm.message },
                on: { submit: _vm.submitAccount },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          class: [
            "message-time",
            { "align-right-time": _vm.isUser, "align-left": !_vm.isUser },
          ],
        },
        [_vm._v(_vm._s(_vm.message.time))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }