<template>
  <div
      class="j-tabs"
      ref="tabsContainer"
      @mousedown="handleMouseDown"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
  >
      <div
          :class="{
              'j-tabs-item': true,
              'j-tabs-item-activated': tab.title === activatedTab.title,
          }"
          v-for="(tab, index) in tabs"
          :key="tab.title"
          :title="tab.title"
          @click="tabClick(tab, index)"
      >
          {{ tab.emojiTitle }}
      </div>
  </div>
</template>

<script>
export default {
  name: "JTabs",
  props: {
      tabs: {
          type: Array,
          default: () => [],
      },
      defaultTab: {
          type: Array,
          default: () => {},
      },
  },
  data() {
      return {
          activatedTab: {},
          // 鼠标按下时的初始位置
          startX: 0,
          // 滚动的当前位置
          currentScrollLeft: 0,
          // 是否正在滚动
          isScrolling: false,
          scrollDistance: 0,
      };
  },
  created() {
      this.init();
  },
  methods: {
      init() {
          this.activatedTab = this.defaultTab || this.tabs[0];
      },
      tabClick(tab, index) {
          if (this.scrollDistance > 10) {
              this.scrollDistance = 0;
              return;
          }
          this.activatedTab = tab;
          this.$emit("tabClick", tab);

          // 获取tabs容器和被点击的tab元素
          const tabsContainer = this.$refs.tabsContainer;
          const tabElement = tabsContainer.children[index];

          // 计算滚动位置
          const containerWidth = tabsContainer.offsetWidth;
          const containerLeft = tabsContainer.offsetLeft;
          const tabWidth = tabElement.offsetWidth;
          const tabLeft = tabElement.offsetLeft;

          // 计算滚动偏移量，使tab居中
          const scrollOffset =
              tabLeft - containerLeft - containerWidth / 2 + tabWidth / 2;
          // 延迟滚动操作，等待DOM更新
          setTimeout(() => {
              tabsContainer.scrollLeft = scrollOffset;
          }, 0);
      },
      handleMouseDown(event) {
          this.scrollDistance = 0;
          // 记录鼠标按下时的位置和滚动位置
          this.startX = event.clientX;
          this.currentScrollLeft = this.$refs.tabsContainer.scrollLeft;
          this.isScrolling = true;

          // 监听mousemove和mouseup事件
          window.addEventListener("mousemove", this.handleMouseMove);
          window.addEventListener("mouseup", this.handleMouseUp);
      },
      handleMouseMove(event) {
          if (!this.isScrolling) return;

          // 计算鼠标移动的距离，并更新滚动位置
          const deltaX = event.clientX - this.startX;
          const newScrollLeft = this.currentScrollLeft - deltaX;
          this.scrollDistance = Math.abs(newScrollLeft);
          this.$refs.tabsContainer.scrollLeft = newScrollLeft;
      },
      handleMouseUp(event) {
          if (!this.isScrolling) return;
          // 清理工作
          this.isScrolling = false;
          event.preventDefault();
          window.removeEventListener("mousemove", this.handleMouseMove);
          window.removeEventListener("mouseup", this.handleMouseUp);
      },
      handleTouchStart(event) {
          this.scrollDistance = 0;
          this.startTouchX = event.touches[0].clientX;
          this.scrollLeftStart = this.$refs.tabsContainer.scrollLeft;
          this.scrolling = true;
      },
      handleTouchMove(event) {
          if (!this.scrolling) return;

          const touchX = event.touches[0].clientX;
          const deltaX = touchX - this.startTouchX;
          const newScrollLeft = this.scrollLeftStart - deltaX;
          this.scrollDistance = Math.abs(newScrollLeft);
          this.$refs.tabsContainer.scrollLeft = newScrollLeft;

          // 阻止默认滚动行为
          event.preventDefault();
      },
      handleTouchEnd(event) {
          this.scrolling = false;
          event.preventDefault();
          const target = event.target;
          for (let i = 0; i < this.tabs.length; i++) {
              if (target.title === this.tabs[i].title) {
                  this.tabClick(this.tabs[i], i);
              }
          }
      },
  },
};
</script>

<style lang="less" scoped>
.j-tabs {
  width: 100%;
  display: flex;
  overflow: hidden;
  cursor: pointer;
  font-size: 20px;
  user-select: none; /* 防止用户选择文本 */
  &-item {
      flex-wrap: nowrap;
      padding: 0.1em;
      margin: 0 0.5em;
      word-break: keep-all;
      &:hover {
          color: skyblue;
      }
  }
  &-item-activated {
      color: skyblue;
      border-bottom: skyblue 2px solid;
  }
}
</style>
