<!-- 编辑文稿 -->
<template>
  <div class="priceTag">
    <div
      v-for="item in shopList"
      :key="item.value"
      :class="['tag', item.select ? 'selectTag' : '', !item.isSelectable ? 'disabledTag' : '']"
      @click="item.isSelectable && selectTagFun(item.value)"
    >
      <el-tooltip
        v-if="item.label.length > 4"
        class="item"
        effect="dark"
        :content="item.label"
        placement="top"
      >
        <span>{{ item.label }}</span>
      </el-tooltip>
      <span v-else>{{ item.label }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "EditManuscripts",
  components: {},
  props: {
    list: {
      require: true,
      default: () => [],
    },
  },
  data() {
    return {
      shopList: [],
    };
  },
  watch: {
    list: {
      handler(newVal) {
        this.shopList = newVal
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {},
  methods: {
    selectTagFun(code) {
      this.shopList.forEach((item) => {
        if (item.value === code) {
          item.select = true;
          this.$emit("selectTag", item);
        } else {
          item.select = false;
        }
      });
    },
  },
};
</script>
<style lang="scss">
.priceTag {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0px;
  .tag {
    cursor: pointer;
    width: 80px;
    height: 28px;
    // background: #eeeef0;
    background: #EFE3F7;
    border-radius: 8px;
    border: 1px solid #e5e7eb;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-weight: 400;
    font-size: 14px;
    color: rgba(161, 161, 161, 0.9);
    margin: 4px;
    span {
      width: 75px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
  }
  .selectTag {
    background: #0156ff;
    color: #fff;
  }
  .disabledTag {
    cursor: not-allowed;
    background: #f5f5f5;
    border: 1px dashed #b0b0b0;
    color: #b0b0b0;
  }
}
</style>
