var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "videoTitle" }, [
        _vm._v(_vm._s(_vm.$t("mixing.mixSet"))),
      ]),
      _c("div", { staticClass: "set-des" }, [
        _vm._v(_vm._s(_vm.$t("videoSetting.visualExperience"))),
      ]),
      _c(
        "el-collapse",
        { attrs: { accordion: "" } },
        [
          _c(
            "el-collapse-item",
            [
              _c("template", { slot: "title" }, [
                _c("span", { staticStyle: { width: "60px" } }, [
                  _vm._v("Logo"),
                ]),
                _c("div", { staticClass: "image-thumbnail-multiple" }, [
                  _vm.settings.logoTrackClipsBo &&
                  _vm.settings.logoTrackClipsBo.mediaUrl
                    ? _c("img", {
                        staticClass: "image-thumbnail",
                        attrs: { src: _vm.settings.logoTrackClipsBo.mediaUrl },
                      })
                    : _vm._e(),
                ]),
              ]),
              _c(
                "el-form",
                {
                  attrs: {
                    model: _vm.settings,
                    "label-position": "top",
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { staticStyle: { width: "100%" }, attrs: { label: "" } },
                    [
                      _vm.settings.logoTrackClipsBo &&
                      _vm.settings.logoTrackClipsBo.mediaUrl
                        ? _c(
                            "div",
                            {
                              staticClass: "material-container",
                              on: {
                                mouseover: function ($event) {
                                  _vm.settings.logoTrackClipsBo.hover = true
                                },
                                mouseleave: function ($event) {
                                  _vm.settings.logoTrackClipsBo.hover = false
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "material-thumbnail",
                                attrs: {
                                  src: _vm.settings.logoTrackClipsBo.mediaUrl,
                                  alt: "material",
                                },
                              }),
                              _vm.settings.logoTrackClipsBo.hover
                                ? _c("div", { staticClass: "overlay" }, [
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeMaterial(3)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("basicInfo.remove"))
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        : _c("picUpload", {
                            on: {
                              picSelectMaterial: _vm.picSelectMaterial,
                              showLocaPic: _vm.showLocaPic,
                            },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("mixing.position") } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { input: _vm.logoChange },
                          model: {
                            value: _vm.settings.logoTrackClipsBo.position,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.settings.logoTrackClipsBo,
                                "position",
                                $$v
                              )
                            },
                            expression: "settings.logoTrackClipsBo.position",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { label: "left-top" },
                            },
                            [_vm._v(_vm._s(_vm.$t("mixing.leftTop")))]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { label: "center-top" },
                            },
                            [_vm._v(_vm._s(_vm.$t("mixing.centerTop")))]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { label: "right-top" },
                            },
                            [_vm._v(_vm._s(_vm.$t("mixing.rightTop")))]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { label: "left-bottom" },
                            },
                            [_vm._v(_vm._s(_vm.$t("mixing.leftBottom")))]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { label: "center-bottom" },
                            },
                            [_vm._v(_vm._s(_vm.$t("mixing.centerBottom")))]
                          ),
                          _c(
                            "el-radio",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { label: "right-bottom" },
                            },
                            [_vm._v(_vm._s(_vm.$t("mixing.rightBottom")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            [
              _c("template", { slot: "title" }, [
                _c("span", { staticStyle: { width: "60px" } }, [
                  _vm._v(_vm._s(_vm.$t("mixing.cover"))),
                ]),
                _c("div", { staticClass: "image-thumbnail-multiple" }, [
                  _vm.settings.coverUrl
                    ? _c("img", {
                        staticClass: "image-thumbnail",
                        attrs: { src: _vm.settings.coverUrl },
                      })
                    : _vm._e(),
                ]),
              ]),
              !_vm.settings.coverUrl
                ? _c("picUpload", {
                    on: {
                      picSelectMaterial: function ($event) {
                        return _vm.chooseMaterial(4)
                      },
                      showLocaPic: _vm.showLocaPic4,
                    },
                  })
                : _c(
                    "div",
                    {
                      staticClass: "material-container",
                      on: {
                        mouseover: function ($event) {
                          _vm.settings.coverUrlHover = true
                        },
                        mouseleave: function ($event) {
                          _vm.settings.coverUrlHover = false
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "material-thumbnail",
                        attrs: { src: _vm.settings.coverUrl, alt: "material" },
                      }),
                      _vm.settings.coverUrlHover
                        ? _c("div", { staticClass: "overlay" }, [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.removeMaterial(4)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("basicInfo.remove")))]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
            ],
            2
          ),
          false
            ? _c(
                "el-collapse-item",
                [
                  _c("template", { slot: "title" }, [
                    _c("span", { staticStyle: { width: "60px" } }, [
                      _vm._v(_vm._s(_vm.$t("mixing.backCover"))),
                    ]),
                    _c("div", { staticClass: "image-thumbnail-multiple" }, [
                      _vm.settings.backCoverUrl
                        ? _c("img", {
                            staticClass: "image-thumbnail",
                            attrs: { src: _vm.settings.backCoverUrl },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                  !_vm.settings.backCoverUrl
                    ? _c("picUpload", {
                        on: {
                          picSelectMaterial: function ($event) {
                            return _vm.chooseMaterial(5)
                          },
                          showLocaPic: _vm.showLocaPic5,
                        },
                      })
                    : _c(
                        "div",
                        {
                          staticClass: "material-container",
                          on: {
                            mouseover: function ($event) {
                              _vm.settings.backCoverUrlHover = true
                            },
                            mouseleave: function ($event) {
                              _vm.settings.backCoverUrlHover = false
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "material-thumbnail",
                            attrs: {
                              src: _vm.settings.backCoverUrl,
                              alt: "material",
                            },
                          }),
                          _vm.settings.backCoverUrlHover
                            ? _c("div", { staticClass: "overlay" }, [
                                _c(
                                  "div",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeMaterial(5)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("basicInfo.remove")))]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                ],
                2
              )
            : _vm._e(),
          _c(
            "el-collapse-item",
            [
              _c("template", { slot: "title" }, [
                _c("span", { staticStyle: { width: "60px" } }, [
                  _vm._v(_vm._s(_vm.$t("mixing.music"))),
                ]),
                _c(
                  "div",
                  { staticClass: "image-thumbnail-multiple" },
                  [
                    _vm.settings.audioTrackClipsBos &&
                    _vm.settings.audioTrackClipsBos.length > 0 &&
                    _vm.settings.audioTrackClipsBos[0].mediaUrl
                      ? _c("svg-icon", {
                          staticStyle: { width: "20px", height: "20px" },
                          attrs: { "icon-class": "audio" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.chooseMaterial(2)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("tip.select")))]
              ),
              _vm.settings.audioTrackClipsBos &&
              _vm.settings.audioTrackClipsBos.length > 0 &&
              _vm.settings.audioTrackClipsBos[0].mediaUrl
                ? _c("div", { staticClass: "flex-column" }, [
                    _c(
                      "div",
                      {
                        staticClass: "flex-row-center",
                        staticStyle: { "margin-top": "5px" },
                      },
                      [
                        _c("audio", {
                          ref: "audioPlayer",
                          staticClass: "f-grow",
                          attrs: {
                            controls: "",
                            src: _vm.settings.audioTrackClipsBos[0].mediaUrl,
                          },
                        }),
                        _c("i", {
                          staticClass: "el-icon-circle-close",
                          on: { click: _vm.delMusic },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "flex-row-center mt5" },
                      [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#606266",
                              "font-size": "14px",
                              "margin-right": "12px",
                              "font-weight": "700",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("editVideo.musicVolume")))]
                        ),
                        _c("el-slider", {
                          staticClass: "f-grow",
                          attrs: {
                            step: 0.1,
                            max: 10,
                            min: 0,
                            "show-input": "",
                          },
                          on: { change: _vm.handleItemUpdated },
                          model: {
                            value:
                              _vm.settings.audioTrackClipsBos[0].effects[0]
                                .gain,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.settings.audioTrackClipsBos[0].effects[0],
                                "gain",
                                $$v
                              )
                            },
                            expression:
                              "settings.audioTrackClipsBos[0].effects[0].gain",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            [
              _c("template", { slot: "title" }, [
                _c("span", { staticStyle: { width: "60px" } }, [
                  _vm._v(_vm._s(_vm.$t("mixing.bgColor"))),
                ]),
                _c("div", { staticClass: "image-thumbnail-multiple" }, [
                  _c("div", {
                    staticClass: "image-thumbnail ",
                    style: { backgroundColor: _vm.settings.backgroundColor },
                  }),
                ]),
              ]),
              _c("el-color-picker", {
                staticStyle: { "margin-left": "20px" },
                on: { change: _vm.handleItemUpdated },
                model: {
                  value: _vm.settings.backgroundColor,
                  callback: function ($$v) {
                    _vm.$set(_vm.settings, "backgroundColor", $$v)
                  },
                  expression: "settings.backgroundColor",
                },
              }),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            [
              _c("template", { slot: "title" }, [
                _c(
                  "span",
                  { staticStyle: { "flex-shrink": "0", "min-width": "60px" } },
                  [_vm._v(_vm._s(_vm.$t("mixing.filter")))]
                ),
                _c(
                  "div",
                  { staticClass: "image-thumbnail-multiple" },
                  _vm._l(_vm.selectedFilters, function (filter) {
                    return _c("img", {
                      key: filter.id,
                      staticStyle: {
                        "margin-left": "2px",
                        width: "20px",
                        height: "20px",
                      },
                      attrs: { src: filter.url, alt: filter.label },
                    })
                  }),
                  0
                ),
              ]),
              _c("span", { staticClass: "category-label" }, [
                _vm._v(_vm._s(_vm.$t("mixing.filterType"))),
              ]),
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.filterTypeUpdated },
                  model: {
                    value: _vm.settings.filterType,
                    callback: function ($$v) {
                      _vm.$set(_vm.settings, "filterType", $$v)
                    },
                    expression: "settings.filterType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "random" } }, [
                    _vm._v(_vm._s(_vm.$t("mixing.filterRandom"))),
                  ]),
                  _c("el-radio", { attrs: { label: "same" } }, [
                    _vm._v(_vm._s(_vm.$t("mixing.filterSame"))),
                  ]),
                ],
                1
              ),
              _vm.settings.filterType == "random"
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticClass: "flex-row space-between mt10" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: { indeterminate: _vm.isIndeterminate },
                              on: { change: _vm.checkedAllFilters },
                              model: {
                                value: _vm.allFiltersSelected,
                                callback: function ($$v) {
                                  _vm.allFiltersSelected = $$v
                                },
                                expression: "allFiltersSelected",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("checkAll")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-checkbox-group",
                        {
                          staticClass: "checkbox-group",
                          on: { change: _vm.checkedSingleFilters },
                          model: {
                            value: _vm.settings.filters,
                            callback: function ($$v) {
                              _vm.$set(_vm.settings, "filters", $$v)
                            },
                            expression: "settings.filters",
                          },
                        },
                        [
                          _vm._l(
                            Object.keys(_vm.categorizedFilters),
                            function (category) {
                              return [
                                _vm._l(
                                  _vm.categorizedFilters[category],
                                  function (filter, index) {
                                    return [
                                      filter.subtype
                                        ? _c(
                                            "el-checkbox",
                                            {
                                              key: filter.id,
                                              attrs: { label: filter.subtype },
                                            },
                                            [
                                              index === 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "category-label",
                                                    },
                                                    [_vm._v(_vm._s(category))]
                                                  )
                                                : _vm._e(),
                                              index === 1
                                                ? _c("div", {
                                                    staticClass:
                                                      "category-label",
                                                  })
                                                : _vm._e(),
                                              _c("img", {
                                                staticClass: "filter-preview",
                                                attrs: {
                                                  src: filter.url,
                                                  alt: filter.label,
                                                },
                                              }),
                                              _vm._v(
                                                " " + _vm._s(filter.label) + " "
                                              ),
                                            ]
                                          )
                                        : _c("div", {
                                            staticClass: "checkbox-placeholder",
                                          }),
                                    ]
                                  }
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.settings.filterType == "same"
                ? _c(
                    "div",
                    { staticClass: "mt10" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "custom-radio-group",
                          on: { input: _vm.singleFilter },
                          model: {
                            value: _vm.settings.filter,
                            callback: function ($$v) {
                              _vm.$set(_vm.settings, "filter", $$v)
                            },
                            expression: "settings.filter",
                          },
                        },
                        [
                          _vm._l(
                            Object.keys(_vm.categorizedFilters),
                            function (category) {
                              return [
                                _vm._l(
                                  _vm.categorizedFilters[category],
                                  function (filter, index) {
                                    return [
                                      filter.subtype
                                        ? _c(
                                            "el-radio",
                                            {
                                              key: filter.id,
                                              staticClass: "flex-row-center",
                                              attrs: { label: filter.subtype },
                                            },
                                            [
                                              index === 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "category-label",
                                                    },
                                                    [_vm._v(_vm._s(category))]
                                                  )
                                                : _vm._e(),
                                              index === 1
                                                ? _c("div", {
                                                    staticClass:
                                                      "category-label",
                                                  })
                                                : _vm._e(),
                                              _c("img", {
                                                staticClass: "filter-preview",
                                                attrs: {
                                                  src: filter.url,
                                                  alt: filter.label,
                                                },
                                              }),
                                              _vm._v(
                                                " " + _vm._s(filter.label) + " "
                                              ),
                                            ]
                                          )
                                        : _c("div", {
                                            staticClass: "checkbox-placeholder",
                                          }),
                                    ]
                                  }
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
          _c(
            "el-collapse-item",
            { attrs: { title: "" } },
            [
              _c("template", { slot: "title" }, [
                _c(
                  "span",
                  { staticStyle: { "flex-shrink": "0", "min-width": "60px" } },
                  [_vm._v(_vm._s(_vm.$t("mixing.transition")))]
                ),
                _c(
                  "div",
                  { staticClass: "image-thumbnail-multiple" },
                  _vm._l(_vm.selectedTransitions, function (transition) {
                    return _c("img", {
                      key: transition.id,
                      staticStyle: {
                        "margin-left": "2px",
                        width: "20px",
                        height: "20px",
                      },
                      attrs: { src: transition.url, alt: transition.label },
                    })
                  }),
                  0
                ),
              ]),
              _c("span", { staticClass: "category-label" }, [
                _vm._v(_vm._s(_vm.$t("mixing.transitionType"))),
              ]),
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.transitionTypeUpdated },
                  model: {
                    value: _vm.settings.transitionType,
                    callback: function ($$v) {
                      _vm.$set(_vm.settings, "transitionType", $$v)
                    },
                    expression: "settings.transitionType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "random" } }, [
                    _vm._v(_vm._s(_vm.$t("mixing.transitionRandom"))),
                  ]),
                  _c("el-radio", { attrs: { label: "same" } }, [
                    _vm._v(_vm._s(_vm.$t("mixing.transitionSame"))),
                  ]),
                ],
                1
              ),
              _vm.settings.transitionType == "random"
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticClass: "flex-row space-between mt10" },
                        [
                          _c(
                            "el-checkbox",
                            {
                              attrs: {
                                indeterminate: _vm.isIndeterminateTransitions,
                              },
                              on: { change: _vm.checkedAllTransitions },
                              model: {
                                value: _vm.allTransitionsSelected,
                                callback: function ($$v) {
                                  _vm.allTransitionsSelected = $$v
                                },
                                expression: "allTransitionsSelected",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("checkAll")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-checkbox-group",
                        {
                          staticClass: "checkbox-group",
                          on: { change: _vm.checkedSingleTransitions },
                          model: {
                            value: _vm.settings.transitions,
                            callback: function ($$v) {
                              _vm.$set(_vm.settings, "transitions", $$v)
                            },
                            expression: "settings.transitions",
                          },
                        },
                        _vm._l(_vm.transitionOptions, function (transition) {
                          return _c(
                            "el-checkbox",
                            {
                              key: transition.id,
                              attrs: { label: transition.subtype },
                            },
                            [
                              _c("img", {
                                staticClass: "transition-preview",
                                attrs: {
                                  src: transition.url,
                                  alt: transition.label,
                                },
                              }),
                              _vm._v(" " + _vm._s(transition.label) + " "),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.settings.transitionType == "same"
                ? _c(
                    "div",
                    { staticClass: "mt10" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "custom-radio-group",
                          on: { input: _vm.singleTransition },
                          model: {
                            value: _vm.settings.transition,
                            callback: function ($$v) {
                              _vm.$set(_vm.settings, "transition", $$v)
                            },
                            expression: "settings.transition",
                          },
                        },
                        _vm._l(_vm.transitionOptions, function (transition) {
                          return _c(
                            "el-radio",
                            {
                              key: transition.id,
                              attrs: { label: transition.subtype },
                            },
                            [
                              _c("img", {
                                staticClass: "transition-preview",
                                attrs: {
                                  src: transition.url,
                                  alt: transition.label,
                                },
                              }),
                              _vm._v(" " + _vm._s(transition.label) + " "),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("material-drawer", {
        ref: "materialDrawer",
        attrs: { visible: _vm.drawer },
        on: {
          "update:visible": function ($event) {
            _vm.drawer = $event
          },
          selectionConfirmed: _vm.selectionConfirmed,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }