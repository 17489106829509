import request from '@/utils/request'

const baseApi = '/system/merchant'

// 获取配额
export function getQuota(query) {
  return request({
    url: baseApi + '/getQuota',
    method: 'get',
    params: query
  })
}
// 获取注册时的信息
export function myMerchant(query) {
  return request({
    url: baseApi + '/myMerchant',
    method: 'get',
    params: query
  })
}
