<!-- 转场 -->
<template>
  <div class="voice-container">
    <div>{{$t('editVideo.transitionSetup')}}</div>
    <el-radio-group
      v-model="item"
      @input="selectItem"
      class="custom-radio-group">
        <div class="voice-items">
          <el-radio v-for="transition in transitionList" :label="transition.subtype" :key="transition.id" class="voice-item">
            <img :src="transition.url" alt="" class="transition-preview"/>
            {{ transition.label }}
          </el-radio>
        </div>
    </el-radio-group>
  </div>
</template>
<script>
export default {
  name: 'Voice',
  components: {  },
  props: {
    transitionList: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      item: '',
    }
  },
  methods: {
    selectItem() {
      this.$emit('item-selected', this.item); // 传递当前选中的 item
    },
  }
}
</script>
<style lang="scss" scoped>
.voice-container{
  width: 100%;
}
.voice-category {
  margin-bottom: 20px;
}
.category{
  margin-bottom: 20px;
  font-size: 20px;
  color: blue;
}

.voice-items {
  display: flex;
  flex-wrap: wrap;
}

.voice-item {
  width: calc(33.333% - 10px);
  margin-right: 10px;
  margin-bottom: 10px;
}
.transition-preview {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  margin-bottom: 5px;
}
</style>
