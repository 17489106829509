import request from '@/utils/request'
//ai视频生成-设置
const baseApi = '/admin/aiSetting'

// 新增
export function addAiSetting(data) {
  return request({
    url: baseApi,
    method: 'post',
    data: data
  })
}
// 修改
export function editAiSetting(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}

// 详情
export function getAiSetting() {
  return request({
    url: baseApi+'/getInfo',
    method: 'get'
  })
}
