var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
      staticStyle: { padding: "30px 10%" },
    },
    [
      _vm.detail.sellNickName
        ? _c("div", [
            _c("span", {}, [
              _vm._v(" " + _vm._s(_vm.$t("mall.sellerInformation"))),
            ]),
            _c("span", { staticClass: "ml10" }, [
              _vm._v(" " + _vm._s(_vm.detail.sellNickName)),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex-row-center mt20" },
        [
          _vm.coverImages.length > 1
            ? _c(
                "el-carousel",
                {
                  staticClass: "carousel-video",
                  attrs: { "indicator-position": "outside" },
                },
                _vm._l(_vm.coverImages, function (url, index) {
                  return _c("el-carousel-item", { key: index }, [
                    _c("img", {
                      staticClass: "image-cover",
                      attrs: { src: url, alt: "image" },
                    }),
                  ])
                }),
                1
              )
            : _c("img", {
                staticClass: "image-cover",
                attrs: {
                  src: _vm.coverImages && _vm.coverImages[0],
                  alt: "image",
                },
              }),
          _c(
            "div",
            {
              staticClass: "ml20 flex-column",
              staticStyle: {
                "justify-content": "space-between",
                "min-height": "300px",
              },
            },
            [
              _c("div", { staticClass: "video-name" }, [
                _vm._v(_vm._s(_vm.detail.title)),
              ]),
              _vm.detail.description
                ? _c("div", { staticClass: "video-description mt5" }, [
                    _vm._v(_vm._s(_vm.detail.description)),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "mt5" }, [
                _vm._v(_vm._s(_vm.$t("mall.discountPrice")) + "： "),
                _c(
                  "span",
                  { staticStyle: { "font-size": "20px", color: "red" } },
                  [_vm._v("¥" + _vm._s(_vm.detail.price))]
                ),
              ]),
              _vm.detail.originalPrice
                ? _c("div", { staticClass: "strikethrough mt5" }, [
                    _vm._v(
                      _vm._s(_vm.$t("mall.originalPrice")) +
                        "：¥" +
                        _vm._s(_vm.detail.originalPrice)
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "flex-row-center mt5" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        loading: _vm.loadingCart,
                        disabled: _vm.detail.inCart,
                      },
                      on: { click: _vm.addCart },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.detail.inCart
                            ? _vm.$t("mall.addedToCart")
                            : _vm.$t("mall.addToCart")
                        )
                      ),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { loading: _vm.loadingBuy, type: "primary" },
                      on: { click: _vm.createOrder },
                    },
                    [_vm._v(_vm._s(_vm.$t("mall.buyNow")))]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "video-name mt20" }, [
        _vm._v(_vm._s(_vm.$t("mall.productDetails"))),
      ]),
      _c("div", { staticClass: "flex-center mt5" }, [
        _c("video", {
          staticClass: "video-cover",
          attrs: { src: _vm.detail.originalUrl, controls: "", alt: "video" },
        }),
      ]),
      _vm.detail.detail
        ? _c("div", {
            staticClass: "mt20",
            domProps: { innerHTML: _vm._s(_vm.detail.detail) },
          })
        : _vm._e(),
      _c("PayDialog", {
        ref: "payDialog",
        attrs: { visible: _vm.payVisible, orderInfo: _vm.orderInfo },
        on: {
          "update:visible": function ($event) {
            _vm.payVisible = $event
          },
          paySuccess: _vm.paySuccess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }