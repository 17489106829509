import request from '@/utils/request'
//素材分类管理
const baseApi = '/admin/materialType'

// 列表
export function listMaterialType(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}

// 新增
export function addMaterialType(data) {
  return request({
    url: baseApi,
    method: 'post',
    data: data
  })
}
// 修改
export function editMaterialType(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}

// 删除
export function delMaterialType(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'delete'
  })
}
