<template>
  <div class="flex-column">
    <div
      v-for="(step, index) in steps"
      :key="index"
      class="step-item card-normal flex-row-center"
      :class="{ active: index === activeStep }"
      @click="selectStep(index)"
    >
      <div
        class="step-number f-shrink"
        :class="{ active: index === activeStep }">{{ index + 1 }}</div>
      <div class="flex-column">
        <div class="step-title">{{ step.title }}</div>
        <div class="step-des">{{ step.des }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      required: true,
    },
    activeStep: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    selectStep(index) {
      this.$emit('update:activeStep', index);
    },
  },
};
</script>

<style scoped>
.step-item {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 16px 12px;
  margin-bottom: 10px;
  border: 1px solid #E5E7EB;
  border-radius: 14px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.step-item.active {
  border-color: #016FEE; /* 选中步骤的边框颜色 */
}

.step-number {
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #D4D4D8;
  border-radius: 50%;
  color: #D4D4D8;
  margin-right: 16px;
  font-family: PingFangSC-Regular;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}
.step-number.active {
  border: 2px solid #016FEE; /* 选中步骤的边框颜色 */
  color: #016FEE;
}

.step-title {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: rgba(0,0,0,0.90);
  line-height: 24px;
  font-weight: 400;
}
.step-des{
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: rgba(0,0,0,0.26);
  text-align: left;
  line-height: 20px;
  font-weight: 400;
  margin-top: 8px;
}
</style>
