<template>
  <div class="flex-row" style="width: 100%;">
    <div class="f-grow editor-scroll" style="padding: 23px;">
      <BasicInfo :value="item" ref="basicInfo"
        @item-updated="handleItemUpdated"
        @update-background="updateBackgroundImageUrl"
        @update-videoDuration="updateVedioDuration"
        @remove="$emit('remove', item)">
      </BasicInfo>
    </div>
    <div class="editor-scroll" style="width: 27%;padding: 20px;border-left:1px solid rgba(229,231,235,1);border-right:1px solid rgba(229,231,235,1);">
      <div class="videoTitle">{{$t('basicInfo.sceneSettings')}}</div>
      <div class="set-des">{{$t('videoSetting.subtitleTitleEffects')}}</div>
      <el-collapse v-model="activeNames" accordion>
        <el-collapse-item :title="$t('subtitle.subtitle')" name="1">
          <SubtitleSettings :value="item.audioTrackClipsBos" :openSubtitle="item.openSubtitle" ref="subtitleInfo"
            @item-updated="updatedAudio"
            @item-updated-open="updatedOpenSubtitle"
            @update-subtitle-style="updateSubtitleStyle1"
            :background-image-url="backgroundImageUrl"
            :video-duration="videoDuration"
            :effectColorStyleList="effectColorStyleList"
            >
            <!-- <template v-slot:default="props">
              {{ props.value }}
            </template> -->
          </SubtitleSettings>
        </el-collapse-item>
        <el-collapse-item :title="$t('title.title')" name="2">
          <TitleSettings :value="item.titleTrackClipsBo" :openTitle="item.openTitle" ref="titleInfo"
            @item-updated="updatedTitle"
            @item-updated-open="updatedOpenTitle"
            @update-subtitle-style="updateSubtitleStyle2"
            :background-image-url="backgroundImageUrl"
            :effectColorStyleList="effectColorStyleList">
          </TitleSettings>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="editor-scroll" style="width: 293px;padding: 20px;" >
      <div class="videoTitle" >{{$t('videoEditor.effectPreview')}}</div>
      <div class="set-des">{{$t('videoEditor.viewConfigEffect')}}</div>
      <div class="preview" :style="previewStyle">
        <p :style="activeNames=='1' ? subtitlePreviewStyle1 : subtitlePreviewStyle2">{{ getContent }}</p>
      </div>
    </div>




    <!-- <div style="margin-top: 10px;">
      <SubtitleSettings :value="item.audioTrackClipsBos" :openSubtitle="item.openSubtitle" ref="subtitleInfo"
        @item-updated="updatedAudio"
        @item-updated-open="updatedOpenSubtitle"
        :background-image-url="backgroundImageUrl"
        :video-duration="videoDuration"
        :effectColorStyleList="effectColorStyleList"
        >  <template v-slot:default="props">
        {{ props.value }}
      </template>
      </SubtitleSettings>
    </div>
    <div style="margin-top: 10px;">
      <TitleSettings :value="item.titleTrackClipsBo" :openTitle="item.openTitle" ref="titleInfo"
        @item-updated="updatedTitle"
        @item-updated-open="updatedOpenTitle"
        :background-image-url="backgroundImageUrl"
        :effectColorStyleList="effectColorStyleList"></TitleSettings>
    </div> -->
  </div>
</template>
<script>
import BasicInfo from './BasicInfo.vue';
import SubtitleSettings from './SubtitleSettings.vue';
import TitleSettings from './TitleSettings.vue';

export default {
  name: 'TaskItem',
  components: {
    BasicInfo,
    SubtitleSettings,
    TitleSettings,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}) // 默认值为空对象
    },
    effectColorStyleList:{
      type: Array,
      default: () => [] // 默认值为空对象
    },
  },
  watch: {
    // 根据名称筛选部门树
    value(val) {
      this.item = {
        audioTrackClipsBos: {},
        titleTrackClipsBo: {},
        openSubtitle: false,
        openTitle: false,
        ...this.value
      }
      this.$forceUpdate()
    },
  },
  computed: {
    previewStyle(){
      return {
        backgroundImage: `url(${this.backgroundImageUrl})`,
        backgroundSize: 'contain', /* 将背景图片调整到足够大，以使其在背景区域内完全显示 */
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',/* 防止背景图片重复 */
      };
    },
    getContent(){
      let content = ''
      if(this.activeNames=='1'){
        content = this.item.audioTrackClipsBos && this.item.audioTrackClipsBos.content || ''
      }else{
        content = this.item.titleTrackClipsBo && this.item.titleTrackClipsBo.content || ''
      }
      return content
    },
  },
  data() {
    return {
      item: {
        audioTrackClipsBos: {},
        titleTrackClipsBo: {},
        ...this.value
      },
      backgroundImageUrl: '', // 初始化背景图片 URL
      videoDuration:0,// 视频时长
      activeNames:'1',
      subtitlePreviewStyle1: {},//字幕的样式
      subtitlePreviewStyle2: {},//标题的样式
    }
  },
  methods: {
    updateSubtitleStyle1(newStyle){
      this.subtitlePreviewStyle1 = newStyle;
    },
    updateSubtitleStyle2(newStyle){
      this.subtitlePreviewStyle2 = newStyle;
    },
    handleItemUpdated(updatedItem) {
      this.$emit('item-updated', updatedItem);
    },
    updateBackgroundImageUrl(newUrl) {
      this.backgroundImageUrl = newUrl;
    },
    updateVedioDuration(duration){
      this.videoDuration = duration
    },
    updatedAudio(updatedItem) {
      this.item.audioTrackClipsBos = updatedItem
      this.handleItemUpdated(this.item)
    },
    updatedOpenSubtitle(openSubtitle){
      this.item.openSubtitle = openSubtitle
      this.handleItemUpdated(this.item)
    },
    updatedTitle(updatedItem) {
      this.item.titleTrackClipsBo = updatedItem
      this.handleItemUpdated(this.item)
    },
    updatedOpenTitle(openTitle){
      this.item.openTitle = openTitle
      this.handleItemUpdated(this.item)
    },
  },
}
</script>
<style scoped>
.preview {
  position: relative;
  width: 253px;
  height: 450px;
  background-color: #000;
  color: #fff;
  text-align: center;
  line-height: 30px;
  margin-top: 20px;
  overflow: hidden; /* 确保内容不会超出预览框 */
  border-radius: 10px;
}
* {
  max-width: 100%;
  box-sizing: border-box; /* 确保内边距和边框都计算在宽度内 */
}
</style>
