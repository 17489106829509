import { mapState, mapMutations } from 'vuex';
import store from '@/store/index.js';
import { getToken } from '@/utils/auth'
import Cookies from "js-cookie";
import _ from "lodash";
import download from '../../plugins/download.js';
import { downloadFile } from '../downloadUtil.js';
export const common = {
  data() {
    return {


    }
  },
  filters: {
    pureNick(nick) {
      if (!nick) {
        return '***';
      }
      if (nick.length < 2) {
        return nick + '*';
      }
      return nick[0] + '***' + nick[nick.length - 1];
    },
    trimIdNo(value) {
      if (!value) {
        return '';
      }
      if (value.length < 4) {
        return "***"
      }
      return value.substr(0, value.length - 4) + "****";
    },
    fixedValue(value) {
      if (!value) {
        return 0;
      }
      return parseFloat(value).toFixed(2);
    },
    pureHtmlTag(str, len) {
      if (!len) {
        len = 150;
      }
      return str.replace(/<[^>]+>/g, "").substr(0, len);  //正则去掉所有的html标记
    },
    dateDiff(time) {
      var dateTimeStamp = new Date(time);
      var minute = 1000 * 60;
      var hour = minute * 60;
      var day = hour * 24;
      var halfamonth = day * 15;
      var month = day * 30;
      var now = new Date().getTime();
      var diffValue = now - dateTimeStamp;
      if (diffValue < 0) { return; }
      var monthC = diffValue / month;
      var weekC = diffValue / (7 * day);
      var dayC = diffValue / day;
      var hourC = diffValue / hour;
      var minC = diffValue / minute;
      var result = "";
      if (monthC >= 1) {
        result = "1 年前";
      } else if (monthC >= 1) {
        result = "" + parseInt(monthC) + " 月前";
      }
      else if (weekC >= 1) {
        result = "" + parseInt(weekC) + " 周前";
      }
      else if (dayC >= 1) {
        result = "" + parseInt(dayC) + " 天前";
      }
      else if (hourC >= 1) {
        result = "" + parseInt(hourC) + " 小时前";
      }
      else if (minC >= 1) {
        result = "" + parseInt(minC) + " 分钟前";
      } else {
        result = "刚刚";
      }
      return result;
    },
  },
  computed: {
    ...mapState({
      sysConfig: state => state.settings.sysConfig,
      userInfo: state => state.user.user,
    }),
  },
  methods: {
    navTo(path) {
      this.$router.push(path);
    },
    getweekday(date) {
      var weekArray = new Array('日', '一', '二', '三', '四', '五', '六');

      var week = weekArray[new Date(date).getDay()];//注意此处必须是先new一个Date

      return week;

    },
    cloneDeep(data) {
      return _.cloneDeep(data);
    },
    fixedValue(value) {
      return parseFloat(value).toFixed(2);
    },

    drawerBeforeClose(done) {
      this.$confirm('还有未保存的工作哦确定关闭吗？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    isJSON(str) {
      if (typeof str == 'string') {
        try {
          var obj = JSON.parse(str);
          if (typeof obj == 'object' && obj) {
            return true;
          } else {
            return false;
          }

        } catch (e) {
          return false;
        }
      }
      return false;
    },
    isHttp(str) {
      if (str.substr(0, 7) == 'http://' || str.substr(0, 8) == 'https://') {
        return true;
      }
      return false;
    },
    // 视频里的时间格式化 例：03:50
    secondsFormatTime(value) {
      if (typeof value !== "number") {
        return value;
      }
      // 处理秒数精度问题
      value = Math.round(value * 1000) / 1000;

      let minutes = Math.floor(value / 60);
      let seconds = value % 60;

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds.toFixed(0) : seconds.toFixed(0);

      return minutes + ':' + seconds;
    },
    //计算视频大小——以KB\MB\GB为单位
    formatFileSize(size) {
      if (typeof size !== "number") {
        return size;
      }
      if (size < 1024) return size + ' B';
      else if (size < 1024 * 1024) return (size / 1024).toFixed(2) + ' KB';
      else if (size < 1024 * 1024 * 1024) return (size / (1024 * 1024)).toFixed(2) + ' MB';
      else return (size / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
    },
    // 计算剩余时间的函数
    calculateRemainingTime(expireTime) {
      if (!expireTime) {
        return '00:00';
      }
      // 将 expireTime 转换为时间对象
      const expireDate = new Date(expireTime);
      const currentTime = new Date(); // 当前时间
      const timeDifference = expireDate - currentTime; // 计算到期时间与当前时间的差值，单位为毫秒
      if (timeDifference <= 0) {
        return '00:00';
      }
      // 计算小时、分钟、秒
      // const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
      let minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
      let seconds = Math.floor((timeDifference / 1000) % 60);

      // hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds.toFixed(0) : seconds.toFixed(0);

      // 格式化输出
      // return hours + ':' + minutes + ':' + seconds;
      return minutes + ':' + seconds;
    },
    //返回对应的素材格式
    getTypeByUrl(url) {
      if (!url) {
        return ''
      }
      if (/\.(png|jpg|jpeg|webp)$/i.test(url)) {
        return 'image';
      } else if (/\.(mp4|avi|mkv|mov)$/i.test(url)) {
        return 'video';
      } else if (/\.(mp3|aac|wav|m4a)$/i.test(url)) {
        return 'audio';
      } else {
        return '';
      }
    },
    //统一配额单位
    formatQuota(size) {
      if (typeof size === 'string') {
        // 匹配数值和单位部分
        let match = size.match(/^([\d.]+)\s*(GB|MB|KB|G|M|K|B|分钟)?$/);
        if (match) {
          let value = parseFloat(match[1]);  // 提取数值部分
          let unit = match[2];               // 提取单位部分

          // 根据不同单位转换数值
          switch (unit) {
            case 'GB':
            case 'G':
              return value * 1024 * 1024;    // 转换为 KB
            case 'MB':
            case 'M':
              return value * 1024;           // 转换为 KB
            case 'KB':
            case 'K':
              return value;                  // 保持为 KB
            case 'B':
              return value / 1024;           // 转换为 KB
            case '分钟':
              return value;                  // 分钟直接返回数值
            default:
              return value;                  // 没有单位的情况直接返回数值
          }
        }
      }
      return Number(size);
    },
    // 是否超出配额
    isExceedQuota(use, total){
      console.log('isExceedQuota-use',use,'total',total)
      if(use && total && this.formatQuota(use) > this.formatQuota(total)){
        return true
      }
      return false
    },
    // 下载文件
    downloadFile(url) {
      if (url) {
        console.log('downloadFile-url-before', url)
        // 替换以指定前缀开头的 URL
        const oldPrefix1 = 'http://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com';
        const oldPrefix2 = 'https://yfwx-cg-publishing.oss-cn-beijing.aliyuncs.com';
        // const newPrefix = 'https://pub.grds.cn/oss';
        if (url.startsWith(oldPrefix1)) {
          url = url.replace(oldPrefix1, oldPrefix2);
        }
        // else if (url.startsWith(oldPrefix2)) {
        //   url = url.replace(oldPrefix2, newPrefix);
        // }
        const filename = url.split('/').pop();
        console.log('downloadFile-url-after', url, filename)
        download.oss(url,filename);// 当前页开启a标签下载，目前个别网址只支持查看，不支持下载，会有问题，直接刷新了当前的网页
        // downloadFile(url);// 后台下载，成功之后才提示到前端下载成功，会导致用户不知道自己已经点击下载了，也看不到下载进度，重复点击会导致窗口“阻止自动下载多个文件”的提示
        // window.open(url, '_blank');//暂时先用这个
      } else {
        this.$modal.msgError('链接不存在');
      }
    }
  },
}
