import request from '@/utils/request'
//成品库管理
const baseApi = '/admin/product'

// 列表
export function listProduct(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}
// 详情
export function getProductDetail(id) {
  return request({
      url: baseApi + '/' + id,
      method: 'get'
  })
}
// 新增
export function addProduct(data) {
  return request({
    url: baseApi,
    method: 'post',
    data: data
  })
}
// 带文件上传
export function addMultProduct(data, onProgress) {
  return request({
    url: baseApi,
    method: 'post',
    data,  // 直接发送 FormData 对象
    headers: {
      'Content-Type': 'multipart/form-data' // 明确设置为文件传输格式
    },
    onUploadProgress: onProgress // 上传进度回调
  });
}
// 修改
export function editProduct(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}
// 删除——删除文件和文件夹
export function removeProductAll(data) {
  return request({
    url: baseApi + '/remove',
    method: 'delete',
    data
  })
}
