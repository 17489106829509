// src/utils/downloadUtil.js

export async function downloadFile(url) {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });

    if (!response.ok) {
      throw new Error('网络响应不正常');
    }

    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = getFileNameFromUrl(url);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('获取操作出现问题：', error);
  }
}

function getFileNameFromUrl(url) {
  return url.substring(url.lastIndexOf('/') + 1);
}
