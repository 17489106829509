var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex-row", staticStyle: { width: "100%" } },
    [
      _c(
        "div",
        {
          staticClass: "f-grow editor-scroll",
          staticStyle: { padding: "23px" },
        },
        [
          _c("BasicInfo", {
            ref: "basicInfo",
            attrs: { value: _vm.item },
            on: {
              "item-updated": _vm.handleItemUpdated,
              "update-background": _vm.updateBackgroundImageUrl,
              "update-videoDuration": _vm.updateVedioDuration,
              remove: function ($event) {
                return _vm.$emit("remove", _vm.item)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "editor-scroll",
          staticStyle: {
            width: "27%",
            padding: "20px",
            "border-left": "1px solid rgba(229,231,235,1)",
            "border-right": "1px solid rgba(229,231,235,1)",
          },
        },
        [
          _c("div", { staticClass: "videoTitle" }, [
            _vm._v(_vm._s(_vm.$t("basicInfo.sceneSettings"))),
          ]),
          _c("div", { staticClass: "set-des" }, [
            _vm._v(_vm._s(_vm.$t("videoSetting.subtitleTitleEffects"))),
          ]),
          _c(
            "el-collapse",
            {
              attrs: { accordion: "" },
              model: {
                value: _vm.activeNames,
                callback: function ($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames",
              },
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { title: _vm.$t("subtitle.subtitle"), name: "1" } },
                [
                  _c("SubtitleSettings", {
                    ref: "subtitleInfo",
                    attrs: {
                      value: _vm.item.audioTrackClipsBos,
                      openSubtitle: _vm.item.openSubtitle,
                      "background-image-url": _vm.backgroundImageUrl,
                      "video-duration": _vm.videoDuration,
                      effectColorStyleList: _vm.effectColorStyleList,
                    },
                    on: {
                      "item-updated": _vm.updatedAudio,
                      "item-updated-open": _vm.updatedOpenSubtitle,
                      "update-subtitle-style": _vm.updateSubtitleStyle1,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-collapse-item",
                { attrs: { title: _vm.$t("title.title"), name: "2" } },
                [
                  _c("TitleSettings", {
                    ref: "titleInfo",
                    attrs: {
                      value: _vm.item.titleTrackClipsBo,
                      openTitle: _vm.item.openTitle,
                      "background-image-url": _vm.backgroundImageUrl,
                      effectColorStyleList: _vm.effectColorStyleList,
                    },
                    on: {
                      "item-updated": _vm.updatedTitle,
                      "item-updated-open": _vm.updatedOpenTitle,
                      "update-subtitle-style": _vm.updateSubtitleStyle2,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "editor-scroll",
          staticStyle: { width: "293px", padding: "20px" },
        },
        [
          _c("div", { staticClass: "videoTitle" }, [
            _vm._v(_vm._s(_vm.$t("videoEditor.effectPreview"))),
          ]),
          _c("div", { staticClass: "set-des" }, [
            _vm._v(_vm._s(_vm.$t("videoEditor.viewConfigEffect"))),
          ]),
          _c("div", { staticClass: "preview", style: _vm.previewStyle }, [
            _c(
              "p",
              {
                style:
                  _vm.activeNames == "1"
                    ? _vm.subtitlePreviewStyle1
                    : _vm.subtitlePreviewStyle2,
              },
              [_vm._v(_vm._s(_vm.getContent))]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }