var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "destroy-on-close": "",
        visible: _vm.localVisible,
        title: _vm.$t("mall.pay"),
        "before-close": _vm.handleBeforeClose,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "70%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.localVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticStyle: { "text-align": "center" } },
        [
          _c("div", { staticStyle: { color: "#404E63" } }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("mall.transactionNumber")) +
                  "：" +
                  _vm._s((_vm.orderInfo && _vm.orderInfo.outTradeNo) || "--")
              ),
            ]),
          ]),
          _c("h2", [
            _vm._v(_vm._s(_vm.$t("mall.paymentAmount")) + ":"),
            _c("span", { staticStyle: { color: "#C3923F" } }, [
              _vm._v(
                "￥" +
                  _vm._s((_vm.orderInfo && _vm.orderInfo.totalMoneyAll) || "--")
              ),
            ]),
          ]),
          _c("el-divider"),
          _c(
            "div",
            { staticStyle: { height: "50%" } },
            [
              _c("span", [_vm._v(_vm._s(_vm.$t("mall.wechatScan")))]),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("mall.remainingTime")) +
                    "：" +
                    _vm._s(_vm.countDown)
                ),
              ]),
              _c("br"),
              _c("vue-qr", {
                attrs: {
                  logoSrc: _vm.imageUrl,
                  text: _vm.orderInfo && _vm.orderInfo.qrCode,
                  size: 200,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }