var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    { attrs: { name: "fade-transform", mode: "out-in" } },
    _vm._l(_vm.iframeViews, function (item, index) {
      return _c("inner-link", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.$route.path === item.path,
            expression: "$route.path === item.path",
          },
        ],
        key: item.path,
        attrs: {
          iframeId: "iframe" + index,
          src: _vm.iframeUrl(item.meta.link, item.query),
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }