import request from '@/utils/request'
//ai视频生成
const baseApi = '/admin/aiVideo'
// status：0接收到提示语（默认状态）1分析成功（素材、脚本生成成功）2分析失败（素材脚本生成失败）3确认脚本（脚本已确认，或已修改）
// generateVideoStatus(脚本确认后，开始修改这个状态)：0默认1排队中（生成任务队列排队中）2生成成功3生成失败
// chooseSocialStatus(视频生成成功后开始修改这个状态）：0默认1选择账号（社交账号已经选择并保存后的状态）
// generatePostStatus：0默认1生成成功2生成失败
// publishSocialStatus：0默认1推送队列2发布成功3发布失败
// 状态从上至下依次修改

// 开启会话（第一次提问）——改成新建会话调用，无需传参
export function openAiSession(data) {
  return request({
    url: baseApi + '/openAiSession',
    method: 'post',
    data: data
  })
}
//聊天：一次性返回，不是流式输出，返回之后用户可以再次随便发消息，不需要限制了，问啥都行，也能聊天
// 但是ai会引导你告诉他剪辑视频的几个要素：主题、面向国家、语种，时长，一旦ai知道了四要素，
// 就会返回固定格式{"theme":"","country":"","language":"","duration":""}，否则会继续返回引导你的话。前端要参考星谷云，显示：视频主题是XXX，时长为XX，语言为XX，面向国家是XXX。
// 然后加个确认按钮，或“不满意”按钮，具体名字找产品确认。点击确认的话，调下个接口，反之继续聊天
export function chat(data) {
  return request({
    url: baseApi + '/chat',
    method: 'post',
    data: data
  })
}
//确认关键词
export function confirmKeywords(params) {
  return request({
    url: baseApi + '/confirmKeywords',
    method: 'post',
    params
  })
}
// 确认脚本
export function confirmScript(data) {
  return request({
    url: baseApi + '/confirmScript',
    method: 'post',
    data: data
  })
}
// 详情——确认视频状态
export function getAiSatus(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'get'
  })
}
// 选择社媒
export function chooseSocial(data) {
  return request({
    url: baseApi + '/chooseSocial',
    method: 'post',
    data: data
  })
}
// 生成帖子文案
export function generatePost(data) {
  return request({
    url: baseApi + '/generatePost',
    method: 'post',
    data: data
  })
}
// 发布社媒
export function publishAi(data) {
  return request({
    url: baseApi +'/publish',
    method: 'post',
    data: data
  })
}

// 修改
export function editAiSetting(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}


