var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container flex-center" }, [
    _c("div", { staticClass: "login-main flex-row" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "login-bg-50 flex-column" },
        [
          _c("lang-select", { staticClass: "set-language self-end" }),
          _c("img", {
            staticClass: "login-logo",
            attrs: { src: require("@/assets/logo/logo.png"), alt: "" },
          }),
          _c(
            "el-form",
            {
              ref: "loginForm",
              staticClass: "login-form",
              attrs: { model: _vm.loginForm, rules: _vm.loginRules },
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex-row-center between",
                  staticStyle: { "margin-bottom": "32px" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "title-container flex-column-center" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "title",
                          style:
                            _vm.loginForm.loginType !== "username"
                              ? "color:rgba(0,0,0,0.4);"
                              : "color:black;",
                          on: {
                            click: function ($event) {
                              return _vm.changeLoginType("username")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("login.accountLogin")))]
                      ),
                      _vm.loginForm.loginType == "username"
                        ? _c("div", { staticClass: "title-line" })
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "title-container flex-column-center" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "title",
                          style:
                            _vm.loginForm.loginType !== "phone"
                              ? "color:rgba(0,0,0,0.4);"
                              : "color:black;",
                          on: {
                            click: function ($event) {
                              return _vm.changeLoginType("phone")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("login.mobileLogin")))]
                      ),
                      _vm.loginForm.loginType == "phone"
                        ? _c("div", { staticClass: "title-line" })
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
              _vm.loginForm.loginType === "username"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "username", "label-width": "0" } },
                        [
                          _c("el-input", {
                            attrs: {
                              name: _vm.usernameInputName,
                              autocomplete: _vm.autocompleteOff,
                              type: "text",
                              "auto-complete": "off",
                              placeholder: _vm.$t("loginNew.inputAccount"),
                            },
                            model: {
                              value: _vm.loginForm.username,
                              callback: function ($$v) {
                                _vm.$set(_vm.loginForm, "username", $$v)
                              },
                              expression: "loginForm.username",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "password" } },
                        [
                          _c("el-input", {
                            attrs: {
                              name: _vm.passwordInputName,
                              autocomplete: _vm.autocompleteOff,
                              type: "password",
                              "auto-complete": "off",
                              "show-password": "",
                              placeholder: _vm.$t("loginNew.inputPassword"),
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleLogin($event)
                              },
                            },
                            model: {
                              value: _vm.loginForm.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.loginForm, "password", $$v)
                              },
                              expression: "loginForm.password",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.captchaEnabled
                        ? _c(
                            "el-form-item",
                            { attrs: { prop: "code" } },
                            [
                              _c(
                                "el-input",
                                {
                                  attrs: {
                                    "auto-complete": "off",
                                    placeholder: _vm.$t(
                                      "loginNew.inputCaptcha"
                                    ),
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.handleLogin($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.loginForm.code,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.loginForm, "code", $$v)
                                    },
                                    expression: "loginForm.code",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "el-input__icon input-icon login-code",
                                      attrs: { slot: "suffix" },
                                      slot: "suffix",
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "login-code-img",
                                        attrs: { src: _vm.codeUrl },
                                        on: { click: _vm.getCode },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "phone", "label-width": "0" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              "auto-complete": "off",
                              placeholder: _vm.$t("loginNew.inputPhone"),
                            },
                            model: {
                              value: _vm.loginForm.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.loginForm, "phone", $$v)
                              },
                              expression: "loginForm.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "imgCode" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                "auto-complete": "off",
                                placeholder: _vm.$t(
                                  "login.imageVerificationCode"
                                ),
                              },
                              model: {
                                value: _vm.loginForm.imgCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.loginForm, "imgCode", $$v)
                                },
                                expression: "loginForm.imgCode",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "el-input__icon input-icon login-code",
                                  attrs: { slot: "suffix" },
                                  slot: "suffix",
                                },
                                [
                                  _c("img", {
                                    staticClass: "login-code-img",
                                    attrs: { src: _vm.codeUrl },
                                    on: { click: _vm.getCode },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "code" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                "auto-complete": "off",
                                placeholder: _vm.$t("login.verificationCode"),
                              },
                              model: {
                                value: _vm.loginForm.code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.loginForm, "code", $$v)
                                },
                                expression: "loginForm.code",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "el-input__icon input-icon phone-code",
                                  attrs: { slot: "suffix" },
                                  slot: "suffix",
                                },
                                [
                                  !_vm.isDisabled
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "phone-code-img",
                                          attrs: { loading: _vm.phoneLoading },
                                          on: {
                                            click: function ($event) {
                                              return _vm.sendPhone()
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.phoneContent) + " ")]
                                      )
                                    : _vm._e(),
                                  _vm.isDisabled
                                    ? _c(
                                        "el-button",
                                        { staticClass: "phone-code-img" },
                                        [_vm._v(_vm._s(_vm.phoneContent))]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _c(
                "div",
                { staticClass: "flex-row-center f-between mt16 ml10 mr10" },
                [
                  _vm.register
                    ? _c("div", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#194BFB",
                              cursor: "pointer",
                              "font-size": "14px",
                            },
                            on: { click: _vm.gotoRegister },
                          },
                          [_vm._v(_vm._s(_vm.$t("loginNew.registerAccount")))]
                        ),
                      ])
                    : _vm._e(),
                  _vm.loginForm.loginType === "username"
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            color: "rgba(0,0,0,0.4)",
                            cursor: "pointer",
                            "font-size": "14px",
                          },
                          on: { click: _vm.gotoRorgot },
                        },
                        [_vm._v(_vm._s(_vm.$t("loginNew.forgotPassword")))]
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "el-button",
                {
                  staticClass: "login-btn",
                  staticStyle: { "margin-top": "8px" },
                  attrs: { loading: _vm.loading },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleLogin($event)
                    },
                  },
                },
                [
                  !_vm.loading
                    ? _c("span", [_vm._v(_vm._s(_vm.$t("login.logIn")))])
                    : _c("span", [_vm._v(_vm._s(_vm.$t("login.logIng")))]),
                ]
              ),
              _c("el-form-item", { attrs: { prop: "agreed" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "flex-wrap": "wrap",
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.loginForm.agreed,
                          expression: "loginForm.agreed",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.loginForm.agreed)
                          ? _vm._i(_vm.loginForm.agreed, null) > -1
                          : _vm.loginForm.agreed,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.loginForm.agreed,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.loginForm,
                                  "agreed",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.loginForm,
                                  "agreed",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.loginForm, "agreed", $$c)
                          }
                        },
                      },
                    }),
                    _c(
                      "span",
                      { staticStyle: { "margin-left": "8px" } },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("agreed.readAndAgree")) + " "
                        ),
                        _c(
                          "el-link",
                          {
                            staticStyle: {
                              "vertical-align": "middle",
                              color: "#194BFB",
                            },
                            attrs: {
                              type: "primary",
                              underline: false,
                              href: "/service",
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("agreed.userAgreement")))]
                        ),
                        _vm._v(" " + _vm._s(_vm.$t("agreed.and")) + " "),
                        _c(
                          "el-link",
                          {
                            staticStyle: {
                              "vertical-align": "middle",
                              color: "#194BFB",
                            },
                            attrs: {
                              type: "primary",
                              underline: false,
                              href: "/privacy",
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("agreed.privacyPolicy")))]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login-bg-50 flex-column-center" }, [
      _c("img", {
        staticClass: "login-img-left",
        attrs: { src: require("@/assets/images/login/bg-small.jpg"), alt: "" },
      }),
      _c("div", { staticClass: "left-logo" }, [_vm._v("InfoPub")]),
      _c("div", { staticClass: "left-des f-grow" }, [
        _vm._v("智能创作发布管理系统"),
      ]),
      _c("div", { staticClass: "left-bottom" }, [_vm._v("一键发布多个平台")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "el-login-footer" }, [
      _c("span", [_vm._v("Copyright © 2022-2025 Infonity AI ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }