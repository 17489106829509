var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "j-emoji-picker" },
    [
      _c("JTabs", {
        attrs: { tabs: _vm.emojiJson },
        on: { tabClick: _vm.tabClick },
      }),
      _c(
        "div",
        { ref: "emojiPickerContent", staticClass: "j-emoji-picker-content" },
        [
          _c(
            "div",
            {
              staticClass: "j-emoji-picker-content-tab",
              on: {
                mousedown: _vm.handleMouseDown,
                touchstart: _vm.handleTouchStart,
                touchmove: _vm.handleTouchMove,
                touchend: _vm.handleTouchEnd,
              },
            },
            _vm._l(_vm.activatedTab.list, function (emoji) {
              return _c(
                "div",
                {
                  key: emoji.name,
                  staticClass: "j-emoji-picker-content-tab-item",
                  attrs: { title: emoji.name },
                  on: {
                    click: function ($event) {
                      return _vm.emojiClick(emoji)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    { staticClass: "j-emoji-picker-content-tab-item-font" },
                    [_vm._v(_vm._s(emoji.font))]
                  ),
                ]
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }