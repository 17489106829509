var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.localVisible,
        title: _vm.$t("template.scriptTemplate"),
        "before-close": _vm.handleBeforeClose,
        "append-to-body": _vm.isInner,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.localVisible = $event
        },
      },
    },
    [
      _c("h3", [_vm._v(_vm._s(_vm.template.title))]),
      _c("p", [_vm._v(_vm._s(_vm.template.description))]),
      _c(
        "div",
        { staticClass: "steps" },
        _vm._l(_vm.template.details, function (step, i) {
          return _c("div", { key: i, staticClass: "mt5" }, [
            _c("span", { staticClass: "step mt10" }, [
              _vm._v(_vm._s(step.label)),
            ]),
            _c("div", [
              _c("div", { staticClass: "mt5" }, [_vm._v(_vm._s(step.value))]),
              _c("div", { staticClass: "mt5" }, [_vm._v(_vm._s(step.example))]),
            ]),
          ])
        }),
        0
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleConfirm } },
            [_vm._v(_vm._s(_vm.$t("template.applyTemplate")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }