<template>
  <div class="material-detail-container">
    <div class="title-header">
      <el-page-header @back="goBack">
        <template #content>
          <div class="title-header-text">素材详情</div>
        </template>
      </el-page-header>
    </div>
    <div
      class="material-content"
      v-loading="loading"
    >
      <div class="mb20">
        <img
          v-if="detail.fileType === 'image'"
          :src="detail.url"
          alt="image"
          class="image-viewer"
        />
        <audio
          v-else-if="detail.fileType=='audio'"
          controls
          :src="detail.url"
          class="audio-player"
          ref="audioPlayer"
        ></audio>
        <video
          v-else-if="detail.fileType=='video'"
          controls
          :src="detail.url"
          class="video-player"
          ref="videoPlayer"
        ></video>
      </div>
      <div class="material-title">
        {{ detail.fileType == 'text' ? detail.title :detail.name }}
      </div>
      <div class="material-content-text">
        {{ detail.fileType == 'text' ? detail.content :detail.description }}
      </div>
      <div class="mt20">
        <el-tag
          v-for="(tag, index) in detail.labels"
          :key="index"
          style="margin-right: 5px; margin-bottom: 5px;"
        >
          {{ tag }}
        </el-tag>
      </div>
      <el-descriptions
        title=""
        label-class-name="my-label"
        class="f-grow"
        :column="2"
        border
      >
        <el-descriptions-item v-if="detail.width && detail.height">
          <template slot="label">
            素材尺寸
          </template>
          <span>
            {{ detail.width }} * {{ detail.height }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item v-if="detail.fileSize">
          <template slot="label">
            文件大小
          </template>
          <span>
            {{ formatFileSize(detail.fileSize) }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item v-if="detail.videoLength">
          <template slot="label">
            视频时长
          </template>
          <span>
            {{ secondsFormatTime(detail.videoLength) }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            创建时间
          </template>
          <span>
            {{ detail.createTime || '--' }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item v-if="detail.splitVideoPartsStatus || detail.mergeVideoFaceStatus || detail.eraseVideoSubtitlesStatus || detail.jobStatus">
          <template slot="label">
            任务
          </template>
          <el-tooltip
            :content="detail.jobError"
            v-if="detail.jobError"
          >
            <div
              class="controlsStatus"
              :class="[filterState(detail)]"
              v-if="detail.splitVideoPartsStatus || detail.mergeVideoFaceStatus || detail.eraseVideoSubtitlesStatus || detail.jobStatus"
            >{{ statusMessage }}</div>
          </el-tooltip>
          <span
            class="controlsStatus"
            :class="[filterState(detail)]"
          >
            {{ statusMessage }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item v-if="detail.jobFinishedTime">
          <template slot="label">
            任务完成时间
          </template>
          <span>
            {{ detail.jobFinishedTime || '--' }}
          </span>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            素材格式
          </template>
          <span>
            {{ getText() }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item v-if="detail.allName">
          <template slot="label">
            素材位置
          </template>
          <span>
            {{ detail.allName }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            素材编号
          </template>
          <span>
            {{ detail.id }}
          </span>
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>

<script>
import { getMaterialDetail } from "../../api/ai/material";

export default {
  name: "MaterialDetail",
  data() {
    return {
      loading: false,
      detail: {},
    };
  },
  computed: {
    statusMessage() {
      const statusMessages = [];
      const { eraseVideoSubtitlesStatus, mergeVideoFaceStatus, jobStatus } =
        this.detail;

      if (eraseVideoSubtitlesStatus === 1 || mergeVideoFaceStatus === 1) {
        return this.$t("videoItem.generating");
      }
      if (eraseVideoSubtitlesStatus === 0 && mergeVideoFaceStatus === 0) {
        return ""; // 默认状态，不需要显示任何消息
      }
      // 拼接其他状态的消息
      if (eraseVideoSubtitlesStatus === 2) {
        statusMessages.push(this.$t("videoItem.removeSubtitleSuccess"));
      } else if (eraseVideoSubtitlesStatus === 3) {
        statusMessages.push(this.$t("videoItem.removeSubtitleFail"));
      }
      if (mergeVideoFaceStatus === 2) {
        statusMessages.push(this.$t("videoItem.aiFaceSwapSuccess"));
      } else if (mergeVideoFaceStatus === 3) {
        statusMessages.push(this.$t("videoItem.aiFaceSwapFail"));
      }
      if (jobStatus === "Executing") {
        statusMessages.push("翻译中…");
      } else if (jobStatus === "Finished") {
        statusMessages.push("翻译成功");
      } else if (jobStatus === "Failed") {
        statusMessages.push("翻译失败");
      }
      return statusMessages.join(" ");
    },
  },
  created() {
    this.id = this.$route.query.id || "";
    if (this.id) {
      this.getDetail();
    }
  },
  methods: {
    filterState(item) {
      const { eraseVideoSubtitlesStatus, mergeVideoFaceStatus, jobStatus } =
        item;
      if (eraseVideoSubtitlesStatus === 1 || mergeVideoFaceStatus === 1) {
        return "loadingTag";
      }
      if (eraseVideoSubtitlesStatus === 0 && mergeVideoFaceStatus === 0) {
        return "";
      }
      if (eraseVideoSubtitlesStatus === 2) {
        return "successTag";
      } else if (eraseVideoSubtitlesStatus === 3) {
        return "errorTag";
      }
      if (mergeVideoFaceStatus === 2) {
        return "successTag";
      } else if (mergeVideoFaceStatus === 3) {
        return "errorTag";
      }
      if (jobStatus === "Executing") {
        return "loadingTag";
      } else if (jobStatus === "Finished") {
        return "successTag";
      } else if (jobStatus === "Failed") {
        return "errorTag";
      }
    },
    goBack() {
      // this.$router.go(-1)
      this.$tab.closeOpenPage({ path: "/material" });
    },
    getText() {
      switch (this.detail.fileType) {
        case "image":
          return "图片";
        case "audio":
          return "音乐";
        case "video":
          return "视频";
        case "text":
          return "文本";
        default:
          return "其他";
      }
    },
    getDetail() {
      this.loading = true;
      getMaterialDetail(this.id)
        .then((res) => {
          this.detail = res.data;
          if (this.detail.label) {
            this.detail.labels = this.detail.label.split(",");
          } else {
            this.detail.labels = [];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.material-detail-container {
  margin: 15px;
}
.material-content {
  padding: 37px 195px;
  background: #ffffff;
  border: 1px solid rgba(229, 231, 235, 1);
  border-radius: 14px;
}
.material-title {
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 24px;
  text-align: left;
  font-style: normal;
}
.material-content-text {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #71717a;
  line-height: 24px;
  text-align: left;
  font-style: normal;
  margin-top: 16px;
}
.my-label {
  width: 120px;
}

.video-player {
  max-height: 400px;
}
.image-viewer {
  /* max-width: 100%; */
  max-height: 400px;
  display: block;
  margin: auto;
}
.audio-player {
  width: 100%;
}
.controlsStatus {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 0 5px;
  /* width: 56px; */
  height: 20px;
  /* background: #006CFF; */
  border-radius: 10px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.9);
  line-height: 20px;
  text-align: center;
  font-style: normal;
  cursor: pointer;
}

.errorTag {
  background: #ff0000;
}
.successTag {
  background: #006cff;
}
.loadingTag {
  background: #ffbb00;
}
</style>
