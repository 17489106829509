import request from '@/utils/request'

// 短信验证码
export function sendMyPhone(data) {
  return request({
    url: '/resource/sms/code',
    method: 'post',
    params: data
  })
}

// 邮箱验证码
export function sendEmilCode(data) {
  return request({
    url: '/resource/email/code',
    method: 'post',
    params: data
  })
}

// 查询行业下拉树结构
export function industryCategoryTreeSelect() {
  return request({
    url: '/open-api/industryCategory/tree',
    method: 'get'
  })
}
