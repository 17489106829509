import request from '@/utils/request'
// 查询商品列表（卖家）
const baseApi = '/mall/goods'
//列表
export function myGoodsList(query) {
  return request({
    url: baseApi + '/myGoodsList',
    method: 'get',
    params: query
  })
}

// 详情
export function getMyGoods(id) {
  return request({
    url: baseApi + '/myGoods/' + id,
    method: 'get'
  })
}

// 删除
export function delMyGoods(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'delete'
  })
}

// 上架
export function upMyGoods(id) {
  return request({
    url: baseApi + '/up/' + id,
    method: 'post',
  })
}

// 下架
export function downMyGoods(id) {
  return request({
    url: baseApi + '/down/' + id,
    method: 'post',
  })
}
// 新增
export function addMyGoods(data) {
  return request({
    url: baseApi,
    method: 'post',
    data: data
  })
}
// 修改
export function updateMyGoods(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}

