import { listAccount } from '../../api/account/tiktok'

const state = {
  accountList: [],
  profileTypes: [
    'tiktok',
    'instagram',
    'youtube',
    'facebook',
    'linkedin',
    'twitter',
    // 'gmb',
    // 'pinterest',
    // 'reddit',
    // 'telegram',
  ],
}

const mutations = {
  SET_ACCOUNT_LIST(state, payload) {
    state.accountList = payload
  },
}

const actions = {
  async getAccountList({ commit }, payload) {
    try {
      const { rows } = await listAccount(payload)
      const newList = state.profileTypes.reduce((acc, platform) => {
        const filteredData = rows.filter(row => row.platform === platform);
        if (filteredData.length > 0) {
          acc.push({ platform, data: filteredData, isActive: false });
        }
        return acc;
      }, []);
      console.log('SET_ACCOUNT_LIST', newList);
      commit('SET_ACCOUNT_LIST', newList)
    } catch (e) {
      console.log(e);
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
