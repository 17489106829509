<!-- 模板列表的item -->
<template>
  <div class="template-card"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <h3>{{ item.title }}</h3>
    <p>{{ item.description }}</p>
    <div class="steps">
      <div  v-for="(step, i) in item.details" :key="i" class="mt5">
        <span class="step">{{ step.label }}</span>
        <span v-if="i < item.details.length - 1">→</span>
      </div>
    </div>
    <div v-if="hover" class="overlay">
      <el-button @click="$emit('view-template', item)">{{$t('view')}}</el-button>
      <el-button type="primary" @click="$emit('apply-template', item)" style="margin: 0;">{{$t('template.apply')}}</el-button>
    </div>
  </div>
</template>
<script>

export default {
  name: 'templateItem',
  components: {  },
  props: {
    template: {
      type: Object,
      default: () => ({
        hover: false // 添加 hover 参数的默认值
    })
  }
  },
  data () {
    return {
      item: this.template,
      hover: this.template.hover || false,
    }
  },
  watch: {
    template: {
      handler(val) {
        this.item = val;
        this.hover = val.hover || false; // 更新 hover
      },
      deep: true
    }
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.template-card {
  position: relative; /* 确保蒙层相对于模板卡片定位 */
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}
.steps {
  color: blue;
  display: flex;
  flex-wrap: wrap;
}
.step {
  padding: 3px 6px;
  border:1px solid blue;
  border-radius: 5px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  border-radius: 5px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.overlay el-button {
  color: #fff;
}
</style>
