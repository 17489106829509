<template>
  <div class="app-container">
    <el-row :gutter="20" v-loading="loading">
      <el-col :span="6" :xs="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>{{$t('user.userInfo')}}</span>
          </div>
          <div>
            <div class="text-center">
              <userAvatar :user="user"/>
            </div>
            <ul class="list-group list-group-striped">
              <!-- <li class="list-group-item">
                <svg-icon icon-class="home"/>
                {{$t('register.companyName')}}
                <div class="pull-right">{{ user.companyName }}</div>
              </li> -->
              <li class="list-group-item">
                <svg-icon icon-class="user"/>
                {{$t('user.userName')}}
                <div class="pull-right">{{ user.userName }}</div>
              </li>
              <li class="list-group-item">
                <svg-icon icon-class="phone"/>
                {{$t('user.mobilePhone')}}
                <div class="pull-right">{{ user.phonenumber }}</div>
              </li>
              <li class="list-group-item">
                <svg-icon icon-class="email"/>
                {{$t('user.userEmail')}}
                <div class="pull-right">{{ user.email }}</div>
              </li>
                <!--
                              <li class="list-group-item">
                                <svg-icon icon-class="tree" />所属部门
                                <div class="pull-right" v-if="user.dept">
                                  {{ user.dept.deptName }} / {{ postGroup }}
                                </div>
                              </li>-->
                <li class="list-group-item">
                  <svg-icon icon-class="peoples"/>
                  {{$t('user.userRole')}}
                  <div class="pull-right">{{ roleGroup }}</div>
                </li>
                <li class="list-group-item">
                  <svg-icon icon-class="date"/>
                  {{$t('user.creationDate')}}
                  <div class="pull-right">{{ user.createTime }}</div>
                </li>
            </ul>
          </div>
        </el-card>
      </el-col>
      <el-col :span="18" :xs="24">
        <el-card>
          <div slot="header" class="clearfix">
            <span>{{$t('user.basicInfo')}}</span>
          </div>
          <el-tabs v-model="activeTab">
            <el-tab-pane :label="$t('user.basicInfo')" name="userinfo">
              <userInfo :user="user"/>
            </el-tab-pane>
            <el-tab-pane
              :label="$t('user.changePassword')"
              name="resetPwd"
              v-if="userInfo.registerType != 'yizhan_shop'"
            >
              <resetPwd/>
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import userAvatar from "./userAvatar";
import userInfo from "./userInfo";
import resetPwd from "./resetPwd";
import {getUserProfile} from "@/api/system/user";
import { myMerchant } from "../../../../api/system/merchant";

export default {
  name: "Profile",
  components: {userAvatar, userInfo, resetPwd},
  data() {
    return {
      user: {},
      roleGroup: {},
      postGroup: {},
      activeTab: "userinfo",
      merchant: {},
      loading: false
    };
  },
  async created() {
    let redirectPath = this.$route.query.redirectPath
    if (redirectPath != null) {
      this.activeTab = "resetPwd"
    }
    this.loading = true
    await this.getUser();
    this.getMerchant()
  },
  methods: {
    async getUser() {
      getUserProfile().then((response) => {
        this.user = response.data.user;
        this.roleGroup = response.data.roleGroup;
        this.postGroup = response.data.postGroup;
      });
    },
    getMerchant() {
      myMerchant().then((response) => {
        this.merchant = response.data
        this.user.companyName = this.merchant.companyName || ''
        this.user.industryCategory = this.merchant.industryCategory || ''
        this.user.industryCategorys = this.user.industryCategory.split(',')
        this.user.brand = this.merchant.brand || ''
        this.user.product = this.merchant.product || ''
      }).finally(() => {
        this.loading = false
      })
    }
  },
};
</script>
