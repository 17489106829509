import request from '@/utils/request'
// 查询订单
const baseApi = '/mall/order'
// 列表
export function listMallOrder(query) {
  return request({
    url: baseApi + '/buyList',
    method: 'get',
    params: query
  })
}

// 详情
export function getMallOrder(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'get'
  })
}
// 取消订单
export function cancelMallOrder(id) {
  return request({
    url: baseApi + '/cancel/' + id,
    method: 'post',
  })
}
// 购买、结算
export function createMallOrder(data) {
  return request({
    url: baseApi + '/createOrder',
    method: 'post',
    data: data
  })
}

// 修改
export function updateMallOrder(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}

// 删除
export function delMallOrder(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'delete'
  })
}
// 去付款
export function payMallOrder(id) {
  return request({
    url: baseApi + '/toPay/' + id,
    method: 'post',
  })
}

// 商家-列表
export function sellListOrder(query) {
  return request({
    url: baseApi + '/sellList',
    method: 'get',
    params: query
  })
}
