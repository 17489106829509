<template>
  <div style="width: 100%;">
    <div class="flex-row-center f-between">
      <div class="videoTitle" style="background: rgba(1,86,255,0.05);padding: 4px 16px;width: 340px;">{{ $t('basicInfo.scene') }}</div>
      <!-- <i class="el-icon-delete"
         style="cursor: pointer;"
         @click="$emit('remove', basicInfo)"></i> -->
    </div>
    <div class="set-des" style="margin: 8px 16px;">{{$t('videoSetting.segmentAllocation')}}</div>

    <!-- label-position="left" -->
    <el-form :model="basicInfo" :rules="rules" ref="basicInfoForm" label-width="120px" label-position="top" >
      <el-form-item :label="$t('basicInfo.sceneName')" prop="sceneName">
        <el-input v-model="basicInfo.sceneName" :placeholder="$t('tip.input')" style="width:340px"
                  @input="handleItemUpdated"></el-input>
      </el-form-item>
      <!-- <el-form-item label="素材类型">
        <el-radio-group v-model="basicInfo.materialType">
          <el-radio label="视频素材">视频素材</el-radio>
          <el-radio label="图片素材">图片素材</el-radio>
        </el-radio-group>
      </el-form-item> -->
      <el-form-item :label="$t('basicInfo.import')" prop="videoTrackClipsBos">
        <div class="aspect-ratio-box-container">
          <uploadFile
            :type="'materialType'"
            @selectMaterial="selectMaterial"
            @showLocaMedia="showLocaMedia"
          />
          <!-- <div class="aspect-ratio-box flex-column" @click="chooseMaterial">
            <i class="el-icon-upload"></i>
            <div style="margin-top: 5px;">
              {{ $t('basicInfo.materialImport') }}
            </div>
          </div> -->
          <div
            v-for="(material, index) in basicInfo.videoTrackClipsBos"
            :key="index"
            class="aspect-ratio-box flex-column"
            @mouseover="material.hover = true"
            @mouseleave="material.hover = false"
          >
            <div class="material-container">
              <img v-if="material.fileType === 'image'||material.tracksType=== 'image'" :src="material.mediaUrl"
                   alt="material" class="material-thumbnail"/>
              <video v-if="material.fileType === 'video'||material.tracksType=== 'video'" :src="material.mediaUrl"
                     controls
                     class="material-thumbnail"></video>
              <div v-if="material.hover" class="overlay">
                <div @click="removeMaterial(index)">{{ $t('basicInfo.remove') }}</div>
                <div style="margin: 5px;" v-if="material.fileType === 'video'">|</div>
                <div @click="pruning(material,index)" v-if="material.fileType === 'video'">{{ $t('basicInfo.pruning') }}</div>
              </div>
            </div>
            <div class="video-duration" v-if="(material.fileType === 'video'||material.tracksType=== 'video') && material.out">{{ secondsFormatTime(material.out) }}</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item :label="$t('basicInfo.isMute')" prop="isMute">
        <el-switch v-model="basicInfo.isMute" :active-value="false" :inactive-value="true"
                   @change="handleItemUpdated"></el-switch>
      </el-form-item>
      <el-form-item :label="$t('basicInfo.timeType')" prop="durationType">
        <el-row>
          <el-col :span="12">
            <el-select v-model="basicInfo.durationType" @change="timeTypeChange" style="width:340px">
              <el-option
                v-for="item in computedTimeTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled">
                <span style="float: left">{{ item.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px;margin-left: 10px;">{{ item.des }}</span>
              </el-option>
            </el-select>
            <el-alert
                      v-if="basicInfo.durationType == 'audio'"
                      style="margin-top: 5px;line-height: 15px"
                      show-icon
                      center
                      effect="dark"
              :title="$t('basicInfo.previewNotSupported')"
              type="warning" :closable="false">
            </el-alert>
          </el-col>
          <el-col :span="12" v-if="basicInfo.durationType === 'regular'">
            <el-form-item prop="regularDuration" style="margin-left: 10px;">
              <el-input v-model.number="basicInfo.regularDuration" type="number" :min="0" style="width: 100px;"
                        @input="handleItemUpdated">
                <template slot="append">s</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>


      </el-form-item>
    </el-form>
    <material-drawer
      ref="materialDrawer"
      :visible.sync="drawer"
      @selectionConfirmed="selectionConfirmed"
    />
    <el-drawer
      size="50%"
      class="publicDrawer"
      :title="$t('basicInfo.pruningTitle')"
      :visible.sync="drawerPruning"
      :with-header="true"
      :wrapperClosable="false"
      :show-close="false"
    >
      <div slot="title">
        <div class="headerFlex">
          <div class="headerTitle">{{ $t('basicInfo.pruningTitle') }}</div>
          <!-- <i class="el-icon-close" @click="drawerPruning = false"></i> -->
        </div>
      </div>
      <!-- :before-close="closePruning" -->
      <pruning ref="pruning" @confirm="confirmPruning" @cancel="cancelPruning"></pruning>
    </el-drawer>
  </div>
</template>
<script>
import MaterialDrawer from '@/components/MaterialDrawer/index.vue';
import Pruning from './Pruning.vue';
import uploadFile from '../uploadFile/index.vue'

export default {
  components: {MaterialDrawer, Pruning, uploadFile},
  props: {
    value: {
      type: Object,
      default: () => ({}) // 默认值为空对象
    }
  },
  watch: {
    // 根据名称筛选部门树
    value(val) {
      // console.log('watch22' + JSON.stringify(val))
      this.basicInfo = {
        sceneName: this.$t('basicInfo.scene1'),
        isMute: true,
        durationType: 'video',
        regularDuration: 0,
        videoTrackClipsBos: [],
        ...this.value
      }
      if (this.basicInfo.videoTrackClipsBos.length > 0) {
        this.basicInfo.videoTrackClipsBos = this.basicInfo.videoTrackClipsBos.map(clip => {
          if (typeof clip.inOutList === 'string') {
            try {
              clip.inOutList = JSON.parse(clip.inOutList); // 将字符串解析为数组或对象
            } catch (e) {
              console.error('JSON解析失败: ', e);
              clip.inOutList = []; // 如果解析失败，赋予一个空数组，或者根据需求处理
            }
          }
          return {
            ...clip,
            hover: false,
            fileType: clip.tracksType || clip.fileType,
            mediaUrl: clip.mediaUrl || clip.url,
            inOutList: clip.inOutList
          };
        });
      }
      this.changeBackground()
      this.changeDuration()
      if (!(this.basicInfo && this.basicInfo.openSubtitle)) {
        if (this.basicInfo.durationType === 'audio') {
          this.basicInfo.durationType = 'video'
        }
      }

      console.log('-----basicInfo----', this.basicInfo)
    },
  },
  data() {
    return {
      basicInfo: {
        sceneName: this.$t('basicInfo.scene1'),
        isMute: true,
        durationType: 'video',
        regularDuration: 0,
        videoTrackClipsBos: [],
        ...this.value
      },
      drawer: false,//素材库
      drawerPruning: false,//粗剪
      pruningIndex: -1,
      rules: {
        sceneName: [
          {required: true, message: this.$t('basicInfo.tipSceneName'), trigger: ['blur', 'change']}
        ],
        videoTrackClipsBos: [
          {required: true, message: this.$t('basicInfo.tipImport'), trigger: ['blur', 'change']}
        ],
        regularDuration: [
          {
            validator: (rule, value, callback) => {
              if (this.basicInfo.durationType === 'regular' && (!value || value <= 0)) {
                callback(new Error(this.$t('basicInfo.tipDuration')));
              } else {
                callback();
              }
            },
            trigger: ['blur', 'change']
          }
        ]
      },
      timeTypes: [
        {
          value: 'video',
          label: this.$t('basicInfo.timeVideo'),
          des: this.$t('basicInfo.timeVideoExp'),
        },
        {
          value: 'audio',
          label: this.$t('basicInfo.timeAudio'),
          des: this.$t('basicInfo.timeAudioExp'),
          disabled: !(this.basicInfo && this.basicInfo.openSubtitle)
        },
        {
          value: 'regular',
          label: this.$t('basicInfo.timeRegular'),
          des: this.$t('basicInfo.timeRegularExp')
        },
      ]
    }
  },
  computed: {
    computedTimeTypes() {
      return [
        {
          value: 'video',
          label: this.$t('basicInfo.timeVideo'),
          des: this.$t('basicInfo.timeVideoExp'),
        },
        {
          value: 'audio',
          label: this.$t('basicInfo.timeAudio'),
          des: this.$t('basicInfo.timeAudioExp'),
          disabled: !(this.basicInfo && this.basicInfo.openSubtitle)
        },
        {
          value: 'regular',
          label: this.$t('basicInfo.timeRegular'),
          des: this.$t('basicInfo.timeRegularExp')
        }
      ];
    }
  },
  created() {
  },
  methods: {
    timeTypeChange() {
      this.basicInfo.regularDuration = 0
      this.handleItemUpdated()
    },
    //修改背景图片
    changeBackground() {
      let url = ''
      if (this.basicInfo.videoTrackClipsBos.length > 0) {
        url = this.basicInfo.videoTrackClipsBos[0].firstFrameImgUrl
      } else {
        url = ""
      }
      console.log(JSON.stringify(this.basicInfo.videoTrackClipsBos))
      this.$emit('update-background', url);
    },
    changeDuration(){
      let duration = 0
      if (this.basicInfo.videoTrackClipsBos.length > 0) {
        duration = this.basicInfo.videoTrackClipsBos.reduce((minOut, clip) => {
          return Math.min(minOut, clip.out);
        }, this.basicInfo.videoTrackClipsBos[0].out);
      }
      console.log('duration',duration)
      this.$emit('update-videoDuration', duration);
    },
    //更新数据到上个页面
    handleItemUpdated() {
      this.$emit('item-updated', this.basicInfo);
    },
    init(data) {
      this.basicInfo = {...data}
    },
    selectMaterial() {
      this.chooseMaterial()
    },
    chooseMaterial() {
      // this.$router.push({ path: '/material',query: { isSelectionMode: true }  });
      this.drawer = true
      this.$nextTick().then((rs) => {
        this.$refs.materialDrawer.$refs.materialLibrary.init(1);
      });
    },
    showLocaMedia(items) {
      this.selectionConfirmed(items)
    },
    selectionConfirmed(items) {
      items.forEach(item => {
        if (!item.fileType) {
          item.fileType = this.getTypeByUrl(item.url)
        }
        delete item.id
        delete item.type
        this.basicInfo.videoTrackClipsBos.push({
          ...item,
          out: item.videoLength,
          mediaUrl: item.url,
          hover: false, // 添加一个hover属性来控制蒙层的显示
          inOutList: [{in: 0, out: item.videoLength, checked: true}]
        });
      });
      this.changeBackground()
      this.changeDuration()
      console.log('选中的素材:', items)
      console.log('videoTrackClipsBos:', this.basicInfo.videoTrackClipsBos);
      this.handleItemUpdated()
      // 这里可以执行进一步的处理，例如保存到数据中等
      this.drawer = false
    },
    removeMaterial(index) {
      this.basicInfo.videoTrackClipsBos.splice(index, 1);
      this.changeBackground()
      this.changeDuration()
      this.handleItemUpdated()
    },
    pruning(item, index) {
      this.drawerPruning = true
      this.pruningIndex = index
      this.$nextTick().then((rs) => {
        this.$refs.pruning.init(item);
      });
    },
    cancelPruning(res) {
      this.drawerPruning = false
      if (this.pruningIndex > -1) {
        this.basicInfo.videoTrackClipsBos[this.pruningIndex].inOutList = res
        this.handleItemUpdated()
        console.log('取消粗剪', res)
      }
    },
    confirmPruning(res) {
      this.drawerPruning = false
      if (this.pruningIndex > -1) {
        this.basicInfo.videoTrackClipsBos[this.pruningIndex].inOutList = res
        this.handleItemUpdated()
        console.log('确认粗剪', res)
      }
    },
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs["basicInfoForm"].validate(valid => {
          if (valid) {
            resolve();
            console.log('valid-basicInfo-1')
          } else {
            console.log('valid-basicInfo-2')
            reject(new Error("Validation failed"));
          }
        });
      });
    },
    getTempInfo() {
      return this.basicInfo
    },
  }
};
</script>

<style scoped>
/* .custom-radio {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
} */
.aspect-ratio-box-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* 调整间距 */
}

.aspect-ratio-box {
  width: 90px;
  height: 160px;
  border: 1px solid #0092FF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 5px; /* 调整间距 */
  position: relative;
}

.material-thumbnail {
  width: 100%;
  height: 100%; /* 根据需要调整高度 */
  object-fit: cover;
  border-radius: 3px;
}

.material-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.video-duration {
  position: absolute;
  right: 5px;
  bottom: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
}

.el-form-item{
  margin-bottom: 5px;
}
.el-form--label-top .el-form-item__label{
  padding: 0;
}
</style>
