var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "video-grid-item",
      class: { selected: _vm.isSelectionMode && _vm.item.selected },
      style: { zIndex: _vm.item.hover ? 10 : "auto" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.toggleSelection($event)
        },
        mouseover: _vm.mouseoverItem,
        mouseleave: _vm.mouseleaveItem,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "left-section",
        },
        [
          _vm.item.fileType == "image"
            ? _c("div", [
                _c("img", {
                  staticClass: "video-grid-cover",
                  attrs: { src: _vm.item.url, alt: "image" },
                }),
                _c("div", { staticClass: "video-grid-type type-image" }, [
                  _vm._v(_vm._s(_vm.$t("material.picture"))),
                ]),
              ])
            : _vm.item.fileType == "audio"
            ? _c(
                "div",
                { staticClass: "video-grid-cover video-grid-bg flex-center" },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/video/audio.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "video-grid-type type-audio" }, [
                    _vm._v(_vm._s(_vm.$t("material.audio"))),
                  ]),
                ]
              )
            : _vm.item.fileType == "text"
            ? _c(
                "div",
                { staticClass: "video-grid-cover video-grid-bg flex-center" },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/video/text.png"),
                      alt: "",
                    },
                  }),
                  _c("div", { staticClass: "video-grid-type type-text" }, [
                    _vm._v(_vm._s(_vm.$t("material.copywriting"))),
                  ]),
                ]
              )
            : _c("div", { staticClass: "video-grid-cover" }, [
                _c("video", {
                  ref: "videoPlayer",
                  staticClass: "video-grid-cover",
                  attrs: {
                    src: _vm.item.url,
                    alt: "video",
                    muted: "",
                    playsinline: "",
                    loop: "",
                    preload: "auto",
                  },
                  domProps: { muted: true },
                  on: { mouseenter: _vm.playVideo, mouseleave: _vm.pauseVideo },
                }),
                _vm.item.videoLength && _vm.item.videoLength > 0
                  ? _c("span", { staticClass: "video-grid-duration" }, [
                      _vm._v(
                        _vm._s(_vm.secondsFormatTime(_vm.item.videoLength))
                      ),
                    ])
                  : _vm._e(),
                !_vm.isFailed &&
                _vm.splitStatus === _vm.$t("videoItem.clipSuccess")
                  ? _c(
                      "div",
                      {
                        staticClass: "video-grid-clip",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.splitDetail(_vm.item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("videoItem.clipDetails")) + " "
                        ),
                      ]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "video-grid-type type-video" }, [
                  _vm._v(_vm._s(_vm.$t("material.video"))),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "video-grid-play",
                    on: {
                      click: function ($event) {
                        return _vm.viewMedia(_vm.item)
                      },
                    },
                  },
                  [_c("i", { staticClass: "el-icon-caret-right" })]
                ),
              ]),
          _vm.item.jobError
            ? _c("el-tooltip", { attrs: { content: _vm.item.jobError } }, [
                _vm.item.splitVideoPartsStatus ||
                _vm.item.mergeVideoFaceStatus ||
                _vm.item.eraseVideoSubtitlesStatus ||
                _vm.item.jobStatus
                  ? _c(
                      "div",
                      {
                        staticClass: "controlsStatus",
                        class: [_vm.filterState(_vm.item)],
                      },
                      [_vm._v(_vm._s(_vm.statusMessage))]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "middle-section" }, [
        _c("div", { staticClass: "video-grid-name single-line-ellipsis" }, [
          _vm._v(
            _vm._s(_vm.item.fileType == "text" ? _vm.item.title : _vm.item.name)
          ),
        ]),
        _c(
          "div",
          { staticClass: "video-grid-created single-line-ellipsis font12" },
          [_vm._v(_vm._s(_vm.item.createTime))]
        ),
        !_vm.item.hover
          ? _c(
              "div",
              {
                staticClass:
                  "video-grid-labels flex-row-center single-line-ellipsis",
              },
              _vm._l(_vm.item.labels, function (tag, index) {
                return _c("span", { key: index, staticClass: "mr5" }, [
                  _vm._v(" #" + _vm._s(tag) + " "),
                ])
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm.item.hover && !_vm.loading
        ? _c("div", [
            _vm.item.fileType == "text"
              ? _c(
                  "div",
                  { staticClass: "flex-center right-section" },
                  [
                    _c("el-tooltip", { attrs: { content: _vm.$t("view") } }, [
                      _c("i", {
                        staticClass:
                          "iconfont icon-a-xialajiantoubeifen3 color-blue",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.showText($event)
                          },
                        },
                      }),
                    ]),
                    _vm.item.type !== "system" && !_vm.isSelectionMode
                      ? _c(
                          "el-tooltip",
                          { attrs: { content: _vm.$t("edit") } },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit color-blue",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.$emit("edit-text", _vm.item)
                                },
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm.item.type !== "system" && !_vm.isSelectionMode
                      ? _c(
                          "el-tooltip",
                          { attrs: { content: _vm.$t("remove") } },
                          [
                            _c("i", {
                              staticClass: "el-icon-delete color-blue",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteVideo($event)
                                },
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "flex-center right-section" },
                  [
                    _vm.type == 3 &&
                    _vm.item.publishSuccessCount == 0 &&
                    !_vm.isSelectionMode
                      ? _c(
                          "el-tooltip",
                          {
                            attrs: { content: _vm.$t("videoManager.publish") },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-position color-blue",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.publish(_vm.item)
                                },
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm.item.fileType == "image"
                      ? _c(
                          "div",
                          [
                            !_vm.isFailed
                              ? _c(
                                  "el-tooltip",
                                  { attrs: { content: _vm.$t("view") } },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "iconfont icon-a-xialajiantoubeifen3 color-blue",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.viewMedia(_vm.item)
                                        },
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm.item.fileType == "audio"
                      ? _c(
                          "div",
                          [
                            !_vm.isFailed
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: _vm.$t("file.filePreview"),
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "iconfont icon-erji color-blue",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.viewMedia(_vm.item)
                                        },
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            !_vm.isFailed
                              ? _c(
                                  "el-tooltip",
                                  { attrs: { content: _vm.$t("view") } },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "iconfont icon-a-xialajiantoubeifen3 color-blue",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.$emit(
                                            "view-text",
                                            _vm.item
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                    !_vm.isFailed &&
                    _vm.item.type !== "system" &&
                    !_vm.isSelectionMode
                      ? _c(
                          "el-tooltip",
                          { attrs: { content: _vm.$t("edit") } },
                          [
                            _c("i", {
                              staticClass: "el-icon-edit color-blue",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.$emit("edit-video", _vm.item)
                                },
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    !_vm.isFailed &&
                    _vm.item.type !== "system" &&
                    !_vm.isSelectionMode
                      ? _c(
                          "el-tooltip",
                          { attrs: { content: _vm.$t("file.download") } },
                          [
                            _c("i", {
                              staticClass: "el-icon-download color-blue",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.downloadFile(_vm.item.url)
                                },
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    !_vm.isFailed &&
                    _vm.item.type !== "system" &&
                    !_vm.isSelectionMode
                      ? _c(
                          "el-tooltip",
                          { attrs: { content: _vm.$t("copyLink") } },
                          [
                            _c("i", {
                              staticClass: "el-icon-link color-blue",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.copyLink($event)
                                },
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm.item.type !== "system" && !_vm.isSelectionMode
                      ? _c(
                          "el-tooltip",
                          { attrs: { content: _vm.$t("remove") } },
                          [
                            _c("i", {
                              staticClass: "el-icon-delete color-blue",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.deleteVideo($event)
                                },
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
          ])
        : _vm._e(),
      _vm.dialogVisible
        ? _c("VideoButtonGrid", {
            attrs: {
              dialogVisible: _vm.dialogVisible,
              video: _vm.mediaItem,
              type: 1,
              tip: _vm.$t("file.filePreview"),
            },
            on: { closeMedia: _vm.closeMediaFun },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }