import request from '@/utils/request'
//素材管理
const baseApi = '/admin/material'

// 列表
export function listMaterial(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}
// 详情
export function getMaterialDetail(id) {
  return request({
      url: baseApi + '/' + id,
      method: 'get'
  })
}
// 新增
export function addMaterial(data) {
  return request({
    url: baseApi,
    method: 'post',
    data: data
  })
}
// 带文件上传
export function addMultiMaterial(data, onProgress) {
  return request({
    url: baseApi,
    method: 'post',
    data,  // 直接发送 FormData 对象
    headers: {
      'Content-Type': 'multipart/form-data' // 明确设置为文件传输格式
    },
    onUploadProgress: onProgress // 上传进度回调
  });
}
// 修改
export function editMaterial(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}
// 删除——删除文件和文件夹
export function removeMaterialAll(data) {
  return request({
    url: baseApi + '/remove',
    method: 'delete',
    data
  })
}
// 本地素材上传——同时返回视频时长、封面等信息
export function localTuClipAdd(data) {
  return request({
    url: baseApi + '/localTuClipAdd',
    method: 'post',
    data
  })
}
