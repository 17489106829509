<!-- 素材上传 -->
<template>
  <el-dialog
    class="publicDialog"
    width="50%"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleBeforeClose"
    :show-close="false"
  >
    <div slot="title">
      <div class="headerFlex">
        <div class="headerTitle">{{ title }}</div>
        <i class="el-icon-close" @click="close"></i>
      </div>
    </div>
    <el-form :model="form" ref="uploadForm" label-width="100px" :rules="rules" label-position="top" class="uploadForm"
      :style="{ maxHeight: dialogMaxHeight + 'px' }">
      <el-form-item v-if="needMaterialTypeId" prop="materialTypeId">
        <div class="flex-row-center f-between">
          <div class="label" style="font-weight: bold;color: #606266">
            {{ $t('material.position') }}
          </div>
          <el-button type="text" icon="el-icon-plus" @click="openAddFile">{{ $t('file.createFolder') }}</el-button>
        </div>
        <!-- <el-select v-model="form.materialTypeId" :placeholder="$t('file.tipSelectFolder')">
          <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select> -->
        <div class="flexTreeselect">
          <treeselect
            v-model="form.materialTypeId"
            :options="treeFileList"
            :show-count="false"
            :normalizer="normalizer"
            :clearable="false"
            :load-options="loadOptions"
            :placeholder="$t('file.tipSelectFolder')"
            :disabled="isSystem"
          >
            <template #value-label="{ node }">
              {{ node.raw.name }}
            </template>
          </treeselect>
        </div>
      </el-form-item>
      <el-form-item prop="fileList" v-if="title==$t('material.upload')">
        <div class="flex-row-center">
          <div class="label form-label" >
            <span class="required-star">*</span>
            {{ $t('material.materialFile') }}
          </div>
        </div>
        <!-- <videoUpload v-model="form.fileList" :limit="1"/> -->
        <videoUploadMultifile v-model="form.fileList" :limit="10" :fileType="getFileType()"/>
        <div class="upload-tip flex-row-center">
          <i class="el-icon-warning mr10" style="color: #ED7B2F;font-size: 18px;"></i>
          {{ $t('material.tipRemoveSubtitle') }}
        </div>
      </el-form-item>
      <el-form-item :label="$t('file.name')" prop="name" v-if="title==$t('material.edit')">
        <el-input
          v-model="form.name"
          :placeholder="$t('tip.input')"
          maxlength="20"
          show-word-limit
          :disabled="isSystem"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('file.description')" prop="description">
        <el-input v-model="form.description" :placeholder="$t('tip.input')" maxlength="500"
                  show-word-limit></el-input>
      </el-form-item>
      <el-form-item :label="$t('label.label')">
        <el-select
          ref="searchSelect"
          style="width: 100%;"
          v-model="form.labels"
          allow-create
          filterable
          multiple
          default-first-option
          :placeholder="$t('label.tipSelect')"
          @input="handleInput">
          <el-option v-for="dict in labelList" :key="dict.id" :label="dict.label" :value="dict.label"/>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('material.isStripped')" prop="isSplitVideoParts" v-if="title==$t('material.upload') && (containsVideo || activeTab === 'video')">
        <el-radio-group v-model="form.isSplitVideoParts">
          <el-radio :label="true">{{$t('yes')}}</el-radio>
          <el-radio :label="false">{{$t('no')}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item :label="$t('material.removeSubtitle')" prop="isEraseVideoSubtitles" v-if="title==$t('material.upload') && (containsVideo || activeTab === 'video')">
        <!-- v-if="form.suffix=='mp4'" -->
        <el-switch
          v-model="form.isEraseVideoSubtitles"
          :active-value="1"
          :inactive-value="0">
        </el-switch>
      </el-form-item>
      <el-form-item :label="$t('material.aiFaceSwap')" prop="isMergeVideoFace" v-if="title==$t('material.upload') && (containsVideo  || activeTab === 'video')">
        <el-switch
          v-model="form.isMergeVideoFace"
          :active-value="1"
          :inactive-value="0">
        </el-switch>
        <el-form-item v-if="form.isMergeVideoFace==1" prop="mergeVideoFaceImageUrl">
          <div v-if="!form.mergeVideoFaceImageUrl" class="aspect-ratio-box flex-column" @click="chooseMaterial">
            <i class="el-icon-plus" style="font-size: 26px;"></i>
            <div style="margin-top: 16px;">
              {{ $t('material.addMaterial') }}
            </div>
          </div>
          <div v-else
               class="material-container ml80"
               @mouseover="form.hover = true"
               @mouseleave="form.hover = false"
          >
            <img :src="form.mergeVideoFaceImageUrl" alt="material" class="material-thumbnail"/>
            <div v-if="form.hover" class="overlay">
              <div @click="removeMaterial">{{ $t('basicInfo.remove') }}</div>
            </div>
          </div>
        </el-form-item>
      </el-form-item>
      <el-form-item :label="$t('material.retainOriginalVideo')" prop="isReserve"
                    v-if="form.isEraseVideoSubtitles==1||form.isMergeVideoFace==1">
        <el-switch
          v-model="form.isReserve"
          :active-value="0"
          :inactive-value="1">
        </el-switch>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button class="cancelBtn" @click="handleCancel">{{ $t('cancel') }}</el-button>
      <el-button class="submitBtn" :loading="buttonLoading" @click="submitUpload">{{ $t('confirm') }}</el-button>
    </div>
    <el-drawer
      append-to-body
      style="z-index: 9999;"
      size="65%"
      :title="$t('mixing.materia')"
      :visible.sync="drawer"
      :with-header="false"
      :modal="false"
      class="custom-drawer"
    >
      <Material 
        ref="materialLibrary" 
        name="Material" 
        :skipInit="true" 
        @selectionConfirmed="selectionConfirmed" 
        @cancel="drawer=false"
      />
    </el-drawer>
    <addFolderDialog
      v-if="folderDialogVisible"
      :visible.sync="folderDialogVisible"
      :title="$t('file.createFolder')"
      :labelList="labelList"
      :treeFileList="treeFileList"
      :isSystem="isSystem"
      :materialTypeId="materialTypeId"
      @submitAddFile="submitAddFile"
    />
  </el-dialog>
</template>
<script>
import VideoUpload from "../VideoUpload/index.vue";
import VideoUploadMultifile from "../VideoUpload/multifile.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { addMaterial, editMaterial } from "../../api/ai/material";
import {listMaterialType} from '../../api/ai/materialType';
import EventBus from '../../utils/EventBus'
import addFolderDialog from "../dialog/addFolderDialog.vue";
export default {
  name: 'MaterialUploadDialog',
  components: {
    VideoUpload,
    VideoUploadMultifile,
    Material: () => import("../../views/aivideo/material.vue"), // 异步加载
    Treeselect,
    addFolderDialog
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    list: {
      type: Array,
      default: () => []
    },
    labelList: {
      type: Array,
      default: () => []
    },
    // 是否需要选择：切片里子数据不需要
    needMaterialTypeId: {
      type: Boolean,
      default: true
    },
    //默认选择的素材分类
    materialTypeId: {
      type: Number,
      default: 0
    },
    isSystem: {//系统文件不允许编辑名称、文件夹位置
      type: Boolean,
      default: false
    },
    activeTab:{//当前激活的tab类型-all、video、audio、image
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogMaxHeight: window.innerHeight * 0.7, // 初始化最大高度为屏幕的70%
      folderDialogVisible: false,
      treeFileList: [],
      form: {
        fileList: [],
        ossId: '',
        url: '',
        name: '',
        description: '',
        materialTypeId: this.materialTypeId,
        labels: [],
        label: '',
        fileType: '',
        isReserve: 0,//0保留原素材，1不保留（如果下边这俩都不选，写死0，）
        isEraseVideoSubtitles: 0,//0默认，1去字幕（默认传0）
        isMergeVideoFace: 0,//0默认，1 ai换脸融合（默认传0）
        mergeVideoFaceImageUrl: '',//换脸图片地址
        hover: false,
        suffix: '',//后缀
        isSplitVideoParts: false,//是否切片
        oldFileAddressId: null
      },
      rules: {
        fileList: [
          {required: true, message: this.$t('material.tipSelect'), trigger: ['blur', 'change']}
        ],
        description: [
          {max: 500, message: this.$t('material.formRulesNameLength'), trigger: "blur"}
        ],
        name: [
          {required: true, message: this.$t('file.tipName'), trigger: ['blur', 'change']},
          {
            max: 20,
            message: this.$t('material.formRulesNameLength'),
            trigger: "blur",
          },
        ],
        // materialTypeId: [
        //   {required: true, message: this.$t('file.tipSelectFolder'), trigger: ['blur', 'change']}
        // ],
        mergeVideoFaceImageUrl: [
          {
            validator: (rule, value, callback) => {
              if (this.form.isMergeVideoFace === 1 && !value) {
                callback(new Error(this.$t('material.provideFaceSwapMaterial')));
              } else {
                callback();
              }
            },
            trigger: ['blur', 'change']
          }
        ]
      },
      drawer: false,//素材选择的抽屉
      buttonLoading: false,//提交的loading
      maxFileCount: 10,//上传文件的最大数量
      fileSize: 500,//上传文件的最大大小
      containsVideo: false,//是否包含视频-是否显示切片等
    };
  },
  watch: {
    'form.fileList': {
      handler(newFileList, oldFileList) {
        console.log('文件列表已变化:', newFileList);
        // this.changeName(newFileList);
        this.containsVideo = newFileList.some(item => this.getTypeByUrl(item.name) === 'video')
      },
      deep: true,
      immediate: true,
    },
    list: {
      handler(newVal) {
        this.treeFileList = newVal
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getFileType(){
      if (this.activeTab === 'video'){
        return ["mp4", "avi", "mkv", "mov"]
      } else if (this.activeTab === 'audio'){
        return ["mp3", "aac", "wav","m4a"]
      } else if (this.activeTab === 'image'){
        return ["png", "jpg", "jpeg", "webp"]
      } else {
        return ["png", "jpg", "jpeg", "webp",
          "mp4", "avi", "mkv", "mov",
          "mp3", "aac", "wav","m4a"]
      }
    },
    submitAddFile() {
      listMaterialType().then(response => {
        this.treeFileList = JSON.parse(JSON.stringify(response.data));
        this.treeFileList.unshift({
          id: 0,
          name: this.$t('basicInfo.materia'),
          type: 'system',
        });
      });
    },
    // closeFolderDialog() {
    //   this.folderDialogVisible = false
    // },
    openAddFile() {
      this.folderDialogVisible = true
    },
    //编辑时赋值
    init(item){
      this.form = {...item};  // 使用浅拷贝确保数据绑定正确
      // this.oldFileAddressId = this.form.materialTypeId
      if (this.form.label) {
        this.form.labels = this.form.label.split(',')
      }
      this.materialTypeId = this.form.materialTypeId
    },
    //重置form
    resetForm() {
      this.form = {
        id: '',
        fileList: [],
        ossId: '',
        url: '',
        name: '',
        description: '',
        materialTypeId: this.materialTypeId,
        labels: [],
        label: '',
        fileType: '',
        isReserve: 0,
        isEraseVideoSubtitles: 0,
        isMergeVideoFace: 0,
        mergeVideoFaceImageUrl: '',
        hover: false,
        suffix: '',
        isSplitVideoParts: false
      }
    },
    loadOptions(node) {
      // 如果节点没有子节点，返回空数组,此接口可以添加检索功能检索是否有子节点
      if (!node || !node.id) {
        node.callback([]);
        return;
      }
      node.callback([])
      // // 调用 API 获取子节点
      // fetchChildren(node.id)
      //   .then(response => {
      //     const formattedOptions = response.data.map(item => ({
      //       id: item.id,
      //       label: item.name,
      //       hasChildren: item.hasChildren,
      //     }));
      //     callback(formattedOptions);
      //   })
      //   .catch(error => {
      //     console.error('加载选项失败:', error);
      //     callback([]);
      //   });
    },
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      const countLabel = node.materialCount > 0 ? `  (${node.materialCount})` : '';
      return {
        id: node.id,
        label: `${node.name}${countLabel}`,
        children: node.children
      };
    },
    //控制标签输入的文本不超过20位
    handleInput(value) {
      if (value != null) {
       var label = value[value.length - 1]
        if(label != null && label.length > 20){
           value[value.length - 1] = label.substring(0, 20)
        }
      }
    },
    // 填充文件名称
    changeName(newFileList) {
      if (newFileList && newFileList.length > 0) {
        this.form.name = newFileList[0].name
        this.form.suffix = newFileList[0].name.split('.').pop()
      } else {
        this.form.suffix = ''
      }
    },
    //人脸选择
    chooseMaterial() {
      this.drawer = true
      this.$nextTick().then((rs) => {
        this.$refs.materialLibrary.init(3);
      });
    },
    //换脸图片选择成功
    selectionConfirmed(items) {
      if (items.length == 1) {
        this.form.mergeVideoFaceImageUrl = items[0].url
      }
      this.drawer = false
    },
    removeMaterial() {
      this.form.mergeVideoFaceImageUrl = ''
    },
    handleBeforeClose(done) {
      this.close()
    },
    handleCancel() {
      this.close()
    },
    close(){
      this.buttonLoading = false;
      this.$emit('update:visible', false);
      this.$emit('closeUpload')
    },
    flattenTree(data) {
        const result = [];
        function recurse(node) {
            result.push({
                id: node.id,
                name: node.name,
                type: node.type,
                materialCount: node.materialCount,
                parentId: node.parentId,
                merchantId: node.merchantId,
                dataType: node.dataType,
                label: node.label,
            });
            if (node.children) {
                node.children.forEach(child => recurse(child));
            }
        }
        data.forEach(item => recurse(item));
        return result;
    },
    //增改提交
    submitUpload() {
      this.$refs.uploadForm.validate(valid => {
        if (valid) {
          // if (this.title == this.$t('material.upload')) {
          //   this.form.ossId = this.form.fileList[0].ossId
          //   this.form.url = this.form.fileList[0].url
          //   this.form.fileType = this.getTypeByUrl(this.form.url)
          // }
          if (this.form.labels.length > 0)
            this.form.label = this.form.labels.join(',')
          this.buttonLoading = true;
          if (this.title == this.$t('material.upload')) {
            this.buttonLoading = false;
            this.close()
            this.$emit('updateFile')
            EventBus.$emit('post-file', this.form, 'material');
            // addMaterial(this.form).then(res => {
            //   this.$emit('addMaterialSuccess', this.form);
            //   this.close()
            // }).finally(() => {
            //  this.buttonLoading = false;
            // });
          } else {
            let name = ''
            if (this.form.materialTypeId !== this.materialTypeId) {
              const newList = this.flattenTree(this.list)
              const select = newList.find(item => item.id === this.form.materialTypeId)||{}
              name = select.name
            }
            editMaterial(this.form).then(res => {
              this.close()
              this.$nextTick(() => {
                this.$emit('editMaterialSuccess', { ...this.form, fileAddressName: name });
              });
            }).finally(() => {
              this.buttonLoading = false;
            });
          }
        } else {
          this.$modal.msgError(this.$t('videoEditor.tipInfoMust'));
        }
      });
    },
  }
};
</script>
<style lang="scss">

.dialog-footer {
  text-align: right;
}
.flexTreeselect {
  display: flex;
  .el-button {
    margin-left: 8px;
    width: 35px;
    display: flex;
    justify-content: center;
  }
}
.form-label{
  font-weight: bold;
  color: #606266;
}
.required-star {
  color: #f56c6c;
  margin-right: 4px;
  font-size: 16px;
  line-height: 1;
}

.uploadForm{
  overflow-y: auto;
}
.el-form-item{
  margin-bottom: 12px;
}
.el-form--label-top .el-form-item__label{
  padding: 0;
}
.upload-tip{
  height: 40px;
  background: #F9E0C7;
  border-radius: 12px;
  padding: 0 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0,0,0,0.9);
  line-height: 20px;
  text-align: left;
  font-style: normal;
}

.aspect-ratio-box {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px; /* 调整间距 */
  position: relative;
  background: #FDFDFD;
  border-radius: 8px;
  border: 1px solid #DEDEDE;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #0052D9;
  line-height: 20px;
  text-align: center;
  font-style: normal;
  cursor: pointer;
}

.material-thumbnail {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 3px;
}

.material-container {
  position: relative;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}
</style>
