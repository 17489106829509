var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "flex-row-center f-between video-all w-100" }, [
        _c(
          "div",
          { staticClass: "flex-row-center f-wrap" },
          _vm._l(_vm.sceneList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "copywriting-item",
                class: { active: index === _vm.activeStep },
                on: {
                  click: function ($event) {
                    return _vm.selectStep(index)
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("editVideo.video")) +
                    _vm._s(index + 1) +
                    " "
                ),
                _vm.sceneList.length > 1
                  ? _c("i", {
                      staticClass: "el-icon-close",
                      on: {
                        click: function ($event) {
                          return _vm.deleteItem(index)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          { staticStyle: { "align-self": "flex-end" } },
          [
            _c(
              "el-button",
              {
                staticClass: "button-white",
                staticStyle: { margin: "0 5px" },
                on: { click: _vm.last },
              },
              [_vm._v(_vm._s(_vm.$t("register.previous")))]
            ),
            _c(
              "el-button",
              {
                staticClass: "gradient-button",
                staticStyle: { margin: "0 5px" },
                attrs: { loading: _vm.finishLoading },
                on: { click: _vm.makeVideo },
              },
              [_vm._v(_vm._s(_vm.$t("editVideo.generateVideo")))]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex-row mt10" },
        [
          _c(
            "div",
            { staticClass: "video-all mr10", staticStyle: { width: "100px" } },
            _vm._l(_vm.menu, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "copywriting-item flex-column-center",
                  class: { active: index === _vm.currentMenu },
                  staticStyle: { padding: "10px 12px", margin: "5px" },
                  on: {
                    click: function ($event) {
                      return _vm.selectMenu(index)
                    },
                  },
                },
                [
                  _c("i", { class: item.icon }),
                  _vm._v(" " + _vm._s(item.title) + " "),
                ]
              )
            }),
            0
          ),
          _vm.textList[_vm.activeStep]
            ? _c(
                "el-row",
                { staticClass: "w-100" },
                [
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentMenu === 0,
                          expression: "currentMenu === 0",
                        },
                      ],
                      attrs: { span: 12 },
                    },
                    _vm._l(
                      _vm.textList[_vm.activeStep].videoList,
                      function (template, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "template-card-item" },
                          [
                            _c("VideoItem", {
                              attrs: {
                                template: template,
                                "selected-item": _vm.selectedItem,
                                languageCode: _vm.languageCode,
                                languageList: _vm.languageList,
                              },
                              on: {
                                "item-selected": _vm.selectedVideo,
                                "make-video": function ($event) {
                                  return _vm.changeVideo(index, template)
                                },
                                "replace-video": function ($event) {
                                  return _vm.replaceVideo(index, template)
                                },
                                "delete-video": function ($event) {
                                  return _vm.deleteVideo(index)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  ),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentMenu === 1,
                          expression: "currentMenu === 1",
                        },
                      ],
                      attrs: { span: 12 },
                    },
                    [
                      _vm.sceneList[_vm.activeStep].music.url
                        ? _c("div", { staticClass: "flex-column" }, [
                            _c("div", [
                              _vm._v(_vm._s(_vm.$t("editVideo.musicSelect"))),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "flex-row-center",
                                staticStyle: { "margin-top": "5px" },
                              },
                              [
                                _c("audio", {
                                  ref: "audioPlayer",
                                  staticClass: "f-grow",
                                  attrs: {
                                    controls: "",
                                    src: _vm.sceneList[_vm.activeStep].music
                                      .url,
                                  },
                                }),
                                _c("i", {
                                  staticClass: "el-icon-circle-close",
                                  on: { click: _vm.delMusic },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "flex-row-center mt5" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#606266",
                                      "font-size": "14px",
                                      "margin-right": "12px",
                                      "font-weight": "700",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("editVideo.musicVolume"))
                                    ),
                                  ]
                                ),
                                _c("el-slider", {
                                  staticClass: "f-grow",
                                  attrs: {
                                    step: 0.1,
                                    max: 1,
                                    min: 0,
                                    "show-input": "",
                                  },
                                  model: {
                                    value:
                                      _vm.sceneList[_vm.activeStep].music
                                        .volume,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.sceneList[_vm.activeStep].music,
                                        "volume",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "sceneList[activeStep].music.volume",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _c("Material_library", {
                        ref: "materialLibrary",
                        attrs: { skipInit: true, isTextToVideo: true },
                        on: { selectionConfirmed: _vm.selectionConfirmed },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentMenu === 2,
                          expression: "currentMenu === 2",
                        },
                      ],
                      staticClass: "voice",
                      attrs: { span: 12 },
                    },
                    [
                      _c("Voice", {
                        attrs: {
                          voiceList: _vm.voiceList,
                          value: _vm.sceneList[_vm.activeStep].aiTts.voice,
                        },
                        on: {
                          "item-selected": _vm.voiceSelected,
                          "dubbing-change": _vm.dubbingChange,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentMenu === 3,
                          expression: "currentMenu === 3",
                        },
                      ],
                      staticClass: "voice",
                      attrs: { span: 12 },
                    },
                    [
                      _c("Subtitle", {
                        key: "subtitle-" + _vm.activeStep,
                        attrs: {
                          value: _vm.sceneList[_vm.activeStep].subtitle,
                          effectColorStyleList: _vm.effectColorStyleList,
                        },
                        on: { "item-updated": _vm.subtitleUpdated },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentMenu === 4,
                          expression: "currentMenu === 4",
                        },
                      ],
                      staticClass: "voice",
                      attrs: { span: 12 },
                    },
                    [
                      _c("Title", {
                        key: "title-" + _vm.activeStep,
                        attrs: {
                          value: _vm.sceneList[_vm.activeStep].title,
                          effectColorStyleList: _vm.effectColorStyleList,
                          languageCode: _vm.languageCode,
                          languageList: _vm.languageList,
                        },
                        on: { "item-updated": _vm.titleUpdated },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentMenu === 5,
                          expression: "currentMenu === 5",
                        },
                      ],
                      staticClass: "voice",
                      attrs: { span: 12 },
                    },
                    [
                      _c("Transition", {
                        attrs: { transitionList: _vm.transitionList },
                        on: { "item-selected": _vm.transitionUpdated },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentMenu === 6,
                          expression: "currentMenu === 6",
                        },
                      ],
                      staticClass: "voice",
                      attrs: { span: 12 },
                    },
                    [
                      _c("Settings", {
                        attrs: { value: _vm.sceneList[_vm.activeStep] },
                        on: { "item-updated": _vm.settingsUpdated },
                      }),
                    ],
                    1
                  ),
                  _vm.selectedItem
                    ? _c(
                        "el-col",
                        {
                          staticClass: "flex-column-center video-container",
                          attrs: { span: 12 },
                        },
                        [
                          _c(
                            "video",
                            {
                              ref: "myVideo",
                              staticStyle: { "background-color": "black" },
                              attrs: { width: "315", height: "560" },
                              on: {
                                timeupdate: _vm.handleTimeUpdate,
                                click: _vm.togglePlay,
                              },
                            },
                            [
                              _c("source", {
                                attrs: {
                                  src: _vm.selectedItem.url,
                                  type: "video/mp4",
                                },
                              }),
                              _vm._v(
                                " Your browser does not support the video tag. "
                              ),
                            ]
                          ),
                          !_vm.isPlaying
                            ? _c("i", {
                                staticClass: "el-icon-video-play icon-style",
                              })
                            : _vm._e(),
                          _vm.isPlaying
                            ? _c("i", {
                                staticClass: "el-icon-video-pause icon-style",
                              })
                            : _vm._e(),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.currentTime,
                                expression: "currentTime",
                              },
                            ],
                            staticStyle: {
                              width: "315px",
                              "margin-top": "10px",
                            },
                            attrs: {
                              type: "range",
                              min: "0",
                              max: _vm.selectedItem.duration,
                              step: "0.01",
                            },
                            domProps: { value: _vm.currentTime },
                            on: {
                              input: _vm.seekVideo,
                              __r: function ($event) {
                                _vm.currentTime = $event.target.value
                              },
                            },
                          }),
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.secondsFormatTime(_vm.currentTime)) +
                                " / " +
                                _vm._s(
                                  _vm.secondsFormatTime(
                                    _vm.selectedItem.duration
                                  )
                                )
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.$t("mixing.materia"),
            size: "65%",
            visible: _vm.drawer,
            "with-header": false,
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _vm.selectedItem
            ? _c("Material_library", {
                ref: "videoMaterial",
                attrs: {
                  skipInit: true,
                  timeLength: _vm.selectedItem.duration || 1,
                },
                on: {
                  selectionConfirmed: _vm.videoConfirmed,
                  cancel: function ($event) {
                    _vm.drawer = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }