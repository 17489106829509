<template>
  <div>
    <p>TikTok授权中，请稍候...</p>
  </div>
</template>

<script>
import { addAccount } from '../../../api/account/tiktok';

export default {
  name: 'TikTokCallback',
  mounted() {
    // 当页面加载完成时执行
    this.handleCallback();
  },
  methods: {
    handleCallback() {
      // 获取 URL 参数中的 code
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (code) {
        // 将 code 保存在缓存中（示例使用 localStorage）
        localStorage.setItem('tiktok_auth_code', code);
        addAccount({
          // account_type: 'tiktok',
          code: code
        }).then(res => {
           // 关闭当前页面
           this.closeWindow('绑定成功');
        }).catch(err => {
          this.closeWindow(err.message);
        })
      } else {
        this.closeWindow('授权失败，请重试');
      }
    },
    closeWindow(msg) {
      // 关闭当前页面
      // window.opener.postMessage(msg, 'https://pub.grds.cn');// 使用 '*' 允许所有来源（仅用于测试，不推荐用于生产环境）
      // window.opener.postMessage(msg, 'https://pub.infonity.ai');// 使用 '*' 允许所有来源（仅用于测试，不推荐用于生产环境）
      window.opener.postMessage(msg, window.location.origin);// 使用 '*' 允许所有来源（仅用于测试，不推荐用于生产环境）
      window.close();
    },
  }
}
</script>

<style scoped>
</style>
