var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "flex-column",
      staticStyle: {
        "background-color": "#F2F6F8",
        padding: "24px",
        height: "100vh",
      },
    },
    [
      _c(
        "div",
        { staticClass: "title-header f-shrink" },
        [
          _c("div", { staticClass: "title-header-text" }, [
            _vm._v(_vm._s(_vm.$t("videoEditor.videoMixing"))),
          ]),
          _c(
            "el-popover",
            {
              attrs: {
                placement: "left-start",
                width: "518",
                trigger: "click",
              },
            },
            [
              _c("div", { staticClass: "popover-title" }, [
                _vm._v(_vm._s(_vm.$t("guidance.vidEditIntro"))),
              ]),
              _c("div", { staticClass: "popover-content" }, [
                _vm._v(_vm._s(_vm.$t("guidance.vidEditInstructions")) + " "),
              ]),
              _c("video", {
                ref: "videoPlayer",
                staticClass: "video-player",
                attrs: { controls: "", src: _vm.videoSrc },
              }),
              _c(
                "div",
                {
                  staticClass: "title-header-text pointer",
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [
                  _c("i", { staticClass: "iconfont icon-dacha2" }),
                  _c("span", { staticClass: "font12 fontweight400 ml5" }, [
                    _vm._v(_vm._s(_vm.$t("guidance.guide"))),
                  ]),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex-row f-grow" },
        [
          _c(
            "el-row",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "el-col",
                {
                  staticStyle: { "padding-right": "16px" },
                  attrs: { span: 4 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "ve-step editor-scroll" },
                    [
                      _c("StepProgressBar", {
                        attrs: {
                          steps: _vm.steps,
                          activeStep: _vm.currentStep,
                        },
                        on: { "update:activeStep": _vm.updateStep },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c("el-col", { attrs: { span: 20 } }, [
                _c("div", { staticClass: "f-grow flex-column" }, [
                  _c("div", { staticClass: "card-normal f-grow" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.currentStep === 0,
                            expression: "currentStep===0",
                          },
                        ],
                        staticClass: "flex-row",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "f-grow editor-scroll",
                            staticStyle: { padding: "23px" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "videoTitle",
                                staticStyle: {
                                  background: "rgba(1,86,255,0.05)",
                                  padding: "4px 16px",
                                  width: "340px",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("videoEditor.info")) + " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "set-des",
                                staticStyle: { margin: "8px 16px" },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("videoSetting.videoNameLocation")
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "el-form",
                              {
                                ref: "totalInfo",
                                attrs: {
                                  model: _vm.totalInfo,
                                  rules: _vm.rules,
                                  "label-width": "120px",
                                  "label-position": "top",
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("videoEditor.videoName"),
                                      prop: "taskName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "340px" },
                                      attrs: {
                                        maxlength: "50",
                                        "show-word-limit": "",
                                        placeholder: _vm.$t("tip.input"),
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.updatedMixing(
                                            _vm.totalInfo
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.totalInfo.taskName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.totalInfo,
                                            "taskName",
                                            $$v
                                          )
                                        },
                                        expression: "totalInfo.taskName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("videoEditor.videoRatio"),
                                      prop: "aspectRatio",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: _vm.totalInfo.aspectRatio,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.totalInfo,
                                              "aspectRatio",
                                              $$v
                                            )
                                          },
                                          expression: "totalInfo.aspectRatio",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: {
                                              label: "9:16",
                                              border: "",
                                            },
                                          },
                                          [_vm._v("9:16")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("aiSet.location"),
                                      prop: "productTypeId",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "340px" },
                                        attrs: {
                                          placeholder: _vm.$t(
                                            "file.tipSelectFolder"
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updatedMixing(
                                              _vm.totalInfo
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.totalInfo.productTypeId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.totalInfo,
                                              "productTypeId",
                                              $$v
                                            )
                                          },
                                          expression: "totalInfo.productTypeId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.productTypelist,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              label: item.name,
                                              value: item.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "editor-scroll",
                            staticStyle: {
                              width: "40%",
                              padding: "20px",
                              "border-left": "1px solid rgba(229,231,235,1)",
                              "border-right": "1px solid rgba(229,231,235,1)",
                            },
                          },
                          [
                            _vm.currentStep === 0
                              ? _c(
                                  "div",
                                  [
                                    _c("MixingSettings", {
                                      ref: "mixingSettings",
                                      attrs: { value: _vm.totalInfo },
                                      on: { "item-updated": _vm.updatedMixing },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.currentStep > 0,
                            expression: "currentStep > 0",
                          },
                        ],
                      },
                      _vm._l(
                        _vm.totalInfo.videoSceneBos,
                        function (item, index) {
                          return _c("task-item", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.currentStep - 1 == index,
                                expression: "currentStep-1 == index",
                              },
                            ],
                            key: index,
                            ref: "taskItems",
                            refInFor: true,
                            attrs: {
                              value: _vm.totalInfo.videoSceneBos[index],
                              effectColorStyleList: _vm.effectColorStyleList,
                            },
                            on: {
                              "item-updated": function ($event) {
                                return _vm.handleItemUpdated(index, $event)
                              },
                              remove: function ($event) {
                                return _vm.remove(index)
                              },
                            },
                          })
                        }
                      ),
                      1
                    ),
                  ]),
                  _vm.currentStep === 0
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "margin-top": "10px",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "", loading: _vm.buttonLoading1 },
                              on: { click: _vm.saveDraft },
                            },
                            [_vm._v(_vm._s(_vm.$t("videoEditor.draft")) + " ")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.addScene },
                            },
                            [_vm._v(_vm._s(_vm.$t("videoEditor.addScene")))]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "margin-top": "10px",
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "normal-button",
                              attrs: { loading: _vm.buttonLoading1 },
                              on: { click: _vm.saveDraft },
                            },
                            [_vm._v(_vm._s(_vm.$t("videoEditor.draft")) + " ")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "delete-button",
                              on: {
                                click: function ($event) {
                                  return _vm.remove(_vm.currentStep - 1)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("videoEditor.deleteScene")))]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "gradient-button",
                              on: { click: _vm.addScene },
                            },
                            [_vm._v(_vm._s(_vm.$t("videoEditor.addScene")))]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "gradient-button",
                              attrs: { loading: _vm.buttonLoading3 },
                              on: {
                                click: function ($event) {
                                  return _vm.createVideo(1)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("videoEditor.generateVideo"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.$t("videoEditor.previewResult"),
            size: "65%",
            visible: _vm.drawer,
            "with-header": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c("VideoPreview", {
            ref: "videoPreview",
            on: { createTaskSuccess: _vm.createTaskSuccess },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }