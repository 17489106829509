import request from '@/utils/request'
//AI销售
const baseApi = '/admin/aiSale'
// 列表
export function listSale(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}

// 详情
export function getSale(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'get'
  })
}

// 新增
export function addSale(data) {
  return request({
    url: baseApi,
    method: 'post',
    data: data
  })
}

// 修改
export function updateSale(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}

// 删除
export function delSale(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'delete'
  })
}
// 查询ai销售的评论列表
export function queryAiReplyList(params) {
  return request({
    url: 'admin/postComments/queryAiReplyList',
    method: 'get',
    params
  })
}