<!-- 切片详情 -->
<template>
  <div class="app-container" >
    <el-row v-loading="loading">
      <el-col :span="12">
        <div class="flex-column-center w-100 p-10">
          <video style="max-width: 100%;max-height: 300px;" ref="video" :src="detail.url" controls @timeupdate="updateCurrentTime"></video>
          <div class="progress-container">
            <input type="range" ref="progressBar" @click="seekVideo" :max="detail.videoLength" step="0.001" v-model="currentTime" />
            <div class="markers">
              <div v-for="(marker, index) in markers" :key="index" :style="{ left: marker.out/detail.videoLength*100 + '%' }" class="marker"></div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="flex-row-center f-between">
          <h3>{{$t('stripping.basicInfo')}}</h3>
          <el-tooltip :content="$t('edit')">
            <el-button icon="el-icon-edit" circle @click="editText(1, detail)"></el-button>
          </el-tooltip>
        </div>
        <div class="p-10 border-5">
          <div>{{$t('stripping.name')}}：{{ detail.name }}</div>
          <div class="mt5">{{$t('stripping.description')}}：{{ detail.description }}</div>
          <div class="mt5 flex-row f-wrap">{{$t('stripping.tags')}}：
            <div class="video-tags font14">
              <el-tag
                v-for="(tag, index) in detail.labels"
                :key="index"
                style="margin-right: 5px;margin-bottom: 5px;"
              >
                {{ tag }}
              </el-tag>
            </div>
          </div>
        </div>
        <div class="mt5 p-10 border-5">
          <div>{{$t('stripping.fileSize')}}：{{ formatFileSize(detail.fileSize) }}</div>
          <div class="mt5">{{$t('stripping.videoDuration')}}：{{ secondsFormatTime(detail.videoLength) }}</div>
          <div class="mt5">{{$t('stripping.addedTime')}}：
            <span class="video-created font14">
              {{ detail.createTime }}
            </span>
          </div>
          <div class="mt5 video-status font14">{{ statusMessage }}</div>
          <div class="mt5 video-split font14">{{ splitStatus }}</div>
        </div>
      </el-col>
    </el-row>
    <el-table v-if="detail && detail.splitVideoPartsList && detail.splitVideoPartsList.length > 0" :data="detail.splitVideoPartsList" class="mt10">
      <el-empty :image-size="200" slot="empty"></el-empty>
      <el-table-column :label="$t('stripping.clipInfo')" align="center" prop="name"/>
      <el-table-column :label="$t('stripping.videoClips')" align="center" prop="url">
        <template slot-scope="scope">
          <video :src="scope.row.url" alt="video" class="video-cover" />
        </template>
      </el-table-column>
      <el-table-column :label="$t('stripping.videoDuration')" align="center">
        <template slot-scope="scope">
          {{ secondsFormatTime(scope.row.splitVideoPartsEnd-scope.row.splitVideoPartsBegin) || '--' }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('stripping.videoTags')" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.labels && scope.row.labels.length > 0">
            <el-tag v-for="(tag, index) in scope.row.labels" :key="index"
                    style="margin-right: 5px;margin-bottom: 5px;">
              {{ tag }}
            </el-tag>
          </div>
          <div v-else>--</div>
          </template>
      </el-table-column>
      <el-table-column :label="$t('stripping.videoDescription')" align="center" prop="description">
        <template slot-scope="scope">
          {{ scope.row.description || '--' }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('operate')" align="center">
        <template slot-scope="scope">
          <el-tooltip :content="$t('view')">
            <el-button icon="iconfont icon-a-xialajiantoubeifen3" circle @click="playVideo(scope.row)"></el-button>
          </el-tooltip>
          <el-tooltip :content="$t('edit')">
            <el-button icon="el-icon-edit" circle @click="editText(2, scope.row)"></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <!-- 弹窗播放视频 -->
    <el-dialog :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <video controls :src="currentUrl" class="video-player" ref="videoPlayer"></video>
    </el-dialog>
    <MaterialUploadDialog
      ref="materialUploadDialog"
      :visible.sync="uploadDialogVisible"
      :title="$t('material.edit')"
      :list="list"
      :labelList="labelList"
      :needMaterialTypeId="needMaterialTypeId"
      @editMaterialSuccess="editMaterialSuccess"
    />
  </div>
</template>
<script>
import MaterialUploadDialog from '../../components/video/MaterialUploadDialog.vue';
import { getMaterialDetail } from '../../api/ai/material';
import { listMaterialType } from '../../api/ai/materialType';
import { listLabel } from '../../api/system/label';

export default {
  name: 'Stripping',
  components: {
    MaterialUploadDialog
  },
  data() {
    return {
      id:'',
      detail:{},
      currentTime: 0,
      markers: [],
      loading:false,
      dialogVisible: false,//切片视频的播放
      videoElement: null,  // 添加一个变量用于保存视频元素
      currentUrl:'',//当前播放的url
      uploadDialogVisible: false,//素材弹窗
      list: [{
        id: 0,
        name: this.$t('basicInfo.materia'),
        type: 'system',
      }],
      labelList: [],//标签
      needMaterialTypeId: true,//是否需要素材分类
    };
  },
  created(){
    this.id = this.$route.query.id || '';
    if(this.id){
      this.getDetail()
    }
    this.getMaterialTypeList()
    this.getLabels()
  },
  computed: {
    statusMessage() {
      const statusMessages = [];
      const { eraseVideoSubtitlesStatus, mergeVideoFaceStatus } = this.detail;

      if (eraseVideoSubtitlesStatus === 1 || mergeVideoFaceStatus === 1) {
        return this.$t('videoItem.generating');
      }
      if (eraseVideoSubtitlesStatus === 0 && mergeVideoFaceStatus === 0) {
        return ''; // 默认状态，不需要显示任何消息
      }
      // 拼接其他状态的消息
      if (eraseVideoSubtitlesStatus === 2) {
        statusMessages.push(this.$t('videoItem.removeSubtitleSuccess'));
      } else if (eraseVideoSubtitlesStatus === 3) {
        statusMessages.push(this.$t('videoItem.removeSubtitleFail'));
      }
      if (mergeVideoFaceStatus === 2) {
        statusMessages.push(this.$t('videoItem.aiFaceSwapSuccess'));
      } else if (mergeVideoFaceStatus === 3) {
        statusMessages.push(this.$t('videoItem.aiFaceSwapFail'));
      }
      return statusMessages.join(' ');
    },
    splitStatus(){
      const { splitVideoPartsStatus } = this.detail;
      if(splitVideoPartsStatus === 'QUEUING'
        || splitVideoPartsStatus === 'PROCESSING'){
        return this.$t('videoItem.clipping')
      }else if(splitVideoPartsStatus === 'PROCESS_SUCCESS'){
        return this.$t('videoItem.clipCompleted')
      }else if(splitVideoPartsStatus === 'PROCESS_FAILED'
        ||splitVideoPartsStatus === 'TIMEOUT_FAILED'
        ||splitVideoPartsStatus === 'LIMIT_RETRY_FAILED'){
        return this.$t('videoItem.clipFail')
      }else{
        return ''
      }
    },
  },
  methods: {
    //素材分类列表
    getMaterialTypeList() {
      listMaterialType().then(response => {
        this.list = JSON.parse(JSON.stringify(response.data));
        this.list.unshift({
          id: 0,
          name: this.$t('basicInfo.materia'),
          type: 'system',
        });
      });
    },
    getLabels() {
      listLabel().then(response => {
        this.labelList = response.rows;
      });
    },
    playVideo(row) {
      this.currentUrl = row.url;
      this.dialogVisible = true;
       // 播放视频时获取视频元素
       this.$nextTick(() => {
        this.videoElement = this.$refs.videoPlayer;
        this.videoElement.play();
      });
    },
    handleClose() {
      this.dialogVisible = false;
       // 关闭弹窗时停止视频播放
       if (this.videoElement) {
        this.videoElement.pause();
        this.videoElement.currentTime = 0; // 可选：将视频播放位置重置为起始位置
      }
    },
    editText(type,row){
      if(type === 1){
        this.needMaterialTypeId = true
      }else if(type === 2){
        this.needMaterialTypeId = false
      }
      this.$nextTick().then((rs) => {
        this.$refs.materialUploadDialog.init(row);
        this.uploadDialogVisible = true;
      });
    },
    //编辑素材成功
    editMaterialSuccess({fileAddressName}){
      this.getLabels()//新增或编辑时可能对标签产生修改，需要刷新
      this.getMaterialTypeList()//新增或编辑时会对文件数量产生修改，需要刷新
      this.getDetail();
    },
    getDetail(){
      this.loading = true
      getMaterialDetail(this.id).then(res=>{
        this.detail = res.data
        if (this.detail.label) {
          this.detail.labels = this.detail.label.split(',');
        } else {
          this.detail.labels = [];
        }
        if(this.deatil && this.deatil.splitVideoPartsList && this.deatil.splitVideoPartsList.length > 0){
          this.deatil.splitVideoPartsList.forEach(item => {
          if (item.label) {
            item.labels = item.label.split(',');
          } else {
            item.labels = [];
          }
        })
        }
      }).finally(()=>{
        this.loading = false
      })
    },
    updateCurrentTime() {
      this.currentTime = this.$refs.video.currentTime;
    },
    seekVideo() {
    },
  }
}
</script>
<style scoped>
.video-created {
  color: #999;
}
.video-status{
  color: red
}
.video-split{
  color: blue
}
.border-5{
  border: 1px solid #f5f5f5;
  border-radius: 5px;
}
.video-cover {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.markers {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.marker {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background: red;
  pointer-events: none;
}
</style>
