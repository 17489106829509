var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "voice-container" },
    [
      _vm.type == 0
        ? _c("el-switch", {
            staticStyle: { margin: "10px" },
            attrs: {
              "active-text": _vm.$t("subtitle.open"),
              "inactive-text": _vm.$t("subtitle.close"),
            },
            on: { change: _vm.changeDubbing },
            model: {
              value: _vm.dubbing,
              callback: function ($$v) {
                _vm.dubbing = $$v
              },
              expression: "dubbing",
            },
          })
        : _vm._e(),
      _vm.dubbing
        ? _c(
            "div",
            [
              _vm.type == 0
                ? _c("el-alert", {
                    attrs: {
                      title: _vm.$t("editVideo.timbreTip"),
                      type: "warning",
                      "show-icon": "",
                      closable: false,
                    },
                  })
                : _vm._e(),
              _c("div", {
                staticClass: "flex-row-center",
                staticStyle: { "justify-content": "flex-end" },
              }),
              _c(
                "el-radio-group",
                {
                  staticClass: "custom-radio-group mt10",
                  on: { input: _vm.selectItem },
                  model: {
                    value: _vm.voice,
                    callback: function ($$v) {
                      _vm.voice = $$v
                    },
                    expression: "voice",
                  },
                },
                _vm._l(_vm.voiceList, function (voices) {
                  return _c(
                    "div",
                    { key: voices.name, staticClass: "voice-category" },
                    [
                      _c("div", { staticClass: "category w-100" }, [
                        _vm._v(_vm._s(voices.name)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "voice-items" },
                        _vm._l(voices.list, function (voice) {
                          return _c(
                            "el-radio",
                            {
                              key: voice.shortName,
                              staticClass: "voice-item",
                              style: { width: _vm.computedWidth },
                              attrs: { label: voice.shortName },
                            },
                            [
                              _vm._v(" " + _vm._s(voice.localName) + " "),
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: _vm.$t("editVideo.preview"),
                                  },
                                },
                                [
                                  voice.url
                                    ? _c("i", {
                                        staticClass: "el-icon-video-play",
                                        staticStyle: { "font-size": "16px" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.play(voice.url)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }