<template>
  <div class="slide-container">
    <div
      class="slide-progress"
      :style="progressStyle"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    maxValue: {
      type: Number,
      required: true,
    },
  },
  computed: {
    progressStyle() {
      const percentage = this.value / this.maxValue;
      const width = percentage * 150; // 计算宽度
      return {
        width: `${width}px`,
        background: 'linear-gradient(90deg, #0089FF 0%, #6E16D1 100%)',
        height: '10px',
      };
    },
  },
};
</script>

<style scoped>
.slide-container {
  width: 150px;
  height: 10px;
  background-color: #E7E7E7;
  border-radius: 5px;
  overflow: hidden; /* 确保内部进度条不会超出边界 */
}
.slide-progress {
  height: 100%;
}
</style>
