var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "material-detail-container" }, [
    _c(
      "div",
      { staticClass: "title-header" },
      [
        _c("el-page-header", {
          on: { back: _vm.goBack },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", { staticClass: "title-header-text" }, [
                    _vm._v("作品详情"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "material-content",
      },
      [
        _c("div", { staticClass: "mb20" }, [
          _vm.detail.fileType === "image"
            ? _c("img", {
                staticClass: "image-viewer",
                attrs: { src: _vm.detail.url, alt: "image" },
              })
            : _vm.detail.fileType == "audio"
            ? _c("audio", {
                ref: "audioPlayer",
                staticClass: "audio-player",
                attrs: { controls: "", src: _vm.detail.url },
              })
            : _c("video", {
                ref: "videoPlayer",
                staticClass: "video-player",
                attrs: { controls: "", src: _vm.detail.url },
              }),
        ]),
        _c("div", { staticClass: "material-title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.detail.fileType == "text"
                  ? _vm.detail.title
                  : _vm.detail.name
              ) +
              " "
          ),
        ]),
        _c("div", { staticClass: "material-content-text" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.detail.fileType == "text"
                  ? _vm.detail.content
                  : _vm.detail.description
              ) +
              " "
          ),
        ]),
        _c(
          "div",
          { staticClass: "mt20" },
          _vm._l(_vm.detail.labels, function (tag, index) {
            return _c(
              "el-tag",
              {
                key: index,
                staticStyle: { "margin-right": "5px", "margin-bottom": "5px" },
              },
              [_vm._v(" " + _vm._s(tag) + " ")]
            )
          }),
          1
        ),
        _c(
          "el-descriptions",
          {
            staticClass: "f-grow",
            attrs: {
              title: "",
              "label-class-name": "my-label",
              column: 2,
              border: "",
            },
          },
          [
            _vm.detail.width && _vm.detail.height
              ? _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 素材尺寸 ")]),
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.detail.width) +
                          " * " +
                          _vm._s(_vm.detail.height) +
                          " "
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _vm.detail.fileSize
              ? _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 文件大小 ")]),
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.formatFileSize(_vm.detail.fileSize)) +
                          " "
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _vm.detail.videoLength
              ? _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 视频时长 ")]),
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.secondsFormatTime(_vm.detail.videoLength)
                          ) +
                          " "
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "el-descriptions-item",
              [
                _c("template", { slot: "label" }, [_vm._v(" 创建时间 ")]),
                _c("span", [
                  _vm._v(" " + _vm._s(_vm.detail.createTime || "--") + " "),
                ]),
              ],
              2
            ),
            _vm.detail.splitVideoPartsStatus ||
            _vm.detail.mergeVideoFaceStatus ||
            _vm.detail.eraseVideoSubtitlesStatus ||
            _vm.detail.jobStatus
              ? _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 任务 ")]),
                    _vm.detail.jobError
                      ? _c(
                          "el-tooltip",
                          { attrs: { content: _vm.detail.jobError } },
                          [
                            _vm.detail.splitVideoPartsStatus ||
                            _vm.detail.mergeVideoFaceStatus ||
                            _vm.detail.eraseVideoSubtitlesStatus ||
                            _vm.detail.jobStatus
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "controlsStatus",
                                    class: [_vm.filterState(_vm.detail)],
                                  },
                                  [_vm._v(_vm._s(_vm.statusMessage))]
                                )
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm.detail.jobFinishedTime
              ? _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [
                      _vm._v(" 任务完成时间 "),
                    ]),
                    _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.detail.jobFinishedTime || "--") + " "
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "el-descriptions-item",
              [
                _c("template", { slot: "label" }, [_vm._v(" 素材格式 ")]),
                _c("span", [_vm._v(" " + _vm._s(_vm.getText()) + " ")]),
              ],
              2
            ),
            _vm.detail.allName
              ? _c(
                  "el-descriptions-item",
                  [
                    _c("template", { slot: "label" }, [_vm._v(" 素材位置 ")]),
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.detail.allName) + " "),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "el-descriptions-item",
              [
                _c("template", { slot: "label" }, [_vm._v(" 素材编号 ")]),
                _c("span", [_vm._v(" " + _vm._s(_vm.detail.id) + " ")]),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }