import request from '@/utils/request'
//模型训练
const baseApi = '/admin/model'
// 列表
export function listModel(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}

// 详情
export function getModel(id) {
  return request({
    url: baseApi+ '/' + id,
    method: 'get'
  })
}

// 新增
export function addModel(data) {
  return request({
    url: baseApi,
    method: 'post',
    data: data
  })
}

// 修改
export function updateModel(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}

// 删除模型应用
export function delModel(id) {
  return request({
    url: baseApi+ '/' + id,
    method: 'delete'
  })
}
