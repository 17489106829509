var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
      },
      _vm._l(_vm.forms, function (form, index) {
        return _c("issue-item", {
          key: index,
          ref: "issueItems",
          refInFor: true,
          attrs: {
            index: index,
            accountList: _vm.accountList,
            newAccounts: _vm.newAccounts,
            timeOptions: _vm.timeOptions,
            languageOptions: _vm.languageOptions,
            languageList: _vm.languageList,
          },
          on: {
            remove: function ($event) {
              return _vm.remove(index)
            },
            saveDraftClick: _vm.saveDraftClick,
          },
          model: {
            value: _vm.forms[index],
            callback: function ($$v) {
              _vm.$set(_vm.forms, index, $$v)
            },
            expression: "forms[index]",
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }