var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "image-item",
      class: { selected: _vm.isSelectionMode && _vm.image.selected },
      on: { click: _vm.toggleSelection },
    },
    [
      _c(
        "div",
        {
          staticClass: "flex-center",
          staticStyle: { width: "120px", height: "120px" },
        },
        [
          _c("img", {
            staticClass: "image-cover",
            attrs: { src: _vm.image.url, alt: "image" },
          }),
        ]
      ),
      _c("div", { staticClass: "middle-section" }, [
        _c("div", { staticClass: "image-name" }, [
          _vm._v(_vm._s(_vm.image.name)),
        ]),
        _c("div", { staticClass: "video-description" }, [
          _vm._v(_vm._s(_vm.image.description)),
        ]),
        _c("div", { staticClass: "image-details" }, [
          _c(
            "div",
            { staticClass: "image-tags" },
            _vm._l(_vm.image.labels, function (tag, index) {
              return _c(
                "el-tag",
                {
                  key: index,
                  staticStyle: {
                    "margin-right": "5px",
                    "margin-bottom": "5px",
                  },
                },
                [_vm._v(" " + _vm._s(tag) + " ")]
              )
            }),
            1
          ),
          _c("div", { staticClass: "video-created" }, [
            _vm._v(_vm._s(_vm.image.createTime)),
          ]),
        ]),
      ]),
      !_vm.isSelectionMode
        ? _c(
            "div",
            { staticClass: "right-section" },
            [
              _c(
                "el-tooltip",
                { attrs: { content: _vm.$t("file.filePreview") } },
                [
                  _c("el-button", {
                    attrs: {
                      icon: "iconfont icon-a-xialajiantoubeifen3",
                      circle: "",
                    },
                    on: { click: _vm.viewImage },
                  }),
                ],
                1
              ),
              _c(
                "el-tooltip",
                { attrs: { content: _vm.$t("edit") } },
                [
                  _c("el-button", {
                    attrs: { icon: "el-icon-edit", circle: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("edit-image", _vm.image)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-tooltip",
                { attrs: { content: _vm.$t("file.download") } },
                [
                  _c("el-button", {
                    attrs: { icon: "el-icon-download", circle: "" },
                    on: {
                      click: function ($event) {
                        return _vm.downloadFile(_vm.image.url)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-tooltip",
                { attrs: { content: _vm.$t("copyLink") } },
                [
                  _c("el-button", {
                    attrs: { icon: "el-icon-link", circle: "" },
                    on: { click: _vm.copyLink },
                  }),
                ],
                1
              ),
              _c(
                "el-tooltip",
                { attrs: { content: _vm.$t("remove") } },
                [
                  _c("el-button", {
                    attrs: { icon: "el-icon-delete", circle: "" },
                    on: { click: _vm.deleteImage },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "50%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            staticClass: "image-viewer",
            attrs: { src: _vm.image.url, alt: "image" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }