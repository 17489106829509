<template>
  <div>
    <div class="videoTitle">{{$t('mixing.mixSet')}}</div>
    <div class="set-des">{{$t('videoSetting.visualExperience')}}</div>
    <el-collapse accordion>
      <!-- Logo上传 -->
      <el-collapse-item>
        <template slot="title" >
          <span style="width: 60px;">Logo</span>
          <div class="image-thumbnail-multiple">
            <img v-if="settings.logoTrackClipsBo && settings.logoTrackClipsBo.mediaUrl" :src="settings.logoTrackClipsBo.mediaUrl" class="image-thumbnail"/>
          </div>
        </template>
        <el-form :model="settings" label-position="top" label-width="80px">
          <el-form-item label="" style="width: 100%;">
            <!-- <imageUpload v-model="settings.logoTrackClipsBo.mediaUrl" :limit="1" @input="handleItemUpdated"/> -->
            <div class="material-container"
              v-if="settings.logoTrackClipsBo && settings.logoTrackClipsBo.mediaUrl"
              @mouseover="settings.logoTrackClipsBo.hover = true"
              @mouseleave="settings.logoTrackClipsBo.hover = false"
            >
              <img :src="settings.logoTrackClipsBo.mediaUrl" alt="material" class="material-thumbnail"/>
              <div v-if="settings.logoTrackClipsBo.hover" class="overlay">
                <div  @click="removeMaterial(3)">{{$t('basicInfo.remove')}}</div>
              </div>
            </div>
            <!-- <div v-else  class="aspect-ratio-box flex-column" @click="chooseMaterial(3)">
              <i class="el-icon-upload"></i>
              <div style="margin-top: 5px;">
                {{$t('basicInfo.materialImport')}}
              </div>
            </div> -->
            <picUpload
              v-else
              @picSelectMaterial="picSelectMaterial"
              @showLocaPic="showLocaPic"
            />

          </el-form-item>
          <el-form-item :label="$t('mixing.position')">
            <el-radio-group v-model="settings.logoTrackClipsBo.position" @input="logoChange">
                <el-radio label="left-top" style="margin-bottom: 10px;">{{$t('mixing.leftTop')}}</el-radio>
                <el-radio label="center-top" style="margin-bottom: 10px;">{{$t('mixing.centerTop')}}</el-radio>
                <el-radio label="right-top" style="margin-bottom: 10px;">{{$t('mixing.rightTop')}}</el-radio>
                <el-radio label="left-bottom" style="margin-bottom: 10px;">{{$t('mixing.leftBottom')}}</el-radio>
                <el-radio label="center-bottom" style="margin-bottom: 10px;">{{$t('mixing.centerBottom')}}</el-radio>
                <el-radio label="right-bottom" style="margin-bottom: 10px;">{{$t('mixing.rightBottom')}}</el-radio>
              </el-radio-group>
          </el-form-item>
        </el-form>
      </el-collapse-item>
      <!-- 封面上传 -->
      <el-collapse-item>
        <template slot="title">
         <span style="width: 60px;">{{$t('mixing.cover')}}</span>
         <div class="image-thumbnail-multiple">
           <img v-if="settings.coverUrl" :src="settings.coverUrl" class="image-thumbnail"/>
         </div>
        </template>
        <!-- <imageUpload v-model="settings.coverUrl" :limit="1" style="margin-left: 80px;" @input="handleItemUpdated"/> -->
        <!-- <div v-if="!settings.coverUrl" class="aspect-ratio-box flex-column" @click="chooseMaterial(4)">
          <i class="el-icon-upload"></i>
          <div style="margin-top: 5px;">
            {{$t('basicInfo.materialImport')}}
          </div>
        </div> -->
        <picUpload
          v-if="!settings.coverUrl"
          @picSelectMaterial="chooseMaterial(4)"
          @showLocaPic="showLocaPic4"
        />
        <div v-else
          class="material-container"
          @mouseover="settings.coverUrlHover = true"
          @mouseleave="settings.coverUrlHover = false"
        >
          <img :src="settings.coverUrl" alt="material" class="material-thumbnail"/>
          <div v-if="settings.coverUrlHover" class="overlay">
            <div  @click="removeMaterial(4)">{{$t('basicInfo.remove')}}</div>
          </div>
        </div>
      </el-collapse-item>
      <!-- 封底上传 -->
      <el-collapse-item v-if="false">
        <template slot="title">
         <span style="width: 60px;">{{$t('mixing.backCover')}}</span>
         <div class="image-thumbnail-multiple">
           <img v-if="settings.backCoverUrl" :src="settings.backCoverUrl" class="image-thumbnail"/>
         </div>
        </template>
        <!-- <imageUpload v-model="settings.backCoverUrl" :limit="1" style="margin-left: 80px;" @input="handleItemUpdated"/> -->
        <!-- <div v-if="!settings.backCoverUrl" class="aspect-ratio-box flex-column" @click="chooseMaterial(5)">
          <i class="el-icon-upload"></i>
          <div style="margin-top: 5px;">
            {{$t('basicInfo.materialImport')}}
          </div>
        </div> -->
        <picUpload
          v-if="!settings.backCoverUrl"
          @picSelectMaterial="chooseMaterial(5)"
          @showLocaPic="showLocaPic5"
        />
        <div v-else
          class="material-container"
          @mouseover="settings.backCoverUrlHover = true"
          @mouseleave="settings.backCoverUrlHover = false"
        >
          <img :src="settings.backCoverUrl" alt="material" class="material-thumbnail"/>
          <div v-if="settings.backCoverUrlHover" class="overlay">
            <div  @click="removeMaterial(5)">{{$t('basicInfo.remove')}}</div>
          </div>
        </div>
      </el-collapse-item>
      <!-- 背景音乐上传 -->
      <el-collapse-item>
        <template slot="title">
          <span style="width: 60px;">{{$t('mixing.music')}}</span>
          <div class="image-thumbnail-multiple">
            <svg-icon v-if="settings.audioTrackClipsBos && settings.audioTrackClipsBos.length > 0 && settings.audioTrackClipsBos[0].mediaUrl"  style="width: 20px;height: 20px;" icon-class="audio"/>
          </div>
        </template>
       <el-button size="small" type="primary" @click="chooseMaterial(2)">{{$t('tip.select')}}</el-button>
       <div v-if="settings.audioTrackClipsBos && settings.audioTrackClipsBos.length > 0 && settings.audioTrackClipsBos[0].mediaUrl" class="flex-column">
         <div class="flex-row-center" style="margin-top: 5px">
          <audio controls :src="settings.audioTrackClipsBos[0].mediaUrl" class="f-grow" ref="audioPlayer"></audio>
          <i class="el-icon-circle-close" @click="delMusic"></i>
         </div>
         <div class="flex-row-center mt5">
          <span style="color: #606266;font-size: 14px;margin-right: 12px;font-weight: 700;">{{ $t('editVideo.musicVolume') }}</span>
          <el-slider :step="0.1" class="f-grow" v-model="settings.audioTrackClipsBos[0].effects[0].gain" :max="10" :min="0" show-input @change="handleItemUpdated"></el-slider>
         </div>
       </div>
      </el-collapse-item>
      <!-- 背景颜色 -->
      <el-collapse-item>
        <template slot="title">
          <span style="width: 60px;">{{$t('mixing.bgColor')}}</span>
          <div class="image-thumbnail-multiple">
            <div class="image-thumbnail " :style="{ backgroundColor: settings.backgroundColor }"></div>
          </div>
        </template>
        <el-color-picker v-model="settings.backgroundColor" style="margin-left: 20px;" @change="handleItemUpdated"></el-color-picker>
      </el-collapse-item>
      <el-collapse-item>
        <template slot="title" >
          <span style="flex-shrink: 0; min-width: 60px;">{{$t('mixing.filter')}}</span>
          <div class="image-thumbnail-multiple">
            <img v-for="filter in selectedFilters" :src="filter.url" :alt="filter.label" :key="filter.id"
                 style="margin-left: 2px; width: 20px; height: 20px;" />
          </div>
        </template>
        <span class="category-label">{{$t('mixing.filterType')}}</span>
        <el-radio-group v-model="settings.filterType" @change="filterTypeUpdated">
          <el-radio label="random">{{$t('mixing.filterRandom')}}</el-radio>
          <el-radio label="same">{{$t('mixing.filterSame')}}</el-radio>
        </el-radio-group>
        <div v-if="settings.filterType=='random'">
          <div class="flex-row space-between mt10">
            <el-checkbox :indeterminate="isIndeterminate"
              v-model="allFiltersSelected"
              @change="checkedAllFilters" >{{ $t('checkAll') }}</el-checkbox>
          </div>
          <el-checkbox-group
            v-model="settings.filters"
            @change="checkedSingleFilters"
            class="checkbox-group">
            <template v-for="category in Object.keys(categorizedFilters)">
              <template v-for="(filter, index) in categorizedFilters[category]">
                <el-checkbox v-if="filter.subtype" :label="filter.subtype" :key="filter.id">
                  <div class="category-label" v-if="index === 0">{{ category }}</div>
                  <div class="category-label" v-if="index === 1"></div>
                  <img :src="filter.url" :alt="filter.label" class="filter-preview" />
                  {{ filter.label }}
                </el-checkbox>
                <div v-else class="checkbox-placeholder"></div>
              </template>
            </template>
          </el-checkbox-group>
        </div>
        <div v-if="settings.filterType=='same'" class="mt10">
          <el-radio-group
            v-model="settings.filter"
            @input="singleFilter"
            class="custom-radio-group">
            <template v-for="category in Object.keys(categorizedFilters)">
              <template v-for="(filter, index) in categorizedFilters[category]">
                <el-radio v-if="filter.subtype" :label="filter.subtype" :key="filter.id" class="flex-row-center">
                  <div class="category-label" v-if="index === 0">{{ category }}</div>
                  <div class="category-label" v-if="index === 1"></div>
                  <img :src="filter.url" :alt="filter.label" class="filter-preview" />
                  {{ filter.label }}
                </el-radio>
                <div v-else class="checkbox-placeholder"></div>
              </template>
            </template>
          </el-radio-group>

        </div>

      </el-collapse-item>
      <el-collapse-item title="">
        <template slot="title">
          <span style="flex-shrink: 0; min-width: 60px;">{{$t('mixing.transition')}}</span>
          <div class="image-thumbnail-multiple">
            <img v-for="transition in selectedTransitions" :src="transition.url" :alt="transition.label" :key="transition.id"
              style="margin-left: 2px;width: 20px;height: 20px;"/>
          </div>
        </template>
        <span class="category-label">{{$t('mixing.transitionType')}}</span>
        <el-radio-group v-model="settings.transitionType" @change="transitionTypeUpdated">
          <el-radio label="random">{{$t('mixing.transitionRandom')}}</el-radio>
          <el-radio label="same">{{$t('mixing.transitionSame')}}</el-radio>
        </el-radio-group>
        <div v-if="settings.transitionType=='random'">
          <div class="flex-row space-between mt10">
            <el-checkbox :indeterminate="isIndeterminateTransitions"
              v-model="allTransitionsSelected"
              @change="checkedAllTransitions" >{{ $t('checkAll') }}</el-checkbox>
          </div>
          <el-checkbox-group
            v-model="settings.transitions"
            @change="checkedSingleTransitions"
            class="checkbox-group">
            <el-checkbox v-for="transition in transitionOptions" :label="transition.subtype" :key="transition.id">
              <img :src="transition.url" :alt="transition.label" class="transition-preview"/>
              {{ transition.label }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div v-if="settings.transitionType=='same'" class="mt10">
          <el-radio-group
            v-model="settings.transition"
            @input="singleTransition"
            class="custom-radio-group">
            <el-radio v-for="transition in transitionOptions" :label="transition.subtype" :key="transition.id">
              <img :src="transition.url" :alt="transition.label" class="transition-preview"/>
              {{ transition.label }}
            </el-radio>
          </el-radio-group>
        </div>

      </el-collapse-item>
    </el-collapse>
    <material-drawer
      ref="materialDrawer"
      :visible.sync="drawer"
      @selectionConfirmed="selectionConfirmed"
    />
  </div>
</template>

<script>
import { getFilterList, getTransitionsList } from '../../api/ai/ali';
import MaterialDrawer from '@/components/MaterialDrawer/index.vue';
import picUpload from '../../components/picUpload/index.vue';

export default {
  components: { MaterialDrawer, picUpload },
  props:{
    value: {
      type: Object,
      default: () => ({}) // 默认值为空对象
    }
  },
  watch: {
    value(val) {
      console.log('-----mix----', val);
      this.settings = {
        backgroundColor: "#000000",
        filter: '',
        filters: [],
        filterType: 'same',
        transition: '',
        transitions: [],
        transitionType: 'same',
        coverUrl: '', // 封面
        backCoverUrl: '', // 封底
        coverUrlHover: false, // 封面 hover
        backCoverUrlHover: false, // 封底 hover
        logoTrackClipsBo: {
          type: 'GlobalImage',
          x: '20',
          y: '20',
          width: 200,
          height: 200,
          mediaUrl: '',
          position: 'left-top',
          hover: false
        },
        audioTrackClipsBos: [
          {
            type: 'music',
            mediaUrl: '',
            effects: [
              {
                type: "Volume",
                gain: 0.1, // 声音大小（0-10）
              }
            ],
          }
        ],
        ...this.value
      };
      if(!this.settings.logoTrackClipsBo){
        this.settings.logoTrackClipsBo = {
          type: 'GlobalImage',
          x: '20',
          y: '20',
          width: 200,
          height: 200,
          mediaUrl: '',
          position: 'left-top',
          hover: false
        }
      }
      if(!this.settings.audioTrackClipsBos || this.settings.audioTrackClipsBos.length == 0){
        this.settings.audioTrackClipsBos = [
          {
            type: 'music',
            mediaUrl: '',
            effects: [
              {
                type: "Volume",
                gain: 0.1, // 声音大小（0-10）
              }
            ],
          }
        ]
      }
      if(!this.settings.audioTrackClipsBos[0].effects || this.settings.audioTrackClipsBos[0].effects.length == 0){
        this.settings.audioTrackClipsBos[0].effects = [
          {
            type: "Volume",
            gain: 0.1, // 声音大小（0-10）
          }
        ]
      }
      if(!this.settings.backgroundColor){
        this.settings.backgroundColor = '#000000'
      }
      if(!this.settings.filterType){
        this.settings.filterType = 'same'
      }
      if(!this.settings.transitionType){
        this.settings.transitionType = 'same'
      }
      if (this.settings.transition) {
        this.settings.transitions = this.settings.transition.split(',');
      }
      if (this.settings.filter) {
        this.settings.filters = this.settings.filter.split(',');
      }

      console.log('-----mix111----', this.settings);
    }
  },
  data() {
    return {
      settings: {
        backgroundColor:"#000000",
        filter:'',
        filters: [],
        filterType:'same',
        transition:'',
        transitions: [],
        transitionType:'same',
        coverUrl:'',//封面
        backCoverUrl:'',//封底
        coverUrlHover:false,//封面hover
        backCoverUrlHover:false,//封底hover
        logoTrackClipsBo:{
          type:'GlobalImage',
          x: '20',
          y: '20',
          width: 200,
		      height: 200,
          mediaUrl:'',
          position: 'left-top',
          hover:false
        },
        audioTrackClipsBos:[
          {
            type:'music',
            mediaUrl:'',
            effects:[
				    	{
				    		type: "Volume",
				    		gain: 0.1,//声音大小（0-10）
				    	}
				    ],
          }
        ],
        ...this.value
      },
      audioExists: true, // 假设音频存在，你可以根据实际情况设置这个值
      filterOptions: [],
      data: {}, // 新增的对象，用于存储分类后的滤镜和转场选项
      transitionOptions: [],
      drawer:false,//素材选择的抽屉
      drawerMaterialType:0,//2-音频（背景音乐）、3-logo、4-封面、5-封底
      allFiltersSelected: false, // 添加全选状态
      isIndeterminate: false,//新增：不确定状态
      allTransitionsSelected: false, // 添加全选状态
      isIndeterminateTransitions: false,//新增：不确定状态
    };
  },
  computed: {
    categorizedFilters() {
      let categorizedFilters = {};
      Object.keys(this.data).forEach(category => {
        const filters = [...this.data[category]];
        if (filters.length % 2 !== 0) {
          filters.push({ id: `${category}-empty`, subtype: '', url: '', label: '' }); // 添加一个空的滤镜对象
        }
        categorizedFilters[category] = filters;
      });
      return categorizedFilters;
    },
    selectedFilters() {
      return this.filterOptions.filter(filter => this.settings.filters.includes(filter.subtype));
    },
    selectedTransitions() {
      return this.transitionOptions.filter(transition => this.settings.transitions.includes(transition.subtype));
    },
  },
  created(){
    this.getFilterList();
    this.getTransitionsList();
  },
  methods: {
    //更新数据到上个页面
    handleItemUpdated() {
      if(this.settings.filters&&this.settings.filters.length>0){
        this.settings.filter = this.settings.filters.join(',')
      }else{
        this.settings.filter = ''
      }
      if(this.settings.transitions && this.settings.transitions.length>0){
        this.settings.transition = this.settings.transitions.join(',')
      }else{
        this.settings.transition = ''
      }
      this.$emit('item-updated', this.settings);
    },
    init(data) {
      this.settings = {...data}
      console.log('mixingSetting', this.settings);
      this.getFilterList();
      this.getTransitionsList();
    },

    // 获取滤镜列表
    getFilterList(){
      getFilterList().then(res => {
        this.data = res.data
        for (let key in this.data) {
        this.filterOptions.push(...this.data[key]);
      }
      })
    },
    // 获取转场列表
    getTransitionsList(){
      getTransitionsList().then(res => {
        this.transitionOptions = res.data
      })
    },
    delMusic(){
      this.settings.audioTrackClipsBos[0].mediaUrl = ""
      this.$forceUpdate(); // 手动触发更新
      console.log('music',this.settings.audioTrackClipsBos[0].mediaUrl)
      this.handleItemUpdated()
    },
    picSelectMaterial() {
      this.chooseMaterial(3)
    },
    //选择素材：2-音频（背景音乐）、3-logo、4-封面、5-封底
    chooseMaterial(num){
      this.drawerMaterialType = num
      this.drawer = true
      let type;
      if (num === 2) {
        type = 2;
      } else if (num === 3 || num === 4 || num === 5) {
        type = 3;
      }
      this.$nextTick().then((rs) => {
        this.$refs.materialDrawer.$refs.materialLibrary.init(type);
      });
    },
    showLocaPic(items) {
      this.drawerMaterialType = 3
      this.selectionConfirmed(items)
    },
    showLocaPic4(items) {
      this.drawerMaterialType = 4
      this.selectionConfirmed(items)
    },
    showLocaPic5(items) {
      this.drawerMaterialType = 5
      this.selectionConfirmed(items)
    },
    selectionConfirmed(items) {
      if(items.length==1){
        if(this.drawerMaterialType==2){
          this.settings.audioTrackClipsBos[0].mediaUrl = items[0].url
        }else if(this.drawerMaterialType==3){
          this.settings.logoTrackClipsBo.mediaUrl = items[0].url
        }else if(this.drawerMaterialType==4){
          this.settings.coverUrl = items[0].url
        }else if(this.drawerMaterialType==5){
          this.settings.backCoverUrl = items[0].url
        }
      }
      console.log('选中的素材:', items)
      // 这里可以执行进一步的处理，例如保存到数据中等
      this.drawer = false
      this.handleItemUpdated()
    },
    removeMaterial(type){
      if(type == 3){
        this.settings.logoTrackClipsBo.mediaUrl = ''
      }else if(type == 4){
        this.settings.coverUrl = ''
      }else if(type == 5){
        this.settings.backCoverUrl = ''
      }
      this.handleItemUpdated()
    },
    logoChange(){
      const w = 253
      const h = 450
      switch(this.settings.logoTrackClipsBo.position){
        case 'left-top':
          // this.settings.logoTrackClipsBo.x = 20;
          // this.settings.logoTrackClipsBo.y = 20;
          this.settings.logoTrackClipsBo.x = 0;
          this.settings.logoTrackClipsBo.y = 0;
          break;
        case 'center-top':
          // this.settings.logoTrackClipsBo.x = (w-this.settings.logoTrackClipsBo.width)/2;
          // this.settings.logoTrackClipsBo.y = 20;
          this.settings.logoTrackClipsBo.x = 0.5;
          this.settings.logoTrackClipsBo.y = 0;
          break;
        case 'right-top':
          // this.settings.logoTrackClipsBo.x = w-this.settings.logoTrackClipsBo.width-20;
          // this.settings.logoTrackClipsBo.y = 20;
          this.settings.logoTrackClipsBo.x = 0.9;
          this.settings.logoTrackClipsBo.y = 0;
          break;
        case 'left-bottom':
          // this.settings.logoTrackClipsBo.x = 20;
          // this.settings.logoTrackClipsBo.y = h-this.settings.logoTrackClipsBo.height-20;
          this.settings.logoTrackClipsBo.x = 0;
          this.settings.logoTrackClipsBo.y = 0.9;
          break;
        case 'center-bottom':
          // this.settings.logoTrackClipsBo.x = (w-this.settings.logoTrackClipsBo.width)/2;
          // this.settings.logoTrackClipsBo.y = h-this.settings.logoTrackClipsBo.height-20;
          this.settings.logoTrackClipsBo.x = 0.5;
          this.settings.logoTrackClipsBo.y = 0.9;
          break;
        case 'right-bottom':
          // this.settings.logoTrackClipsBo.x = w-this.settings.logoTrackClipsBo.width-20;
          // this.settings.logoTrackClipsBo.y = h-this.settings.logoTrackClipsBo.height-20;
          this.settings.logoTrackClipsBo.x = 0.9;
          this.settings.logoTrackClipsBo.y = 0.0;
          break;
        default:
          // this.settings.logoTrackClipsBo.x = 20;
          // this.settings.logoTrackClipsBo.y = 20;
          this.settings.logoTrackClipsBo.x = 0;
          this.settings.logoTrackClipsBo.y = 0;
          break;
      }
      this.handleItemUpdated()
    },

    //滤镜全选
    checkedAllFilters(val) {
        this.settings.filters = val ? this.filterOptions.map(filter => filter.subtype) : [];
        this.isIndeterminate = false;
        this.handleItemUpdated()
    },
    checkedSingleFilters(value){
      let checkedCount = value.length;
      this.allFiltersSelected = checkedCount === this.filterOptions.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.filterOptions.length;
      this.handleItemUpdated()
    },
    filterTypeUpdated(){
      this.settings.filters = []
      this.handleItemUpdated()
    },
    singleFilter(value){
      this.settings.filters = [value]
      this.handleItemUpdated()
    },
    //转场全选
    checkedAllTransitions(val) {
        this.settings.transitions = val ? this.transitionOptions.map(filter => filter.subtype) : [];
        this.isIndeterminateTransitions = false;
        this.handleItemUpdated()
    },
    checkedSingleTransitions(value){
      let checkedCount = value.length;
      this.allTransitionsSelected = checkedCount === this.transitionOptions.length;
      this.isIndeterminateTransitions = checkedCount > 0 && checkedCount < this.transitionOptions.length;
      this.handleItemUpdated()
    },
    transitionTypeUpdated(){
      this.settings.transitions = []
      this.handleItemUpdated()
    },
    singleTransition(value){
      this.settings.transitions = [value]
      this.handleItemUpdated()
    },
    getInfo(){
      return this.settings
    },
  }
};
</script>

<style scoped>
.filter-preview, .transition-preview {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  margin-bottom: 5px;
}
.flex-row {
  display: flex;
  justify-content: space-between;
}
.audio-player {
  width: 100%;
  margin-top: 5px;
}
.checkbox-group,
.custom-radio-group {
  display: flex;
  flex-wrap: wrap;
}
.checkbox-group .el-checkbox,
.custom-radio-group .el-radio {
  width: calc(50% - 10px);/* 每行两个 */
  margin-right: 10px;
  margin-bottom: 10px;
}
.checkbox-group .el-checkbox:nth-child(2n),
.custom-radio-group .el-radio:nth-child(2n){
  margin-right: 0;
}

/* 只对 custom-radio-group 内的第一个和第二个 el-radio 元素应用特定样式 */
.custom-radio-group .el-radio:nth-child(1),
.custom-radio-group .el-radio:nth-child(2) {
  width: calc(50% - 10px); /* 每行两个 */
  margin-right: 10px;
  margin-bottom: 10px;
}

.custom-radio-group .el-radio:nth-child(2) {
  margin-right: 0;
}

.checkbox-placeholder {
  width: calc(50% - 10px);/* 每行两个 */
  margin-right: 10px;
  margin-bottom: 10px;
  visibility: hidden; /* 确保占位符不可见 */
}
.checkbox-placeholder:nth-child(2n) {
  margin-right: 0;
}
.category-label{
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.aspect-ratio-box {
  width: 140px;
  height: 140px;
  border: 1px solid #0092FF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 5px; /* 调整间距 */
  position: relative;
  margin: auto;
}

.material-container {
  position: relative;
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.material-thumbnail {
  max-width: 100%; /* 限制最大宽度 */
  max-height: 100%; /* 限制最大高度 */
  width: auto; /* 自动调整宽度 */
  height: auto; /* 自动调整高度 */
  margin: auto;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.image-thumbnail-multiple{
  display: flex;
  flex-wrap: nowrap;
  width: 70px;
  overflow-x: auto;
  flex-grow: 1;
  padding-right: 5px;
  align-items: center;
  justify-content: flex-end;
}
.image-thumbnail{
  width: 20px;
  height: 20px;
}
</style>
