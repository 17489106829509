<template>
  <div class="image-item" :class="{ 'selected': isSelectionMode&&image.selected }" @click="toggleSelection">
    <!-- 左侧：图片封面 -->
    <div class="flex-center" style="width: 120px;height: 120px;">
      <img :src="image.url" alt="image" class="image-cover" />
    </div>
    <!-- 中间：图片信息 -->
    <div class="middle-section">
      <div class="image-name">{{ image.name }}</div>
      <div class="video-description">{{ image.description }}</div>
      <div class="image-details">
      <div class="image-tags">
        <el-tag
          v-for="(tag, index) in image.labels"
          :key="index"
          style="margin-right: 5px;margin-bottom: 5px;"
        >
         <!-- closable
          @close="removeTag(index)" -->
          {{ tag }}
        </el-tag>
        <!-- <el-input
          v-if="addingTag"
          v-model="newTag"
          @keyup.enter.native="confirmTag"
          @blur="confirmTag"
          class="tag-input"
          size="small"
        ></el-input>
        <el-button
          v-else
          type="text"
          icon="el-icon-plus"
          @click="addingTag = true"
          size="mini"
        ></el-button> -->
      </div>
      <div class="video-created">{{ image.createTime }}</div>
    </div>
  </div>
   <!-- 右侧：操作按钮 -->
    <div class="right-section" v-if="!isSelectionMode">
      <el-tooltip :content="$t('file.filePreview')">
        <el-button icon="iconfont icon-a-xialajiantoubeifen3" circle @click="viewImage"></el-button>
      </el-tooltip>
      <el-tooltip :content="$t('edit')">
        <el-button icon="el-icon-edit" circle @click="$emit('edit-image',image)"></el-button>
      </el-tooltip>
      <el-tooltip :content="$t('file.download')">
        <el-button icon="el-icon-download" circle @click="downloadFile(image.url)"></el-button>
      </el-tooltip>
      <el-tooltip :content="$t('copyLink')">
        <el-button icon="el-icon-link" circle @click="copyLink"></el-button>
      </el-tooltip>
      <el-tooltip :content="$t('remove')">
        <el-button icon="el-icon-delete" circle @click="deleteImage"></el-button>
      </el-tooltip>
    </div>
    <!-- 弹窗查看图片 -->
    <el-dialog :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <img :src="image.url" alt="image" class="image-viewer" />
    </el-dialog>
  </div>
</template>

<script>
import { copyTextToClipboard } from '@/utils/copyLink.js';
export default {
  name: 'ImageItem',
  props: {
    image: {
      type: Object,
      required: true
    },
    isSelectionMode:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      addingTag: false,
      newTag: '',
      // selected: false,  // 添加选中状态
    };
  },
  methods: {
    viewImage() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    removeTag(index) {
      this.image.tags.splice(index, 1);
    },
    // confirmTag() {
    //   if (this.newTag) {
    //     this.image.tags.push(this.newTag);
    //   }
    //   this.addingTag = false;
    //   this.newTag = '';
    // },
    copyLink(){
      copyTextToClipboard(this.image.url,this.$message);
    },
    deleteImage() {
      this.$emit('delete-image', this.image);
    },
    toggleSelection() {
      this.image.selected = !this.image.selected;
      console.log('this.image.selected',this.image.selected);
      this.$emit('toggle-selection', this.image.selected, this.image);
    },

  }
};
</script>

<style scoped>
.image-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}
.image-item.selected {
  border-color: blue; /* 选中时的样式示例 */
}
.image-cover {
  max-width: 100%; /* 限制最大宽度 */
  max-height: 100%; /* 限制最大高度 */
  width: auto; /* 自动调整宽度 */
  height: auto; /* 自动调整高度 */
}

.middle-section {
  flex: 1;
  margin-left: 10px;
}

.image-name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
.video-description{
  font-size: 14px;
  margin-bottom: 5px;
  color: #666;
}
.image-details {
  font-size: 14px;
}
.image-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.tag-input {
  width: auto;
  margin-right: 5px;
}
.video-created {
  color: #999;
}
.right-section {
  display: flex;
}

.right-section .el-button {
  margin-left: 5px;
}
.image-actions {
  text-align: right;
}
/* Hover样式 */
.el-button .el-tooltip__popper.is-dark {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.image-viewer {
  width: 100%;
}
</style>
