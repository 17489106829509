var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "cut-scroll" },
      [
        _c(
          "el-form",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "form",
            staticClass: "form-top",
            attrs: {
              "label-position": "top",
              model: _vm.form,
              rules: _vm.rules,
            },
          },
          [
            _c("div", { staticClass: "model-title" }, [_vm._v("角色设定")]),
            _c(
              "el-row",
              { attrs: { gutter: 10 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "AI销售名称", prop: "saleName" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.form.saleName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "saleName", $$v)
                            },
                            expression: "form.saleName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "引用模型", prop: "modelId" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              filterable: "",
                              "default-first-option": "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.form.modelId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "modelId", $$v)
                              },
                              expression: "form.modelId",
                            },
                          },
                          _vm._l(_vm.modelList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.modelName, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "replyTypes" } },
              [
                _c("div", { staticClass: "flex-row-center" }, [
                  _c(
                    "span",
                    {
                      staticStyle: { color: "#ff4949", "margin-right": "4px" },
                    },
                    [_vm._v("*")]
                  ),
                  _c("span", { staticClass: "sale-label" }, [
                    _vm._v("回复方式"),
                  ]),
                  _c("span", { staticClass: "sale-tip ml10" }, [
                    _vm._v(
                      "消息目前仅支持Facebook、Instagram、X/Twitter三个平台"
                    ),
                  ]),
                ]),
                _c(
                  "el-checkbox-group",
                  {
                    model: {
                      value: _vm.form.replyTypes,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "replyTypes", $$v)
                      },
                      expression: "form.replyTypes",
                    },
                  },
                  [
                    _c("el-checkbox", { attrs: { label: "comment" } }, [
                      _vm._v("评论"),
                    ]),
                    _c("el-checkbox", { attrs: { label: "message" } }, [
                      _vm._v("消息"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "描述", prop: "description" } },
              [
                _c("el-input", {
                  staticStyle: { width: "90%" },
                  attrs: { type: "textarea", placeholder: "请输入", rows: 3 },
                  model: {
                    value: _vm.form.description,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "description", $$v)
                    },
                    expression: "form.description",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "model-title" }, [_vm._v("角色分配")]),
            _c(
              "el-form-item",
              { attrs: { label: "应用社媒", prop: "socialTypes" } },
              [
                _c(
                  "el-checkbox-group",
                  {
                    staticStyle: { width: "90%" },
                    model: {
                      value: _vm.form.socialTypes,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "socialTypes", $$v)
                      },
                      expression: "form.socialTypes",
                    },
                  },
                  _vm._l(_vm.dict.type.social_platform, function (dict) {
                    return _c(
                      "el-checkbox",
                      {
                        key: dict.value,
                        attrs: { border: "", label: dict.value },
                      },
                      [_vm._v(" " + _vm._s(dict.label) + " ")]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._l(_vm.form.socialTypes, function (item, index) {
              return _c(
                "el-form-item",
                { key: index, attrs: { prop: "selectedValues." + item } },
                [
                  _c(
                    "div",
                    { staticClass: "flex-row-center" },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#ff4949",
                            "margin-right": "4px",
                          },
                        },
                        [_vm._v("*")]
                      ),
                      _c("dict-tag", {
                        attrs: {
                          options: _vm.dict.type.social_platform,
                          value: item,
                        },
                      }),
                      _vm._v(" 账号 "),
                    ],
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        multiple: "",
                        filterable: "",
                        "default-first-option": "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.form.selectedValues[item],
                        callback: function ($$v) {
                          _vm.$set(_vm.form.selectedValues, item, $$v)
                        },
                        expression: "form.selectedValues[item]",
                      },
                    },
                    _vm._l(_vm.filterAccounts(item), function (account) {
                      return _c(
                        "el-option",
                        {
                          key: account.id,
                          attrs: { label: account.username, value: account.id },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex-row-center" },
                            [
                              _c("el-avatar", {
                                staticStyle: { "margin-right": "10px" },
                                attrs: {
                                  src: account.userImage,
                                  size: "small",
                                },
                              }),
                              _c("span", [_vm._v(_vm._s(account.username))]),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            }),
            _c(
              "el-form-item",
              { attrs: { label: "购买意向回复", prop: "intentionReply" } },
              [
                _c("el-input", {
                  staticStyle: { width: "90%" },
                  attrs: { type: "textarea", placeholder: "请输入", rows: 3 },
                  model: {
                    value: _vm.form.intentionReply,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "intentionReply", $$v)
                    },
                    expression: "form.intentionReply",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "model-tip flex-row-center" }, [
              _c("i", { staticClass: "el-icon-warning" }),
              _vm._v(
                " 提示：请勿在自定义回复目标中填写社媒账号，网站链接和联系方式，例如WhatsApp，邮箱等信息，可能会造成个人隐私泄露，触发平台处罚机制 "
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "flex-center mt10" },
      [
        _c(
          "el-button",
          { staticClass: "button-white", on: { click: _vm.cancel } },
          [_vm._v("取消")]
        ),
        _c(
          "el-button",
          {
            staticClass: "gradient-button",
            attrs: { loading: _vm.buttonLoading },
            on: { click: _vm.submitForm },
          },
          [_vm._v("确定")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }