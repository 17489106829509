<template>
  <div class="app-container">
    <div class="cut-scroll">
      <el-form
        v-loading="loading"
        ref="form"
        label-position="top"
        class="form-top"
        :model="form"
        :rules="rules"
      >
        <div class="model-title">角色设定</div>
        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item
              label="AI销售名称"
              prop="saleName"
            >
              <el-input
                v-model="form.saleName"
                placeholder="请输入"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="引用模型"
              prop="modelId"
            >
              <el-select
                v-model="form.modelId"
                filterable
                default-first-option
                placeholder="请选择"
                style="width: 100%;"
              >
                <el-option
                  v-for="item in modelList"
                  :key="item.id"
                  :label="item.modelName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="replyTypes">
          <div class="flex-row-center">
            <span style="color: #ff4949;margin-right: 4px;">*</span>
            <span class="sale-label">回复方式</span>
            <span class="sale-tip ml10">消息目前仅支持Facebook、Instagram、X/Twitter三个平台</span>
          </div>
          <el-checkbox-group v-model="form.replyTypes">
            <el-checkbox label="comment">评论</el-checkbox>
            <el-checkbox label="message">消息</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item
          label="描述"
          prop="description"
        >
          <el-input
            v-model="form.description"
            type="textarea"
            placeholder="请输入"
            style="width: 90%;"
            :rows="3"
          />
        </el-form-item>
        <div class="model-title">角色分配</div>
        <el-form-item
          label="应用社媒"
          prop="socialTypes"
        >
          <el-checkbox-group
            v-model="form.socialTypes"
            style="width: 90%;"
          >
            <el-checkbox
              v-for="dict in dict.type.social_platform"
              border
              :key="dict.value"
              :label="dict.value"
            >
              {{ dict.label }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item
          v-for="(item, index) in form.socialTypes"
          :key="index"
          :prop="`selectedValues.${item}`"
        >
          <div class="flex-row-center">
            <span style="color: #ff4949;margin-right: 4px;">*</span>
            <dict-tag
              :options="dict.type.social_platform"
              :value="item"
            />
            账号
          </div>
          <el-select
            v-model="form.selectedValues[item]"
            multiple
            filterable
            default-first-option
            placeholder="请选择"
            style="width: 100%;"
          >
            <el-option
              v-for="account in filterAccounts(item)"
              :key="account.id"
              :label="account.username"
              :value="account.id"
            >
              <div class="flex-row-center">
                <el-avatar
                  :src="account.userImage"
                  size="small"
                  style="margin-right: 10px;"
                ></el-avatar>
                <span>{{ account.username }}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="购买意向回复"
          prop="intentionReply"
        >
          <el-input
            v-model="form.intentionReply"
            type="textarea"
            placeholder="请输入"
            style="width: 90%;"
            :rows="3"
          />
        </el-form-item>
        <div class="model-tip flex-row-center">
          <i class="el-icon-warning"></i>
          提示：请勿在自定义回复目标中填写社媒账号，网站链接和联系方式，例如WhatsApp，邮箱等信息，可能会造成个人隐私泄露，触发平台处罚机制
        </div>
      </el-form>
    </div>
    <div class="flex-center mt10">
      <el-button
        class="button-white"
        @click="cancel"
      >取消</el-button>
      <el-button
        :loading="buttonLoading"
        class="gradient-button"
        @click="submitForm"
      >确定</el-button>
    </div>
  </div>
</template>

<script>
import { getSale, addSale, updateSale } from "@/api/sale/sale";
import { listModel } from "../../api/model/model";
import { listAllAccount } from "../../api/account/tiktok";
export default {
  name: "AddSale",
  dicts: ["social_platform"],
  components: {},
  data() {
    return {
      // 表单参数
      form: {
        replyTypes: ["comment", "message"],
        socialTypes: [],
        socialAccountIds: [],
        selectedValues: {},
        description: "",
      },
      // 表单校验
      rules: {
        saleName: [
          {
            required: true,
            message: "请输入销售名称",
            trigger: ["blur", "change"],
          },
        ],
        modelId: [
          {
            required: true,
            message: "请选择引用模型",
            trigger: ["blur", "change"],
          },
        ],
        replyTypes: [
          {
            required: true,
            message: "请选择回复方式",
            trigger: ["blur", "change"],
          },
        ],
        selectedValues: {
          tiktok: [
            {
              required: true,
              message: "请选择 TikTok 账号",
              trigger: "change",
            },
          ],
          youtube: [
            {
              required: true,
              message: "请选择 YouTube 账号",
              trigger: "change",
            },
          ],
          instagram: [
            {
              required: true,
              message: "请选择 Instagram 账号",
              trigger: "change",
            },
          ],
          twitter: [
            {
              required: true,
              message: "请选择 Twitter 账号",
              trigger: "change",
            },
          ],
        },
      },
      saleId: null,
      loading: false,
      buttonLoading: false,
      modelList: [], //模型列表
      socialAccounts: [], //社媒账号列表
      allowedSocialPlatforms: ["facebook", "instagram", "twitter"], // 当仅选中消息时允许的社交平台
    };
  },
  computed: {},
  async created() {
    this.saleId = this.$route.query.id || "";
    this.getModelList();
    await this.getSocialAccountList();
    if (this.saleId) {
      this.getDetail();
    }
  },
  methods: {
    filterAccounts(socialType) {
      return this.socialAccounts.filter(
        (account) => account.platform === socialType
      );
    },
    // 获取账号列表
    async getSocialAccountList() {
      listAllAccount().then((res) => {
        this.socialAccounts = res.data;
      });
    },
    getModelList() {
      listModel().then((response) => {
        this.modelList = response.rows.filter((item) => item.status == 1);
      });
    },
    getDetail() {
      this.loading = true;
      getSale(this.saleId)
        .then((response) => {
          this.form = { ...this.form, ...response.data };
          this.form.replyTypes = this.form.replyType
            ? this.form.replyType.split(",")
            : ["comment", "message"];
          this.form.socialTypes = this.form.socialType
            ? this.form.socialType.split(",")
            : [];
          this.form.socialAccountIds = this.form.socialIds
            ? this.form.socialIds.split(",")
            : [];
          const newSelectedValues = {};
          // 遍历 socialAccounts
          this.socialAccounts.forEach((account) => {
            if (this.form.socialAccountIds.includes(account.id)) {
              if (!newSelectedValues[account.platform]) {
                newSelectedValues[account.platform] = [];
              }
              newSelectedValues[account.platform].push(account.id);
            }
          });
          this.form.selectedValues = newSelectedValues;
          // 检查 socialIds 中未出现在 socialAccounts 的 id，分配到未知平台
          // this.form.socialIds.forEach((id) => {
          //   const exists = this.socialAccounts.some((account) => account.id === id);
          //   if (!exists) {
          //     if (!newSelectedValues.unknown) {
          //       newSelectedValues.unknown = [];
          //     }
          //     newSelectedValues.unknown.push(id);
          //   }
          // });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (
            this.form.replyTypes.length === 1 &&
            this.form.replyTypes[0] === "message"
          ) {
            // 找到 socialTypes 中不允许的值
            const invalidPlatforms = this.form.socialTypes.filter(
              (platform) => !this.allowedSocialPlatforms.includes(platform)
            );
            // 如果有无效值，提示
            if (invalidPlatforms.length > 0) {
              this.$modal.alertError(
                "消息目前仅支持Facebook、Instagram、X/Twitter三个平台,请重新选择应用社媒"
              );
              return;
            }
          }
          this.form.socialAccountIds = Object.values(
            this.form.selectedValues
          ).flat();
          this.form.replyType = this.form.replyTypes.join(",");
          this.form.socialType = this.form.socialTypes.join(",");
          this.form.socialIds = this.form.socialAccountIds.join(",");
          this.buttonLoading = true;
          if (this.saleId) {
            updateSale(this.form)
              .then((response) => {
                this.$modal.msgSuccess(this.$t("tip.update"));
                this.$tab.closeOpenPage({ path: "/aiSale" });
              })
              .finally(() => {
                this.buttonLoading = false;
              });
          } else {
            addSale(this.form)
              .then((response) => {
                this.$modal.msgSuccess(this.$t("tip.add"));
                this.$tab.closeOpenPage({ path: "/aiSale" });
              })
              .finally(() => {
                this.buttonLoading = false;
              });
          }
        }
      });
    },
    // 取消按钮
    cancel() {
      this.$tab.closeOpenPage({ path: "/aiSale" });
    },
  },
};
</script>

<style scoped  lang="scss">
.form-top ::v-deep .el-form-item__label {
  padding: 0;
}
.form-top .el-form-item {
  margin-bottom: 15px;
}
.form-top .el-form--label-top .el-form-item__label {
  padding: 0 0 0px 0;
}
.model-title {
  width: 90%;
  background: rgba(236, 242, 254, 0.3);
  border-radius: 8px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #0052d9;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  margin-bottom: 20px;
  padding: 5px 16px;
}
.model-tip {
  width: 90%;
  background: #e9f1fd;
  border-radius: 12px;
  padding: 14px 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.9);
  line-height: 20px;
  text-align: left;
  font-style: normal;
  margin-bottom: 16px;

  .el-icon-warning {
    color: #0052d9;
    font-size: 18px;
    margin-right: 8px;
  }
}
.cut-scroll {
  width: 100%;
  height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
  scroll-padding-bottom: 20px;
}
.sale-label {
  font-size: 14px;
  color: #606266;
  font-weight: 700;
  line-height: 36px;
}
.sale-tip {
  font-size: 11px;
  color: #a1a1a1;
  font-weight: 400;
}
</style>
