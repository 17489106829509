<template>
  <el-form ref="form" :model="user" :rules="rules" label-width="80px">
    <el-form-item :label="$t('register.companyName')" prop="companyName">
      <el-input
        v-model="user.companyName"
        type="text"
        autocomplete="off"
        :placeholder="$t('tip.input')"
      >
      </el-input>
    </el-form-item>
    <el-form-item :label="$t('register.industry')" prop="industryCategorys" style="margin-bottom:20px">
      <el-cascader
        style="width: 100%;"
        :show-all-levels="false"
        :props="props"
        v-model="user.industryCategorys"
        :options="industryCategoryOptions"
      ></el-cascader>
    </el-form-item>
    <el-form-item :label="$t('register.brand')" prop="brand">
      <el-input
        v-model="user.brand"
        type="text"
        autocomplete="off"
        :placeholder="$t('register.brandTip')"
      >
      </el-input>
    </el-form-item>
    <el-form-item :label="$t('register.product')" prop="product">
      <el-input
        v-model="user.product"
        type="text"
        autocomplete="off"
        :placeholder="$t('register.productTip')"
      >
      </el-input>
    </el-form-item>
    <el-form-item :label="$t('userInfo.nickName')" prop="nickName">
      <el-input v-model="user.nickName" maxlength="30" />
    </el-form-item>
    <el-form-item :label="$t('userInfo.phonenumber')" prop="phonenumber">
      <el-input v-model="user.phonenumber" maxlength="11" />
    </el-form-item>
    <el-form-item :label="$t('userInfo.email')" prop="email">
      <el-input v-model="user.email" maxlength="50" />
    </el-form-item>
    <el-form-item :label="$t('userInfo.sex')">
      <el-radio-group v-model="user.sex">
        <el-radio label="0">{{$t('userInfo.male')}}</el-radio>
        <el-radio label="1">{{$t('userInfo.female')}}</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" size="mini" :loading="loading" @click="submit">{{$t('save')}}</el-button>
      <el-button type="danger" size="mini" @click="close">{{$t('back')}}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import store from "@/store";
import { updateUserProfile } from "@/api/system/user";
import { industryCategoryTreeSelect } from "../../../../api/base/resource";

export default {
  props: {
    user: {
      type: Object
    }
  },
  data() {
    return {
      industryCategoryOptions: undefined, // 行业分类
      loading: false,
      props:{
        value:'name',
        label:'name',
        emitPath: false,
        multiple: true
      },
      // 表单校验
      rules: {
        // industryCategorys: [{required: true, trigger: ['blur', 'change'], message: this.$t('register.selectIndustry')}],
        // brand: [{required: true, trigger: ['blur', 'change'], message: this.$t('register.enterBrandName')}],
        // product: [{required: true, trigger: ['blur', 'change'], message: this.$t('register.enterProduct')}],
        nickName: [
          { required: true, message: this.$t('userInfo.tipName'), trigger: "blur" }
        ],
        phonenumber: [
          // { required: true, message: this.$t('userInfo.tipPhone'), trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: this.$t('userInfo.tipCorrectPhone'),
            trigger: "blur"
          }
        ],
        email: [
          // { required: true, message: this.$t('userInfo.tipEmail'), trigger: "blur" },
          {
            type: "email",
            message: this.$t('userInfo.tipCorrectEmail'),
            trigger: ["blur", "change"]
          }
        ],

      }
    };
  },
  created(){
    this.getIndustryCategoryOptionsTree()
  },
  methods: {
    /** 所属行业下拉树结构 */
    getIndustryCategoryOptionsTree() {
      industryCategoryTreeSelect().then((response) => {
        this.industryCategoryOptions = response.data;
      });
    },
    submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if(!this.user.phonenumber && !this.user.email){
            this.$modal.msgError(this.$t('userInfo.tipPhoneEmail'));
            return
          }
          if(this.user.industryCategorys){
            this.user.industryCategory = this.user.industryCategorys.join(',')
          }
          this.loading = true;
          updateUserProfile(this.user).then(response => {
            store.commit('SET_NICK_NAME', this.user.nickName);
            this.$modal.msgSuccessTime(this.$t('tip.update'), 3000);
            // this.close();
          }).finally(() => {
            this.loading = false;
          })
        }
      });
    },
    close() {
      this.$tab.closePage();
    }
  }
};
</script>
