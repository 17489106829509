<template>
  <el-dialog
    title="拖动下方滑块完成拼图"
    width="362px"
    class="custom-dialog"
    :visible.sync="localShowSlide"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="handleBeforeClose"
  >
    <slide-verify
      ref="slideVerify"
      :l="l"
      :r="r"
      :w="w"
      :h="h"
      :imgs="imgs"
      slider-text="向右滑动"
      @success="onSuccess"
      @fail="onFail"
    >
    </slide-verify>
  </el-dialog>
</template>

<script>
export default {
  name: "SlideVerifyDialog",
  props: {
    // 控制对话框显示
    showSlide: {
      type: Boolean,
      default: false,
    },
    // 图片列表
    imgs: {
      type: Array,
      default: () => [
        require("@/assets/images/login/verify1.png"),
        require("@/assets/images/login/verify2.png"),
        require("@/assets/images/login/verify3.png"),
        require("@/assets/images/login/verify4.png"),
        require("@/assets/images/login/verify5.png"),
        require("@/assets/images/login/verify6.png"),
      ],
    },
    // 滑块的宽度、高度等参数
    l: {
      type: Number,
      default: 42,
    },
    r: {
      type: Number,
      default: 10,
    },
    w: {
      type: Number,
      default: 340,
    },
    h: {
      type: Number,
      default: 198,
    },
  },
  data() {
    return {
      localShowSlide: this.showSlide,
    };
  },
  watch: {
    showSlide(newVal) {
      this.localShowSlide = newVal;
    },
  },
  methods: {
    // 验证成功的回调
    onSuccess() {
      this.$emit("success"); // 向外部发出 success 事件
      this.$refs.slideVerify.reset();
    },
    // 验证失败的回调
    onFail() {
      this.$emit("fail"); // 向外部发出 fail 事件
    },
    handleBeforeClose(done) {
      this.close();
      done();
    },
    close() {
      this.localShowSlide = false;
      this.$emit("update:showSlide", false);
    },
  },
};
</script>

<style scoped>
.custom-dialog {
  top: 50% !important;
  transform: translateY(-50%) !important; /* 将弹窗垂直居中 */
  min-height: 380px;
}
::v-deep .el-dialog__body {
  padding: 10px;
}
</style>
