import request from '@/utils/request'

const baseApi = 'admin/socialMessage'

//会话列表
export function msgList(params) {
  return request({
    url: baseApi + '/conversationList',
    method: 'get',
    params
  })
}

//会话详情
export function msgDetail(params) {
  return request({
    url: baseApi + `/messageDetails`,
    method: 'get',
    params
  })
}

//发送私信
export function sendMsg(data) {
  return request({
    url: baseApi + '/send',
    method: 'post',
    data
  })
}
