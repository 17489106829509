var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "flex-column-center w-100 p-10" }, [
              _c("video", {
                ref: "video",
                staticStyle: { "max-width": "100%", "max-height": "300px" },
                attrs: { src: _vm.detail.url, controls: "" },
                on: { timeupdate: _vm.updateCurrentTime },
              }),
              _c("div", { staticClass: "progress-container" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.currentTime,
                      expression: "currentTime",
                    },
                  ],
                  ref: "progressBar",
                  attrs: {
                    type: "range",
                    max: _vm.detail.videoLength,
                    step: "0.001",
                  },
                  domProps: { value: _vm.currentTime },
                  on: {
                    click: _vm.seekVideo,
                    __r: function ($event) {
                      _vm.currentTime = $event.target.value
                    },
                  },
                }),
                _c(
                  "div",
                  { staticClass: "markers" },
                  _vm._l(_vm.markers, function (marker, index) {
                    return _c("div", {
                      key: index,
                      staticClass: "marker",
                      style: {
                        left: (marker.out / _vm.detail.videoLength) * 100 + "%",
                      },
                    })
                  }),
                  0
                ),
              ]),
            ]),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "flex-row-center f-between" },
              [
                _c("h3", [_vm._v(_vm._s(_vm.$t("stripping.basicInfo")))]),
                _c(
                  "el-tooltip",
                  { attrs: { content: _vm.$t("edit") } },
                  [
                    _c("el-button", {
                      attrs: { icon: "el-icon-edit", circle: "" },
                      on: {
                        click: function ($event) {
                          return _vm.editText(1, _vm.detail)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "p-10 border-5" }, [
              _c("div", [
                _vm._v(
                  _vm._s(_vm.$t("stripping.name")) +
                    "：" +
                    _vm._s(_vm.detail.name)
                ),
              ]),
              _c("div", { staticClass: "mt5" }, [
                _vm._v(
                  _vm._s(_vm.$t("stripping.description")) +
                    "：" +
                    _vm._s(_vm.detail.description)
                ),
              ]),
              _c("div", { staticClass: "mt5 flex-row f-wrap" }, [
                _vm._v(_vm._s(_vm.$t("stripping.tags")) + "： "),
                _c(
                  "div",
                  { staticClass: "video-tags font14" },
                  _vm._l(_vm.detail.labels, function (tag, index) {
                    return _c(
                      "el-tag",
                      {
                        key: index,
                        staticStyle: {
                          "margin-right": "5px",
                          "margin-bottom": "5px",
                        },
                      },
                      [_vm._v(" " + _vm._s(tag) + " ")]
                    )
                  }),
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "mt5 p-10 border-5" }, [
              _c("div", [
                _vm._v(
                  _vm._s(_vm.$t("stripping.fileSize")) +
                    "：" +
                    _vm._s(_vm.formatFileSize(_vm.detail.fileSize))
                ),
              ]),
              _c("div", { staticClass: "mt5" }, [
                _vm._v(
                  _vm._s(_vm.$t("stripping.videoDuration")) +
                    "：" +
                    _vm._s(_vm.secondsFormatTime(_vm.detail.videoLength))
                ),
              ]),
              _c("div", { staticClass: "mt5" }, [
                _vm._v(_vm._s(_vm.$t("stripping.addedTime")) + "： "),
                _c("span", { staticClass: "video-created font14" }, [
                  _vm._v(" " + _vm._s(_vm.detail.createTime) + " "),
                ]),
              ]),
              _c("div", { staticClass: "mt5 video-status font14" }, [
                _vm._v(_vm._s(_vm.statusMessage)),
              ]),
              _c("div", { staticClass: "mt5 video-split font14" }, [
                _vm._v(_vm._s(_vm.splitStatus)),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm.detail &&
      _vm.detail.splitVideoPartsList &&
      _vm.detail.splitVideoPartsList.length > 0
        ? _c(
            "el-table",
            {
              staticClass: "mt10",
              attrs: { data: _vm.detail.splitVideoPartsList },
            },
            [
              _c("el-empty", {
                attrs: { slot: "empty", "image-size": 200 },
                slot: "empty",
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("stripping.clipInfo"),
                  align: "center",
                  prop: "name",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("stripping.videoClips"),
                  align: "center",
                  prop: "url",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("video", {
                            staticClass: "video-cover",
                            attrs: { src: scope.row.url, alt: "video" },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1186284254
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("stripping.videoDuration"),
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.secondsFormatTime(
                                  scope.row.splitVideoPartsEnd -
                                    scope.row.splitVideoPartsBegin
                                ) || "--"
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1519301548
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("stripping.videoTags"),
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.labels && scope.row.labels.length > 0
                            ? _c(
                                "div",
                                _vm._l(scope.row.labels, function (tag, index) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: index,
                                      staticStyle: {
                                        "margin-right": "5px",
                                        "margin-bottom": "5px",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(tag) + " ")]
                                  )
                                }),
                                1
                              )
                            : _c("div", [_vm._v("--")]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  740387237
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("stripping.videoDescription"),
                  align: "center",
                  prop: "description",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " + _vm._s(scope.row.description || "--") + " "
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2654366491
                ),
              }),
              _c("el-table-column", {
                attrs: { label: _vm.$t("operate"), align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-tooltip",
                            { attrs: { content: _vm.$t("view") } },
                            [
                              _c("el-button", {
                                attrs: {
                                  icon: "iconfont icon-a-xialajiantoubeifen3",
                                  circle: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.playVideo(scope.row)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-tooltip",
                            { attrs: { content: _vm.$t("edit") } },
                            [
                              _c("el-button", {
                                attrs: { icon: "el-icon-edit", circle: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editText(2, scope.row)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1563900012
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "50%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("video", {
            ref: "videoPlayer",
            staticClass: "video-player",
            attrs: { controls: "", src: _vm.currentUrl },
          }),
        ]
      ),
      _c("MaterialUploadDialog", {
        ref: "materialUploadDialog",
        attrs: {
          visible: _vm.uploadDialogVisible,
          title: _vm.$t("material.edit"),
          list: _vm.list,
          labelList: _vm.labelList,
          needMaterialTypeId: _vm.needMaterialTypeId,
        },
        on: {
          "update:visible": function ($event) {
            _vm.uploadDialogVisible = $event
          },
          editMaterialSuccess: _vm.editMaterialSuccess,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }