<!-- 带视频播放的按钮 -->
<template>
  <div>
    <el-tooltip :content="tip">
      <div @click="playVideo">
        <slot name="customButton">
          <!-- <el-button icon="el-icon-view" circle ></el-button> -->
          <el-button icon="el-icon-video-play" circle ></el-button>
        </slot>
      </div>
    </el-tooltip>
    <el-dialog :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <video v-if="type === 0" controls :src="url" class="video-player" ref="videoPlayer"></video>
      <div v-else>
        <img v-if="video.fileType=='image'" :src="video.url" alt="image" class="image-viewer" />
        <audio v-else-if="video.fileType=='audio'" controls :src="video.url" class="audio-player" ref="audioPlayer"></audio>
        <video v-else controls :src="video.url" class="video-player" ref="videoPlayer"></video>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name:"",
  props: {
    video: {
      type: Object,
      default: () => ({})
    },
    url: {
      type: String,
      default: ''
    },
    type: {
      type:Number,
      default: 0,//0-传的url，1-传的video，2-成品库/视频列表，只有视频，但没有fileType
    },
    tip: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: false,//视频播放弹窗
      videoElement: null,  // 添加一个变量用于保存视频元素
      audioElement: null,  // 添加一个变量用于保存音频元素
    }
  },
  created() {},
  methods: {
    //播放视频
    playVideo() {
      this.$emit('viewVideo')
      this.dialogVisible = true;
      if(this.video.fileType=='audio'){
        // 播放音频时获取音频元素
        this.$nextTick(() => {
          this.audioElement = this.$refs.audioPlayer;
          this.audioElement.play();
        });
      } else if(this.video.fileType == 'video'|| this.type == 0 || this.type == 2){
        // 播放视频时获取视频元素
        this.$nextTick(() => {
          this.videoElement = this.$refs.videoPlayer;
          this.videoElement.play();
        });
      }
    },
    //关闭视频弹窗
    handleClose() {
      this.dialogVisible = false;
      this.$emit('closeViewVideo')
      if(this.video.fileType=='audio'){
        // 关闭弹窗时停止音频播放
        if (this.audioElement) {
          this.audioElement.pause();
          this.audioElement.currentTime = 0; // 可选：将音频播放位置重置为起始位置
        }
      } else if(this.video.fileType=='video' || this.type == 0 || this.type == 2){
        // 关闭弹窗时停止视频播放
        if (this.videoElement) {
         this.videoElement.pause();
         this.videoElement.currentTime = 0; // 可选：将视频播放位置重置为起始位置
       }
      }
    },
  },
}
</script>
<style scoped>
.video-player {
  max-height:400px;
}
.image-viewer {
  max-width: 100%;
  max-height:400px;
  display: block;
  margin: auto;
}
.audio-player {
  width: 100%;
}
</style>
