<template>
  <div class="list-selector">
    <p>{{ message.text }}</p>
    <el-radio-group v-model="videoUrl" :disabled="message.completed" class="checkbox-group">
      <el-radio v-for="(item, index) in message.videoUrls" :label="item" :key="index" class="flex-row-center">
        <video :src="item" class="material-thumbnail"></video>
      </el-radio>
    </el-radio-group>
    <el-button type="primary" v-if="!message.completed" @click="submit">{{$t('ai.sure')}}</el-button>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      videoUrl: this.message.videoUrl
    };
  },
  methods: {
    submit() {
      if(!this.videoUrl) {
        this.$modal.msgError(this.$t('ai.videoChooseFirst'));
      } else {
        this.$emit('submit', this.videoUrl);
      }
    }
  }
};
</script>

<style scoped>
.list-selector {
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
}
.checkbox-group {
  display: flex;
  flex-wrap: wrap;
}
.checkbox-group .el-checkbox {
  width: calc(50% - 10px);/* 每行两个 */
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}
.checkbox-group .el-checkbox:nth-child(2n) {
  margin-right: 0;
}
.material-thumbnail {
  width: 100%;
  height: 100%; /* 根据需要调整高度 */
  object-fit: cover;
  border-radius: 3px;
}
</style>
