import request from '@/utils/request'
//模型训练——问答
const baseApi = '/admin/documentSegment'
// 查询知识库文本段落列表
export function listModelDocumentSegments(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}

// 查询知识库文本段落详细
export function getModelDocumentSegments(id) {
  return request({
    url: baseApi+ '/' + id,
    method: 'get'
  })
}

// 新增知识库文本段落
export function addModelDocumentSegments(data) {
  return request({
    url: baseApi,
    method: 'post',
    data: data
  })
}

// 修改知识库文本段落
export function updateModelDocumentSegments(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}

// 删除知识库文本段落
export function delModelDocumentSegments(id) {
  return request({
    url: baseApi+ '/' + id,
    method: 'delete'
  })
}
