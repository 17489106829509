var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-selector" },
    [
      _c("p", [_vm._v(_vm._s(_vm.message.text))]),
      _c(
        "el-radio-group",
        {
          staticClass: "checkbox-group",
          attrs: { disabled: _vm.message.completed },
          model: {
            value: _vm.videoUrl,
            callback: function ($$v) {
              _vm.videoUrl = $$v
            },
            expression: "videoUrl",
          },
        },
        _vm._l(_vm.message.videoUrls, function (item, index) {
          return _c(
            "el-radio",
            {
              key: index,
              staticClass: "flex-row-center",
              attrs: { label: item },
            },
            [
              _c("video", {
                staticClass: "material-thumbnail",
                attrs: { src: item },
              }),
            ]
          )
        }),
        1
      ),
      !_vm.message.completed
        ? _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v(_vm._s(_vm.$t("ai.sure")))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }