<!-- 发布页面的视频选择 -->
<template>
  <div
    class="uploadbox"
    v-loading="loading"
  >
    <div class="material">
      <i class="iconfont icon-tianjia"></i>
      <el-button
        class="uploadBtn"
        type="primary"
        round
        @click="selectMaterial"
      >{{ computedTitle }}</el-button>
    </div>
    <div class="line"></div>
    <div class="local">
      <i class="iconfont icon-shangchuan"></i>
      <el-upload
        action=""
        :before-upload="handleBeforeUpload"
        :limit="1"
      >
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('file.pleaseUpload') + $t('file.sizeLimit') + fileSize +'MB' + $t('file.formatLimit') + fileType.join('/') + $t('file.dFile')"
        >
          <el-button
            class="uploadBtn"
            type="primary"
            round
          >{{ $t('file.localUpload') }}</el-button>
        </el-tooltip>
      </el-upload>
    </div>
  </div>
</template>
<script>
import { localTuClipAdd } from "../../api/ai/material";
import { getOssToken } from "../../api/ai/ali";
import OSS from "ali-oss";
export default {
  props: {
    type: String,
    title: {
      type: String,
      default: "",
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 500,
    },
    // 文件类型
    fileType: {
      type: Array,
      default: () => ["jpg", "jpeg", "webp", "mp4", "avi", "mkv", "mov"],
    },
  },
  watch: {},
  data() {
    return {
      credentials: null,
      loading: false,
    };
  },
  computed: {
    computedTitle() {
      return this.title || this.$t("file.selectMaterial");
    },
  },
  created() {},
  methods: {
    selectMaterial() {
      this.$emit("selectMaterial");
    },
    async uploadFile(file) {
      console.log(file);
      try {
        this.loading = true;
        const client = await this.getOssClient();
        const result = await client.put(
          `file/${this.getCurrentDate()}/${this.generateUniqueCode(
            30
          )}${file.name.replace(/^[^.]+/, "")}`,
          file
        );
        // console.log(result, '上传至oss的文件');
        // debugger
        const name = file.name.replace(/\.[^/.]+$/, "");
        const fileType = file.type.includes("video")
          ? "video"
          : file.type.includes("image")
          ? "image"
          : "";
        if (this.type === "materialType") {
          localTuClipAdd({
            ...file,
            fileType: fileType,
            url: result.url,
            fileSize: file.size,
            name: name,
          })
            .then((response) => {
              if (response.data) {
                this.$emit("showLocaMedia", [
                  {
                    url: response.data.url,
                    firstFrameImgUrl: response.data.firstFrameImgUrl,
                    videoLength: response.data.videoLength,
                    name: name,
                    fileType: fileType,
                  },
                ]);
                // this.$emit('showLocaMedia', [{url: result.url, firstFrameImgUrl: result.url, out: response.data.videoLength}])
                this.loading = false;
              }
            })
            .catch((error) => {
              this.loading = false;
            });
        } else {
          this.$emit("showLocaMedia", [
            {
              url: result.url,
              firstFrameImgUrl: result.url,
              name: name,
              fileType: fileType,
            },
          ]);
          this.loading = false;
          // localTuClipAdd({
          //   ...file,
          //   url: result.url,
          //   fileSize: file.size,
          //   name: file.name.replace(/\.[^/.]+$/, '')
          // })
          // .then(response => {
          //   if (response.data) {
          //     this.$emit('showLocaMedia', [{url: response.data.url, firstFrameImgUrl: response.data.firstFrameImgUrl, out: response.data.videoLength}])
          //     // this.$emit('showLocaMedia', [{url: result.url, firstFrameImgUrl: result.url, out: response.data.videoLength}])
          //     this.loading = false
          //   }
          // })
          // .catch(error => {
          //   this.loading = false
          // });
        }
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    async getOssClient() {
      // 临时凭证过期时重新获取，减少对STS服务的调用。
      if (this.isCredentialsExpired(this.credentials)) {
        this.credentials = await getOssToken();
      }
      return new OSS({
        bucket: process.env.VUE_APP_OSS_BUCKET, // OSS Bucket名称
        region: "oss-cn-beijing", // OSS所在地域
        accessKeyId: this.credentials.accessKeyId,
        accessKeySecret: this.credentials.accessKeySecret,
        stsToken: this.credentials.securityToken,
        timeout: 500000,
        secure: true,
      });
    },
    isCredentialsExpired(credentials) {
      if (!credentials) {
        return true;
      }
      const expireDate = new Date(credentials.Expiration);
      const now = new Date();
      return expireDate.getTime() - now.getTime() <= 60000; // 有效期不足一分钟，视为过期
    },
    handleBeforeUpload(file) {
      // 校检文件类型
      if (this.fileType) {
        const fileName = file.name.split(".");
        const fileExt = fileName[fileName.length - 1];
        const isTypeOk = this.fileType.indexOf(fileExt) >= 0;
        if (!isTypeOk) {
          this.$modal.msgError(
            `${this.$t("file.invalidFormat1")}${this.fileType.join(
              "/"
            )}${this.$t("file.invalidFormat2")}`
          );
          return false;
        }
      }
      // 校检文件大小
      if (this.fileSize) {
        const isLt = file.size / 1024 / 1024 < this.fileSize;
        if (!isLt) {
          this.$modal.msgError(
            `${this.$t("file.sizeExceed")} ${this.fileSize} MB!`
          );
          return false;
        }
      }
      this.uploadFile(file);
      // this.$modal.loading("正在上传文件，请稍候...");
      this.number++;
      return true;
    },
    generateRandomCode(length = 30) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
      return result;
    },
    generateUniqueCode(length) {
      const generatedCodes = new Set();
      let code;
      do {
        code = this.generateRandomCode(length);
      } while (generatedCodes.has(code));
      generatedCodes.add(code);
      return code;
    },
    getCurrentDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份从0开始，所以加1
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}/${month}/${day}`;
    },
  },
};
</script>

<style lang="scss">
.uploadbox {
  width: 200px;
  height: 366px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #e5e7eb;
  .line {
    width: 100%;
    height: 1px;
    border: 1px dashed #e5e7eb;
  }
  .material {
    height: 174px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .local {
    height: 174px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .uploadBtn {
    width: 80px;
    height: 32px;
    background: #0156ff;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 22px;
    text-align: center;
    font-style: normal;
    align-items: center;
    margin-top: 15px;
  }
  .iconfont {
    font-size: 28px;
    margin-top: 40px;
  }
  .el-upload-list {
    margin: 0;
    padding: 0;
    display: none;
    list-style: none;
  }
}
</style>
