<template>
  <div class="video-grid-item"
    :class="{ 'selected': isSelectionMode && item.selected }"
    @click.stop="toggleSelection"
    @mouseover="mouseoverItem"
    @mouseleave="mouseleaveItem"
    :style="{ zIndex: item.hover ? 10 : 'auto' }"
    >
    <!-- 视频封面 -->
    <div class="left-section" v-loading="loading">
      <div v-if="item.fileType == 'image'">
        <img :src="item.url" alt="image" class="video-grid-cover" />
        <div class="video-grid-type type-image">{{ $t('material.picture') }}</div>
      </div>
      <div v-else-if="item.fileType == 'audio'" class="video-grid-cover video-grid-bg flex-center">
        <img src="../../assets/images/video/audio.png" alt="">
        <div class="video-grid-type type-audio">{{ $t('material.audio') }}</div>
        <!-- <svg-icon class="video-grid-cover" icon-class="audio"/> -->
      </div>
      <div v-else-if="item.fileType == 'text'" class="video-grid-cover video-grid-bg flex-center">
        <img src="../../assets/images/video/text.png" alt="">
        <div class="video-grid-type type-text">{{ $t('material.copywriting') }}</div>
      </div>
      <div v-else class="video-grid-cover">
        <video
          :src="item.url"
          alt="video"
          class="video-grid-cover"
          muted
          playsinline
          loop
          preload="auto"
          ref="videoPlayer"
          @mouseenter="playVideo"
          @mouseleave="pauseVideo"
        />
        <!-- autoplay -->
        <span class="video-grid-duration" v-if="item.videoLength&&item.videoLength>0">{{ secondsFormatTime(item.videoLength) }}</span>
        <div class="video-grid-clip" @click.stop="splitDetail(item)" v-if="!isFailed && splitStatus === $t('videoItem.clipSuccess')">
          {{ $t('videoItem.clipDetails') }}
        </div>
        <div class="video-grid-type type-video">{{ $t('material.video') }}</div>
        <div class="video-grid-play" @click="viewMedia(item)">
          <i class="el-icon-caret-right"></i>
        </div>
      </div>
      <el-tooltip :content="item.jobError" v-if="item.jobError">
        <div class="controlsStatus" :class="[filterState(item)]" v-if="item.splitVideoPartsStatus || item.mergeVideoFaceStatus || item.eraseVideoSubtitlesStatus || item.jobStatus">{{ statusMessage }}</div>
      </el-tooltip>
    </div>
    <!-- 视频信息 -->
    <div class="middle-section">
      <div class="video-grid-name single-line-ellipsis">{{ item.fileType == 'text' ? item.title :item.name }}</div>
      <!-- <div class="video-grid-description">{{ item.description }}</div> -->
      <div class="video-grid-created single-line-ellipsis font12" >{{ item.createTime }}</div>
      <div class="video-grid-labels flex-row-center single-line-ellipsis" v-if="!item.hover">
        <span
          v-for="(tag, index) in item.labels"
          :key="index"
          class="mr5"
        >
        #{{ tag }}
        </span>
      </div>
      <!-- <div class="video-grid-status font14">{{ statusMessage }}</div> -->
      <!-- <div class="video-grid-split font14">{{ splitStatus }}</div> -->
    </div>

    <!--" -->
    <!-- 操作按钮 -->
    <div v-if="item.hover && !loading">
      <div v-if="item.fileType == 'text' " class="flex-center right-section">
        <el-tooltip :content="$t('view')">
          <i class="iconfont icon-a-xialajiantoubeifen3 color-blue" @click.stop="showText"></i>
        </el-tooltip>
        <el-tooltip :content="$t('edit')" v-if="item.type !== 'system' && !isSelectionMode ">
          <i class="el-icon-edit color-blue" @click.stop="$emit('edit-text',item)"></i>
        </el-tooltip>
        <el-tooltip :content="$t('remove')" v-if="item.type !== 'system' && !isSelectionMode ">
          <i class="el-icon-delete color-blue" @click.stop="deleteVideo"></i>
        </el-tooltip>
      </div>
      <div v-else class="flex-center right-section">
        <el-tooltip :content="$t('videoManager.publish')" v-if="type == 3 && item.publishSuccessCount == 0 && !isSelectionMode ">
          <i class="el-icon-position color-blue" @click.stop="publish(item)"></i>
        </el-tooltip>
        <div v-if="item.fileType == 'image'">
          <el-tooltip :content="$t('view')" v-if="!isFailed">
            <i class="iconfont icon-a-xialajiantoubeifen3 color-blue" @click.stop="viewMedia(item)"></i>
          </el-tooltip>
        </div>
        <div v-else-if="item.fileType == 'audio'">
          <el-tooltip :content="$t('file.filePreview')" v-if="!isFailed">
            <i class="iconfont icon-erji color-blue" @click.stop="viewMedia(item)"></i>
          </el-tooltip>
        </div>
        <div v-else>
          <el-tooltip :content="$t('view')" v-if="!isFailed">
            <i class="iconfont icon-a-xialajiantoubeifen3 color-blue" @click.stop="$emit('view-text',item)"></i>
          </el-tooltip>
        </div>
        <!-- <div  v-if="!isFailed"> -->
          <!-- <video-grid-button :type="1" :video="item" :tip="$t('file.filePreview')"> -->
            <!-- <template #customButton> -->
              <!-- <i class="el-icon-view color-blue" @click.stop="viewMedia(item)"></i> -->
            <!-- </template> -->
          <!-- </video-grid-button> -->
        <!-- </div> -->
        <el-tooltip :content="$t('edit')" v-if="!isFailed && item.type !== 'system' && !isSelectionMode ">
          <i class="el-icon-edit color-blue" @click.stop="$emit('edit-video',item)"></i>
        </el-tooltip>
        <el-tooltip :content="$t('file.download')" v-if="!isFailed && item.type !== 'system' && !isSelectionMode ">
          <i class="el-icon-download color-blue" @click.stop="downloadFile(item.url)"></i>
        </el-tooltip>
        <el-tooltip :content="$t('copyLink')" v-if="!isFailed && item.type !== 'system' && !isSelectionMode ">
          <i class="el-icon-link color-blue" @click.stop="copyLink"></i>
        </el-tooltip>
        <el-tooltip :content="$t('remove')" v-if="item.type !== 'system' && !isSelectionMode ">
          <i class="el-icon-delete color-blue" @click.stop="deleteVideo"></i>
        </el-tooltip>
      </div>
    </div>
    <VideoButtonGrid
      v-if="dialogVisible"
      :dialogVisible="dialogVisible"
      :video="mediaItem"
      :type="1"
      :tip="$t('file.filePreview')"
      @closeMedia="closeMediaFun"
      />
  </div>
</template>

<script>
import { copyTextToClipboard } from '@/utils/copyLink.js'; // 根据你的项目路径调整导入路径
import VideoButton from '../VideoButton/VideoButton.vue';
import VideoButtonGrid from '../VideoButtonGrid/VideoButton.vue';
export default {
  name: 'VideoItem',
  components: {
    VideoButton,
    VideoButtonGrid
  },
  props: {
    video: {
      type: Object,
      required: true
    },
    isSelectionMode:{
      type: Boolean,
      default: false
    },
    type:{
      type: Number,
      default: 0 //默认为0，3-成片页面
    }
  },
  data() {
    return {
      item: {},
      addingTag: false,
      newTag: '',
      dialogVisible: false,
      mediaItem: {}
      // selected: false,  // 添加选中状态
    };
  },
  watch: {
    video: {
      handler(newVal, oldVal) {
        this.item = {...newVal}
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    loading() {
      return this.item.eraseVideoSubtitlesStatus === 1 || this.item.mergeVideoFaceStatus === 1;
    },
    isFailed() {
      const { eraseVideoSubtitlesStatus, mergeVideoFaceStatus } = this.item;
      return (
        (eraseVideoSubtitlesStatus === 3 && mergeVideoFaceStatus === 3) ||
        (eraseVideoSubtitlesStatus === 0 && mergeVideoFaceStatus === 3) ||
        (eraseVideoSubtitlesStatus === 3 && mergeVideoFaceStatus === 0)
      );
    },
    statusMessage() {
      const statusMessages = [];
      const { eraseVideoSubtitlesStatus, mergeVideoFaceStatus, jobStatus } = this.item;

      if (eraseVideoSubtitlesStatus === 1 || mergeVideoFaceStatus === 1) {
        return this.$t('videoItem.generating');
      }
      if (eraseVideoSubtitlesStatus === 0 && mergeVideoFaceStatus === 0) {
        return ''; // 默认状态，不需要显示任何消息
      }
      // 拼接其他状态的消息
      if (eraseVideoSubtitlesStatus === 2) {
        statusMessages.push(this.$t('videoItem.removeSubtitleSuccess'));
      } else if (eraseVideoSubtitlesStatus === 3) {
        statusMessages.push(this.$t('videoItem.removeSubtitleFail'));
      }
      if (mergeVideoFaceStatus === 2) {
        statusMessages.push(this.$t('videoItem.aiFaceSwapSuccess'));
      } else if (mergeVideoFaceStatus === 3) {
        statusMessages.push(this.$t('videoItem.aiFaceSwapFail'));
      }
      if (jobStatus === 'Executing') {
        statusMessages.push(this.$t('translate.inProgress'));
      } else if (jobStatus === 'Finished') {
        statusMessages.push(this.$t('translate.success'));
      }  else if (jobStatus === 'Failed') {
        statusMessages.push(this.$t('translate.failure'));
      }
      return statusMessages.join(' ');
    },
    splitStatus(){
      const { splitVideoPartsStatus } = this.item;
      if(splitVideoPartsStatus === 'QUEUING'
        || splitVideoPartsStatus === 'PROCESSING'){
        return this.$t('videoItem.clipping')
      }else if(splitVideoPartsStatus === 'PROCESS_SUCCESS'){
        return this.$t('videoItem.clipSuccess')
      }else if(splitVideoPartsStatus === 'PROCESS_FAILED'
        ||splitVideoPartsStatus === 'TIMEOUT_FAILED'
        ||splitVideoPartsStatus === 'LIMIT_RETRY_FAILED'){
        return this.$t('videoItem.clipFail')
      }else{
        return ''
      }
    },
  },
  methods: {
    playVideo() {
      const video = this.$refs.videoPlayer;
      video.play();
    },
    pauseVideo() {
      const video = this.$refs.videoPlayer;
      video.pause();
    },
    filterState(item) {
      const { eraseVideoSubtitlesStatus, mergeVideoFaceStatus, jobStatus } = item;
      if (eraseVideoSubtitlesStatus === 1 || mergeVideoFaceStatus === 1) {
        return 'loadingTag'
      }
      if (eraseVideoSubtitlesStatus === 0 && mergeVideoFaceStatus === 0) {
        return ''
      }
      if (eraseVideoSubtitlesStatus === 2) {
        return 'successTag'
      } else if (eraseVideoSubtitlesStatus === 3) {
        return 'errorTag'
      }
      if (mergeVideoFaceStatus === 2) {
        return 'successTag'
      } else if (mergeVideoFaceStatus === 3) {
        return 'errorTag'
      }
      if (jobStatus === 'Executing') {
        return 'loadingTag'
      } else if (jobStatus === 'Finished') {
        return 'successTag'
      }  else if (jobStatus === 'Failed') {
        return 'errorTag'
      }
    },
    closeMediaFun() {
      this.dialogVisible = false
    },
    viewMedia(item) {
      this.mediaItem = item
      this.dialogVisible = true
    },
    //拆条详情
    splitDetail(video){
      this.$router.push({ path: `/stripping/${video.id}`,query: { id: video.id } });
    },
    //去发布
    publish(video){
      this.$router.push({ path: `/issueVideo/${video.id}`,query: { url: video.url,productId:video.id } });
    },
    removeTag(index) {
      this.item.tags.splice(index, 1);
    },
    // confirmTag() {
    //   if (this.newTag) {
    //     this.video.tags.push(this.newTag);
    //   }
    //   this.addingTag = false;
    //   this.newTag = '';
    // },
    copyLink(){
      copyTextToClipboard(this.item.url,this.$message);
    },
    deleteVideo() {
      this.$emit('delete-video', this.item);
    },
    toggleSelection() {
      if(this.isSelectionMode){
        this.item.selected = !this.item.selected;
        console.log('this.video.selected',this.item.selected);
        this.$emit('toggle-selection', this.item.selected, this.item);
      } else {
        // this.$emit('view-text',this.item)
        if(this.item.fileType === 'text'){
          this.showText()
        } else {
          this.viewMedia(this.item)
        }
      }
    },
    showText(){
      this.$modal.alert(this.item.title, this.item.content)
    },
    mouseleaveItem() {
      if (this.dialogVisible === true) return
      this.$set(this.item, 'hover', false)
    },
    mouseoverItem() {
      if (this.dialogVisible === true) return
      this.$set(this.item, 'hover', true)
    }
  }
};
</script>

<style scoped lang="scss">
.video-grid-item {
  width: 100%;
  margin: 0 10px 10px 0;
  border: 1px solid #DFDFDF;
  border-radius: 8px;
  overflow: hidden;
  z-index: auto;
}
.video-grid-item:hover{
  box-shadow: 0 0 10px #ccc
}
.video-grid-item.selected {
  border-color: blue; /* 选中时的样式示例 */
}
.left-section {
  position: relative;
  margin: 8px;
}
.controlsStatus {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 0 5px;
  /* width: 56px; */
  height: 20px;
  /* background: #006CFF; */
  border-radius: 10px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 10px;
  color: rgba(255,255,255,0.9);
  line-height: 20px;
  text-align: center;
  font-style: normal;
  cursor: pointer;
}

.errorTag {
  background: #FF0000;
}
.successTag {
  background: #006CFF;
}
.loadingTag {
  background: #FFBB00;
}
.video-grid-cover {
  width: 100%;
  height: 120px;
  cursor: pointer;
  border-radius: 8px;
  object-fit: cover;/* 确保视频不会拉伸 */
}
.video-grid-bg {
  background-color: #ECF2FE;
}
.video-grid-duration {
  position: absolute;
  left: 8px;
  bottom: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  border: 1px solid white;
  height: 20px;
  border-radius: 10px;
  padding:3px 8px;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  font-style: normal;
}
.video-grid-clip{
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: #0156FF;
  border-radius: 8px;
  color: white;
  padding: 3px 6px;
  font-size: 10px;
}

.video-grid-type{
  position: absolute;
  left: 0px;
  top: 0px;
  color: white;
  padding: 0 6px;
  font-size: 10px;
  height: 24px;
  border-radius: 8px 4px 4px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.type-video{
  background-color: #00D147;
}
.type-image{
  background-color: #DC0266;
}
.type-audio{
  background-color: #6D20D3;
}
.type-text{
  background-color: #FF8E18;
}

.video-grid-play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: rgba(255,255,255,0.6);
  cursor: pointer;
  .el-icon-caret-right {
    color: #fff;
    font-size: 24px;
  }
}

.middle-section {
  margin: 0 8px 0;
}

.video-grid-name {
  height: 32px;
  font-size: 14px;
  font-weight: bold;
}
.video-grid-description{
  font-size: 14px;
  margin-bottom: 5px;
  color: #666;
}

.video-grid-labels {
  font-size: 12px;
  color: #0156FF;
  line-height: 14px;
  height: 32px;
}

.video-grid-created {
  color: #999;
  line-height: 14px;
}
.video-grid-status{
  color: red
}
.video-grid-split{
  color: blue
}

.right-section {
  width: 100%;
  height: 32px;
  display: flex;
  border-radius: 0 8px 8px 0;
  background: #F4F5FF;
  justify-content: space-around;
}

.right-section .el-button {
  margin-left: 5px;
}

/* Hover样式 */
.el-button .el-tooltip__popper.is-dark {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
</style>
