<template>
  <div id="root" class="flex-row">
    <div ref="player" style="width: 800px; height: 450px;"></div>
  </div>
</template>

<script>
import {getMediaInfo} from '../../api/ai/videoTask';

export default {
  name: "Preview",
  data() {
    return {
      player: null,
      timeline: {}
    }
  },
  mounted() {
    // 动态加载CSS
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://g.alicdn.com/thor-server/video-editing-websdk/4.13.6/player.css';
    document.head.appendChild(link);

    // 动态加载JS
    const script = document.createElement('script');
    script.src = 'https://g.alicdn.com/thor-server/video-editing-websdk/4.13.6/player.js';
    script.onload = this.initializePlayer;
    document.head.appendChild(script);
  },
  beforeDestroy() {
    this.destroyPlayer(); // 销毁 player 实例
  },
  methods: {
    init(data) {
      // this.destroyPlayer()
      this.timeline = data
      this.initializePlayer()
    },
    destroyPlayer() {
      console.log('销毁player')
      if (this.player && typeof this.player.destroy === 'function') {
        this.player.destroy(); // 调用销毁方法
      }
    },
    initializePlayer() {
      this.destroyPlayer()
      console.log('initializePlayer')
      // 前端解析Timeline数据
      const parsedData = window.AliyunTimelinePlayer.parseTimeline(this.timeline,
        {
          outputWidth: this.timeline.FECanvas.Width,
          outputHeight: this.timeline.FECanvas.Height
        });

      console.log('PARSED DEATA', parsedData);

      this.player = new window.AliyunTimelinePlayer({
        container: this.$refs.player,
        trackLog: false,
        getMediaInfo: async (mediaId) => {
          const mediaMap = parsedData.mediaMap;
          if (mediaMap[mediaId]) {
            // 调用后端接，后端再调用阿里getMediaInfo api 口获取url
            return await this.getMediaInfo(mediaId);
          }
          return '';
        }
      });
      // 设置自定义字体
      this.player.setFontList(parsedData.fontList);
      // 预览前端Timeline，前端Timeline不能直接提交合成
      this.player.timeline = parsedData.timeline;
      // 可以通过toBackendTimeline转换为后端Timeline
      console.log('toBackendTimeline', parsedData.toBackendTimeline(parsedData.timeline));
    },
    getMediaInfo: async function (mediaId) {
      try {
        const res = await getMediaInfo(mediaId);
        return res;
      } catch (err) {
        console.error(err);
        return "";
      }
    },
  },
};
</script>

<style scoped>
/* 在这里添加任何组件特定的样式 */
</style>
