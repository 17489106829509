var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
    },
    [
      _c(
        "el-descriptions",
        { attrs: { column: 3 } },
        [
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("mall.orderNumber") } },
            [_c("span", [_vm._v(_vm._s(_vm.detail.orderNo))])]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("mall.orderTime") } },
            [_c("span", [_vm._v(_vm._s(_vm.goods.createTime))])]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("mall.payTime") } },
            [_c("span", [_vm._v(_vm._s(_vm.detail.payTime))])]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("mall.sellerInformation") } },
            [_c("span", [_vm._v(_vm._s(_vm.goods.sellNickName))])]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("mall.buyInformation") } },
            [_c("span", [_vm._v(_vm._s(_vm.goods.buyNickName))])]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("mall.orderStatus") } },
            [
              _c("dict-tag", {
                attrs: {
                  options: _vm.dict.type.order_status,
                  value: _vm.detail.orderStatus,
                },
              }),
            ],
            1
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("mall.paymentMethod") } },
            [_c("span", [_vm._v(_vm._s(_vm.$t("mall.wechat")))])]
          ),
        ],
        1
      ),
      _c(
        "el-descriptions",
        { attrs: { column: 4, direction: "vertical", border: "" } },
        [
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("mall.productCode") } },
            [_c("span", [_vm._v(_vm._s(_vm.goods.id))])]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("mall.productInfo") } },
            [
              _c("div", { staticClass: "flex-row-center" }, [
                _c(
                  "div",
                  {
                    staticClass: "flex-center",
                    staticStyle: { width: "80px", height: "80px" },
                  },
                  [
                    _c("img", {
                      staticStyle: {
                        "max-width": "80px",
                        "max-height": "80px",
                      },
                      attrs: { src: _vm.goods.coverUrl },
                    }),
                  ]
                ),
                _c("span", { staticClass: "ml5" }, [
                  _vm._v(_vm._s(_vm.goods.title)),
                ]),
              ]),
            ]
          ),
          _c(
            "el-descriptions-item",
            {
              attrs: {
                label:
                  _vm.$t("mall.unitPrice") + " * " + _vm.$t("mall.quantity"),
              },
            },
            [
              _c("span", [
                _vm._v(
                  "￥" +
                    _vm._s(_vm.goods.price) +
                    " * " +
                    _vm._s(_vm.detail.count || 1)
                ),
              ]),
            ]
          ),
          _c(
            "el-descriptions-item",
            { attrs: { label: _vm.$t("mall.paidAmount") } },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.detail.payPrice ||
                      _vm.detail.price * (_vm.detail.count || 1)
                  )
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }