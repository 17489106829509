<template>
  <div class="video-item" :class="{ 'selected': isSelectionMode&&video.selected }" @click.stop="toggleSelection">
     <!-- 左侧：视频封面 -->
     <div class="left-section" v-loading="loading">
      <video :src="video.url" alt="video" class="video-cover" />
      <span class="video-duration" v-if="video.videoLength&&video.videoLength>0">{{ secondsFormatTime(video.videoLength) }}</span>
    </div>
     <!-- 中间：视频信息 -->
     <div class="middle-section">
      <div class="video-name">{{ video.name }}</div>
      <div class="video-description">{{ video.description }}</div>
      <div class="video-tags font14">
        <el-tag
          v-for="(tag, index) in video.labels"
          :key="index"
          style="margin-right: 5px;margin-bottom: 5px;"
        >
        <!-- closable
        @close="removeTag(index)" -->
          {{ tag }}
        </el-tag>
        <!-- <el-input
          v-if="addingTag"
          v-model="newTag"
          @keyup.enter.native="confirmTag"
          @blur="confirmTag"
          class="tag-input"
          size="small"
        ></el-input> -->
        <!-- <el-button
          v-else
          type="text"
          icon="el-icon-plus"
          @click="addingTag = true"
          size="mini"
        >新建标签</el-button> -->
      </div>
      <div class="video-created font14" >{{ video.createTime }}</div>
      <div class="video-status font14">{{ statusMessage }}</div>
      <div class="video-split font14">{{ splitStatus }}</div>
    </div>

    <!-- 右侧：操作按钮 -->
    <div class="right-section" v-if="(!isSelectionMode)&&(!loading)">
      <el-tooltip :content="$t('videoManager.publish')" v-if="type == 3 && video.publishSuccessCount == 0">
        <el-button icon="el-icon-position" circle @click="publish(video)"></el-button>
      </el-tooltip>
      <el-tooltip :content="$t('videoItem.clipDetails')" v-if="!isFailed && splitStatus === $t('videoItem.clipSuccess')">
        <el-button icon="el-icon-more" circle @click="splitDetail(video)"></el-button>
      </el-tooltip>
      <span  v-if="!isFailed" style="display: inline-block;" class="ml5">
        <video-button :url="video.url" :tip="$t('file.filePreview')"></video-button>
      </span >
      <el-tooltip :content="$t('edit')" v-if="!isFailed">
        <el-button icon="el-icon-edit" circle @click="$emit('edit-video',video)"></el-button>
      </el-tooltip>
      <el-tooltip :content="$t('file.download')" v-if="!isFailed">
        <el-button icon="el-icon-download" circle @click="downloadFile(video.url)"></el-button>
      </el-tooltip>
      <el-tooltip :content="$t('copyLink')" v-if="!isFailed">
        <el-button icon="el-icon-link" circle @click="copyLink"></el-button>
      </el-tooltip>
      <el-tooltip :content="$t('remove')">
        <el-button icon="el-icon-delete" circle @click="deleteVideo"></el-button>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { copyTextToClipboard } from '@/utils/copyLink.js'; // 根据你的项目路径调整导入路径
import VideoButton from '../VideoButton/VideoButton.vue';
export default {
  name: 'VideoItem',
  components: {
    VideoButton
  },
  props: {
    video: {
      type: Object,
      required: true
    },
    isSelectionMode:{
      type: Boolean,
      default: false
    },
    type:{
      type: Number,
      default: 0 //默认为0，3-成片页面
    }
  },
  data() {
    return {
      addingTag: false,
      newTag: '',
      // selected: false,  // 添加选中状态
    };
  },
  computed: {
    loading() {
      return this.video.eraseVideoSubtitlesStatus === 1 || this.video.mergeVideoFaceStatus === 1;
    },
    isFailed() {
      const { eraseVideoSubtitlesStatus, mergeVideoFaceStatus } = this.video;
      return (
        (eraseVideoSubtitlesStatus === 3 && mergeVideoFaceStatus === 3) ||
        (eraseVideoSubtitlesStatus === 0 && mergeVideoFaceStatus === 3) ||
        (eraseVideoSubtitlesStatus === 3 && mergeVideoFaceStatus === 0)
      );
    },
    statusMessage() {
      const statusMessages = [];
      const { eraseVideoSubtitlesStatus, mergeVideoFaceStatus } = this.video;

      if (eraseVideoSubtitlesStatus === 1 || mergeVideoFaceStatus === 1) {
        return this.$t('videoItem.generating');
      }
      if (eraseVideoSubtitlesStatus === 0 && mergeVideoFaceStatus === 0) {
        return ''; // 默认状态，不需要显示任何消息
      }
      // 拼接其他状态的消息
      if (eraseVideoSubtitlesStatus === 2) {
        statusMessages.push(this.$t('videoItem.removeSubtitleSuccess'));
      } else if (eraseVideoSubtitlesStatus === 3) {
        statusMessages.push(this.$t('videoItem.removeSubtitleFail'));
      }
      if (mergeVideoFaceStatus === 2) {
        statusMessages.push(this.$t('videoItem.aiFaceSwapSuccess'));
      } else if (mergeVideoFaceStatus === 3) {
        statusMessages.push(this.$t('videoItem.aiFaceSwapFail'));
      }
      return statusMessages.join(' ');
    },
    splitStatus(){
      const { splitVideoPartsStatus } = this.video;
      if(splitVideoPartsStatus === 'QUEUING'
        || splitVideoPartsStatus === 'PROCESSING'){
        return this.$t('videoItem.clipping')
      }else if(splitVideoPartsStatus === 'PROCESS_SUCCESS'){
        return this.$t('videoItem.clipSuccess')
      }else if(splitVideoPartsStatus === 'PROCESS_FAILED'
        ||splitVideoPartsStatus === 'TIMEOUT_FAILED'
        ||splitVideoPartsStatus === 'LIMIT_RETRY_FAILED'){
        return this.$t('videoItem.clipFail')
      }else{
        return ''
      }
    },
  },
  methods: {
    //切片详情
    splitDetail(video){
      this.$router.push({ path: `/stripping/${video.id}`,query: { id: video.id } });
    },
    //去发布
    publish(video){
      this.$router.push({ path: `/issueVideo/${video.id}`,query: { url: video.url,productId:video.id } });
    },
    removeTag(index) {
      this.video.tags.splice(index, 1);
    },
    // confirmTag() {
    //   if (this.newTag) {
    //     this.video.tags.push(this.newTag);
    //   }
    //   this.addingTag = false;
    //   this.newTag = '';
    // },
    copyLink(){
      copyTextToClipboard(this.video.url,this.$message);
    },
    deleteVideo() {
      this.$emit('delete-video', this.video);
    },
    toggleSelection() {
      this.video.selected = !this.video.selected;
      console.log('this.video.selected',this.video.selected);
      this.$emit('toggle-selection', this.video.selected, this.video);
    }
  }
};
</script>

<style scoped>
.video-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}
.video-item.selected {
  border-color: blue; /* 选中时的样式示例 */
}
.left-section {
  position: relative;
}
.video-cover {
  width: 120px;
  height: 120px;
  cursor: pointer;
}
.video-duration {
  position: absolute;
  right: 5px;
  bottom: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
}

.middle-section {
  flex: 1;
  margin-left: 10px;
}

.video-name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
.video-description{
  font-size: 14px;
  margin-bottom: 5px;
  color: #666;
}

.font14 {
  font-size: 14px;
}

.video-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.tag-input {
  width: auto;
  margin-right: 5px;
}

.video-created {
  color: #999;
}
.video-status{
  color: red
}
.video-split{
  color: blue
}

.right-section {
  display: flex;
}

.right-section .el-button {
  margin-left: 5px;
}

/* Hover样式 */
.el-button .el-tooltip__popper.is-dark {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
</style>
