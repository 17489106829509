import request from '@/utils/request'

const baseApi = '/admin/social'

// 查询账号列表
export function listAccount(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}
// 查询各平台账号列表
export function listAllAccount(query) {
  return request({
    url: baseApi + '/listAll',
    method: 'get',
    params: query
  })
}
// 详情
export function getAccount(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'get',
  })
}
// 新增账号
export function addAccount(data) {
  return request({
    url: baseApi + '/bindingTiktok',
    method: 'post',
    params: data
  })
}
// 修改
export function editAccount(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}

// 删除账号
export function delAccount(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'delete'
  })
}

// 查询账号总数量
export function indexStatistics(query) {
  return request({
    url: baseApi + '/indexStatistics',
    method: 'get',
    params: query
  })
}
// 首页账号统计
export function userStatistics(query) {
  return request({
    url: baseApi + '/userStatistics',
    method: 'get',
    params: query
  })
}
// 查询重复绑定的数据
export function queryRepeat(query) {
  return request({
    url: baseApi + '/queryRepeat',
    method: 'get',
    params: query
  })
}
