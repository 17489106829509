import request from '@/utils/request'
// 查询tiktok账号商品
const baseApi = '/mall/account'
// 列表
export function listMallTiktok(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}

// 详情
export function getMallTiktok(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'get'
  })
}

// 新增
export function addMallTiktok(data) {
  return request({
    url: baseApi,
    method: 'post',
    data: data
  })
}

// 修改
export function updateMallTiktok(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}

// 删除
export function delMallTiktok(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'delete'
  })
}


//账号库存查询
export function getQueryAccountInventory(params) {
  return request({
    url: baseApi + '/queryAccountInventory',
    method: 'get',
    params
  })
}

//账号库存查询-全部
export function queryAccountInventoryAll(params) {
  return request({
    url: baseApi + '/queryAccountInventoryAll',
    method: 'get',
    params
  })
}

