<template>
  <div>
    <div>
      <h1>测试webSocket</h1>
      <el-input v-model="sendContent"/>
      <button @click="initWebSocket">重新连接</button>
      <button @click="send">点击请求后台数据</button>
      <button @click="onClose">关闭连接</button>
      <div>
        {{ content }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      content: '',
      sendContent: ''
    }
  },
  created() {
    // 页面创建生命周期函数
    this.initWebSocket();
  },
  methods: {
    onClose() {
      this.websock.close();
    },
    initWebSocket() {
      if (this.websock != null) {
        this.websock.close()
      }
      if ("WebSocket" in window) {
        // WebSocket与普通的请求所用协议有所不同，ws等同于http，wss等同于https
        //d5533相当于我这台客户端的标识，key,用来单客户端通话
        this.websock = new WebSocket(
          "ws://127.0.0.1:6188/admin/ws/chat/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dpblR5cGUiOiJsb2dpbiIsImxvZ2luSWQiOiJhZG1pbjoxIiwicm5TdHIiOiJjYldnTjdmbzBGZkZyMXJ0VTBLWXVoaHBOUFVwcWNUdSJ9.OY7xs93hZTjB7MivXGcchuukS3UMLF5n_ExIYXGe2j8"
        );
        this.websock.onopen = this.websocketonopen;
        this.websock.onerror = this.websocketonerror;
        this.websock.onmessage = this.websocketonmessage;
        this.websock.onclose = this.websocketclose;
      } else {
        alert("not error  不支持websocket");
      }
    },
    //连接回调
    websocketonopen() {
      console.log("WebSocket连接成功");
    },
    //发生错误回调
    websocketonerror(e) {
      console.log("WebSocket连接发生错误");
    },
    //接收到消息的回调函数
    websocketonmessage(e) {
      console.log(e.data); // console.log(e);
      const data = JSON.parse(e.data)
      console.log(data.content)
      console.log(data.conversationId)
      this.content = this.content + data.content
    },
    // 连接关闭时的回调函数
    websocketclose(e) {
      console.log("connection closed (" + e + ")");
    },
    send() {
      this.content = ''
      if (this.sendContent == '' || this.sendContent == null) {
        return
      }
      const data = {
        "conversationId": "c2f85989-76aa-43c8-aae7-fdff2a4d422d", // 会话id，第一次没有不用传，后面需要，回答的结果是json格式
        "content": this.sendContent, // 问题
        "id": 1 // 模型id
      }
      this.websock.send(JSON.stringify(data));
    }
  }
};
</script>
<style lang="less" scoped>
</style>
