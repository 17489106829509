var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-image",
    {
      style: "width:" + _vm.realWidth + ";height:" + _vm.realHeight + ";",
      attrs: {
        src: "" + _vm.realSrc,
        fit: "cover",
        "preview-src-list": _vm.realSrcList,
      },
    },
    [
      _c(
        "div",
        { staticClass: "image-slot", attrs: { slot: "error" }, slot: "error" },
        [_c("i", { staticClass: "el-icon-picture-outline" })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }