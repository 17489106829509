var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "upload-container",
    },
    [
      _c(
        "el-upload",
        {
          ref: "upload",
          class: { hide: this.fileList.length >= this.limit },
          attrs: {
            action: "",
            multiple: "",
            directory: "",
            "list-type": "text",
            "auto-upload": false,
            limit: _vm.limit,
            "file-list": _vm.fileList,
            "show-file-list": true,
            "on-change": _vm.handleFileChange,
            "on-remove": _vm.handleFileRemove,
            "on-exceed": _vm.handleFileExceed,
            "on-preview": _vm.handleCardPreview,
          },
        },
        [
          _c(
            "div",
            { staticClass: "upload-content flex-column-center f-center" },
            [
              _c("i", {
                staticClass: "iconfont icon-shangchuan",
                staticStyle: { "font-size": "20px" },
              }),
              _c("span", { staticClass: "upload-text" }, [
                _vm._v(_vm._s(_vm.$t("file.clickUpload"))),
              ]),
            ]
          ),
          _vm.showTip
            ? _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("file.pleaseUpload")) + " "),
                  _vm.fileSize
                    ? [
                        _vm._v(" " + _vm._s(_vm.$t("file.sizeLimit")) + " "),
                        _c("b", { staticStyle: { color: "#f56c6c" } }, [
                          _vm._v(_vm._s(_vm.fileSize) + "MB"),
                        ]),
                      ]
                    : _vm._e(),
                  _vm.fileType
                    ? [
                        _vm._v(" " + _vm._s(_vm.$t("file.formatLimit")) + " "),
                        _c("b", { staticStyle: { color: "#f56c6c" } }, [
                          _vm._v(_vm._s(_vm.fileType.join("/"))),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.$t("file.dFile")) + " "),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: _vm.$t("file.filePreview"),
            width: "800",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.isImagePreview
            ? _c("img", {
                staticStyle: {
                  display: "block",
                  "max-width": "100%",
                  margin: "0 auto",
                },
                attrs: { src: _vm.dialogMediaUrl },
              })
            : _c("video", {
                staticStyle: {
                  display: "block",
                  "max-width": "100%",
                  margin: "0 auto",
                },
                attrs: { src: _vm.dialogMediaUrl, controls: "" },
              }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }