import request from '@/utils/request'
// 语言、时区查询列表
export function listLanguage(query) {
  return request({
    url: '/admin/language/list',
    method: 'get',
    params: query
  })
}
// 时区列表
export function listTimeZone(query) {
  return request({
    url: '/admin/timeZone/list',
    method: 'get',
    params: query
  })
}
// 翻译
export function translate(query) {
  return request({
    url: '/admin/baidu/translate',
    method: 'get',
    params: query
  })
}
//重写——type：zj（总结）、sx（缩写）、kx（扩写）、cx（重写）、rs（润色）、bt（标题）
export function rewrite(query) {
  return request({
    url: '/admin/ai/rewrite',
    method: 'post',
    params: query
  })
}
// 一键翻译
export function batchTranslate(query) {
  return request({
    url: '/admin/baidu/batchTranslate',
    method: 'get',
    params: query
  })
}
//计算口播时长
export function aiTtsDuration(data) {
  return request({
    url: '/admin/ai/aiTtsDuration',
    method: 'post',
    data: data
  })
}
