<!-- 模板详情 -->
<template>
  <el-dialog
    :visible.sync="localVisible"
    :title="$t('template.scriptTemplate')"
    :before-close="handleBeforeClose"
    :append-to-body="isInner"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <h3>{{ template.title }}</h3>
    <p>{{ template.description }}</p>
    <div class="steps">
      <div  v-for="(step, i) in template.details" :key="i" class="mt5">
        <span class="step mt10">{{ step.label }}</span>
        <div>
          <div class="mt5">{{ step.value }}</div>
          <div class="mt5">{{ step.example }}</div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <!-- <el-button @click="handleCancel">{{$t('cancel')}}</el-button> -->
      <el-button type="primary" @click="handleConfirm">{{$t('template.applyTemplate')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    template: {
      type: Object,
      default: () => ({})
    },
    isInner: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localVisible: this.visible
    };
  },
  watch: {
    visible(val) {
      this.localVisible = val; // 当 prop 变化时更新本地的 visible
    }
  },
  methods: {
    handleBeforeClose(done) {
      this.localVisible = false;
      this.$emit('update:visible', false);
      done();
    },
    handleCancel() {
      this.localVisible = false;
      this.$emit('update:visible', false);
    },
    handleConfirm() {
      this.$emit('confirm', this.template);
      this.localVisible = false;
      this.$emit('update:visible', false);
    }
  }
};
</script>

<style scoped>
.dialog-footer {
  text-align: right;
}
.step {
  width: auto;
  padding: 3px 6px;
  color: blue;
  border:1px solid blue;
  border-radius: 5px;
}
</style>
