var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.localVisible,
        title: _vm.$t("template.moreTemplates"),
        "before-close": _vm.handleBeforeClose,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "70%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.localVisible = $event
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.tabClick },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c("el-tab-pane", {
            attrs: { label: _vm.$t("template.simplified"), name: "精简版" },
          }),
          _c("el-tab-pane", {
            attrs: { label: _vm.$t("template.enriched"), name: "丰富版" },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "flex-row f-wrap",
          staticStyle: { height: "50vh", "overflow-y": "scroll" },
        },
        _vm._l(_vm.templates, function (template) {
          return _c(
            "div",
            { key: template.id, staticClass: "template-more-item" },
            [
              _c("TemplateItem", {
                attrs: { template: template },
                on: {
                  "view-template": _vm.viewTemplate,
                  "apply-template": _vm.applyTemplate,
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _c("templateItemDetail", {
        attrs: {
          visible: _vm.detailVisible,
          template: _vm.templateDetail,
          isInner: true,
        },
        on: {
          "update:visible": function ($event) {
            _vm.detailVisible = $event
          },
          confirm: _vm.applyTemplate,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }