var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentTab == 0,
            expression: "currentTab == 0",
          },
        ],
        staticClass: "aicross-container",
      },
      [
        _c("router-view"),
        _c(
          "div",
          {
            staticClass: "flex-row-center f-end",
            staticStyle: { "margin-bottom": "15px" },
          },
          [
            _c(
              "el-popover",
              {
                attrs: {
                  placement: "left-start",
                  width: "518",
                  trigger: "click",
                },
              },
              [
                _c("div", { staticClass: "popover-title" }, [
                  _vm._v(_vm._s(_vm.$t("guidance.txtToVidWelcome"))),
                ]),
                _c("div", { staticClass: "popover-content" }, [
                  _vm._v(_vm._s(_vm.$t("guidance.txtToVidInstructions")) + " "),
                ]),
                _c("video", {
                  ref: "videoPlayer",
                  staticClass: "video-player",
                  attrs: { controls: "", src: _vm.videoSrc },
                }),
                _c(
                  "div",
                  {
                    staticClass: "title-header-text pointer",
                    attrs: { slot: "reference" },
                    slot: "reference",
                  },
                  [
                    _c("i", { staticClass: "iconfont icon-dacha2" }),
                    _c("span", { staticClass: "font12 fontweight400 ml5" }, [
                      _vm._v(_vm._s(_vm.$t("guidance.guide"))),
                    ]),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "aicross-content" },
          [
            _c(
              "div",
              { staticClass: "generation-container" },
              [
                _c(
                  "el-row",
                  { staticStyle: { width: "100%" } },
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "left-section" }, [
                        _c("div", { staticClass: "cut-scroll" }, [
                          _c("div", [
                            _c(
                              "div",
                              { staticClass: "flex-row-center f-between" },
                              [
                                _c("span", { staticClass: "font16" }, [
                                  _c("img", {
                                    staticClass: "img-title",
                                    attrs: {
                                      src: require("../../assets/images/aiCutting/copywriting.png"),
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("aiCrosscutting.scriptCopy")
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass: "font12 mr10 pointer",
                                    on: { click: _vm.reset },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "iconfont icon-shuaxin",
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("aiCrosscutting.resetContent")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm.activeTab === "template"
                              ? _c(
                                  "div",
                                  [
                                    _vm.currentTemplate &&
                                    _vm.currentTemplate.title
                                      ? _c("TemplateItemEdit", {
                                          attrs: {
                                            template: _vm.currentTemplate,
                                          },
                                          on: {
                                            "change-Template":
                                              _vm.changeTemplate,
                                            "update-template":
                                              _vm.updateTemplate,
                                          },
                                        })
                                      : _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "loading",
                                                rawName: "v-loading",
                                                value: _vm.templateLoading,
                                                expression: "templateLoading",
                                              },
                                            ],
                                            staticClass: "template-selector",
                                          },
                                          [
                                            _vm._l(
                                              _vm.templates.slice(0, 4),
                                              function (template) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: template.id,
                                                    staticClass:
                                                      "template-card-item",
                                                  },
                                                  [
                                                    _c("TemplateItem", {
                                                      attrs: {
                                                        template: template,
                                                      },
                                                      on: {
                                                        "view-template":
                                                          _vm.viewTemplate,
                                                        "apply-template":
                                                          _vm.applyTemplate,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "flex-center w-100",
                                              },
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "primary",
                                                      plain: "",
                                                    },
                                                    on: {
                                                      click: _vm.moreTemplate,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "aiCrosscutting.viewMore"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        ),
                                  ],
                                  1
                                )
                              : _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "cut-title mt10 mb5" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("aiCrosscutting.inputPrompt")
                                        ) + "："
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "f-grow",
                                      staticStyle: { padding: "10px 10px 0" },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "textarea",
                                          rows: 8,
                                          placeholder: _vm.$t(
                                            "aiCrosscutting.keywordPrompt"
                                          ),
                                        },
                                        model: {
                                          value: _vm.form.text,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "text", $$v)
                                          },
                                          expression: "form.text",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                            _c(
                              "div",
                              {
                                staticClass: "flex-row-center f-end mt10 mr10",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "button-normal",
                                    on: { click: _vm.moreTemplate },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "iconfont icon-wenanmoban font14",
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("aiCrosscutting.selectTemp")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "button-normal ml10",
                                    on: { click: _vm.importText },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "iconfont icon-sucaiku1 font14",
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "aiCrosscutting.selectMaterial"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "flex-column" },
                            [
                              _c("div", { staticClass: "cut-title mt10 mb5" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("aiCrosscutting.contentLength")
                                  ) + "："
                                ),
                              ]),
                              _c(
                                "el-radio-group",
                                {
                                  staticClass: "mt5",
                                  model: {
                                    value: _vm.form.wordsSize,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "wordsSize", $$v)
                                    },
                                    expression: "form.wordsSize",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 50 } }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("aiCrosscutting.short")) +
                                        "(0～50)"
                                    ),
                                  ]),
                                  _c("el-radio", { attrs: { label: 100 } }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("aiCrosscutting.medium")) +
                                        "(50～100)"
                                    ),
                                  ]),
                                  _c("el-radio", { attrs: { label: 200 } }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("aiCrosscutting.long")) +
                                        "(100～200)"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "cut-title mt10 mb5" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("aiCrosscutting.generationCount")
                                  ) + "："
                                ),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "11px",
                                      "margin-left": "10px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("aiCrosscutting.maxGeneration")
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                              _c("el-input-number", {
                                staticClass: "mt5",
                                attrs: { min: 1, max: 5 },
                                model: {
                                  value: _vm.form.generateCount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "generateCount", $$v)
                                  },
                                  expression: "form.generateCount",
                                },
                              }),
                              _c("div", { staticClass: "cut-title mt10 mb5" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("aiCrosscutting.seoIntegration")
                                  ) + "："
                                ),
                              ]),
                              _c(
                                "el-select",
                                {
                                  staticClass: "mt5",
                                  attrs: {
                                    filterable: "",
                                    multiple: "",
                                    remote: "",
                                    clearable: "",
                                    "default-first-option": "",
                                    placeholder: _vm.$t("tip.select"),
                                  },
                                  model: {
                                    value: _vm.form.seo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "seo", $$v)
                                    },
                                    expression: "form.seo",
                                  },
                                },
                                _vm._l(_vm.seoList, function (dict) {
                                  return _c("el-option", {
                                    key: dict.id,
                                    attrs: {
                                      label: dict.label,
                                      value: dict.label,
                                    },
                                  })
                                }),
                                1
                              ),
                              _c("div", { staticClass: "cut-title mt10 mb5" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("aiCrosscutting.golden3sStart")
                                  ) + "："
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "golden-3s" },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      staticClass: "mt5",
                                      model: {
                                        value: _vm.form.startStyle,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "startStyle", $$v)
                                        },
                                        expression: "form.startStyle",
                                      },
                                    },
                                    _vm._l(_vm.goldenTypes, function (type) {
                                      return _c(
                                        "el-radio-button",
                                        {
                                          key: type,
                                          staticClass: "content-length-item",
                                          attrs: { label: type, border: "" },
                                        },
                                        [_vm._v(" " + _vm._s(type) + " ")]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "flex-center mt10" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "gradient-button",
                                attrs: { loading: _vm.contentLoading },
                                on: { click: _vm.generate },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("aiCrosscutting.generateContent")
                                  )
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "right-section" }, [
                        _c("div", { staticClass: "cut-scroll" }, [
                          _c("div", [
                            _c("span", { staticClass: "font16" }, [
                              _c("img", {
                                staticClass: "img-title",
                                attrs: {
                                  src: require("../../assets/images/aiCutting/preview.png"),
                                  alt: "",
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("aiCrosscutting.scriptPreview")
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "span",
                              { staticClass: "font14 color-gray6 ml10" },
                              [
                                _c("i", {
                                  staticClass: "iconfont icon-zhushi font16",
                                  staticStyle: { color: "#626364" },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("aiCrosscutting.parseHint")) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.contentLoading,
                                  expression: "contentLoading",
                                },
                              ],
                              staticClass: "preview-list",
                            },
                            [
                              _vm._l(
                                _vm.generatedContents,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "preview-item" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex-row-center" },
                                        [
                                          _c("el-checkbox", {
                                            on: { change: _vm.checkChange },
                                            model: {
                                              value: item.checked,
                                              callback: function ($$v) {
                                                _vm.$set(item, "checked", $$v)
                                              },
                                              expression: "item.checked",
                                            },
                                          }),
                                          _c("div", { staticClass: "ml5" }, [
                                            _c(
                                              "p",
                                              { staticClass: "resutl-title" },
                                              [_vm._v(_vm._s(item.text))]
                                            ),
                                            item && item.title
                                              ? _c(
                                                  "h4",
                                                  {
                                                    staticClass: "resutl-title",
                                                  },
                                                  [_vm._v(_vm._s(item.title))]
                                                )
                                              : _vm._e(),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "actions ml20" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                loading:
                                                  item.loading &&
                                                  _vm.rewriteType === "bt",
                                                type: "mini",
                                                disabled: item.loading,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.rewrite(
                                                    "bt",
                                                    index,
                                                    item.text
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "aiCrosscutting.titleExtraction"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                loading:
                                                  item.loading &&
                                                  _vm.rewriteType === "kx",
                                                type: "mini",
                                                disabled: item.loading,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.rewrite(
                                                    "kx",
                                                    index,
                                                    item.text
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "aiCrosscutting.scriptSupplement"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                loading:
                                                  item.loading &&
                                                  _vm.rewriteType === "rs",
                                                type: "mini",
                                                disabled: item.loading,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.rewrite(
                                                    "rs",
                                                    index,
                                                    item.text
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "aiCrosscutting.scriptPolish"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                loading:
                                                  item.loading &&
                                                  _vm.rewriteType === "sx",
                                                type: "mini",
                                                disabled: item.loading,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.rewrite(
                                                    "sx",
                                                    index,
                                                    item.text
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "aiCrosscutting.scriptSimplify"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _vm.generatedContents.length === 0
                                ? _c(
                                    "el-empty",
                                    {
                                      attrs: {
                                        "image-size": 128,
                                        image: require("@/assets/images/aiCutting/blank.png"),
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "description" },
                                          slot: "description",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "font14 color-gray9",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "aiCrosscutting.keywordInputHint"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c("br"),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "font12 color-gray",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "aiCrosscutting.uniqueContent"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                        _vm.generatedContents.length > 0
                          ? _c(
                              "div",
                              { staticClass: "flex-center mt10" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "gradient-button",
                                    attrs: { loading: _vm.parseLoading },
                                    on: { click: _vm.parseAll },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("aiCrosscutting.analyze"))
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("templateItemDetail", {
              attrs: {
                visible: _vm.detailVisible,
                template: _vm.templateDetail,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.detailVisible = $event
                },
                confirm: _vm.applyTemplate,
              },
            }),
            _c("TemplateMore", {
              attrs: { visible: _vm.moreVisible },
              on: {
                "update:visible": function ($event) {
                  _vm.moreVisible = $event
                },
                "view-template": _vm.viewTemplate,
                "apply-template": _vm.applyTemplate,
              },
            }),
            _c("material-drawer", {
              ref: "materialDrawer",
              attrs: { visible: _vm.drawerMaterial },
              on: {
                "update:visible": function ($event) {
                  _vm.drawerMaterial = $event
                },
                selectionConfirmed: _vm.selectionText,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentTab == 1,
            expression: "currentTab == 1",
          },
        ],
      },
      [
        _c("EditManuscripts", {
          ref: "editManuscripts",
          on: {
            last: function ($event) {
              return _vm.last(0)
            },
            next: _vm.next,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.currentTab == 2,
            expression: "currentTab == 2",
          },
        ],
      },
      [
        _c("EditVideo", {
          ref: "editVideo",
          on: {
            last: function ($event) {
              return _vm.last(1)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }