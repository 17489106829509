import request from '@/utils/request'
//模型训练——模型文件
const baseApi = '/admin/modelFile'
// 列表
export function listModelFile(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}

// 详情
export function getModelFile(id) {
  return request({
    url: baseApi+ '/' + id,
    method: 'get'
  })
}

// 新增
export function addModelFile(data) {
  return request({
    url: baseApi,
    method: 'post',
    data: data
  })
}

// 修改
export function updateModelFile(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}

// 删除
export function delModelFile(id) {
  return request({
    url: baseApi+ '/' + id,
    method: 'delete'
  })
}
