var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-search", class: { show: _vm.show } },
    [
      _c("svg-icon", {
        attrs: { "class-name": "search-icon", "icon-class": "search" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.click($event)
          },
        },
      }),
      _c(
        "el-select",
        {
          ref: "headerSearchSelect",
          staticClass: "header-search-select",
          attrs: {
            "remote-method": _vm.querySearch,
            filterable: "",
            "default-first-option": "",
            remote: "",
            placeholder: "Search",
          },
          on: { change: _vm.change },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v
            },
            expression: "search",
          },
        },
        _vm._l(_vm.options, function (option) {
          return _c("el-option", {
            key: option.item.path,
            attrs: { value: option.item, label: option.item.title.join(" > ") },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }