var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "audio-item",
      class: { selected: _vm.isSelectionMode && _vm.audio.selected },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.toggleSelection($event)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "left-section" },
        [
          _c("svg-icon", {
            staticClass: "audio-cover",
            attrs: { "icon-class": "audio" },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "middle-section" }, [
        _c("div", { staticClass: "audio-name" }, [
          _vm._v(_vm._s(_vm.audio.name)),
        ]),
        _c("div", { staticClass: "audio-description" }, [
          _vm._v(_vm._s(_vm.audio.description)),
        ]),
        _c("div", { staticClass: "audio-details" }, [
          _c(
            "div",
            { staticClass: "audio-tags" },
            _vm._l(_vm.audio.labels, function (tag, index) {
              return _c(
                "el-tag",
                {
                  key: index,
                  staticStyle: {
                    "margin-right": "5px",
                    "margin-bottom": "5px",
                  },
                },
                [_vm._v(" " + _vm._s(tag) + " ")]
              )
            }),
            1
          ),
          _c("div", { staticClass: "audio-created" }, [
            _vm._v(_vm._s(_vm.audio.createTime)),
          ]),
        ]),
      ]),
      !_vm.isSelectionMode
        ? _c(
            "div",
            { staticClass: "right-section" },
            [
              _c(
                "el-tooltip",
                { attrs: { content: _vm.$t("play") } },
                [
                  _c("el-button", {
                    attrs: { icon: "el-icon-video-play", circle: "" },
                    on: { click: _vm.playAudio },
                  }),
                ],
                1
              ),
              _c(
                "el-tooltip",
                { attrs: { content: _vm.$t("edit") } },
                [
                  _c("el-button", {
                    attrs: { icon: "el-icon-edit", circle: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("edit-audio", _vm.audio)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-tooltip",
                { attrs: { content: _vm.$t("file.download") } },
                [
                  _c("el-button", {
                    attrs: { icon: "el-icon-download", circle: "" },
                    on: {
                      click: function ($event) {
                        return _vm.downloadFile(_vm.audio.url)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-tooltip",
                { attrs: { content: _vm.$t("copyLink") } },
                [
                  _c("el-button", {
                    attrs: { icon: "el-icon-link", circle: "" },
                    on: { click: _vm.copyLink },
                  }),
                ],
                1
              ),
              _c(
                "el-tooltip",
                { attrs: { content: _vm.$t("remove") } },
                [
                  _c("el-button", {
                    attrs: { icon: "el-icon-delete", circle: "" },
                    on: { click: _vm.deleteAudio },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "50%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("audio", {
            ref: "audioPlayer",
            staticClass: "audio-player",
            attrs: { controls: "", src: _vm.audio.url },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }