<!-- 模板列表的item -->
<template>
  <div
    class="work-tab"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click="goTarget(item.path)"
  >
    <!-- :style="{ backgroundImage: `url(${require(`@/assets/images/workbench/${item.background}`)})` }" -->
    <div class="flex-row-center">
      <div
        class="tab-img flex-center"
        :style="{background: item.bgColor}"
      >
        <i
          class="iconfont"
          :class="item.icon"
          :style="{color: item.color}"
        ></i>
      </div>
      <span
        class="tab-title"
        :class="{ 'hover': hover }"
      >{{ item.name }}</span>
      <!-- <div v-if="item.quota" class="flex-row-center f-wrap" style="text-align: baseline;">
          <span class="num-big">{{ item.use || 0}}</span>
          <span class="num-total ">/{{ item.quota || 100}}</span>
        </div> -->
    </div>
    <div class="des">{{ item.des }}</div>
  </div>
</template>
<script>
export default {
  name: "TabItem",
  components: {},
  props: {
    template: {
      type: Object,
      default: () => ({
        hover: false, // 添加 hover 参数的默认值
      }),
    },
  },
  data() {
    return {
      item: this.template,
      hover: this.template.hover || false,
    };
  },
  watch: {
    template: {
      handler(val) {
        this.item = val;
        this.hover = val.hover || false; // 更新 hover
      },
      deep: true,
    },
  },
  methods: {
    goTarget(href) {
      if (href) {
        this.$router.push({ path: href });
      } else {
        this.$modal.alert(this.$t("workbench.notOpen"));
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.work-tab {
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  border: 1px solid #e5e7eb;
  padding: 24px;
  box-sizing: border-box;
  cursor: pointer;

  .tab-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  .tab-title {
    height: 28px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 20px;
    color: #152133;
    line-height: 28px;
    letter-spacing: 1px;
    text-align: left;
    font-style: normal;
    margin-left: 16px;
  }
  .hover {
    color: #0052d9;
  }
  .des {
    height: 22px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 22px;
    text-align: left;
    font-style: normal;
    margin-top: 16px;
  }
  .num-big {
    font-family: BebasNeue;
    font-size: 22px;
    color: #152133;
    letter-spacing: 0;
    font-weight: 400;
  }
  .num-unit {
    font-family: PingFang-SC-Heavy;
    font-size: 14px;
    color: #152133;
    letter-spacing: 0;
    text-align: right;
    font-weight: 400;
  }
  .num-total {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: rgba(21, 33, 51, 0.4);
    letter-spacing: 0;
    text-align: right;
    font-weight: 400;
  }
}
</style>
