var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c("h1", [_vm._v("测试webSocket")]),
        _c("el-input", {
          model: {
            value: _vm.sendContent,
            callback: function ($$v) {
              _vm.sendContent = $$v
            },
            expression: "sendContent",
          },
        }),
        _c("button", { on: { click: _vm.initWebSocket } }, [
          _vm._v("重新连接"),
        ]),
        _c("button", { on: { click: _vm.send } }, [_vm._v("点击请求后台数据")]),
        _c("button", { on: { click: _vm.onClose } }, [_vm._v("关闭连接")]),
        _c("div", [_vm._v(" " + _vm._s(_vm.content) + " ")]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }