var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "tabsContainer",
      staticClass: "j-tabs",
      on: {
        mousedown: _vm.handleMouseDown,
        touchstart: _vm.handleTouchStart,
        touchmove: _vm.handleTouchMove,
        touchend: _vm.handleTouchEnd,
      },
    },
    _vm._l(_vm.tabs, function (tab, index) {
      return _c(
        "div",
        {
          key: tab.title,
          class: {
            "j-tabs-item": true,
            "j-tabs-item-activated": tab.title === _vm.activatedTab.title,
          },
          attrs: { title: tab.title },
          on: {
            click: function ($event) {
              return _vm.tabClick(tab, index)
            },
          },
        },
        [_vm._v(" " + _vm._s(tab.emojiTitle) + " ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }