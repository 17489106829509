var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "template-card flex-row-center",
      class: { selected: _vm.isSelected },
      on: { click: _vm.selectItem },
    },
    [
      _c("div", { staticClass: "left-section" }, [
        _c("video", {
          staticClass: "video-cover",
          attrs: { src: _vm.item.url, alt: "video" },
        }),
        _vm.item.duration && _vm.item.duration > 0
          ? _c("span", { staticClass: "video-duration" }, [
              _vm._v(_vm._s(_vm.secondsFormatTime(_vm.item.duration))),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "f-grow ml10" },
        [
          _vm._l(_vm.item.text, function (temp, index) {
            return _c(
              "div",
              { key: index, staticClass: "flex-row-center w-100" },
              [
                _c("div", { staticClass: "language-tag" }, [
                  _vm._v(_vm._s(_vm.getLanguageName(temp.value))),
                ]),
                _c("el-input", {
                  staticClass: "w-100",
                  attrs: { type: "textarea" },
                  on: {
                    blur: function ($event) {
                      return _vm.onInputBlur(temp)
                    },
                  },
                  model: {
                    value: temp.text,
                    callback: function ($$v) {
                      _vm.$set(temp, "text", $$v)
                    },
                    expression: "temp.text",
                  },
                }),
              ],
              1
            )
          }),
          _c("div", { staticClass: "flex-row-center mt10 w-100 f-between" }, [
            _vm._v(
              " " +
                _vm._s(_vm.secondsFormatTime(_vm.item.startTime)) +
                " - " +
                _vm._s(_vm.secondsFormatTime(_vm.item.endTime)) +
                " "
            ),
            _c(
              "div",
              { staticStyle: { "align-self": "flex-end" } },
              [
                _c(
                  "el-tooltip",
                  { attrs: { content: _vm.$t("editVideo.autoGenerate") } },
                  [
                    _c("el-button", {
                      attrs: {
                        icon: "el-icon-orange",
                        size: "mini",
                        circle: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("make-video", _vm.item)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-tooltip",
                  { attrs: { content: _vm.$t("editVideo.replaceClip") } },
                  [
                    _c("el-button", {
                      attrs: {
                        icon: "el-icon-connection",
                        size: "mini",
                        circle: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("replace-video", _vm.item)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-tooltip",
                  { attrs: { content: _vm.$t("remove") } },
                  [
                    _c("el-button", {
                      attrs: {
                        icon: "el-icon-delete",
                        size: "mini",
                        circle: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("delete-video", _vm.item)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }