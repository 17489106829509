<!-- 编辑视频 -->
<template>
  <div class="app-container">
    <div class="flex-row-center f-between video-all w-100" >
      <div class="flex-row-center f-wrap">
        <div
          v-for="(item, index) in sceneList"
          :key="index"
          class="copywriting-item"
          :class="{ active: index === activeStep }"
          @click="selectStep(index)"
        >
          {{$t('editVideo.video')}}{{ index + 1 }}
          <i class="el-icon-close" v-if="sceneList.length > 1" @click="deleteItem(index)"></i>
        </div>
      </div>
      <div style="align-self: flex-end;">
        <el-button class="button-white" @click="last" style="margin: 0 5px;">{{$t('register.previous')}}</el-button>
        <el-button :loading="finishLoading" class="gradient-button"  @click="makeVideo" style="margin: 0 5px;">{{$t('editVideo.generateVideo')}}</el-button>
      </div>
    </div>
    <div class="flex-row mt10">
      <div class="video-all mr10" style="width: 100px; ">
        <div
          v-for="(item, index) in menu"
          :key="index"
          class="copywriting-item flex-column-center"
          style="padding: 10px 12px; margin: 5px;"
          :class="{ active: index === currentMenu }"
          @click="selectMenu(index)"
        >
          <i :class="item.icon"></i>
          {{ item.title }}
        </div>
      </div>
      <el-row class="w-100" v-if="textList[activeStep]">
        <el-col :span="12" v-loading="loading" v-show="currentMenu === 0" >
          <div v-for="(template, index) in textList[activeStep].videoList" :key="index" class="template-card-item">
            <VideoItem
              :template="template"
              :selected-item="selectedItem"
              :languageCode="languageCode"
              :languageList="languageList"
              @item-selected="selectedVideo"
              @make-video="changeVideo(index, template)"
              @replace-video="replaceVideo(index, template)"
              @delete-video="deleteVideo(index)"
            />
          </div>
        </el-col>
        <el-col :span="12" v-show="currentMenu === 1">
          <div v-if="sceneList[activeStep].music.url" class="flex-column">
            <div>{{ $t('editVideo.musicSelect') }}</div>
            <div class="flex-row-center" style="margin-top: 5px">
              <audio controls :src="sceneList[activeStep].music.url" class="f-grow" ref="audioPlayer"></audio>
              <i class="el-icon-circle-close" @click="delMusic"></i>
            </div>
            <div class="flex-row-center mt5">
             <span style="color: #606266;font-size: 14px;margin-right: 12px;font-weight: 700;">{{ $t('editVideo.musicVolume') }}</span>
             <el-slider :step="0.1" class="f-grow" v-model="sceneList[activeStep].music.volume" :max="1" :min="0" show-input></el-slider>
            </div>
          </div>
          <Material_library
            ref="materialLibrary"
            :skipInit="true"
            :isTextToVideo="true"
            @selectionConfirmed="selectionConfirmed"
          ></Material_library>
        </el-col>
        <el-col :span="12" v-show="currentMenu === 2" class="voice">
          <!-- 配音音色 -->
          <Voice
            :voiceList="voiceList"
            :value="sceneList[activeStep].aiTts.voice"
            @item-selected="voiceSelected"
            @dubbing-change="dubbingChange"
          />
        </el-col>
        <el-col :span="12" v-show="currentMenu === 3" class="voice">
          <!-- 字幕 -->
          <Subtitle
            :key="`subtitle-${activeStep}`"
            :value="sceneList[activeStep].subtitle"
            :effectColorStyleList="effectColorStyleList"
            @item-updated="subtitleUpdated"
          />
        </el-col>
        <el-col :span="12" v-show="currentMenu === 4" class="voice">
          <!-- 标题 -->
          <Title
            :key="`title-${activeStep}`"
            :value="sceneList[activeStep].title"
            :effectColorStyleList="effectColorStyleList"
            :languageCode="languageCode"
            :languageList="languageList"
            @item-updated="titleUpdated"
          />
        </el-col>
        <el-col :span="12" v-show="currentMenu === 5" class="voice">
          <!-- 转场 -->
           <Transition
            :transitionList="transitionList"
            @item-selected="transitionUpdated"
           />
        </el-col>
        <el-col :span="12" v-show="currentMenu === 6" class="voice">
          <!-- 设置-Logo、封面、封底 -->
           <Settings
            :value="sceneList[activeStep]"
            @item-updated="settingsUpdated"
           />
        </el-col>
        <el-col :span="12" class="flex-column-center video-container" v-if="selectedItem">
          <video
            ref="myVideo"
            width="315"
            height="560"
            @timeupdate="handleTimeUpdate"
            @click="togglePlay"
            style="background-color: black;"
          >
            <source :src="selectedItem.url" type="video/mp4">
            Your browser does not support the video tag.
          </video>
          <!-- <i v-if="isPlaying" class="el-icon-video-pause" style="margin-top: -30px;"></i> -->
          <i
            v-if="!isPlaying"
            class="el-icon-video-play icon-style"
          ></i>
          <i
            v-if="isPlaying"
            class="el-icon-video-pause icon-style"
          ></i>
          <input
            type="range"
            min="0"
            :max="selectedItem.duration"
            step="0.01"
            v-model="currentTime"
            @input="seekVideo"
            style="width: 315px;margin-top: 10px;"
          />
          <div>{{ secondsFormatTime(currentTime) }} / {{ secondsFormatTime(selectedItem.duration) }}</div>
        </el-col>
      </el-row>
    </div>
    <el-drawer
      :title="$t('mixing.materia')"
      size="65%"
      :visible.sync="drawer"
      :with-header="false"
      :modal="false"
    >
      <Material_library 
        v-if="selectedItem" 
        ref="videoMaterial" 
        :skipInit="true" 
        :timeLength="selectedItem.duration || 1" 
        @selectionConfirmed="videoConfirmed"
        @cancel="drawer=false"
      />
    </el-drawer>
  </div>
</template>
<script>
import { analysisVideo, generateVideo } from '../../api/aiClip/aiClip';
import VideoItem from './videoItem.vue';
import Voice from './Voice.vue';
import Subtitle from './Subtitle.vue';
import Title from './Title.vue';
import Transition from './Transition.vue';
import Settings from './Settings.vue';
import Material_library from '../aivideo/material.vue';
import { getAzureVoice, getEffectColorStyleList, getLanguages, getTransitionsList } from '../../api/ai/ali';
export default {
  name: 'EditVideo',
  components: {
    VideoItem,
    Material_library,
    Voice,
    Subtitle,
    Title,
    Transition,
    Settings,
  },
  data() {
    return {
      textList:[],//上个页面传过来的所有数据
      sceneList: [],//需要上传接口的格式
      activeStep: 0,
      menu:[
        {
          title: this.$t('videoMenu.scenes'),
          icon: 'el-icon-video-camera'
        },
        {
          title: this.$t('videoMenu.music'),
          icon: 'el-icon-headset'
        },
        {
          title: this.$t('videoMenu.voiceOver'),
          icon: 'el-icon-mic'
        },
        {
          title: this.$t('videoMenu.subtitles'),
          icon: 'el-icon-notebook-2'
        },
        {
          title: this.$t('videoMenu.text'),
          icon: 'el-icon-notebook-1'
        },
        {
          title: this.$t('videoMenu.transitions'),
          icon: 'el-icon-help'
        },
        {
          title: this.$t('videoMenu.setting'),
          icon: 'el-icon-setting'
        },
      ],
      currentMenu: 0,
      loading: false,
      finishLoading: false,
      currentTime: 0,//当前视频播放的时间
      isPlaying: false,  // 用于控制播放和暂停状态
      selectedItem: null, // 用于存储选中的视频 item
      voiceList: [],//音色列表
      effectColorStyleList: [],//花字效果
      transitionList: [],//转场效果
      drawer:false,//视频素材选择的抽屉
      videoIndex:0,//当前选择的视频素材的索引
      languageCode: null,
      languageList: [],
    }
  },
  created() {
    // this.init(sessionStorage.getItem('editVideo'))
    this.getLanguage()
  },
  methods: {
    init(data, languageCode){
      // this.textList = JSON.parse(sessionStorage.getItem('editVideo'))
      this.languageCode = languageCode
      this.textList = JSON.parse(data)
      this.selectedItem = this.textList[0].videoList[0]
      console.log(this.textList);
      this.convertListToSceneList(JSON.parse(JSON.stringify(this.textList)));
    },
    getLanguage(){
      getLanguages().then(response => {
        this.languageList = response.data
      });
    },
    transitionUpdated(value){
      if(value){
        this.sceneList[this.activeStep].transition = {
          Duration: 2,
				  SubType: value
        }
      }else{
        this.sceneList[this.activeStep].transition = null
      }
      console.log('this.sceneList',this.sceneList)
    },
    titleUpdated(value){
      // this.sceneList[this.activeStep].title = value
      this.$set(this.sceneList[this.activeStep], 'title', value);
      console.log('this.sceneList',this.sceneList)
    },
    settingsUpdated(value){
      this.$set(this.sceneList, this.activeStep, {...this.sceneList[this.activeStep], ...value});
      console.log('this.sceneList',this.sceneList)
    },
    subtitleUpdated(value){
      // this.sceneList[this.activeStep].subtitle = value
      this.$set(this.sceneList[this.activeStep], 'subtitle', value);
      console.log('this.sceneList',this.sceneList)
    },
    voiceSelected(value){
      console.log('voiceSelected',value)
      this.sceneList[this.activeStep].aiTts.voice = value.shortName
      this.sceneList[this.activeStep].aiTts.language = value.locale
      console.log('this.sceneList',this.sceneList)
    },
    // 配音开启or关闭
    dubbingChange(value){
      this.sceneList[this.activeStep].aiTts.dubbing = value
    },
    // 获取音色列表
    getVoiceList(){
      getAzureVoice().then(res => {
        this.voiceList = res.data.filter(voice => voice.name.startsWith('中文'))
        // if ("多语种" in this.voiceList) {
        //   delete this.voiceList["多语种"];
        // }
        // if ("英文" in this.voiceList) {
        //   delete this.voiceList["英文"];
        // }
      })
    },
    //花字效果
    getEffectColorStyleList() {
      getEffectColorStyleList().then(res => {
        this.effectColorStyleList = res.data;
      });
    },
    // 获取转场列表
    getTransitionsList(){
      getTransitionsList().then(res => {
        this.transitionList = res.data
      })
    },
    //选中music
    selectionConfirmed(items) {
      if(items.length==1){
        this.sceneList[this.activeStep].music.url = items[0].url
      }
    },
    delMusic(){
      this.sceneList[this.activeStep].music.url = ''
    },
    selectedVideo(item) {
      this.selectedItem = item; // 更新选中项
      this.isPlaying = false; // 重置播放状态
      this.$nextTick(() => {
        const video = this.$refs.myVideo;
        if (video) {
          video.load(); // 重新加载新的视频文件
        }
      });
    },
    //开始or暂停播放
    togglePlay() {
      const video = this.$refs.myVideo;
      if(this.currentTime == this.selectedItem.duration){
        this.currentTime = 0
        video.currentTime = 0
      }
      if (this.isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    handleTimeUpdate() {
      const video = this.$refs.myVideo;
      const duration = this.selectedItem.duration
      this.currentTime = Math.min(video.currentTime, duration);

      // 当视频播放到达自定义时长时暂停
      if (this.currentTime >= duration) {
        video.pause();
      }
    },
    seekVideo(event) {
      // 使视频进度条和显示时间一致
      this.$refs.myVideo.currentTime = event.target.value;
    },
    //按照上传需求更改数据结构
    convertListToSceneList(list) {
      this.sceneList = list.map(item => {
        return {
          isMute: true,
          aiTts: {
            speed: 1,
            voice: "zh-CN-XiaoxiaoNeural",
            language:'locale',
            volume: 1
          },
          outputMediaConfig:{
			    	height:1920,
			    	width:1080,
			    },
          music: {
            speed: 1,
            url: "",
            volume: 0.1
          },
          subtitle: {
            open: true,
            FontFaces: [],
            fontFace: {
              Italic: false,
              Underline: false,
              Bold: false
            },
            Y: 0.9,
            position: "bottom",
            alignment: "BottomCenter",
            font:"SimSun",
				    fontColor:"#ffffff",
				    fontSize: 53,
            fontSizeType: "normal",
            openEffectColorStyle:false,
            effectColorStyle:'',//花字
          },
          textList: item.videoList.map(video => ({
            // effects: [
            //   {
            //     gain: 0,
            //     type: "Volume"
            //   }
            // ],
            text: video.text,
            title: item.title || '',
            url: video.url
          })),
          title: {
            adaptMode: "AutoWrapAtSpaces",
            content: item.title || [],
            fontColorOpacity: 1,//透明度传的之后0-1
            x: 0,
            y: 0.1,
            outline: 0,//描边宽度
            outlineColour: "#ffffff",//描边颜色
            spacing: 0,//字间距
            fontSize: 53,//字体大小
            fontSizeType:"normal",
            font:"SimSun",//字体
            fontColor:"#ffffff",//字体颜色
            alignment:'TopCenter',
            position:'center',
            fontFaces: [],//字体样式
            fontFace:{
              Italic:false,
              Underline:false,
              Bold:false
            },
            openEffectColorStyle:false,
            effectColorStyle:'',//花字
          },
          transition: null,
          coverUrl: '', // 封面
          backCoverUrl: '', // 封底
          coverUrlHover: false, // 封面 hover
          backCoverUrlHover: false, // 封底 hover
          logoTrackClipsBo: {
            type: 'GlobalImage',
            x: '20',
            y: '20',
            width: 200,
            height: 200,
            mediaUrl: '',
            position: 'left-top',
            hover: false
          },
        };
      });
      console.log('this.sceneList',this.sceneList)
    },
    //切换菜单
    selectMenu(index) {
      this.currentMenu = index;
      if(this.currentMenu !== 0){
        this.$refs.myVideo.pause()
      }
      if(this.currentMenu === 1){
        this.$nextTick().then((rs) => {
          this.$refs.materialLibrary.init(2);
        });
      }else if(this.currentMenu === 2){
        this.getVoiceList()
      }else if(this.currentMenu === 3){
        this.getEffectColorStyleList()
      }else if(this.currentMenu === 4){
        this.getEffectColorStyleList()
      }else if(this.currentMenu === 5){
        this.getTransitionsList()
      }else if(this.currentMenu === 6){

      }
    },
    //切换视频
    selectStep(index) {
      this.activeStep = index;
      this.selectedVideo(this.textList[this.activeStep].videoList[0])
    },
    //删除整个视频
    deleteItem(index) {
      this.sceneList.splice(index, 1);
      this.textList.splice(index, 1)
    },
    //智能生成
    changeVideo(index, item){
      this.loading = true
      let zhTextItem = item.text.find(item => item.value === 'zh');
      let zhText = zhTextItem ? zhTextItem.text : null;

      let zhTitleItem = this.textList[this.activeStep].title.find(item => item.value === 'zh');
      let zhTitle = zhTitleItem ? zhTitleItem.text : null;

      analysisVideo({
        paragraphList:[{
          title: zhTitle,
          analysisTextList:[
            {
              text: zhText,
            }
          ],
        }],
        languageCode: this.languageCode,
      }).then(response => {
        console.log(response);
        if(response.data && response.data.length > 0 && response.data[0].videoList.length > 0){
          this.textList[this.activeStep].videoList[index] = response.data[0].videoList[0]
          this.recalculateTimes()
          this.selectedVideo(this.textList[this.activeStep].videoList[index])
        }
      }).finally(() => {
        this.loading = false
      })
    },
    //替换素材
    replaceVideo(index){
      this.videoIndex = index
      this.drawer = true
      this.$nextTick().then((rs) => {
        this.$refs.videoMaterial.init(6);
      });
    },
    videoConfirmed(items){
      if(items.length==1){
        this.textList[this.activeStep].videoList[this.videoIndex].url = items[0].url
        this.selectedVideo(this.textList[this.activeStep].videoList[this.videoIndex])
      }
      this.drawer = false
    },
    //删除一条视频
    deleteVideo(index){
      this.textList[this.activeStep].videoList.splice(index, 1)
    },
    //重新计算textList的startTime和endTime值
    recalculateTimes() {
      this.textList.forEach(item => {
        let accumulatedTime = 0; // 初始化累计时间为0
        item.videoList.forEach(video => {
            video.startTime = accumulatedTime; // 设置当前视频的开始时间
            accumulatedTime += video.duration; // 累计时间增加当前视频的时长
            video.endTime = accumulatedTime; // 设置当前视频的结束时间
        });
      });
    },
    //上一步
    last(){
      this.finishLoading = false
      this.$emit('last')
    },
    //生成视频
    makeVideo(){
      if(this.sceneList.length > 0 && this.textList.length > 0 && this.sceneList.length === this.textList.length){
        this.sceneList = this.sceneList.map((scene, index) => {
          const updatedTextList = this.textList[index].videoList.map(video => {
            const textObj = video.text.reduce((acc, item) => {
              acc[item.value] = item.text;
              return acc;
            }, {});
            delete this.textList[index].title
            return {
              textObj: textObj,  // 使用转换后的 textObj
              title: this.textList[index].title, // 你可以设置 title 或者使用其他值
              url: video.url
            };
          }) || [];
          // 将 scene.title.content 转换为 contentObj
          const contentObj = scene.title.content
            ? scene.title.content.reduce((acc, item) => {
                acc[item.value] = item.text;
                return acc;
              }, {})
            : null;
          delete scene.title.content
          // 返回更新后的 scene 对象，同时保留其他参数不变
          if (!scene.coverUrl) {
            scene.coverUrl = null
          }
          if (!scene.backCoverUrl) {
            scene.backCoverUrl = null
          }
          if (!scene.logoTrackClipsBo.mediaUrl) {
            scene.logoTrackClipsBo = null
          }
          return {
            ...scene,
            textList: updatedTextList,
            title: {
              ...scene.title,
              contentObj: contentObj, // 同步修改 title.content 的值
            }
          };
        })
        this.finishLoading = true
        console.log(this.sceneList);
        generateVideo({
          sceneList: this.sceneList,
          languageCode: this.languageCode,
        }).then(response => {
          console.log(response);
          this.$modal.msgSuccess(this.$t('issueVideo.addSuccess'));
          this.$tab.closePage({ path: "/newCutting", name: "AiCrosscutting" });
          setTimeout(() => {
            this.$tab.closeOpenPage({ path: "/videoManager/aiCrosscutting" });
          }, 2000);
        }).finally(() => {
          this.finishLoading = false
        })
      }else{
        this.$modal.msgError(this.$t('editVideo.dataError'))
      }
    }

  }
}
</script>
<style scoped>
.video-all{
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 10px;
}
.copywriting-item {
  padding: 5px 8px;
  margin: 5px 10px 5px 0;
  font-size: 12px;
  border: 1px solid #E5E7EB;
  border-radius: 5px;
  color:gray;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.copywriting-item.active {
  border-color: #016FEE; /* 选中步骤的边框颜色 */
  color:#016FEE;
}
.template-card-item{
  width: 100%;
}
.video-preview{
  width: 315px;
  height: 560px;
}
.custom-radio-group {
  display: flex;
  flex-wrap: wrap;
}
.voice{
  border: 1px solid #E5E7EB;
  padding: 10px;
  border-radius: 5px;
  /* margin: 10px; */
}
.video-container{
  z-index: 1;
}

.icon-style {
  color: white;
  margin-top: -300px;
  margin-bottom: 260px;
  font-size: 40px;
  z-index: 10; /* 确保图标在视频之上 */
  cursor: pointer;
  pointer-events: none; /* 使按钮不可交互 */
}
</style>
