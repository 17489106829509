var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "20px", width: "100%" } },
    [
      _c(
        "div",
        { staticClass: "flex-row-center f-between" },
        [
          _c(
            "span",
            { staticStyle: { "font-size": "18px", "font-weight": "bold" } },
            [_vm._v(_vm._s(_vm.$t("preview.result")))]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                loading: _vm.buttonLoading,
              },
              on: { click: _vm.createTask },
            },
            [_vm._v(_vm._s(_vm.$t("preview.make")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex-row", staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "el-checkbox",
            {
              attrs: { indeterminate: _vm.isIndeterminate },
              on: { change: _vm.checkedAll },
              model: {
                value: _vm.allSelected,
                callback: function ($$v) {
                  _vm.allSelected = $$v
                },
                expression: "allSelected",
              },
            },
            [_vm._v(_vm._s(_vm.$t("checkAll")) + " ")]
          ),
        ],
        1
      ),
      _c(
        "el-checkbox-group",
        {
          staticClass: "checkbox-group",
          on: { change: _vm.checkedSingle },
          model: {
            value: _vm.select,
            callback: function ($$v) {
              _vm.select = $$v
            },
            expression: "select",
          },
        },
        _vm._l(_vm.videoOptions, function (material) {
          return _c(
            "el-checkbox",
            { key: material.id, attrs: { label: material.id } },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.previewLoading,
                      expression: "previewLoading",
                    },
                  ],
                  staticClass: "aspect-ratio-box flex-column",
                  on: {
                    mouseover: function ($event) {
                      material.hover = true
                    },
                    mouseleave: function ($event) {
                      material.hover = false
                    },
                  },
                },
                [
                  _c("div", { staticClass: "material-container" }, [
                    material.type === "Image"
                      ? _c("img", {
                          staticClass: "material-thumbnail",
                          attrs: { src: material.mediaUrl, alt: "" },
                        })
                      : _c("video", {
                          staticClass: "material-thumbnail",
                          attrs: { src: material.mediaUrl, controls: "" },
                        }),
                    material.hover
                      ? _c(
                          "div",
                          {
                            staticClass: "overlay",
                            on: {
                              click: function ($event) {
                                return _vm.openPreview(material.projectId)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("preview.preview")) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  material.duration
                    ? _c("div", { staticClass: "video-duration" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.secondsFormatTime(material.duration)) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ]
          )
        }),
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            "destroy-on-close": true,
            title: _vm.$t("preview.videoPreview"),
            "append-to-body": true,
            size: "65%",
            visible: _vm.drawer,
            "with-header": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [_c("preview", { ref: "videoPreview" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }