import request from '@/utils/request'
//视频混剪
const baseApi = '/admin/videoTask'

// 列表
export function listVideoTask(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}

// 查询客户详细
export function getVideoTask(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'get'
  })
}

// 新建进入：
// 	  保存草稿：isPreview=false，	status=0
// 	  直接预览：isPreview=true，	status=0
// 从列表的草稿进入：
// 	  保存草稿：isPreview=false，	status=接口返回的值
// 	  直接预览：isPreview=true，	status=接口返回的值
export function createVideoTask(data) {
  return request({
    url: baseApi + '/createVideoTask',
    method: 'post',
    data: data
  })
}

//通过预览、生成视频 post  json
//id、ids必传
export function generateVideoTask(data) {
  return request({
    url: baseApi + '/generateVideoTask',
    method: 'post',
    data: data
  })
}
// 不经过预览直接生成视频
export function createAndGenerateVideoTask(data) {
  return request({
    url: baseApi + '/createAndGenerateVideoTask',
    method: 'post',
    data: data
  })
}
// 删除
export function delVideoTask(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'delete'
  })
}

// 获取前端timeline
export function getTimeline(id) {
  return request({
    url: baseApi + '/getTimeline/' + id,
    method: 'get'
  })
}
// 获取MediaInfo的url
export function getMediaInfo(id) {
  return request({
    url: baseApi + '/getMediaInfo/' + id,
    method: 'get'
  })
}

// 列表中各类型的数量
export function getIndexStatistics(query) {
  return request({
    url: baseApi + '/getIndexStatistics',
    method: 'get',
    params: query
  })
}
