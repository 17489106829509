<template>
  <div style="padding: 20px;width:100%">
    <div class="flex-row-center f-between">
      <span style=" font-size: 18px;font-weight: bold;">{{ $t('preview.result') }}</span>
      <el-button type="primary" size="small" :loading="buttonLoading" @click="createTask">{{
          $t('preview.make')
        }}
      </el-button>
    </div>
    <div class="flex-row" style="margin-top: 20px;">
      <el-checkbox :indeterminate="isIndeterminate"
                   v-model="allSelected"
                   @change="checkedAll">{{ $t('checkAll') }}
      </el-checkbox>
    </div>
    <el-checkbox-group
      v-model="select"
      @change="checkedSingle"
      class="checkbox-group">
      <el-checkbox v-for="material in videoOptions" :label="material.id" :key="material.id">
        <div
          v-loading="previewLoading"
          class="aspect-ratio-box flex-column"
          @mouseover="material.hover = true"
          @mouseleave="material.hover = false"
        >
          <div class="material-container">
            <img v-if="material.type === 'Image'" :src="material.mediaUrl" alt="" class="material-thumbnail"/>
            <video v-else :src="material.mediaUrl" controls class="material-thumbnail"></video>
            <div v-if="material.hover" class="overlay" @click="openPreview(material.projectId)">
              {{ $t('preview.preview') }}
            </div>
          </div>
          <div class="video-duration" v-if="material.duration">
            {{ secondsFormatTime(material.duration) }}
          </div>
          <!-- <div style="margin-top: 5px;">{{ material.name }}</div> -->
        </div>
      </el-checkbox>
    </el-checkbox-group>

    <el-drawer
      :destroy-on-close="true"
      :title="$t('preview.videoPreview')"
      :append-to-body="true"
      size="65%"
      :visible.sync="drawer"
      :with-header="true"
      >
      <!-- :before-close="handleClose" -->
      <preview ref="videoPreview"></preview>
    </el-drawer>
  </div>
</template>

<script>
import {generateVideoTask, getTimeline} from '../../api/ai/videoTask';
import preview from './preview.vue'

export default {
  name: "VideoPreview",
  components: {preview},
  data() {
    return {
      previewLoading: false,
      allSelected: false, // 全选状态
      isIndeterminate: false,//全选：不确定状态
      select: [],//选中的视频
      videoOptions: [],//所有的视频
      data: [],//所有的数据
      drawer: false,
      id: '',
      buttonLoading: false,
    }
  },
  methods: {
    createTask() {
      if (this.select.length === 0) {
        this.$modal.msgError(this.$t('preview.tipVideo'))
        return
      }
      this.buttonLoading = true
      generateVideoTask({id: this.id, ids: this.select}).then(res => {
        this.$modal.msgSuccess(this.$t('preview.tipSuccess'))
        this.$emit('createTaskSuccess')
      }).finally(() => {
        this.buttonLoading = false
      })
    },
    init(id, data) {
      console.log(data)
      this.id = id
      this.data = data
      this.select = []
      this.videoOptions = []
      this.data.find(item => {
        this.videoOptions.push({
          id: item.id,
          projectId: item.projectId,
          mediaUrl: item.firstMediaUrl,
          hover: false,
          type: item.firstMediaType || '',
          duration: item.duration,
        })
      });
    },
    openPreview(id) {
      if (this.previewLoading == true) {
        return
      }
      this.previewLoading = true
      getTimeline(id).then(res => {
        this.drawer = true;
        this.$nextTick().then(() => {
          if (res.code == 200 && res.msg) {
            this.previewLoading = false
            this.$refs.videoPreview.init(JSON.parse(res.msg));
          }/*else{
            this.$refs.videoPreview.init(res.msg);
          }*/
        });
      }).catch(err => {
        this.previewLoading = false
        //  this.$modal.msgError(this.$t('preview.tipFailed'))
      });
    },
    handleClose(){
      this.$refs.videoPreview.destroyPlayer();
    },
    //视频全选
    checkedAll(val) {
      this.select = val ? this.videoOptions.map(item => item.id) : [];
      this.isIndeterminate = false;
    },
    checkedSingle(value) {
      let checkedCount = value.length;
      this.allSelected = checkedCount === this.videoOptions.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.videoOptions.length;
    },
  },
};
</script>

<style scoped>
.aspect-ratio-box-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* 调整间距 */
}

.aspect-ratio-box {
  width: 90px;
  height: 160px;
  border: 1px solid #0092FF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 5px; /* 调整间距 */
  position: relative;
}

.material-thumbnail {
  width: 100%;
  height: 100%; /* 根据需要调整高度 */
  object-fit: cover;
  border-radius: 3px;
}

.material-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}

.video-duration {
  position: absolute;
  right: 5px;
  bottom: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
}
</style>
