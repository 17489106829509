var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "custom-dialog",
      attrs: {
        title: "拖动下方滑块完成拼图",
        width: "362px",
        visible: _vm.localShowSlide,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.handleBeforeClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.localShowSlide = $event
        },
      },
    },
    [
      _c("slide-verify", {
        ref: "slideVerify",
        attrs: {
          l: _vm.l,
          r: _vm.r,
          w: _vm.w,
          h: _vm.h,
          imgs: _vm.imgs,
          "slider-text": "向右滑动",
        },
        on: { success: _vm.onSuccess, fail: _vm.onFail },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }