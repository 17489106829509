var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "scrollList", staticClass: "scroll-list" },
    [
      !_vm.nowList
        ? _c(
            "vue-seamless-scroll",
            {
              ref: "scrollDom",
              staticClass: "scroll",
              attrs: {
                data: _vm.communityList,
                "class-option": _vm.defaultOption,
              },
            },
            [
              _c(
                "transition-group",
                { attrs: { name: "bounce", tag: "div" } },
                _vm._l(_vm.communityList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "flexItem",
                      staticStyle: {
                        "min-height": "80px",
                        height: "80px",
                        "border-bottom": "1px solid #DFDFDF",
                        padding: "14px 0",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "32px",
                          height: "32px",
                          "margin-right": "10px",
                        },
                        attrs: {
                          src: require("@/assets/images/head/头像" +
                            item.avatar +
                            ".png"),
                        },
                      }),
                      _c("div", { staticClass: "content" }, [
                        _c("div", { staticClass: "tag" }, [
                          _c("i", { staticClass: "iconfont icon-gongzuotai2" }),
                          _vm._v(_vm._s(item.time)),
                        ]),
                        _c("span", { staticClass: "community-content" }, [
                          _c("i", {
                            class: ["iconfont", _vm.filterIcon(item.platform)],
                          }),
                          _vm._v(_vm._s(item.platform)),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "community-content",
                            staticStyle: { color: "#016FEE" },
                          },
                          [_vm._v(" " + _vm._s(item.name))]
                        ),
                        _c("span", { staticClass: "community-content" }, [
                          _vm._v(_vm._s(item.content1)),
                        ]),
                        _c("span", { staticClass: "community-content" }, [
                          _vm._v(_vm._s(item.content2)),
                        ]),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          )
        : _c(
            "vue-seamless-scroll",
            {
              ref: "scrollDom",
              staticClass: "scroll",
              attrs: {
                data: _vm.communityList,
                "class-option": _vm.defaultOption,
              },
            },
            [
              _c(
                "transition-group",
                { attrs: { name: "bounce", tag: "div" } },
                _vm._l(_vm.communityList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "flexItem",
                      staticStyle: {
                        "min-height": "80px",
                        height: "80px",
                        "border-bottom": "1px solid #DFDFDF",
                        padding: "14px 0",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "32px",
                          height: "32px",
                          "margin-right": "10px",
                        },
                        attrs: {
                          src: require("@/assets/images/head/头像" +
                            item.avatar +
                            ".png"),
                        },
                      }),
                      _c("div", { staticClass: "content" }, [
                        _c("div", { staticClass: "tag" }, [
                          _c("i", { staticClass: "iconfont icon-gongzuotai2" }),
                          _vm._v(_vm._s(item.time)),
                        ]),
                        _c("span", { staticClass: "community-content" }, [
                          _c("i", {
                            class: ["iconfont", _vm.filterIcon(item.platform)],
                          }),
                          _vm._v(_vm._s(item.platform)),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "community-content",
                            staticStyle: { color: "#016FEE" },
                          },
                          [_vm._v(" " + _vm._s(item.name))]
                        ),
                        _c("span", { staticClass: "community-content" }, [
                          _vm._v(_vm._s(item.content1)),
                        ]),
                        _c("span", { staticClass: "community-content" }, [
                          _vm._v(_vm._s(item.content2)),
                        ]),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }