export function copyTextToClipboard(text, showMessage) {
  if (!navigator.clipboard) {
    // 如果浏览器不支持 Clipboard API，则退回到旧方法
    fallbackCopyTextToClipboard(text, showMessage);
    return;
  }

  navigator.clipboard.writeText(text)
    .then(() => {
      console.log('Text copied to clipboard');
      if (showMessage) {
        showMessage({
          message: '链接已复制到剪贴板',
          type: 'success'
        });
      }
    })
    .catch(err => {
      console.error('Could not copy text: ', err);
      // 处理复制失败的情况
    });
}

function fallbackCopyTextToClipboard(text, showMessage) {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  textarea.style.position = 'fixed';  // 避免在页面上显示
  document.body.appendChild(textarea);
  textarea.focus();
  textarea.select();

  try {
    document.execCommand('copy');
    console.log('Text copied to clipboard');
    if (showMessage) {
      showMessage({
        message: '链接已复制到剪贴板',
        type: 'success'
      });
    }
  } catch (err) {
    console.error('Fallback: Could not copy text: ', err);
    // 处理复制失败的情况
  } finally {
    document.body.removeChild(textarea);
  }
}
