var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video-player-message" }, [
    _c("p", [_vm._v(_vm._s(_vm.message.text))]),
    _c("video", {
      staticClass: "video-player",
      attrs: { src: _vm.message.videoUrl, controls: "" },
    }),
    _c(
      "div",
      { staticClass: "flex-row-center f-between" },
      [
        !_vm.message.completed
          ? _c(
              "el-button",
              {
                staticClass: "gradientBtn",
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("submit")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("ai.sure")))]
            )
          : _vm._e(),
        !_vm.message.completed
          ? _c(
              "el-button",
              {
                staticClass: "againBtn",
                on: {
                  click: function ($event) {
                    return _vm.$emit("again")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("ai.videoAgain")))]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }