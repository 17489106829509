<template>
  <div>
    <el-table v-loading="loading" :data="list" @selection-change="handleSelectionChange">
      <el-table-column
        type="selection"
        width="55"
        align="center"
        :selectable="checkSelectable"
      />
      <el-table-column :label="$t('file.name')" prop="url" width="200">
        <template slot-scope="scope" >
          <div class="flex-row-center">
            <div v-if="scope.row.documentType == 'folder'">
              <img src="../../assets/images/folder-system.png" alt="" class="img-forder-list" v-if="scope.row.type === 'system'">
              <img src="../../assets/images/folder-user.png" alt="" class="img-forder-list" v-else>
            </div>
            <div v-else>
              <div v-if="scope.row.fileType == 'image'">
                <img :src="scope.row.url" alt="image" class="video-cover" />
              </div>
              <div v-else-if="scope.row.fileType == 'text'">
                <img src="../../assets/images/video/text.png" alt="" width="30px" height="30px">
              </div>
              <div v-else-if="scope.row.fileType == 'audio'">
                <img src="../../assets/images/video/audio.png" alt="" style="" width="30px" height="30px">
                <!-- <svg-icon class="video-cover" icon-class="audio"/> -->
              </div>
              <div v-else>
                <video :src="scope.row.url" alt="video" class="video-cover" />
              </div>
            </div>
            <span class="single-line-ellipsis ml5 material-name" @click="nameClick(scope.row)">
              {{ scope.row.fileType == 'text' ? scope.row.title :scope.row.name }}
            </span>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="标题" align="center" prop="name" /> -->
      <!-- <el-table-column label="时长" align="center" prop="videoLength">
        <template slot-scope="scope">
          {{ scope.row.videoLength && scope.row.videoLength>0?secondsFormatTime(scope.row.videoLength):'--' }}
        </template>
      </el-table-column> -->
      <el-table-column :label="$t('label.label')" align="center" prop="labels">
        <template slot-scope="scope">
          <!-- <div class="video-tags font14"> -->
            <el-tag
              v-for="(tag, index) in scope.row.labels"
              :key="index"
              style="margin-right: 5px;margin-bottom: 5px;"
            >
              {{ tag }}
            </el-tag>
          <!-- </div> -->
        </template>
      </el-table-column>
      <el-table-column :label="$t('file.format')" align="center" prop="suffix">
      </el-table-column>
      <el-table-column :label="$t('time.createTime')" align="center" prop="createTime">
      </el-table-column>
      <el-table-column :label="$t('file.fileSize')" align="center" prop="fileSize">
        <template slot-scope="scope">
          {{ scope.row.fileSize ? formatFileSize(scope.row.fileSize) : '--' }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('operate')" align="center" class-name="small-padding fixed-width" width="300">
        <template slot-scope="scope">
          <div v-if="scope.row.documentType == 'folder'">
            <el-tooltip :content="$t('edit')" v-if="scope.row.type !== 'system'">
              <el-button icon="el-icon-edit" circle @click="$emit('edit-folder',scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip :content="$t('remove')" v-if="scope.row.type !== 'system'">
              <el-button icon="el-icon-delete" circle @click="$emit('delete-folder',scope.row)"></el-button>
            </el-tooltip>
          </div>
          <div v-else>
            <div v-if="scope.row.fileType == 'text'">
              <el-tooltip :content="$t('view')">
                <el-button icon="iconfont icon-chakan" circle @click="$emit('view-text',scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip :content="$t('edit')" v-if="scope.row.type !== 'system'">
                <!-- <i class="el-icon-edit color-blue" @click="$emit('edit-text',item)"></i> -->
                <el-button icon="el-icon-edit" circle @click="$emit('edit-text',scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip :content="$t('remove')" v-if="scope.row.type !== 'system'">
                <!-- <i class="el-icon-delete color-blue" @click="deleteVideo"></i> -->
                <el-button icon="el-icon-delete" circle @click="$emit('delete-video',scope.row)"></el-button>
              </el-tooltip>
            </div>
            <div v-else>
              <el-tooltip :content="$t('videoManager.publish')" v-if="type == 3 && scope.row.publishSuccessCount == 0">
                <el-button icon="el-icon-position" circle @click="publish(scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip :content="$t('videoItem.clipDetails')" v-if="!isFailed(scope.row) && splitStatus(scope.row) === $t('videoItem.clipSuccess')">
                <el-button icon="el-icon-more" circle @click="splitDetail(scope.row)"></el-button>
              </el-tooltip>
              <span  v-if="!isFailed(scope.row)" style="display: inline-block;" class="ml10 mr10">
                <video-button :type="2" :video="scope.row" :tip="$t('file.filePreview')"></video-button>
              </span >
              <el-tooltip :content="$t('edit')" v-if="!isFailed(scope.row)">
                <el-button icon="el-icon-edit" circle @click="$emit('edit-video',scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip :content="$t('file.download')" v-if="!isFailed(scope.row) && scope.row.type !== 'system'">
                <el-button icon="el-icon-download" circle @click="downloadFile(scope.row.url)"></el-button>
              </el-tooltip>
              <el-tooltip :content="$t('copyLink')" v-if="!isFailed(scope.row) && scope.row.type !== 'system'">
                <el-button icon="el-icon-link" circle @click="copyLink(scope.row)"></el-button>
              </el-tooltip>
              <el-tooltip :content="$t('remove')" v-if="scope.row.type !== 'system'">
                <el-button icon="el-icon-delete" circle @click="$emit('delete-video',scope.row)"></el-button>
              </el-tooltip>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>

     <!-- 中间：视频信息 -->
     <!-- <div class="middle-section">
      <div class="video-created font14" >{{ video.createTime }}</div>
      <div class="video-status font14">{{ statusMessage }}</div>
      <div class="video-split font14">{{ splitStatus }}</div> -->
    <!-- </div> -->

    <!-- 右侧：操作按钮 -->
    <!-- <div class="right-section" v-if="(!isSelectionMode)&&(!loading)">

    </div> -->
  </div>
</template>

<script>
import { copyTextToClipboard } from '@/utils/copyLink.js'; // 根据你的项目路径调整导入路径
import VideoButton from '../VideoButton/VideoButton.vue';
export default {
  name: 'VideoItem',
  components: {
    VideoButton
  },
  props: {
    videoList: {
      type: Array,
      default: () => []
    },
    isSelectionMode:{
      type: Boolean,
      default: false
    },
    type:{
      type: Number,
      default: 0 //默认为0，3-成片页面
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: this.videoList,
      addingTag: false,
      newTag: '',
      // selected: false,  // 添加选中状态
    };
  },
  watch: {
    videoList: {
      handler(newVal) {
        this.list = newVal;
      },
      immediate: true
    }
  },
  computed: {
    // loading() {
    //   return this.video.eraseVideoSubtitlesStatus === 1 || this.video.mergeVideoFaceStatus === 1;
    // },

    // statusMessage() {
    //   const statusMessages = [];
    //   const { eraseVideoSubtitlesStatus, mergeVideoFaceStatus } = this.video;

    //   if (eraseVideoSubtitlesStatus === 1 || mergeVideoFaceStatus === 1) {
    //     return this.$t('videoItem.generating');
    //   }
    //   if (eraseVideoSubtitlesStatus === 0 && mergeVideoFaceStatus === 0) {
    //     return ''; // 默认状态，不需要显示任何消息
    //   }
    //   // 拼接其他状态的消息
    //   if (eraseVideoSubtitlesStatus === 2) {
    //     statusMessages.push(this.$t('videoItem.removeSubtitleSuccess'));
    //   } else if (eraseVideoSubtitlesStatus === 3) {
    //     statusMessages.push(this.$t('videoItem.removeSubtitleFail'));
    //   }
    //   if (mergeVideoFaceStatus === 2) {
    //     statusMessages.push(this.$t('videoItem.aiFaceSwapSuccess'));
    //   } else if (mergeVideoFaceStatus === 3) {
    //     statusMessages.push(this.$t('videoItem.aiFaceSwapFail'));
    //   }
    //   return statusMessages.join(' ');
    // },

  },
  methods: {
    nameClick(row) {
      if(row.documentType === 'folder'){
        this.$emit('open-folder', row)
      }else{

      }
    },
    // 判断系统文件夹是否可选中
    checkSelectable(row){
      if(this.userInfo.userType ==="admin")
        return true
      return row.type !== "system";
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id) || []
      // this.labels = selection.map(item => item.label)
      // this.single = selection.length!==1
      // this.multiple = !selection.length
      this.$emit('select-multiple', selection)
    },
    isFailed(row) {
      const { eraseVideoSubtitlesStatus, mergeVideoFaceStatus } = row;
      return (
        (eraseVideoSubtitlesStatus === 3 && mergeVideoFaceStatus === 3) ||
        (eraseVideoSubtitlesStatus === 0 && mergeVideoFaceStatus === 3) ||
        (eraseVideoSubtitlesStatus === 3 && mergeVideoFaceStatus === 0)
      );
    },
    splitStatus(row){
      const { splitVideoPartsStatus } = row;
      if(splitVideoPartsStatus === 'QUEUING'
        || splitVideoPartsStatus === 'PROCESSING'){
        return this.$t('videoItem.clipping')
      }else if(splitVideoPartsStatus === 'PROCESS_SUCCESS'){
        return this.$t('videoItem.clipSuccess')
      }else if(splitVideoPartsStatus === 'PROCESS_FAILED'
        ||splitVideoPartsStatus === 'TIMEOUT_FAILED'
        ||splitVideoPartsStatus === 'LIMIT_RETRY_FAILED'){
        return this.$t('videoItem.clipFail')
      }else{
        return ''
      }
    },
    //拆条详情
    splitDetail(video){
      this.$router.push({ path: `/stripping/${video.id}`,query: { id: video.id } });
    },
    //去发布
    publish(video){
      this.$router.push({ path: `/issueVideo/${video.id}`,query: { url: video.url,productId:video.id } });
    },

    copyLink(row){
      copyTextToClipboard(row.url,this.$message);
    },
    // toggleSelection() {
    //   this.video.selected = !this.video.selected;
    //   console.log('this.video.selected',this.video.selected);
    //   this.$emit('toggle-selection', this.video.selected, this.video);
    // }
  }
};
</script>

<style scoped>
.left-section {
  position: relative;
}
.video-cover {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  object-fit: cover; /* 保持比例，裁剪多余部分 */
}
.material-name{
  color: #05082c;
}
.material-name:hover{
  color: #00AAFF;
  cursor: pointer;
}

.middle-section {
  flex: 1;
  margin-left: 10px;
}

.video-name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
.video-description{
  font-size: 14px;
  margin-bottom: 5px;
  color: #666;
}

.font14 {
  font-size: 14px;
}

.video-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.tag-input {
  width: auto;
  margin-right: 5px;
}

.video-created {
  color: #999;
}
.video-status{
  color: red
}
.video-split{
  color: blue
}

.right-section {
  display: flex;
}

.right-section .el-button {
  margin-left: 5px;
}

/* Hover样式 */
.el-button .el-tooltip__popper.is-dark {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.img-forder-list{
  width: 32px;
  height: 27px;
}
</style>
