import request from '@/utils/request'
// 查询微信交易记录
const baseApi = '/mall/mallWxOrder'
// 列表
export function listMallWxOrder(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}

// 详情
export function getMallWxOrder(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'get'
  })
}

// 新增
export function addMallWxOrder(data) {
  return request({
    url: baseApi,
    method: 'post',
    data: data
  })
}

// 修改
export function updateMallWxOrder(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}

// 删除
export function delMallWxOrder(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'delete'
  })
}
