var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "selectAccountDialog" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "publicDialog",
          attrs: {
            visible: _vm.accountVisible,
            width: "65%",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.accountVisible = $event
            },
          },
        },
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "headerFlex" }, [
              _c("div", { staticClass: "headerTitle" }, [
                _vm._v(_vm._s(_vm.$t("selectAccount.choose"))),
              ]),
              _c("i", {
                staticClass: "el-icon-close",
                on: { click: _vm.handleClose },
              }),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "topTitle" },
            [
              _c(
                "el-input",
                {
                  staticClass: "input-with-select",
                  attrs: {
                    placeholder: _vm.$t("selectAccount.searchPlaceholder"),
                  },
                  model: {
                    value: _vm.searchValue,
                    callback: function ($$v) {
                      _vm.searchValue = $$v
                    },
                    expression: "searchValue",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px" },
                      attrs: {
                        slot: "prepend",
                        placeholder: _vm.$t("tip.select"),
                      },
                      slot: "prepend",
                      model: {
                        value: _vm.platform,
                        callback: function ($$v) {
                          _vm.platform = $$v
                        },
                        expression: "platform",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: _vm.$t("selectAccount.allPlatforms"),
                          value: "",
                        },
                      }),
                      _vm._l(_vm.dict.type.social_platform, function (dict) {
                        return _c("el-option", {
                          key: dict.value,
                          attrs: { label: dict.label, value: dict.value },
                        })
                      }),
                    ],
                    2
                  ),
                  _c("i", {
                    staticClass: "iconfont icon-sousuotubiao",
                    attrs: { slot: "append" },
                    on: { click: _vm.searchAccount },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mediaList" },
            [
              _c(
                "el-tabs",
                {
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                _vm._l(_vm.socialPlatformList, function (item, index) {
                  return _c(
                    "el-tab-pane",
                    { key: index, attrs: { name: item.name } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "tabItem",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [
                          _c("i", { class: ["iconfont", item.icon] }),
                          _vm.activeName === item.name
                            ? _c("span", { staticClass: "mediaLabel" }, [
                                _vm._v(_vm._s(item.label)),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          staticClass: "tabContent",
                        },
                        _vm._l(_vm.options, function (item) {
                          return _c(
                            "div",
                            { key: item.name, staticClass: "boxItem" },
                            [
                              _c(
                                "div",
                                { staticClass: "leftTitle" },
                                [
                                  _c("el-avatar", {
                                    attrs: { size: 28, src: item.userImage },
                                  }),
                                  _c("div", { staticClass: "mediaName" }, [
                                    _vm._v(_vm._s(item.displayName)),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  class: [
                                    "selectable-button",
                                    { selected: item.isSelected },
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleSelect(item.id)
                                    },
                                  },
                                },
                                [
                                  item.isSelected
                                    ? _c("div", { staticClass: "checkmark" }, [
                                        _c("i", {
                                          staticClass: "iconfont icon-dagou",
                                        }),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "selectList" }, [
            _c("div", { staticClass: "selectNum" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("selectAccount.selectedAccounts")) + ": "
              ),
              _c("span", [_vm._v(_vm._s(_vm.filterSelectList().length))]),
            ]),
            _c(
              "div",
              { staticClass: "selectListContent" },
              _vm._l(_vm.filterSelectList(), function (item, index) {
                return _c("div", { key: index, staticClass: "boxItem" }, [
                  _c(
                    "div",
                    { staticClass: "leftTitle" },
                    [
                      _c("el-avatar", {
                        attrs: { size: 28, src: item.userImage },
                      }),
                      _c("div", { staticClass: "mediaName" }, [
                        _vm._v(_vm._s(item.displayName) + "("),
                        _c("i", {
                          class: ["iconfont", _vm.filterIcon(item.platform)],
                        }),
                        _vm._v(")"),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "cancelBox",
                          on: {
                            click: function ($event) {
                              return _vm.toggleSelect(item.id)
                            },
                          },
                        },
                        [_vm._v("x")]
                      ),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { staticClass: "cancelBtn", on: { click: _vm.handleClose } },
                [_vm._v(_vm._s(_vm.$t("cancel")))]
              ),
              _c(
                "el-button",
                {
                  staticClass: "submitBtn",
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v(_vm._s(_vm.$t("confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }