<template>
  <div class="flex-column">
    <div class="flex-row-center f-between">
      <span>{{$t('aiSubtitle.setup')}}</span>
      <div>
        {{ settings.open ? $t('aiSubtitle.show') : $t('aiSubtitle.hide') }}
        <el-switch v-model="settings.open"
          style="margin: 10px;" @change="handleItemUpdated"
        ></el-switch>
      </div>
    </div>
    <div class="subtitle-title">{{$t('aiSubtitle.font')}}</div>
    <el-select v-model="settings.font" :placeholder="$t('aiSubtitle.font')" @change="handleItemUpdated">
      <el-option
        v-for="dict in dict.type.font_typeface"
        :key="dict.value"
        :label="dict.label"
        :value="dict.value"
      />
    </el-select>
    <div class="subtitle-title">{{$t('aiSubtitle.textColor')}}</div>
    <el-color-picker v-model="settings.fontColor" @change="handleItemUpdated"></el-color-picker>
    <div class="subtitle-title">{{$t('aiSubtitle.fontSize')}}</div>
    <el-radio-group v-model="settings.fontSizeType" @input="fontSizeChange">
      <el-radio label="small">{{ $t('subtitle.small') }}</el-radio>
      <el-radio label="normal">{{ $t('subtitle.normal') }}</el-radio>
      <el-radio label="big">{{ $t('subtitle.big') }}</el-radio>
    </el-radio-group>
    <div class="subtitle-title">{{$t('aiSubtitle.style')}}</div>
    <el-checkbox-group v-model="settings.FontFaces" @change="changeFontFace">
      <el-checkbox label="Italic">{{ $t('subtitle.italic') }}</el-checkbox>
      <el-checkbox label="Bold">{{ $t('subtitle.blod') }}</el-checkbox>
      <el-checkbox label="Underline">{{ $t('subtitle.underline') }}</el-checkbox>
    </el-checkbox-group>
    <div class="subtitle-title">{{$t('aiSubtitle.position')}}</div>
    <el-radio-group v-model="settings.position" @input="logoChange">
      <el-radio label="top">{{ $t('subtitle.top') }}</el-radio>
      <el-radio label="center">{{ $t('subtitle.center') }}</el-radio>
      <el-radio label="bottom">{{ $t('subtitle.bottom') }}</el-radio>
    </el-radio-group>
    <div class="subtitle-title flex-row-center">{{$t('aiTitle.decorativeText')}}
      <div class="flex-row-center f-grow f-between">
        <el-switch v-model="settings.openEffectColorStyle" style="margin: 10px;"  @change="switchEffectUpdated"></el-switch>
        <el-button type="text" v-if="settings.openEffectColorStyle" @click="isExpansion = !isExpansion">{{ isExpansion ? $t('collapse') : $t('expansion') }}</el-button>
      </div>
    </div>
    <div class="image-container" v-if="settings.openEffectColorStyle">
      <img v-for="(item, index) in styleList"
        :key="index"
        :src="item.imgUrl"
        :class="{ selected: settings.effectColorStyle === item.effectColorStyle }"
        @click="selectEffectItem(item)"
      />
    </div>
  </div>
</template>

<script>
export default {
  dicts: [
    'font_typeface'
  ],
  components: {},
  props: {
    value: {
      type: Object,
      default: () => ({}) // 默认值为空对象
    },
    effectColorStyleList:{
      type: Array,
      default: () => []
    },
    videoDuration: {
      type: Number,
      default: 0
    },
  },
  watch: {
    value: {
      handler(val) {
        // 合并 settings 的初始值与传入的 value 值，保留初始化内容
        this.settings = {
          ...this.settings,
          ...val
        };
      },
      deep: true
    }
  },
  data() {
    return {
      settings: {
        open: true,
        FontFaces: [],
        fontFace: {
          Italic: false,
          Underline: false,
          Bold: false
        },
        Y: 0.9,
        position: "bottom",
        alignment: "BottomCenter",
        font:"SimSun",
				fontColor:"#ffffff",
				fontSize: 53,
        fontSizeType: "normal",
        openEffectColorStyle:false,
        effectColorStyle:'',//花字
      },
      isExpansion: false, //是否展开花字
    };
  },
  computed: {
    styleList(){
      return this.isExpansion? this.effectColorStyleList : this.effectColorStyleList.slice(0, 6)
    }
  },
  methods: {
    //选中花字
    selectEffectItem(item){
      this.settings.effectColorStyle = item.effectColorStyle
      this.handleItemUpdated()
    },
    switchEffectUpdated(){
      if(!this.settings.openEffectColorStyle){
        this.settings.effectColorStyle = ''
      }
      this.handleItemUpdated()
    },
    //更新数据到上个页面
    handleItemUpdated() {
      this.$emit('item-updated', this.settings);
    },
    fontSizeChange() {
      if (this.settings.fontSizeType === "small") {
        effect.fontSize = 37
      } else if (this.settings.fontSizeType === "big") {
        effect.fontSize = 70
      } else {
        effect.fontSize = 53
      }
      this.handleItemUpdated()
    },
    logoChange() {
      switch (this.settings.position) {
        case 'top':
          this.settings.Y = 0.1;
          this.settings.alignment = "TopCenter"
          break;
        case 'center':
          this.settings.Y = 0.5;
          this.settings.alignment = "CenterCenter"
          break;
        case 'bottom':
          this.settings.Y = 0.9
          this.settings.alignment = "BottomCenter"
          break;
        default:
          this.settings.Y = 0.9;
          this.settings.alignment = "BottomCenter"
          break;
      }
      this.handleItemUpdated()
    },
    changeFontFace() {
        let fontFace = {};
        if (Array.isArray(this.settings.FontFaces)) {
          fontFace.Bold = this.settings.FontFaces.includes('Bold');
          fontFace.Italic = this.settings.FontFaces.includes('Italic');
          fontFace.Underline = this.settings.FontFaces.includes('Underline');
        }
        this.settings.fontFace = fontFace;
        this.handleItemUpdated()
    },
  }
};
</script>

<style scoped>
.subtitle-title{
  margin: 5px 0;
}

.selected {
  border: 1px solid blue;
  border-radius: 5px;
}
.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* 图片间距 */
}

.image-container img {
  width: calc(32% - 5px); /* 两个图片一排，减去间距的一半 */
  box-sizing: border-box; /* 包含 padding 和 border */
  padding: 5px;
}
</style>
