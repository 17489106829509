<template>
  <div class="audio-item" :class="{ 'selected': isSelectionMode && audio.selected }" @click.stop="toggleSelection">
    <!-- 左侧：音频封面 -->
    <div class="left-section">
      <svg-icon class="audio-cover" icon-class="audio"/>
    </div>
    <!-- 中间：音频信息 -->
    <div class="middle-section">
      <div class="audio-name">{{ audio.name }}</div>
      <div class="audio-description">{{ audio.description }}</div>
      <div class="audio-details">
        <div class="audio-tags">
          <el-tag
            v-for="(tag, index) in audio.labels"
            :key="index"
            style="margin-right: 5px; margin-bottom: 5px;"
          >
            {{ tag }}
          </el-tag>
        </div>
        <div class="audio-created">{{ audio.createTime }}</div>
      </div>
    </div>

    <!-- 右侧：操作按钮 -->
    <div class="right-section" v-if="!isSelectionMode">
      <el-tooltip :content="$t('play')">
        <el-button icon="el-icon-video-play" circle @click="playAudio"></el-button>
      </el-tooltip>
      <el-tooltip :content="$t('edit')">
        <el-button icon="el-icon-edit" circle @click="$emit('edit-audio', audio)"></el-button>
      </el-tooltip>
      <el-tooltip :content="$t('file.download')">
        <el-button icon="el-icon-download" circle @click="downloadFile(audio.url)"></el-button>
      </el-tooltip>
      <el-tooltip :content="$t('copyLink')">
        <el-button icon="el-icon-link" circle @click="copyLink"></el-button>
      </el-tooltip>
      <el-tooltip :content="$t('remove')">
        <el-button icon="el-icon-delete" circle @click="deleteAudio"></el-button>
      </el-tooltip>
    </div>

    <!-- 弹窗播放音频 -->
    <el-dialog :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <audio controls :src="audio.url" class="audio-player" ref="audioPlayer"></audio>
    </el-dialog>
  </div>
</template>

<script>
import { copyTextToClipboard } from '@/utils/copyLink.js'; // 根据你的项目路径调整导入路径

export default {
  name: 'AudioItem',
  props: {
    audio: {
      type: Object,
      required: true
    },
    isSelectionMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false,
      audioElement: null,  // 添加一个变量用于保存音频元素
    };
  },
  methods: {
    playAudio() {
      this.dialogVisible = true;
      // 播放音频时获取音频元素
      this.$nextTick(() => {
        this.audioElement = this.$refs.audioPlayer;
        this.audioElement.play();
      });
    },
    handleClose() {
      this.dialogVisible = false;
      // 关闭弹窗时停止音频播放
      if (this.audioElement) {
        this.audioElement.pause();
        this.audioElement.currentTime = 0; // 可选：将音频播放位置重置为起始位置
      }
    },
    copyLink() {
      copyTextToClipboard(this.audio.url, this.$message);
    },
    deleteAudio() {
      this.$emit('delete-audio', this.audio);
    },
    toggleSelection() {
      this.audio.selected = !this.audio.selected;
      this.$emit('toggle-selection', this.audio.selected, this.audio);
    }
  }
};
</script>

<style scoped>
.audio-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}
.audio-item.selected {
  border-color: blue; /* 选中时的样式示例 */
}
.left-section {
  position: relative;
}
.audio-cover {
  width: 120px;
  height: 120px;
  cursor: pointer;
}
.middle-section {
  flex: 1;
  margin-left: 10px;
}
.audio-name {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
.audio-description {
  font-size: 14px;
  margin-bottom: 5px;
  color: #666;
}
.audio-details {
  font-size: 14px;
}
.audio-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 5px;
}
.audio-created {
  color: #999;
}
.right-section {
  display: flex;
}
.right-section .el-button {
  margin-left: 5px;
}
.audio-player {
  width: 100%;
}
</style>
