var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        "append-to-body": "",
        title: _vm.$t("basicInfo.materia"),
        size: "65%",
        visible: _vm.visible,
        "with-header": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("Material_library", {
        ref: "materialLibrary",
        attrs: { skipInit: true },
        on: {
          selectionConfirmed: _vm.handleSelectionConfirmed,
          cancel: _vm.handleCancel,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }