<template>
  <div>
    <p>{{ $t('profile.loading') }}</p>
  </div>
</template>

<script>
import { refreshProfile } from '../../api/account/profile';

export default {
  name: 'Callback',
  mounted() {
    // 当页面加载完成时执行
    this.refreshProfile()
    this.closeWindow();
  },
  methods: {
    refreshProfile(){
      refreshProfile().then(() => {
      })
    },
    closeWindow(msg) {
      // 关闭当前页面
      window.opener.postMessage(msg, window.location.origin);// 使用 '*' 允许所有来源（仅用于测试，不推荐用于生产环境）
      window.close();
    },
  }
}
</script>

<style scoped>
</style>
