<template>
  <div class="chat-input">
    <el-tooltip :content="$t('chat.end')" v-if="isRecording">
      <el-button
        type="text"
        circle
        icon="el-icon-turn-off-microphone"
        @click="toggleRecording"
        :disabled="disabled"
      >
      </el-button>
    </el-tooltip>
    <span v-if="isRecording" class="ml5 mr5">
      {{ formattedRecordingTime }}
    </span>
    <el-tooltip :content="$t('chat.start')" v-else>
      <el-button
        type="text"
        circle
        icon="el-icon-microphone"
        @click="toggleRecording"
        :loading="isRecording"
        :disabled="disabled"
      >
      </el-button>
    </el-tooltip>
    <el-tooltip :content="$t('chat.cancel')" v-if="isRecording">
      <el-button
        type="text"
        icon="el-icon-circle-close"
        @click="cancelRecording"
      >
      </el-button>
    </el-tooltip>
    <!-- type="textarea" -->
    <el-input
      style="width: 95%;"
      v-model="message"
      :placeholder="disabled ? $t('ai.workHard') : $t('ai.tipInstruct')"
      @keyup.enter="sendMessage"
      :disabled="disabled || isRecording"
      ></el-input>
    <!-- <el-button
      type="primary"
      @click="sendMessage"
      :disabled="disabled || isRecording"
    >
      {{ $t('ai.send') }}
    </el-button> -->
    <div class="sendMessage" :style="{cursor: disabled || isRecording ? 'not-allowed' : 'pointer'}" @click="sendMessage">
      <i class="iconfont icon-telegram-normal"></i>
    </div>
  </div>
</template>

<script>
import {getToken} from "@/utils/auth";
import Recorder from 'recorder-js';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      message: '',
      isRecording: false,
      mediaRecorder: null,
      recorder: null,
      audioChunks: [],
      mimeType: '',
      uploadFileUrl: process.env.VUE_APP_BASE_API + "/resource/oss/upload", // 上传文件服务器地址
      recordingTime: 0, // 录制的秒数
      recordingTimer: null, // 计时器
      isCancel: false // 是否取消录制
    };
  },
  computed: {
    // 格式化录制时间为 mm:ss 格式
    formattedRecordingTime() {
      const minutes = Math.floor(this.recordingTime / 60).toString().padStart(2, '0');
      const seconds = (this.recordingTime % 60).toString().padStart(2, '0');
      return `${minutes}:${seconds}`;
    }
  },
  methods: {
    sendMessage() {
      if (this.message.trim()) {
        this.$emit('send', this.message);
        this.message = '';
      }
    },
    async toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        await this.startRecording();
      }
    },
    async startRecording() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const recorder = new Recorder(audioContext);

        recorder.init(stream);
        recorder.start().then(() => {
          this.isRecording = true;
          this.startTimer();
        });

        this.recorder = recorder;
      } catch (error) {
        if (err.name === 'NotAllowedError') {
          alert(this.$t('chat.micPermission'));
        } else if (err.name === 'NotFoundError') {
          alert(this.$t('chat.noMic'));
        } else {
          console.error('录音启动失败:', error);
          alert(this.$t('chat.micError'));
        }
      }
    },
    stopRecording() {
      this.recorder.stop().then(({blob}) => {
        // 上传 Blob
        this.audioChunks.push(blob)
        this.uploadAudio(blob);
        this.isRecording = false;
        this.stopTimer();
      });
    },
    // async startRecording() {
    //   if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
    //     alert('您的浏览器不支持语音录制功能');
    //     return;
    //   }
    //   try {
    //     const stream = await navigator.mediaDevices.getUserMedia({audio: true});
    //     // this.mediaRecorder = new MediaRecorder(stream, {mimeType: 'audio/webm'});
    //     const mimeType = MediaRecorder.isTypeSupported('audio/mp4') ? 'audio/mp4' : 'audio/webm';
    //     this.mimeType = mimeType

    //     this.mediaRecorder = new MediaRecorder(stream, {mimeType});
    //     // this.mediaRecorder = new MediaRecorder(stream, {mimeType: 'audio/ogg; codecs=opus'});
    //     this.mediaRecorder.ondataavailable = (event) => {
    //       this.audioChunks.push(event.data);
    //     };
    //     this.mediaRecorder.onstop = this.uploadAudio;
    //     this.mediaRecorder.start();
    //     this.isRecording = true;
    //     this.startTimer();
    //   } catch (error) {
    //     if (err.name === 'NotAllowedError') {
    //     alert(this.$t('chat.micPermission'));
    //   } else if (err.name === 'NotFoundError') {
    //     alert(this.$t('chat.noMic'));
    //   } else {
    //     console.error('录音启动失败:', error);
    //   }
    //   }
    // },
    // stopRecording() {
    //   if (this.mediaRecorder) {
    //     this.mediaRecorder.stop();
    //     this.isRecording = false;
    //     this.stopTimer();
    //   }
    // },
    cancelRecording() {
      if (this.mediaRecorder) {
        this.isCancel = true
        this.audioChunks = []; // 清空已录制的数据，不进行上传
        this.mediaRecorder.stop();
        this.isRecording = false;
        this.stopTimer();
        console.log('录音已取消');
      }
    },
    // 开始录制计时
    startTimer() {
      this.recordingTime = 0;
      this.recordingTimer = setInterval(() => {
        this.recordingTime += 1;
      }, 1000);
    },
    // 停止录制计时
    stopTimer() {
      clearInterval(this.recordingTimer);
      this.recordingTimer = null;
    },
    uploadAudio() {
      if (this.audioChunks.length === 0 || this.isCancel) return; // 如果录音被取消，避免上传
      // const mimeType = MediaRecorder.isTypeSupported('audio/mp4') ? 'audio/mp4' : 'audio/webm';
      const mimeType =  MediaRecorder.isTypeSupported('audio/wav') ? 'audio/wav' : 'audio/mpeg'; // 使用更广泛支持的格式
      const audioBlob = new Blob(this.audioChunks, {type: 'audio/mpeg'});
      // const reader = new FileReader();
      // reader.readAsDataURL(audioBlob);
      // reader.onloadend = () => {
      //   const base64data = reader.result;
      //   this.$emit('playAudioLoca', base64data); // 使用 base64 格式播放音频
      // };
      const audioUrl = URL.createObjectURL(audioBlob);
      this.$emit('playAudioLoca', audioUrl);
      // const fileName = 'voiceAudio.webm'; // 设置你想要的文件名
      const fileName = 'voiceAudio.mp3'; // 设置你想要的文件名
      const audioFile = new File([audioBlob], fileName, { type: mimeType});

      this.audioChunks = [];
      const formData = new FormData();
      formData.append('file', audioFile);

      // this.$emit('setLoading');
      // 上传录音到服务器
      fetch(this.uploadFileUrl, {
        method: 'POST',
        headers: {
          'authorization': 'Bearer ' + getToken(),
        },
        body: formData
      })
        .then(response => response.json())
        .then(data => {
          console.log('音频上传成功:', data);
          this.$emit('sendUrl', data.data.url);
        })
        .catch(error => {
          console.error('音频上传失败:', error);
        });
    },
    // uploadAudio() {
    //   if (this.audioChunks.length === 0) return; // 如果录音被取消，避免上传

    //   const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
    //   this.audioChunks = [];

    //   // 创建 FileReader 对象以读取 Blob 数据并转换为 Base64
    //   const reader = new FileReader();
    //   reader.readAsDataURL(audioBlob);

    //   reader.onloadend = () => {
    //     const base64Audio = reader.result.split(',')[1]; // 获取 Base64 编码后的数据

    //     // 构建 JSON 对象
    //     const payload = {
    //       file: base64Audio, // 将 Base64 编码的音频作为字段传递
    //       fileName: `audio_${Date.now()}.webm`, // 可选字段，用于指定文件名
    //     };

    //     // 上传录音到服务器
    //     fetch(this.uploadFileUrl, {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json' // 设置请求头为 application/json
    //       },
    //       body: JSON.stringify(payload), // 将 JSON 对象转换为字符串
    //     })
    //       .then(response => response.json())
    //       .then(data => {
    //         console.log('音频上传成功:', data);
    //       })
    //       .catch(error => {
    //         console.error('音频上传失败:', error);
    //       });
    //   };

    //   reader.onerror = (error) => {
    //     console.error('读取音频文件失败:', error);
    //   };
    // }
  }
};
</script>

<style lang="scss">
.chat-input {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 10px;
  width: 95%;
  /* width: 1592px; */
  height: 64px;
  background: #DAE5F0;
  border-radius: 12px;
  padding: 0 16px;
}

.chat-input .el-input__inner {
  // width: 90%;
  border: none;
  background-color: transparent;
  caret-color: black;
}

.el-input.is-disabled .el-input__inner {
  border: none;
  background-color: transparent;
  caret-color: black;
  cursor: not-allowed;
}

.el-input__inner::placeholder {
  font-family: PingFang-SC, PingFang-SC;
  font-size: 14px;
  color: #99AECA;
  line-height: 20px;
  text-align: left;
  font-style: normal;
}

.sendMessage {
  width: 44px;
  height: 44px;
  background: linear-gradient( 180deg, #5896FF 0%, #434EFF 100%);
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .icon-telegram-normal {
    color: #fff;
    font-size: 18px;
  }
}
</style>
