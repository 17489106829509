<template>
  <el-dialog
    :visible.sync="visible"
    :title="$t('tip.tip')"
    :before-close="handleBeforeClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
    <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="100px">
      <span>{{$t('mall.emailNote')}}</span>
      <el-form-item :label="$t('mall.email')" prop="email">
        <el-input v-model="form.email"  style="width:100%;"/>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">{{$t('cancel')}}</el-button>
      <el-button type="primary" @click="handleConfirm">{{$t('confirm')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      form: {
        email: '',
      },
      rules: {
        email: [
          { required: true, message: this.$t('tip.input'), trigger: 'blur' },
          {
            type: 'email',
            message: this.$t('userInfo.tipCorrectEmail'), // 提示信息可以使用多语言支持
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    handleBeforeClose(done) {
      this.$emit('update:visible', false);
      done();
    },
    handleCancel() {
      this.$emit('update:visible', false);
    },
    handleConfirm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.$emit('confirm', this.form);
          this.$emit('update:visible', false);
        }
      })
    }
  }
};
</script>

<style scoped>
.dialog-footer {
  text-align: right;
}
</style>
