var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "video-item",
      class: { selected: _vm.isSelectionMode && _vm.video.selected },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.toggleSelection($event)
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "left-section",
        },
        [
          _c("video", {
            staticClass: "video-cover",
            attrs: { src: _vm.video.url, alt: "video" },
          }),
          _vm.video.videoLength && _vm.video.videoLength > 0
            ? _c("span", { staticClass: "video-duration" }, [
                _vm._v(_vm._s(_vm.secondsFormatTime(_vm.video.videoLength))),
              ])
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "middle-section" }, [
        _c("div", { staticClass: "video-name" }, [
          _vm._v(_vm._s(_vm.video.name)),
        ]),
        _c("div", { staticClass: "video-description" }, [
          _vm._v(_vm._s(_vm.video.description)),
        ]),
        _c(
          "div",
          { staticClass: "video-tags font14" },
          _vm._l(_vm.video.labels, function (tag, index) {
            return _c(
              "el-tag",
              {
                key: index,
                staticStyle: { "margin-right": "5px", "margin-bottom": "5px" },
              },
              [_vm._v(" " + _vm._s(tag) + " ")]
            )
          }),
          1
        ),
        _c("div", { staticClass: "video-created font14" }, [
          _vm._v(_vm._s(_vm.video.createTime)),
        ]),
        _c("div", { staticClass: "video-status font14" }, [
          _vm._v(_vm._s(_vm.statusMessage)),
        ]),
        _c("div", { staticClass: "video-split font14" }, [
          _vm._v(_vm._s(_vm.splitStatus)),
        ]),
      ]),
      !_vm.isSelectionMode && !_vm.loading
        ? _c(
            "div",
            { staticClass: "right-section" },
            [
              _vm.type == 3 && _vm.video.publishSuccessCount == 0
                ? _c(
                    "el-tooltip",
                    { attrs: { content: _vm.$t("videoManager.publish") } },
                    [
                      _c("el-button", {
                        attrs: { icon: "el-icon-position", circle: "" },
                        on: {
                          click: function ($event) {
                            return _vm.publish(_vm.video)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isFailed &&
              _vm.splitStatus === _vm.$t("videoItem.clipSuccess")
                ? _c(
                    "el-tooltip",
                    { attrs: { content: _vm.$t("videoItem.clipDetails") } },
                    [
                      _c("el-button", {
                        attrs: { icon: "el-icon-more", circle: "" },
                        on: {
                          click: function ($event) {
                            return _vm.splitDetail(_vm.video)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isFailed
                ? _c(
                    "span",
                    {
                      staticClass: "ml5",
                      staticStyle: { display: "inline-block" },
                    },
                    [
                      _c("video-button", {
                        attrs: {
                          url: _vm.video.url,
                          tip: _vm.$t("file.filePreview"),
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isFailed
                ? _c(
                    "el-tooltip",
                    { attrs: { content: _vm.$t("edit") } },
                    [
                      _c("el-button", {
                        attrs: { icon: "el-icon-edit", circle: "" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("edit-video", _vm.video)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isFailed
                ? _c(
                    "el-tooltip",
                    { attrs: { content: _vm.$t("file.download") } },
                    [
                      _c("el-button", {
                        attrs: { icon: "el-icon-download", circle: "" },
                        on: {
                          click: function ($event) {
                            return _vm.downloadFile(_vm.video.url)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isFailed
                ? _c(
                    "el-tooltip",
                    { attrs: { content: _vm.$t("copyLink") } },
                    [
                      _c("el-button", {
                        attrs: { icon: "el-icon-link", circle: "" },
                        on: { click: _vm.copyLink },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-tooltip",
                { attrs: { content: _vm.$t("remove") } },
                [
                  _c("el-button", {
                    attrs: { icon: "el-icon-delete", circle: "" },
                    on: { click: _vm.deleteVideo },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }