var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pruning" }, [
    _c(
      "div",
      { staticClass: "pruning-container" },
      [
        _c("video", {
          ref: "video",
          attrs: { src: _vm.videoUrl, controls: "" },
          on: {
            timeupdate: _vm.updateCurrentTime,
            play: _vm.onPlay,
            pause: _vm.onPause,
          },
        }),
        _c("div", { staticClass: "video-controls" }, [
          _c("div", { staticClass: "flex-row-center " }, [
            _c("span", { staticClass: "video-time mr16" }, [
              _vm._v(_vm._s(_vm.secondsFormatTime(_vm.currentTime))),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentTime,
                  expression: "currentTime",
                },
              ],
              ref: "progressBar",
              staticClass: "progress-bar",
              attrs: { type: "range", max: _vm.videoDuration, step: "0.001" },
              domProps: { value: _vm.currentTime },
              on: {
                click: _vm.seekVideo,
                __r: function ($event) {
                  _vm.currentTime = $event.target.value
                },
              },
            }),
            _c("span", { staticClass: "video-time ml16" }, [
              _vm._v(_vm._s(_vm.secondsFormatTime(_vm.videoDuration))),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "flex-row-center mt16" },
            [
              !_vm.isPlaying
                ? _c("el-tooltip", { attrs: { content: _vm.$t("play") } }, [
                    _c(
                      "div",
                      {
                        staticClass: "video-play flex-center",
                        on: { click: _vm.togglePlay },
                      },
                      [_c("i", { staticClass: "iconfont icon-bofang1" })]
                    ),
                  ])
                : _c("el-tooltip", { attrs: { content: _vm.$t("pause") } }, [
                    _c(
                      "div",
                      {
                        staticClass: "video-play flex-center",
                        on: { click: _vm.togglePlay },
                      },
                      [_c("i", { staticClass: "iconfont icon-zanting" })]
                    ),
                  ]),
              _c(
                "el-tooltip",
                { attrs: { content: _vm.$t("basicInfo.mark") } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "splitAudio flex-center",
                      on: { click: _vm.setMarker },
                    },
                    [_c("i", { staticClass: "iconfont icon-fenge" })]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "progress-container" }, [
          _c("div", { staticClass: "progress-bar" }),
          _c(
            "div",
            { staticClass: "ticks" },
            _vm._l(_vm.totalTicks, function (tick) {
              return _c(
                "div",
                {
                  key: tick,
                  staticClass: "tick",
                  style: { left: (tick / _vm.videoDuration) * 100 + "%" },
                },
                [
                  _c("span", { staticClass: "tick-time" }, [
                    _vm._v(_vm._s(_vm.secondsFormatTime(tick))),
                  ]),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "markers" },
            _vm._l(_vm.markers, function (marker, index) {
              return _c("i", {
                key: index,
                staticClass: "iconfont icon-fenge2 marker",
                style: {
                  left:
                    "calc(" +
                    (marker.out / _vm.videoDuration) * 100 +
                    "% - 12px)",
                },
              })
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "mt16" }, [
          _c("span", { staticClass: "pruning-title" }, [
            _vm._v(_vm._s(_vm.$t("basicInfo.segment"))),
          ]),
          _c("span", { staticClass: "pruning-tip" }, [
            _vm._v(_vm._s(_vm.$t("basicInfo.selectSegments"))),
          ]),
        ]),
        _vm._l(_vm.markers, function (marker, index) {
          return _c("div", { key: index, staticClass: "pruning-item" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: marker.checked,
                  expression: "marker.checked",
                },
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(marker.checked)
                  ? _vm._i(marker.checked, null) > -1
                  : marker.checked,
              },
              on: {
                change: function ($event) {
                  var $$a = marker.checked,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(marker, "checked", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          marker,
                          "checked",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(marker, "checked", $$c)
                  }
                },
              },
            }),
            _vm._v(
              " " +
                _vm._s(_vm.formatTime(marker.in)) +
                " - " +
                _vm._s(_vm.formatTime(marker.out)) +
                " "
            ),
            index > 0
              ? _c("i", {
                  staticClass: "iconfont icon-delete",
                  on: {
                    click: function ($event) {
                      return _vm.removeMarker(index)
                    },
                  },
                })
              : _vm._e(),
          ])
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "drawer-footer" },
      [
        _c(
          "el-button",
          { staticClass: "cancelBtn", on: { click: _vm.cancel } },
          [_vm._v(_vm._s(_vm.$t("cancel")))]
        ),
        _c(
          "el-button",
          { staticClass: "submitBtn", on: { click: _vm.confirm } },
          [_vm._v(_vm._s(_vm.$t("confirm")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }