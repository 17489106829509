import request from '@/utils/request'

const baseApi = '/admin/label'

// 查询标签管理列表
export function listLabel(query) {
  return request({
    url: baseApi + '/list',
    method: 'get',
    params: query
  })
}

// 查询标签管理详细
export function getLabel(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'get'
  })
}

// 新增标签管理
export function addLabel(data) {
  return request({
    url: baseApi,
    method: 'post',
    data: data
  })
}

// 修改标签管理
export function updateLabel(data) {
  return request({
    url: baseApi,
    method: 'put',
    data: data
  })
}

// 删除标签管理
export function delLabel(id) {
  return request({
    url: baseApi + '/' + id,
    method: 'delete'
  })
}
