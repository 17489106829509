import request from '@/utils/request'
//AI混剪
const baseApi = '/admin/aiClip'

// 生成文案
export function generateText(data) {
  return request({
    url: baseApi + '/generateText',
    method: 'post',
    data: data
  })
}
// 根据文案生成段落
export function generateParagraph(data) {
  return request({
    url: baseApi + '/generateParagraph',
    method: 'post',
    data: data
  })
}
// 根据生成好的段落获取视频
export function analysisVideo(data) {
  return request({
    url: baseApi + '/analysisVideo',
    method: 'post',
    data: data
  })
}

// 最终生成
export function generateVideo(data) {
  return request({
    url: baseApi + '/generateVideo',
    method: 'post',
    data: data
  })
}
