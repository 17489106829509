var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex-row", attrs: { id: "root" } }, [
    _c("div", {
      ref: "player",
      staticStyle: { width: "800px", height: "450px" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }